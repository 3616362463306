import { Theme } from '@material-ui/core/styles';

type SnackbarReturn = { MuiSnackbarContent: { styleOverrides: { root: unknown } } }

export const Snackbar = (theme: Theme): SnackbarReturn => ({
  MuiSnackbarContent: {
    styleOverrides: {
      root: {},
    },
  },
});
