import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type SwitchReturn = { MuiSwitch: { styleOverrides: { switchBase: { '&.Mui-checked.Mui-disabled, &.Mui-disabled': { color: string }; '&:not(:.Mui-checked)': { color: string }; left: number; '&.Mui-disabled+.MuiSwitch-track': { backgroundColor: string; opacity: number }; right: string }; thumb: { boxShadow: string }; track: { backgroundColor: string; opacity: number } } } }
export const Switch = (theme: Theme): SwitchReturn => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow: theme.customShadows.z1,
        },
        track: {
          opacity: 1,
          backgroundColor: theme.palette.grey[500],
        },
        switchBase: {
          left: 0,
          right: 'auto',
          '&:not(:.Mui-checked)': {
            color: theme.palette.grey[isLight ? 100 : 300],
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.grey[isLight ? 400 : 600],
          },
          '&.Mui-disabled+.MuiSwitch-track': {
            opacity: 1,
            backgroundColor: `${theme.palette.action.disabledBackground} !important`,
          },
        },
      },
    },
  };
};
