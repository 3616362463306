import React, { useEffect } from 'react';
import {
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  CommonListHead,
  HeaderBreadcrumbs,
  NotFound,
  Scrollbar,
} from '../../../components';
import { EMPTY_LIST_MESSAGE, LINKS, TABLE_HEAD_LIST } from './constants';
import { useStore } from '../../../hooks';
import { HintItem } from './HintItem';
import { QueryParams } from '../../../types';

export const Hints: React.FC = observer((): JSX.Element => {
  const { id } = useParams<QueryParams>();

  const {
    knowledgeBasesModel: {
      fetchHintsByReclamationId,
      hints: {
        list,
      },
    },
  } = useStore();

  useEffect(() => {
    fetchHintsByReclamationId(Number(id));
  }, []);

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Hints List"
        links={LINKS}
      />
      <Card>
        <TableContainer>
          <Scrollbar>
            <Table>
              <CommonListHead headListData={TABLE_HEAD_LIST} align="center" />
              <TableBody>
                {list.length ? (
                  list.map((item) => (
                    <HintItem
                      key={item.id}
                      data={item}
                    />
                  ))) : (
                    <TableRow>
                      <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                        <NotFound message={EMPTY_LIST_MESSAGE} />
                      </TableCell>
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Card>
    </Container>
  );
});
