import React, { useCallback, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import {
  HeaderBreadcrumbs,
  Page,
} from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { Form } from '../Form';
import {
  QueryParams,
  SparePartValues,
} from '../../../types';
import { useRequest, useStore } from '../../../hooks';
import rules from '../Form/rules';
import { DEFAULT_SPARE_PART } from '../List/constants';
import { LINKS } from './constants';
import { StateWrapper } from '../../../components/common/StateWrapper';

export const UpdateSparePart: React.FC = observer((): JSX.Element => {
  const { id } = useParams<QueryParams>();
  const { push } = useHistory();

  const {
    spareParts: {
      getSparePartById,
      updateSparePart,
      fetchSparePartById,
    },
  } = useStore();

  const sparePart = getSparePartById(id);

  const form = useForm<SparePartValues>({
    defaultValues: DEFAULT_SPARE_PART,
    resolver: yupResolver(rules),
  });

  const handleUpdate = useCallback(async (values: SparePartValues): Promise<void> => {
    await updateSparePart(id, values);
    push(PATH_DASHBOARD.spareParts.list);
  }, [id]);

  const { isFetching, isError } = useRequest({
    action: fetchSparePartById,
    params: id,
    condition: !sparePart,
  });

  useEffect(() => {
    if (sparePart) {
      form.reset({
        name: sparePart.name,
        part: { id: sparePart?.part?.id },
        model: { id: sparePart?.model?.id },
        price: sparePart.price,
      });
    }
  }, [sparePart]);

  const isReady = !!sparePart && !isFetching;

  return (
    <Page title="Edit spare part">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Edit spare part"
          links={LINKS}
        />
        <FormProvider {...form}>
          <StateWrapper isError={isError} isReady={isReady}>
            <Form onAction={handleUpdate} dateUpdatingPrice={sparePart?.dateUpdatingPrice} />
          </StateWrapper>
        </FormProvider>
      </Container>
    </Page>
  );
});
