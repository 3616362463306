import {
  format,
  getTime,
  formatDistanceToNow,
  isValid,
} from 'date-fns';

export const fDate = (date: Date | string | number): string => (date ? format(new Date(date), 'yyyy/MM/dd') : '');

export const fDateTime = (date: Date | string | number): string => (date ? format(new Date(date), 'dd MMM yyyy p') : '');

export const fTimestamp = (date: Date | string | number): number => getTime(new Date(date));

export const fDateTimeSuffix = (date: Date | string | number): string => format(new Date(date), 'dd/MM/yyyy hh:mm');

export const fToNow = (date: Date | string | number): string => (
  formatDistanceToNow(new Date(date), { addSuffix: true })
);

export const fIso8601 = (date: Date | string | number): string => format(new Date(date), 'yyyy-MM-dd');

export const formatPickerValue = (e: string | null): string | null => (e ? fIso8601(e) : e);

export const isValidDate = (date: string | null): boolean => isValid(date);

export const fMessengerDividerDate = (date: string): string => format(new Date(date), 'd MMMM y');
