import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks';
import { Module, PermissionAction } from '../../constants';
import { ErrorPage } from './ErrorPage';
import { LoadingScreen } from './LoadingScreen';

const ERROR_PAGE_PROPS = {
  title: 'Forbidden',
  description: 'Sorry, but you don\'t have permissions',
  goBack: false,
};

type PermissionWrapperProps = {
  children: React.ReactNode,
  action: PermissionAction,
  module: Module,
  isErrorPage?: boolean,
}

export const PermissionWrapper: React.FC<PermissionWrapperProps> = observer(({
  children,
  action,
  module,
  isErrorPage = false,
}) => {
  const {
    auth: {
      profile: {
        role,
        customerCare,
      },
    },
    permissions: {
      checkGrantPermission,
    },
  } = useStore();

  const isGrantedPermission = checkGrantPermission({
    role, action, module,
  });

  if (!role) {
    return (
      <LoadingScreen />
    );
  }

  if (module === Module.CUSTOMER_CARE && customerCare) {
    return (
      <>{children}</>
    );
  }

  if (!isGrantedPermission && isErrorPage) {
    return (
      <ErrorPage {...ERROR_PAGE_PROPS} />
    );
  }

  if (!isGrantedPermission) {
    return <></>;
  }

  return (
    <>{children}</>
  );
});
