import * as yup from 'yup';
import {
  surnameSchema,
  emailSchema,
  phoneSchema,
  userCountrySchema,
  firstNameSchema,
} from '../../../../utils/validation';

const rules = yup.object({
  name: firstNameSchema(),
  surname: surnameSchema(),
  email: emailSchema(),
  phone: phoneSchema(),
  notify: yup.boolean().required('Notify is required').default(false),
  country: userCountrySchema(),
});

export default rules;
