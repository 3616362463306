import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Autocomplete, Grid, TextField, Typography,
} from '@material-ui/core';
import { useStore } from '../../../hooks';
import { ClaimsCountList } from '../../../components';
import { PERIODS } from './constants';
import { Option } from '../../../types';
import { useMultipleSelectFilter, useWarrantyFilter } from '../hooks';
import { makeConfigCards } from '../../../utils';
import { COMMON_OPTIONS } from '../../../constants';

const ITEM_SIZE = 4;

export const ClaimsCountByPeriod: React.FC = observer((): JSX.Element => {
  const {
    countriesModel: {
      countriesAutoComplete: countries,
    },
    reports: {
      fetchReclamationsCountByPeriods,
      reclamationsCount: {
        periods,
      },
    },
    serviceModels: {
      modelsAutoComplete: models,
    },
    deviationCodes: {
      deviationCodesAutoComplete: deviationCodes,
    },
  } = useStore();

  const {
    ids: countryIds,
    handleChangeValues: handleChangeCountries,
  } = useMultipleSelectFilter();

  const {
    warranty,
    handleChangeWarranty,
  } = useWarrantyFilter();

  const {
    ids: modelIds,
    handleChangeValues: handleChangeModelIds,
  } = useMultipleSelectFilter();

  const {
    ids: deviationCodeIds,
    handleChangeValues: handleChangeDeviationCodeIds,
  } = useMultipleSelectFilter();

  useEffect(() => {
    fetchReclamationsCountByPeriods({
      countryIds,
      modelIds,
      warranty: warranty?.value,
      deviationCodeIds,
    });
  }, [
    countryIds,
    modelIds,
    warranty?.value,
    deviationCodeIds,
  ]);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Number of claims by periods
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} mb={2}>
          <Autocomplete
            multiple
            autoComplete={false}
            fullWidth
            options={countries}
            onChange={handleChangeCountries}
            getOptionLabel={(option: Option): string => option.label}
            renderInput={(params): JSX.Element => <TextField {...params} label="Countries" />}
          />
        </Grid>
        <Grid item xs={12} md={3} mb={2}>
          <Autocomplete
            multiple
            autoComplete={false}
            fullWidth
            options={deviationCodes}
            onChange={handleChangeDeviationCodeIds}
            getOptionLabel={(option: Option): string => option.label}
            renderInput={(params): JSX.Element => <TextField {...params} label="Deviation codes" />}
          />
        </Grid>
        <Grid item xs={12} md={3} mb={2}>
          <Autocomplete
            multiple
            autoComplete={false}
            fullWidth
            options={models}
            onChange={handleChangeModelIds}
            getOptionLabel={(option: Option): string => option.label}
            renderInput={(params): JSX.Element => <TextField {...params} label="Models" />}
          />
        </Grid>
        <Grid item xs={12} md={3} mb={2}>
          <Autocomplete
            autoComplete={false}
            fullWidth
            value={warranty}
            options={COMMON_OPTIONS}
            onChange={handleChangeWarranty}
            getOptionLabel={(option: Option): string => option.label}
            renderInput={(params): JSX.Element => <TextField {...params} label="Warranty" />}
          />
        </Grid>
      </Grid>
      <ClaimsCountList
        config={makeConfigCards(PERIODS, periods)}
        itemSize={ITEM_SIZE}
      />
    </>
  );
});
