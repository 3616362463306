import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Autocomplete, Grid, TextField, Typography,
} from '@material-ui/core';
import { useStore } from '../../../hooks';
import { STATUSES } from './constants';
import { Option } from '../../../types';
import { useMultipleSelectFilter } from '../hooks';
import { CountItem } from './CountItem';
import { makeConfigCards } from '../../../utils';

export const ClaimsCountByStatus: React.FC = observer((): JSX.Element => {
  const {
    countriesModel: {
      countriesAutoComplete: countries,
    },
    reports: {
      fetchReclamationsCountByStatuses,
      reclamationsCount: {
        statuses,
      },
    },
  } = useStore();

  const {
    ids: countryIds,
    handleChangeValues: handleChangeCountries,
  } = useMultipleSelectFilter();

  useEffect(() => {
    fetchReclamationsCountByStatuses({
      countryIds,
    });
  }, [countryIds]);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Number of claims by statuses
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} mb={2}>
          <Autocomplete
            multiple
            autoComplete={false}
            fullWidth
            options={countries}
            onChange={handleChangeCountries}
            getOptionLabel={(option: Option): string => option.label}
            renderInput={(params): JSX.Element => <TextField {...params} label="Countries" />}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {makeConfigCards(STATUSES, statuses).map(({ title, value, color }): JSX.Element => (
          <CountItem
            title={title}
            value={value}
            color={color}
          />
        ))}
      </Grid>
    </>
  );
});
