import { Instance, types } from 'mobx-state-tree';
import { ServiceColorModel } from './ServiceColorModel';
import { ServiceModelModel } from './ServiceModelModel';
import { CountryModel } from './CountryModel';
import { CityModel } from './CityModel';

const SellerShopModel = types.model({
  id: types.number,
  address: types.string,
  createdAt: types.string,
  emails: types.array(types.string),
  externalId: types.string,
  name: types.string,
  status: types.number,
  updatedAt: types.string,
});

export const ChannelModel = types.model('ChannelModel', {
  id: types.number,
});

export const BaseConsumerProps = {
  id: types.number,
  firstName: types.string,
  lastName: types.string,
  phone: types.string,
  email: types.string,
  countryExtId: types.maybeNull(types.string),
  city: types.string,
  modelExtId: types.string,
  colorExtId: types.string,
  serialNumber: types.string,
  sellerCountryExtId: types.maybeNull(types.string),
  sellerCityExtId: types.maybeNull(types.string),
  sellerShopExtId: types.maybeNull(types.string),
  datePurchase: types.string,
  receiptPhotoLink: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  price: types.string,
  channel: types.maybeNull(ChannelModel),
};

export const ConsumerModel = types.model('ConsumerModel', {
  sellerCountry: CountryModel,
  sellerCity: CityModel,
  sellerShop: SellerShopModel,
  country: CountryModel,
  model: ServiceModelModel,
  color: ServiceColorModel,
  ...BaseConsumerProps,
});

export const ConsumerCountryCountModel = types.model('ConsumerCountryCountModel', {
  count: types.string,
  country: types.string,
});

export type Consumer = Instance<typeof ConsumerModel>
