import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import rules from './rules';
import { useStore } from '../../../hooks';
import { INVITATION_ROLES } from '../../../constants';
import { getOption } from '../../../utils';
import { InviteValues, Option } from '../../../types';

type InviteUserProps = {
   readonly isOpened: boolean,
   readonly onClose: () => void,
}

export const InviteUser: React.FunctionComponent<InviteUserProps> = observer(({
  isOpened,
  onClose,
}): JSX.Element => {
  const { users } = useStore();
  const {
    control, handleSubmit, reset, setValue,
  } = useForm<InviteValues>({
    defaultValues: {
      email: '',
      role: INVITATION_ROLES[0].value,
    },
    resolver: yupResolver(rules),
  });

  const handleChangeRole = useCallback((
    _, data: Option | null,
  ): void => {
    setValue('role', data?.value || INVITATION_ROLES[0].value);
  }, []);

  const onSubmit = (values: InviteValues): void => {
    users.inviteUser(values);
    reset();
    onClose();
  };

  const handleClose = (): void => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpened} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Invitation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please, enter the email and the role of the user to send invitation link
          </DialogContentText>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({
                field: { onBlur, onChange, value }, fieldState: { error },
              }): JSX.Element => (
                <TextField
                  autoFocus
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label="Email Address"
                  value={value}
                  spellCheck={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="role"
              control={control}
              render={({
                field: { value }, fieldState: { error },
              }): JSX.Element => (
                <Autocomplete
                  disableClearable
                  autoComplete={false}
                  fullWidth
                  value={getOption(INVITATION_ROLES, value)}
                  options={INVITATION_ROLES}
                  onChange={handleChangeRole}
                  getOptionLabel={(option: Option): string => option.label}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      sx={{
                        mt: 1,
                      }}
                      label="Status"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});
