import React, { SyntheticEvent, useEffect } from 'react';
import {
  Autocomplete, Grid, TextField, Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { Option } from '../../../types';
import { useMultipleSelectFilter } from '../hooks';
import { BarChart } from '../../../components';

const EVENT = null as unknown as SyntheticEvent;
const CHART_ID = 'avgTimeProcessingChart';

export const AvgTimeProcessingClaims: React.FC = observer((): JSX.Element => {
  const {
    reports: {
      fetchAvgTimeProcessingReclamations,
      resetAvgTime,
      reclamationsCount: {
        avgTime: {
          totalAvgTime,
          avgTimePerEntities,
        },
      },
    },
    countriesModel: {
      countriesAutoComplete: countries,
      countriesForChartFilters: defaultCountries,
    },
  } = useStore();

  const {
    ids: countryIds,
    handleChangeValues: handleChangeCountries,
    handleDisabledOption,
  } = useMultipleSelectFilter();

  useEffect(() => {
    // use const, because this method is used in the onChange of the AutoComplete
    // onChange has event as a first argument, which type is Synthetic event
    handleChangeCountries(EVENT, defaultCountries);
  }, []);

  useEffect(() => {
    if (countryIds) {
      fetchAvgTimeProcessingReclamations({ countryIds });
    } else {
      resetAvgTime();
    }
  }, [countryIds]);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Average time of claim processing
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={12} mb={2}>
            <Autocomplete
              multiple
              autoComplete={false}
              fullWidth
              options={countries}
              defaultValue={defaultCountries}
              onChange={handleChangeCountries}
              getOptionLabel={(option: Option): string => option.label}
              getOptionDisabled={handleDisabledOption}
              renderInput={(params): JSX.Element => <TextField {...params} label="Countries" />}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <BarChart
            id={CHART_ID}
            title="Average time"
            subtitle={`Total average time - ${totalAvgTime} (in hours)`}
            data={avgTimePerEntities.map((entity) => entity.avgTime)}
            categories={avgTimePerEntities.map((entity) => entity.name)}
            metaData={avgTimePerEntities.map((entity) => entity.isCountry)}
          />
        </Grid>
      </Grid>
    </>
  );
});
