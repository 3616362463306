import { SyntheticEvent, useState } from 'react';
import { Option } from '../../../types';

type UseWarrantyFilter = {
  warranty: Option | null,
  handleChangeWarranty: (event: SyntheticEvent<Element, Event>, value: Option | null) => void,
}

export const useWarrantyFilter = (): UseWarrantyFilter => {
  const [warranty, setWarranty] = useState<Option | null>(null);

  const handleChangeWarranty = (_: SyntheticEvent<Element, Event>, value: Option | null): void => {
    setWarranty(value);
  };

  return {
    warranty,
    handleChangeWarranty,
  };
};
