import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { LoadingScreen, Page } from '../../components';
import { useRequest, useStore } from '../../hooks';
import { NotificationsList } from './NotificationsList';
import { AdminView } from './AdminView/AdminView';
import { DEFAULT_AUTOCOMPLETE_PARAMS, UserRole } from '../../constants';

export const Dashboard:React.FC = observer(() => {
  const {
    countriesModel: {
      fetchCountries,
    },
    serviceModels: {
      fetchServiceModels,
    },
    deviationCodes: {
      fetchDeviationCodes,
    },
    auth: {
      profile: {
        role,
      },
    },
  } = useStore();

  const { isFetching: isFetchingCountries } = useRequest({
    action: fetchCountries,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
    condition: role === UserRole.ADMIN,
  });

  const { isFetching: isFetchingModels } = useRequest({
    action: fetchServiceModels,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
    condition: role === UserRole.ADMIN,
  });

  const { isFetching: isFetchingDeviationCodes } = useRequest({
    action: fetchDeviationCodes,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
    condition: role === UserRole.ADMIN,
  });

  const isReady = !isFetchingCountries && !isFetchingModels && !isFetchingDeviationCodes;

  return isReady ? (
    <Page title="General: App | Minimal-UI">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <NotificationsList />
          </Grid>
          <AdminView />
        </Grid>
      </Container>
    </Page>
  ) : (<LoadingScreen />);
});
