import { Instance, types } from 'mobx-state-tree';

export const ListMetaDataModel = types.model({
  totalItems: types.number,
  itemCount: types.number,
  itemsPerPage: types.number,
  totalPages: types.number,
  currentPage: types.number,
});

export const LocalesModel = types.model({
  id: types.number,
  name: types.string,
  language: types.string,
});

export const LocalesFormModel = types.model({
  name: types.string,
  language: types.string,
});

export const CommonModel = types.model({
  id: types.number,
  name: types.maybe(types.string),
});

export type Locales = Instance<typeof LocalesModel>
export type ListMetaData = Instance<typeof ListMetaDataModel>
export type CommonModelType = Instance<typeof CommonModel>
export type LocalesForm = Instance<typeof LocalesFormModel>
