import React, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  TextField,
  Autocomplete,
  useMediaQuery,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  LoadingButton, LocalizationProvider,
} from '@material-ui/lab';

import DateFnsUtils from '@material-ui/lab/AdapterDateFns';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import {
  ClaimsListToolbarProps,
  Option,
} from '../../../types';
import { useAutocomplete, usePrevious, useStore } from '../../../hooks';
import { getFileAllClaimsReport, getFileAllClaimsReportPdf } from '../../../services';
import {
  FIRST_PAGE,
  List,
} from '../../../constants';
import { isValidDate } from '../../../utils';
import { CustomDatePicker } from '../../../components/CustomDatePicker';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: '0px !important',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const SEARCH_TIMEOUT = 400;

export const WAY_OF_SOLUTION = [
  { label: 'New one', value: 'NEW' },
  { label: 'Reworked', value: 'REPAIR' },
];

export const ClaimsToolbar:React.FC<ClaimsListToolbarProps> = observer(({
  page,
}): JSX.Element => {
  const {
    deviationCodes: {
      deviationCodesAutoComplete: deviationCodes,
    },
    reports: {
      fetchAllClaimsReport,
      allClaimsReport: { items, meta },
    },
    serviceModels: {
      modelsAutoComplete: serviceModels,
    },
    countriesModel: {
      countriesAutoComplete: countries,
    },

  } = useStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const colors = useAutocomplete(List.Colors);

  // eslint-disable-next-line max-len
  const [searchReclamationCreateFrom, setSearchReclamationCreateFrom] = useState<string | null>(null);
  const [searchReclamationCreateTo, setSearchReclamationCreateTo] = useState<string | null>(null);

  const [searchRegistrationDateFrom, setSearchRegistrationDateFrom] = useState<string | null>(null);
  const [searchRegistrationDateTo, setSearchRegistrationDateTo] = useState<string | null>(null);
  const [searchSerialNumber, setSearchSerialNumber] = useState<string>('');
  const [searchCountry, setSearchCountry] = useState<number[] | null>(null);
  const [searchModel, setSearchModel] = useState<number[] | null>(null);
  const [searchColor, setSearchColor] = useState<number[] | null>(null);
  const [searchDeviationCode, setSearchDeviationCode] = useState<number[] | null>(null);
  const [searchSolution, setSearchSolution] = useState<string | undefined>('');
  const [isRepeatChecked, setIsRepeatChecked] = useState<boolean>(false);
  const [isGeneratedFile, setIsGeneratedFile] = useState<boolean>(true);
  const [isGeneratedPdfFile, setIsGeneratedPdfFile] = useState<boolean>(true);

  const [debouncedSearchSolution] = useDebounce(searchSolution, SEARCH_TIMEOUT);
  const [debouncedSearchSerialNumber] = useDebounce(searchSerialNumber, SEARCH_TIMEOUT);

  // eslint-disable-next-line max-len
  const handleFilterByReclamationCreateFrom = useCallback((newFilterDate: string | null):void => {
    if (isValidDate(newFilterDate) || !newFilterDate) {
      setSearchReclamationCreateFrom(newFilterDate);
    }
  }, []);
  const handleFilterByReclamationCreateTo = useCallback((newFilterDate: string | null):void => {
    if (isValidDate(newFilterDate) || !newFilterDate) {
      setSearchReclamationCreateTo(newFilterDate);
    }
  }, []);

  const handleFilterByRegistrationFrom = useCallback((newFilterDate: string | null):void => {
    if (isValidDate(newFilterDate) || !newFilterDate) {
      setSearchRegistrationDateFrom(newFilterDate);
    }
  }, []);
  const handleFilterByRegistrationTo = useCallback((newFilterDate: string | null):void => {
    if (isValidDate(newFilterDate) || !newFilterDate) {
      setSearchRegistrationDateTo(newFilterDate);
    }
  }, []);

  const onFilterCountry = useCallback((newFilterCountry: number[] | null):void => {
    setSearchCountry(newFilterCountry);
  }, []);
  const onFilterModel = useCallback((newFilterModel: number[] | null):void => {
    setSearchModel(newFilterModel);
  }, []);
  const onFilterColor = useCallback((newFilterColor: number[] | null):void => {
    setSearchColor(newFilterColor);
  }, []);
  const onFilterBySerialNumber = useCallback((newFilterSerialNumber: string):void => {
    setSearchSerialNumber(newFilterSerialNumber);
  }, []);
  const onFilterDeviationCode = useCallback(
    (newFilterDeviationCode: number[] | null):void => {
      setSearchDeviationCode(newFilterDeviationCode);
    }, [],
  );

  const handleCountriesChange = useCallback((event: SyntheticEvent, values): void => {
    const customCountries = values.map((item) => item.value);
    onFilterCountry(customCountries);
  }, []);
  const handleModelsChange = useCallback((event: SyntheticEvent, values): void => {
    const customModels = values.map((item) => item.value);
    onFilterModel(customModels);
  }, []);
  const handleColorsChange = useCallback((event: SyntheticEvent, values): void => {
    const customColors = values.map((item) => item.value);
    onFilterColor(customColors);
  }, []);
  const handleDeviationCodesChange = useCallback((event: SyntheticEvent, values): void => {
    const customDeviationCodes = values.map((item) => item.value);
    onFilterDeviationCode(customDeviationCodes);
  }, []);

  const filters = searchReclamationCreateFrom
  || searchReclamationCreateTo
  || searchRegistrationDateFrom
  || searchRegistrationDateTo
  || debouncedSearchSerialNumber
  || searchCountry
  || debouncedSearchSolution
  || searchModel
  || searchColor
  || searchDeviationCode
  || isRepeatChecked;

  const prevFilters = usePrevious(filters);

  const handleGenerateReportFile = useCallback(async (): Promise<void> => {
    setIsGeneratedFile(false);
    const result = await getFileAllClaimsReport({
      reclamationCreateDateFrom: searchReclamationCreateFrom,
      reclamationCreateDateTo: searchReclamationCreateTo,
      registrationDateFrom: searchRegistrationDateFrom,
      registrationDateTo: searchRegistrationDateTo,
      serialNumber: debouncedSearchSerialNumber,
      countryId: searchCountry,
      solution: debouncedSearchSolution,
      modelId: searchModel,
      colorId: searchColor,
      deviationCodeId: searchDeviationCode,
      isRepeat: Number(isRepeatChecked) || null,
    });
    if (result) {
      setIsGeneratedFile(true);
      const buffer = new Int8Array(result.buffer.data);
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([buffer], { type: result.mimeType }),
      );
      a.setAttribute('download', 'all_claims_report.xlsx');
      a.click();
    }
  }, [
    searchReclamationCreateFrom,
    searchReclamationCreateTo,
    searchRegistrationDateFrom,
    searchRegistrationDateTo,
    searchModel,
    debouncedSearchSerialNumber,
    searchCountry,
    searchColor,
    searchDeviationCode,
    debouncedSearchSolution,
    isRepeatChecked,
  ]);

  const handleGenerateReportPdfFile = useCallback(async (): Promise<void> => {
    setIsGeneratedPdfFile(false);
    const result = await getFileAllClaimsReportPdf({
      reclamationCreateDateFrom: searchReclamationCreateFrom,
      reclamationCreateDateTo: searchReclamationCreateTo,
      registrationDateFrom: searchRegistrationDateFrom,
      registrationDateTo: searchRegistrationDateTo,
      serialNumber: debouncedSearchSerialNumber,
      countryId: searchCountry,
      solution: debouncedSearchSolution,
      modelId: searchModel,
      colorId: searchColor,
      deviationCodeId: searchDeviationCode,
      isRepeat: Number(isRepeatChecked) || null,
    });
    if (result) {
      setIsGeneratedPdfFile(true);
      const link = `${process.env.REACT_APP_API_URL}files/pdf/${result}`;
      window.open(link, '_blank');
    }
  }, [
    searchReclamationCreateFrom,
    searchReclamationCreateTo,
    searchRegistrationDateFrom,
    searchRegistrationDateTo,
    searchModel,
    debouncedSearchSerialNumber,
    searchCountry,
    searchColor,
    searchDeviationCode,
    debouncedSearchSolution,
    isRepeatChecked,
  ]);

  useEffect(() => {
    // check if filters were changed
    if (prevFilters !== filters) {
      fetchAllClaimsReport({
        reclamationCreateDateFrom: searchReclamationCreateFrom,
        reclamationCreateDateTo: searchReclamationCreateTo,
        registrationDateFrom: searchRegistrationDateFrom,
        registrationDateTo: searchRegistrationDateTo,
        serialNumber: debouncedSearchSerialNumber,
        countryId: searchCountry,
        solution: debouncedSearchSolution,
        modelId: searchModel,
        colorId: searchColor,
        deviationCodeId: searchDeviationCode,
        isRepeat: Number(isRepeatChecked) || null,
        page: FIRST_PAGE,
      });
    } else {
      fetchAllClaimsReport({
        reclamationCreateDateFrom: searchReclamationCreateFrom,
        reclamationCreateDateTo: searchReclamationCreateTo,
        registrationDateFrom: searchRegistrationDateFrom,
        registrationDateTo: searchRegistrationDateTo,
        serialNumber: debouncedSearchSerialNumber,
        countryId: searchCountry,
        solution: debouncedSearchSolution,
        modelId: searchModel,
        colorId: searchColor,
        deviationCodeId: searchDeviationCode,
        isRepeat: Number(isRepeatChecked) || null,
        page,
      });
    }
  }, [
    searchReclamationCreateFrom,
    searchReclamationCreateTo,
    searchRegistrationDateFrom,
    searchRegistrationDateTo,
    searchModel,
    debouncedSearchSerialNumber,
    searchCountry,
    searchColor,
    searchDeviationCode,
    debouncedSearchSolution,
    isRepeatChecked,
    page,
  ]);

  return (
    <>
      <RootStyle sx={{ paddingTop: '25px', paddingBottom: '25px', paddingRight: '0px !important' }}>
        <Grid
          container
          xs={12}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              size="small"
              autoComplete={false}
              fullWidth
              options={serviceModels}
              onChange={handleModelsChange}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Model" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              size="small"
              autoComplete={false}
              fullWidth
              options={countries}
              onChange={handleCountriesChange}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Country" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              size="small"
              autoComplete={false}
              fullWidth
              options={colors}
              onChange={handleColorsChange}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Color" />}
            />
          </Grid>
        </Grid>
      </RootStyle>
      <RootStyle sx={{ paddingBottom: '25px', paddingRight: '0px !important' }}>
        <Grid
          container
          xs={12}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              size="small"
              autoComplete={false}
              fullWidth
              options={deviationCodes}
              onChange={handleDeviationCodesChange}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Deviation code" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SearchStyle
              size="small"
              value={searchSerialNumber}
              onChange={(e): void => onFilterBySerialNumber(e.target.value)}
              placeholder="Serial number"
              startAdornment={(
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
            )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              size="small"
              autoComplete={false}
              fullWidth
              options={WAY_OF_SOLUTION}
              onChange={(e, value): void => setSearchSolution(value?.label)}
              getOptionLabel={(option: Option<string>): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Solution" />}
            />
          </Grid>
        </Grid>
      </RootStyle>
      <RootStyle sx={{ paddingBottom: '25px', paddingRight: '0px !important' }}>
        <LocalizationProvider dateAdapter={DateFnsUtils} sx={{ marginTop: isMobile ? 10 : 0 }}>
          <Grid
            container
            xs={12}
            spacing={3}
          >
            <Grid item xs={12} md={4}>
              <CustomDatePicker
                label="Create claim date from"
                value={searchReclamationCreateFrom}
                handleChange={handleFilterByReclamationCreateFrom}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomDatePicker
                label="Create claim date to"
                value={searchReclamationCreateTo}
                handleChange={handleFilterByReclamationCreateTo}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </RootStyle>
      <RootStyle sx={{ paddingBottom: '25px', paddingRight: '0px !important' }}>
        <LocalizationProvider dateAdapter={DateFnsUtils} sx={{ marginTop: isMobile ? 10 : 0 }}>
          <Grid
            container
            xs={12}
            spacing={3}
          >
            <Grid item xs={12} md={4}>
              <CustomDatePicker
                label="Registration date from"
                value={searchRegistrationDateFrom}
                handleChange={handleFilterByRegistrationFrom}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomDatePicker
                label="Registration date to"
                value={searchRegistrationDateTo}
                handleChange={handleFilterByRegistrationTo}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </RootStyle>
      <RootStyle sx={{ paddingBottom: '25px', paddingRight: '0px !important' }}>
        <Grid
          container
          xs={12}
          spacing={3}
        >
          <Grid item xs={12} md={3}>
            <FormControlLabel
              label="Show repeated claims"
              control={(
                <Checkbox
                  checked={isRepeatChecked}
                  onChange={(): void => setIsRepeatChecked(!isRepeatChecked)}
                />
                )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LoadingButton
              disabled={!items.length}
              variant="contained"
              startIcon={<Icon icon={fileTextFill} />}
              onClick={handleGenerateReportFile}
              loading={!isGeneratedFile}
            >
              Generate Excel file
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={3}>
            <LoadingButton
              disabled={!items.length}
              variant="contained"
              startIcon={<Icon icon={fileTextFill} />}
              onClick={handleGenerateReportPdfFile}
              loading={!isGeneratedPdfFile}
            >
              Generate PDF file
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="body1" align="right">
              Total claims:
              {' '}
              {meta.totalItems}
            </Typography>
          </Grid>
        </Grid>
      </RootStyle>
    </>
  );
});
