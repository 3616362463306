import React from 'react';
import { Icon } from '@iconify/react';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, List } from '@material-ui/core';
import { styled, Theme, useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { LoadingScreen, MIconButton } from '../../../components';
import { Search } from './Search';
import { ChannelItem } from './ChannelItem';
import { useStore } from '../../../hooks';

type StyledBoxProps = {
    theme: Theme,
    isOpenedSidebar: boolean,
}

const StyledBox = styled(Box)(({ theme, isOpenedSidebar }: StyledBoxProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isOpenedSidebar ? 'space-between' : 'center',
  marginTop: '10px',
  marginBottom: '10px',
}));

type MainListProps = {
    isOpenedSidebar: boolean,
    searchValue: string,
    setSearchValue: (newSearchValue: string) => void,
    toggleOpenSidebar: () => void,
    handleInfiniteScroll: () => void,
    forbidScroll: string | boolean,
    id: string,
}

export const MainList:React.FC<MainListProps> = observer(({
  isOpenedSidebar,
  searchValue,
  setSearchValue,
  toggleOpenSidebar,
  handleInfiniteScroll,
  forbidScroll,
  id,
}) => {
  const {
    channels: {
      items,
    },
  } = useStore();

  const theme = useTheme();

  return (
    <>
      <StyledBox theme={theme} isOpenedSidebar={isOpenedSidebar}>
        {isOpenedSidebar && (
        <Search
          searchValue={searchValue}
          onSearch={setSearchValue}
        />
        )}
        <MIconButton onClick={toggleOpenSidebar}>
          <Icon
            width={20}
            height={20}
            icon={isOpenedSidebar ? arrowIosBackFill : arrowIosForwardFill}
          />
        </MIconButton>
      </StyledBox>
      <div id="scrollableDiv" style={{ overflow: 'auto' }}>
        {items.length ? (
          <InfiniteScroll
            dataLength={items.length}
            next={handleInfiniteScroll}
            hasMore={!forbidScroll}
            loader={<LoadingScreen />}
            scrollableTarget="scrollableDiv"
          >
            <List disablePadding>
              {items.map((item) => (
                <ChannelItem
                  key={item.id}
                  channel={item}
                  isSelected={Number(id) === item.id}
                  isOpenedSidebar={isOpenedSidebar}
                />
              ))}
            </List>
          </InfiniteScroll>
        ) : (<></>)}
      </div>
    </>
  );
});
