import * as yup from 'yup';
import {
  emailSchema, firstNameSchema,
  surnameSchema, numberReqSchema,
  onlyStringReqSchema, phoneReqSchema,
} from '../../../utils';

const rules = yup.object({
  firstName: firstNameSchema(),
  lastName: surnameSchema(),
  email: emailSchema(),
  phone: phoneReqSchema('Phone'),
  city: onlyStringReqSchema('City'),
  countryId: numberReqSchema('Country'),
});

export default rules;
