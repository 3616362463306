import React, { useEffect } from 'react';
import {
  Container, Stack,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { PATH_DASHBOARD } from '../../app';
import { Page, HeaderBreadcrumbs } from '../../components';
import { KanbanColumn } from './components';
import { useStore } from '../../hooks';
import { SkeletonLoad } from './components/KanbanLoader';

const LINKS = [
  {
    name: 'Dashboard',
    href: PATH_DASHBOARD.root,
  },
  { name: 'Kanban' }];

export const Kanban: React.FC = observer(() => {
  const { kanban: { fetchKanbanStatuses, statuses } } = useStore();

  useEffect(() => {
    fetchKanbanStatuses();
  }, []);

  return (
    <Page title="Kanban | Minimal-UI" sx={{ height: '100%' }}>
      <Container maxWidth={false} sx={{ height: '100%' }}>
        <HeaderBreadcrumbs
          heading="Kanban"
          links={LINKS}
        />
        <Stack
          direction="row"
          alignItems="flex-start"
          spacing={3}
          sx={{ height: 'calc(100% - 32px)', overflowY: 'hidden' }}
        >
          {statuses.length ? (statuses.map((status) => (
            <KanbanColumn
              name={status.name}
              countries={status.countries}
            />
          ))) : (SkeletonLoad)}
        </Stack>
      </Container>
    </Page>
  );
});
