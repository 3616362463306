import { Option } from '../types';

export enum DeviationCodeStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export const DEVIATION_CODE_STATUSES: Option[] = [
  { label: 'Active', value: DeviationCodeStatus.ACTIVE },
  { label: 'Inactive', value: DeviationCodeStatus.INACTIVE },
];

export const DEVIATION_CODE_STATUS = {
  [DeviationCodeStatus.ACTIVE]: {
    title: 'Active',
  },
  [DeviationCodeStatus.INACTIVE]: {
    title: 'Inactive',
  },
};
