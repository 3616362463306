import {
  applySnapshot,
  flow, getEnv, Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  StrollerFormValues,
  StrollerListData,
  StrollerModel,
  ListMetaDataModel,
  Stroller,
  Option,
  StrollerListParams, UpdateItemProps,
} from '../types';
import { mapEntityToOption } from '../utils';
import { COMMON_SCHEMA, DEFAULT_ID, DEFAULT_META } from '../constants';
import { StrollerResponse } from '../services/api';

export const StrollerStore = types.model('StrollerStore')
  .props({
    items: types.optional(types.array(StrollerModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    updateStrollerItem<T>({ res, successMessage, id }: UpdateItemProps<T>): void {
      const strollerId = self.items.findIndex((item) => item.id === Number(id));
      if (strollerId !== DEFAULT_ID) {
        applySnapshot(self.items[strollerId], { ...self.items[strollerId], ...res });
        toast.success(successMessage);
      }
    },
  }))
  .actions((self) => ({
    fetchStrollers: flow(function* fetchStrollers(params: StrollerListParams) {
      try {
        const res: StrollerListData = yield getEnv(self).api.fetchStrollers(params);
        const { items, meta } = res;
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        console.log(e);
        toast.error('Error with getting strollers');
      }
    }),
    fetchStroller: flow(function* fetchStroller(id: string) {
      const currentStroller: Stroller = yield getEnv(self).api.fetchStroller(id);
      applySnapshot(self.items, [currentStroller]);
    }),
    updateStroller: flow(function* updateStroller(id: string, values: StrollerFormValues) {
      try {
        const res: StrollerResponse = yield getEnv(self).api.updateStroller(id, values);
        self.updateStrollerItem<StrollerResponse>({
          res,
          successMessage: 'Stroller was updated',
          id,
        });
      } catch (e) {
        toast.error('Error with updating stroller');
      }
    }),
  }))
  .views((self) => ({
    getStrollerById(id): Stroller | undefined {
      return self.items.find((stroller) => stroller.id === Number(id));
    },
    get strollersAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<Stroller>(item, COMMON_SCHEMA));
    },
  }));

export type StrollerStore = Instance<typeof StrollerStore>

export type StrollerStoreSnapShot = SnapshotOut<typeof StrollerStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createStrollerStoreModel = () => types.optional(StrollerStore, {});
