import { Instance, types } from 'mobx-state-tree';

export const ProfileCountry = types.model({
  id: types.maybe(types.number),
});

export const ProfileAvatar = types.maybeNull(types.model({
  id: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
}));

export const ProfileModel = types.model({
  id: types.number,
  name: types.string,
  surname: types.string,
  email: types.string,
  notify: types.boolean,
  phone: types.maybeNull(types.string),
  country: ProfileCountry,
  avatar: ProfileAvatar,
  role: types.number,
  customerCare: types.optional(types.boolean, false),
});

export type Profile = Instance<typeof ProfileModel>
export type Avatar = Instance<typeof ProfileAvatar>

export const AuthModel = types.model('AuthModel', {
  isAuth: types.boolean,
  isSentResetEmail: types.boolean,
  isResetPassword: types.boolean,
  profile: ProfileModel,
});

export type Auth = Instance<typeof AuthModel>
