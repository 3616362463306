import {
  applySnapshot,
  flow, getEnv, Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  ShopFormValues,
  ShopParams,
  ShopListData,
  Option,
  Shop,
  ShopModel,
  ShopMetaModel,
  ShopListParams,
  ShopItemValues,
  HistoryModel,
  ReclamationsParams,
  ReclamationsListData,
} from '../types';
import { mapEntityToOption } from '../utils';
import { COMMON_SCHEMA, DEFAULT_META } from '../constants';

export const ShopStore = types.model('ShopStore')
  .props({
    items: types.optional(types.array(ShopModel), []),
    meta: types.optional(ShopMetaModel, DEFAULT_META),
    history: types.optional(HistoryModel, {}),
  })
  .actions((self) => ({
    fetchShops: flow(function* fetchShops(params: ShopListParams) {
      try {
        const res: ShopListData = yield getEnv(self).api.fetchShops(params);
        const { items, meta } = res;
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting shops');
      }
    }),
    fetchShop: flow(function* fetchShop(id: ShopParams) {
      const data = yield getEnv(self).api.fetchShop(id);
      applySnapshot(self.items, [data]);
    }),
    updateShop: flow(function* updateShop(id: ShopParams, values: ShopFormValues) {
      try {
        const data = yield getEnv(self).api.updateShop(id, values);
        applySnapshot(self.items, [data]);
        toast.success('Shop was updated');
      } catch (e) {
        toast.error('Error with updating shop');
      }
    }),
    getShopHistory: flow(function* fetchReclamations(params: ReclamationsParams = {}) {
      try {
        const {
          items,
          meta,
        }: ReclamationsListData = yield getEnv(self).api.fetchReclamations(params);
        applySnapshot(self.history.items, items);
        self.history.meta = meta;
      } catch (e) {
        toast.error('Error with getting the history of the shop');
      }
    }),
  }))
  .views((self) => ({
    getShopById(id): ShopItemValues | undefined {
      return self.items.find((shop) => shop.id === Number(id));
    },
    get shopsAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<Shop>(item, COMMON_SCHEMA));
    },
  }));

export type ShopStore = Instance<typeof ShopStore>

export type ShopStoreSnapShot = SnapshotOut<typeof ShopStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createShopStoreModel = () => types.optional(ShopStore, {});
