import { Icon } from '@iconify/react';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import { Theme } from '@material-ui/core/styles';

type ChipReturn = {
  MuiChip: { defaultProps: { deleteIcon: JSX.Element }; styleOverrides: { avatarColorSuccess: { backgroundColor: string; color: string }; outlined: { borderColor: string; '&.MuiChip-colorSecondary': { borderColor: string }; '&.MuiChip-colorPrimary': { borderColor: string } }; avatarColorInfo: { backgroundColor: string; color: string }; avatarColorError: { backgroundColor: string; color: string }; colorDefault: { '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': { color: string } }; avatarColorWarning: { backgroundColor: string; color: string } } }
}
export const Chip = (theme: Theme): ChipReturn => ({
  MuiChip: {
    defaultProps: {
      // eslint-disable-next-line react/react-in-jsx-scope
      deleteIcon: <Icon icon={closeCircleFill} />,
    },

    styleOverrides: {
      colorDefault: {
        '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
          // eslint-disable-next-line react/destructuring-assignment
          color: theme.palette.text.secondary,
        },
      },
      outlined: {
        // eslint-disable-next-line react/destructuring-assignment
        borderColor: theme.palette.grey[500_32],
        '&.MuiChip-colorPrimary': {
          // eslint-disable-next-line react/destructuring-assignment
          borderColor: theme.palette.primary.main,
        },
        '&.MuiChip-colorSecondary': {
          // eslint-disable-next-line react/destructuring-assignment
          borderColor: theme.palette.secondary.main,
        },
      },
      //
      avatarColorInfo: {
        // eslint-disable-next-line react/destructuring-assignment
        color: theme.palette.info.contrastText,
        // eslint-disable-next-line react/destructuring-assignment
        backgroundColor: theme.palette.info.dark,
      },
      avatarColorSuccess: {
        // eslint-disable-next-line react/destructuring-assignment
        color: theme.palette.success.contrastText,
        // eslint-disable-next-line react/destructuring-assignment
        backgroundColor: theme.palette.success.dark,
      },
      avatarColorWarning: {
        // eslint-disable-next-line react/destructuring-assignment
        color: theme.palette.warning.contrastText,
        // eslint-disable-next-line react/destructuring-assignment
        backgroundColor: theme.palette.warning.dark,
      },
      avatarColorError: {
        // eslint-disable-next-line react/destructuring-assignment
        color: theme.palette.error.contrastText,
        // eslint-disable-next-line react/destructuring-assignment
        backgroundColor: theme.palette.error.dark,
      },
    },
  },
});
