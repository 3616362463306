import { Option } from '../types';
import { UserRole } from './roles';

enum userStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export const USER_STATUSES: Option[] = [
  {
    label: 'Active',
    value: userStatus.ACTIVE,
  },
  {
    label: 'Inactive',
    value: userStatus.INACTIVE,
  },
];

export const STATUSES = {
  [userStatus.ACTIVE]: {
    title: 'Active',
  },
  [userStatus.INACTIVE]: {
    title: 'Inactive',
  },
};

export const INVITATION_ROLES: Option[] = [
  {
    label: 'Service Manager',
    value: UserRole.SERVICE_MANAGER,
  },
  {
    label: 'Team Member',
    value: UserRole.TEAM_MEMBER,
  },
  {
    label: 'Strollers Viewer',
    value: UserRole.STROLLERS_VIEWER,
  },
];
