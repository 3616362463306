import { HeadCell } from '../../../types/grid';

export const TABLE_HEAD_LIST: readonly HeadCell[] = [
  { id: 'id', label: '#' },
  { id: 'date', label: 'Date of creation' },
  { id: 'description', label: 'Description of defective' },
  { id: 'serial_number', label: 'Serial / order`s number' },
  { id: 'finding', label: 'Finding' },
  { id: 'photos', label: 'Photos' },
  { id: 'parts', label: 'Parts' },
  { id: 'solution', label: 'Solution' },
  { id: 'labor', label: 'Labor cost' },
  { id: 'spare_parts', label: 'Spare parts' },
  { id: 'logistic', label: 'Logistic cost' },
  { id: 'Sum', label: 'Sum' },
] as const;
