import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  Table,
  TableBody, TableCell,
  TableContainer,
  TablePagination, TableRow,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useHistory } from 'react-router-dom';
import {
  CommonListHead, CommonListToolbar,
  ConfirmDialog,
  HeaderBreadcrumbs,
  Page,
  Scrollbar, SearchNotFound,
} from '../../../components';
import { LINKS, TABLE_HEAD_LIST } from './constants';
import { useSearch, useStore } from '../../../hooks';
import { SparePartItem } from './SparePartItem';
import { DEFAULT_ID, FIRST_PAGE } from '../../../constants';
import { PATH_DASHBOARD } from '../../../app';
import { useSorting } from '../../../hooks/useSorting';

export const SparePartsList:React.FC = observer((): JSX.Element => {
  const {
    spareParts: {
      fetchSpareParts,
      deleteSparePart,
      items,
      meta,
    },
  } = useStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [page, setPage] = useState<number>(FIRST_PAGE);
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState<boolean>(false);
  const [sparePartId, setSparePartId] = useState<number>(DEFAULT_ID);
  const { debouncedSearchValue, searchValue, setSearchValue } = useSearch();

  const {
    sort,
    order,
    orderBy,
    onClickSorting,
  } = useSorting();

  const { push } = useHistory();

  const toggleDeleteModal = useCallback((value: boolean) => (): void => {
    setIsOpenedDeleteModal(value);
  }, []);

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  }, []);

  const handleOpenDeleteModal = useCallback((id: number): void => {
    setSparePartId(id);
    setIsOpenedDeleteModal(true);
  }, []);

  const handleDelete = useCallback((): void => {
    deleteSparePart(sparePartId);
    setIsOpenedDeleteModal(false);
    setSparePartId(DEFAULT_ID);
  }, [sparePartId]);

  const handleFilter = (newFilterValue: string):void => {
    setSearchValue(newFilterValue);
  };

  useEffect(() => {
    fetchSpareParts({ page, sort, name: debouncedSearchValue });
  }, [page, sort, debouncedSearchValue]);

  return (
    <Page title="Spare parts list">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Spare parts list"
          links={LINKS}
          action={(
            <Button
              variant="contained"
              onClick={(): void => push(PATH_DASHBOARD.spareParts.create)}
              startIcon={<Icon icon={plusFill} />}
            >
              Create
            </Button>
                    )}
        />
        <Card>
          <CommonListToolbar
            onSearch={handleFilter}
            searchValue={searchValue}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <CommonListHead
                  headListData={TABLE_HEAD_LIST}
                  order={order}
                  orderBy={orderBy}
                  onClick={onClickSorting}
                />
                <TableBody>
                  {items.length ? (
                    items.map((item) => (
                      <SparePartItem
                        key={`spare-part-${item.id}`}
                        data={item}
                        onDelete={handleOpenDeleteModal}
                      />
                    ))) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <SearchNotFound />
                        </TableCell>
                      </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            onPageChange={handlePageChange}
          />
        </Card>
      </Container>
      <ConfirmDialog
        isOpened={isOpenedDeleteModal}
        onClose={toggleDeleteModal(false)}
        callback={handleDelete}
      />
    </Page>
  );
});
