import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type PaperReturn = {
  MuiPaper: {
    defaultProps: { elevation: number }; styleOverrides: { root: { backgroundImage: string } }
  }
}
export const Paper = (theme: Theme): PaperReturn => ({
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },

    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
});
