import {
  applySnapshot,
  flow,
  getEnv,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  ListMetaDataModel,
  SparePartsListData,
  SparePartModel,
  SparePart,
  SparePartsListParams,
  SparePartValues,
} from '../types';
import { DEFAULT_META } from '../constants';
import { ApiErrorData } from '../services/api/api.helpers';

export const SparePartStoreModel = types.model('SparePartStore')
  .props({
    items: types.optional(types.array(SparePartModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    fetchSpareParts: flow(function* fetchSpareParts(
      params: SparePartsListParams,
    ): Generator<Promise<SparePart[]>> {
      try {
        const res = yield getEnv(self).api.fetchSpareParts(params);
        const { items, meta } = res as SparePartsListData;
        applySnapshot(self.items, items);
        self.meta = meta;
        return items;
      } catch (e) {
        toast.error('Error with getting spare parts');
        throw new Error('Error with getting spare part');
      }
    }),
    fetchSparePartById: flow(function* fetchSparePartById(id: string) {
      const sparePart: SparePart = yield getEnv(self).api.fetchSparePartById(id);
      applySnapshot(self.items, [sparePart]);
    }),
    createSparePart: flow(function* createSparePart(data:SparePartValues) {
      try {
        yield getEnv(self).api.createSparePart(data);
        toast.success('Spare part was created successfully');
      } catch (e) {
        toast.error('Error with creating spare part');
      }
    }),
    updateSparePart: flow(function* updateSparePart(id: string, data: SparePartValues) {
      try {
        yield getEnv(self).api.updateSparePart(id, data);
        toast.success('Spare part was updated successfully');
      } catch (e) {
        const error = e as ApiErrorData;
        if (error.error) {
          toast.error(error.error);
        } else {
          toast.error('Error with updating spare part');
        }
      }
    }),
  }))
  .actions((self) => ({
    deleteSparePart: flow(function* deleteSparePart(id: number) {
      try {
        yield getEnv(self).api.deleteSparePart(id);
        const params: SparePartsListParams = { page: self.meta.currentPage };
        self.fetchSpareParts(params);
        toast.success('Spare part was deleted successfully');
      } catch (e) {
        toast.error('Error with deleting spare part');
      }
    }),
  }))
  .views((self) => ({
    getSparePartById(id: string): SparePart | undefined {
      return self.items.find((sparePare) => sparePare.id === Number(id));
    },
  }));

export type SparePartStore = Instance<typeof SparePartStoreModel>

export type SparePartStoreSnapShot = SnapshotOut<typeof SparePartStoreModel>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createSparePartStoreModel = () => types.optional(SparePartStoreModel, {});
