import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import {
  Stack,
  Drawer,
  Tooltip,
  Divider,
  Typography,
  Container,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Card,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { MIconButton } from '../../../components/@material-extend';
import { CommonListHead, Scrollbar } from '../../../components';
import { KanbanReclamationItem } from './KanbanReclamationItem';
import { useStore } from '../../../hooks';
import {
  HEADER_TITLE_STYLES, ICON_DIMENSION, RECLAMATION_LIST_HEAD,
} from '../constants';
import { FIRST_PAGE } from '../../../constants';

type KanbanTaskDetailsProps = {
  id: number,
  name: string,
  count: string,
  status: string,
  handleClose(): void,
  isOpen: boolean,
}

export const KanbanCountryDetails:React.FC<KanbanTaskDetailsProps> = observer(({
  id,
  name,
  count,
  status,
  isOpen,
  handleClose,
}) => {
  const { kanban: { fetchKanbanReclamations, reclamations, meta } } = useStore();
  const [page, setPage] = useState<number>(FIRST_PAGE);
  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => { setPage(newPage + 1); };

  useEffect(() => {
    if (isOpen) {
      fetchKanbanReclamations({ countryId: id, status, page });
    }
  }, [page, id, isOpen]);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        anchor="right"
        PaperProps={{ sx: { width: { xs: 1, sm: '100%', md: '80vw' } } }}
      >
        <Stack sx={{ padding: '20px 24px' }} direction="row" alignItems="center" flexWrap="wrap">
          <Tooltip title="Back">
            <MIconButton onClick={handleClose} sx={{ mr: 1, p: 0, ml: 0 }}>
              <Icon icon={arrowIosBackFill} width={ICON_DIMENSION} height={ICON_DIMENSION} />
            </MIconButton>
          </Tooltip>
          <Stack direction="row" spacing={1} justifyContent="flex-start" flexGrow={1}>
            <Typography
              noWrap
              variant="subtitle1"
              sx={HEADER_TITLE_STYLES}
            >
              {name}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
            <Typography
              noWrap
              variant="subtitle2"
              sx={{ pl: 1 }}
            >
              Total claims:
            </Typography>
            <Typography
              noWrap
              variant="subtitle2"
              sx={{ pl: 1 }}
            >
              {count}
            </Typography>
          </Stack>
        </Stack>

        <Divider />
        <Container sx={{ mt: 3 }} maxWidth={false}>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <CommonListHead headListData={RECLAMATION_LIST_HEAD} />
                  <TableBody>
                    {reclamations?.map((item) => (
                      <KanbanReclamationItem
                        key={`kanban-reclamation-${item.id}`}
                        data={item}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {!!reclamations?.length && (
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={meta.totalItems}
                rowsPerPage={meta.itemsPerPage}
                page={meta.currentPage - 1}
                onPageChange={handlePageChange}
              />
            )}
          </Card>
        </Container>
      </Drawer>
    </>
  );
});
