import React, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Autocomplete, Box, Button, TextField,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce/esm';
import { useStore } from '../../../hooks';
import { QueryParams } from '../../../types';

const SEARCH_TIMEOUT = 400;

type OptionProps = {
  id: number,
}

const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  minHeight: 72,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}));

export const ChatHeader:React.FC = observer((): JSX.Element => {
  const {
    channels: {
      setChannelToReclamation,
    },
    reclamations: {
      fetchUnconnectedReclamations,
      unconnectedReclamations,
    },
  } = useStore();

  const { id } = useParams<QueryParams>();

  const [reclamationId, setReclamationId] = useState<number | null>(null);
  const [debouncedReclamationId] = useDebounce(reclamationId, SEARCH_TIMEOUT);

  useEffect(() => {
    fetchUnconnectedReclamations({ reclamationId: debouncedReclamationId });
  }, [debouncedReclamationId]);

  const handleChangeReclamationId = useCallback((newValue: number | null):void => {
    setReclamationId(newValue);
  }, []);

  const handleSubmit = useCallback(async (): Promise<void> => {
    await setChannelToReclamation(id, { reclamationId });
    fetchUnconnectedReclamations({ reclamationId: null });
  }, [reclamationId]);
  return (
    <RootStyle>
      <Box display="flex" justifyContent="space-between">
        <Autocomplete
          sx={{ mr: 3, minWidth: '200px' }}
          size="small"
          disableClearable
          autoComplete={false}
          fullWidth
          options={unconnectedReclamations.map((option: OptionProps) => option.id)}
          onInputChange={(
            e: SyntheticEvent,
            value: string,
          ): void => handleChangeReclamationId(Number(value))}
          getOptionLabel={(option: number): string => String(option)}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              label="Claim ID"
            />
          )}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          size="small"
          onClick={handleSubmit}
          disabled={!reclamationId}
        >
          Save
        </Button>
      </Box>
    </RootStyle>
  );
});
