import React from 'react';
import {
  alpha, styled, Theme, useTheme,
} from '@material-ui/core/styles';
import { Card, Grid, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import checkMarkCircle from '@iconify/icons-eva/checkmark-circle-outline';
import { Color } from '../../../constants';

type StyleProps = {
  theme: Theme,
  color: string,
}

const RootStyle = styled(Card)(({ theme, color }: StyleProps) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(3, 0),
  color: theme.palette[color].darker,
  backgroundColor: theme.palette[color].lighter,
}));

const IconWrapperStyle = styled('div')(({ theme, color }: StyleProps) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette[color].dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
    theme.palette[color].dark,
    0.24,
  )} 100%)`,
}));

type CountItemProps = {
  title: string,
  value: number,
  color: Color,
}

export const CountItem: React.FC<CountItemProps> = ({ title, value, color }): JSX.Element => {
  const theme = useTheme();
  return (
    <Grid item xs={12} md={3}>
      <RootStyle color={color} theme={theme}>
        <IconWrapperStyle color={color} theme={theme}>
          <Icon icon={checkMarkCircle} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{value}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
      </RootStyle>
    </Grid>
  );
};
