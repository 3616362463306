import { Instance, types } from 'mobx-state-tree';
import { CommonModel } from './CommonModel';

export const KnowledgeBaseFile = types.model({
  id: types.maybe(types.string),
  path: types.maybe(types.string),
  size: types.maybe(types.number),
  file: types.maybe(types.string),
  lastModified: types.maybe(types.number),
  lastModifiedDate: types.maybe(types.Date),
  webkitRelativePath: types.maybe(types.string),
  name: types.string,
  preview: types.string,
  type: types.string,
});

export const KnowledgeBaseModel = types.model('KnowledgeBaseModel', {
  id: types.number,
  title: types.string,
  description: types.string,
  content: types.string,
  strollerParts: types.optional(types.array(CommonModel), []),
  files: types.optional(types.array(KnowledgeBaseFile), []),
  createdAt: types.maybe(types.string),
});

export const HintsItemModel = types.model({
  id: types.number,
  title: types.string,
  description: types.string,
});

export const HintsModel = types.model({
  count: types.number,
  list: types.optional(types.array(HintsItemModel), []),
});

export type KnowledgeBase = Instance<typeof KnowledgeBaseModel>
export type KnowledgeBaseFile = Instance<typeof KnowledgeBaseFile>
export type Hints = Instance<typeof HintsModel>
export type HintsItem = Instance<typeof HintsItemModel>
