import { Instance, types } from 'mobx-state-tree';
import { StrollerPartModel } from './StrollerPartModel';
import { ServiceModelModel } from './ServiceModelModel';

export const SparePartModel = types.model({
  id: types.number,
  name: types.string,
  part: StrollerPartModel,
  model: ServiceModelModel,
  price: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.string,
  dateUpdatingPrice: types.maybeNull(types.string),
});

export type SparePart = Instance<typeof SparePartModel>
