import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { FieldError } from 'react-hook-form';
import { InputTypes } from '../../types';
import { usePasswordField } from '../../hooks';

type PasswordInputProps = {
  onBlur(): void,
  onChange(): void,
  value: string,
  error?: FieldError,
  label: string,
}

export const PasswordInput:React.FC<PasswordInputProps> = ({
  onBlur, onChange, value, error, label = 'New password',
}): JSX.Element => {
  const { isShowPassword, togglePasswordVisibility } = usePasswordField();
  return (
    <>
      {/* fake input for disabling autocomplete */}
      <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
      <TextField
        autoComplete="new-password"
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        fullWidth
        type={isShowPassword ? InputTypes.TEXT : InputTypes.PASSWORD}
        label={label}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                <Icon icon={isShowPassword ? eyeFill : eyeOffFill} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!error}
        helperText={error?.message}
      />
    </>
  );
};
