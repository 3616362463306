import { RefObject, useEffect } from 'react';

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
): void => {
  useEffect(() => {
    const listener = (event: Event): void => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return (): void => document.removeEventListener('mousedown', listener);
  }, [ref, handler]);
};
