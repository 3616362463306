import * as yup from 'yup';
import {
  emailSchema,
  firstNameSchema,
  surnameSchema,
  userCountrySchema,
} from '../../../utils/validation';

const rules = yup.object({
  name: firstNameSchema(),
  surname: surnameSchema(),
  email: emailSchema(),
  status: yup.number().required('Status is required'),
  country: userCountrySchema(),
  countries: yup.array(),
  customerCare: yup.bool().default(false),
});

export default rules;
