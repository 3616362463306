import {
  Instance, SnapshotOut, types, flow, getEnv, applySnapshot, cast,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  AllCountriesReportListData,
  GroupedClaimsReportModel,
  ReclamationsCountModel,
  ClaimsReportModel,
  ReclamationsCountParams,
  ClaimsListParams,
  AvgTimeProcessingParams,
  AllClaimsReportListData,
  CountriesListParams,
  ReclamationsCSATModel,
} from '../types';
import {
  ALL_COUNTRIES_REPORT_META,
  DEFAULT_AVG_TIME,
  DEFAULT_META,
  DEFAULT_RECLAMATIONS_COUNT_BY_PERIODS,
  DEFAULT_RECLAMATIONS_COUNT_BY_STATUSES,
} from '../constants';

export const ReportStore = types.model('ReportStore')
  .props({
    allClaimsReport: types.optional(ClaimsReportModel, {
      items: [],
      meta: DEFAULT_META,
    }),
    allCountriesReport: types.optional(GroupedClaimsReportModel, {
      items: [],
      meta: ALL_COUNTRIES_REPORT_META,
    }),
    reclamationsCount: types.optional(ReclamationsCountModel, {
      periods: DEFAULT_RECLAMATIONS_COUNT_BY_PERIODS,
      statuses: DEFAULT_RECLAMATIONS_COUNT_BY_STATUSES,
      closedClaims: [],
      all: 0,
      avgTime: DEFAULT_AVG_TIME,
      totalAvgTime: 0,
    }),
    reclamationsCSAT: types.optional(ReclamationsCSATModel, {
      csat: [],
    }),
  })
  .actions((self) => ({
    fetchAllClaimsReport: flow(function* fetchAllClaimsReport(params: ClaimsListParams) {
      try {
        const {
          items,
          meta,
        }: AllClaimsReportListData = yield getEnv(self).api.fetchAllClaimsReport(params);
        applySnapshot(self.allClaimsReport.items, items);
        self.allClaimsReport.meta = meta;
      } catch (e) {
        toast.error('Error with getting all claims report');
      }
    }),
    fetchAllCountriesReport: flow(function* fetchAllCountriesReport(params: CountriesListParams) {
      try {
        const {
          items,
          meta,
        }: AllCountriesReportListData = yield getEnv(self).api.fetchAllCountriesReport(params);
        applySnapshot(self.allCountriesReport.items, items);
        self.allCountriesReport.meta = meta;
      } catch (e) {
        toast.error('Error with getting all countries report');
      }
    }),
    fetchReclamationsCountByPeriods: flow(function* fetchReclamationsCountByPeriods(
      params: ReclamationsCountParams = {},
    ) {
      try {
        self.reclamationsCount.periods = yield getEnv(self)
          .api
          .fetchReclamationsCountByPeriods(params);
      } catch (e) {
        toast.error('Error with getting reclamations count by periods');
      }
    }),
    fetchReclamationsCountByStatuses: flow(function* fetchReclamationsCountByStatuses(
      params: ReclamationsCountParams = {},
    ) {
      try {
        self.reclamationsCount.statuses = yield getEnv(self)
          .api
          .fetchReclamationsCountByStatuses(params);
      } catch (e) {
        toast.error('Error with getting claims count by statuses');
      }
    }),
    fetchClosedReclamationsCount: flow(function* fetchClosedReclamationsCount(
      params: ReclamationsCountParams = {},
    ) {
      try {
        self.reclamationsCount.closedClaims = yield getEnv(self)
          .api
          .fetchClosedReclamationsCount(params);
      } catch (e) {
        toast.error('Error with getting closed claims count');
      }
    }),
    fetchReclamationsCount: flow(function* fetchReclamationsCount(
      params: ReclamationsCountParams = {},
    ) {
      try {
        self.reclamationsCount.all = yield getEnv(self)
          .api
          .fetchReclamationsCount(params);
      } catch (e) {
        toast.error('Error with getting claims count');
      }
    }),
    fetchAvgTimeProcessingReclamations: flow(function* fetchAvgTimeProcessingReclamations(
      params: AvgTimeProcessingParams = {},
    ) {
      try {
        const { totalAvgTime, avgTimePerEntities } = yield getEnv(self)
          .api
          .fetchAvgTimeProcessingReclamations(params);
        self.reclamationsCount.avgTime.totalAvgTime = totalAvgTime || 0;
        self.reclamationsCount.avgTime.avgTimePerEntities = avgTimePerEntities;
      } catch (e) {
        toast.error('Error with getting avg time of processing tickets');
      }
    }),
    fetchReclamationsCSAT: flow(function* fetchReclamationsCSAT(
    ) {
      try {
        self.reclamationsCSAT.csat = yield getEnv(self)
          .api
          .fetchReclamationsCSAT();
      } catch (e) {
        toast.error('Error with getting avg time of processing tickets');
      }
    }),
    resetAvgTime(): void {
      self.reclamationsCount.avgTime = cast(DEFAULT_AVG_TIME);
    },
    resetClosedReclamationsCount(): void {
      self.reclamationsCount.closedClaims = cast([]);
    },
  }));

export type ReportStore = Instance<typeof ReportStore>

export type ReportStoreSnapShot = SnapshotOut<typeof ReportStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createReportStoreModel = () => types.optional(ReportStore, {});
