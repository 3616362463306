import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Link, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../app';
import rules from './rules';
import { LoginFormValues } from '../../../types';
import { useStore } from '../../../hooks';
import { PasswordInput } from '../../../components';
import { getItemFromStorage } from '../../../utils/localStorage';
import { LOCAL_STORAGE_DEVICE_TOKEN } from '../../../constants';

export const LoginForm: React.FC = observer((): JSX.Element => {
  const { push } = useHistory();
  const { auth, notifications: { postNotificationToken } } = useStore();

  const {
    control, handleSubmit,
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(rules),
  });

  const handleLogin = (data: LoginFormValues): void => {
    auth.login(data, () => {
      const deviceToken = getItemFromStorage(LOCAL_STORAGE_DEVICE_TOKEN);
      if (deviceToken) {
        postNotificationToken(deviceToken);
      }
      push(PATH_DASHBOARD.root);
    });
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Stack spacing={3}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({
            field: { onBlur, onChange, value }, fieldState: { error },
          }): JSX.Element => (
            <>
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                fullWidth
                type="text"
                label="Email address"
                error={!!error}
                helperText={error?.message}
              />
            </>

          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({
            field: { onBlur, onChange, value }, fieldState: { error },
          }): JSX.Element => (
            <PasswordInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={error}
              label="Password"
            />
          )}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Login
      </LoadingButton>
    </form>
  );
});
