import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type MenuReturn = { MuiMenuItem: { styleOverrides: { root: { '&.Mui-selected': { backgroundColor: string; '&:hover': { backgroundColor: string } } } } } }
export const Menu = (theme: Theme): MenuReturn => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  },
});
