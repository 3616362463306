import * as yup from 'yup';
import {
  nullableStringSchema,
  objectIdSchema,
  stringReqSchema,
} from '../../../utils';

const rules = yup.object({
  name: stringReqSchema('Name'),
  part: objectIdSchema('Part'),
  model: objectIdSchema('Model'),
  price: nullableStringSchema(),
});

export default rules;
