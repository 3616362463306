import { Instance, types } from 'mobx-state-tree';
import { CityModel } from './CityModel';

export const ShopMetaModel = types.model('ShopMetaModel', {
  currentPage: types.number,
  itemCount: types.number,
  itemsPerPage: types.number,
  totalItems: types.number,
  totalPages: types.number,
});
export const ShopModel = types.model('ShopModel', {
  id: types.maybe(types.number),
  name: types.string,
  address: types.maybeNull(types.string),
  emails: types.array(types.string) || types.string,
  city: types.maybeNull(CityModel),
});

export type Shop = Instance<typeof ShopModel>
