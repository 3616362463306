import React, { forwardRef } from 'react';
import { Box, BoxProps } from '@material-ui/core';

interface PageProps extends BoxProps {
  children: React.ReactNode;
  title?: string;
}

export const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...other }, ref) => (
  <Box ref={ref} {...other}>
    <title>{title}</title>
    {children}
  </Box>
));
