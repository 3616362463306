import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type LoadingButtonReturn = { MuiLoadingButton: { styleOverrides: { root: { '&.MuiButton-text': { '& .MuiLoadingButton-startIconPendingStart': { marginLeft: number }; '& .MuiLoadingButton-endIconPendingEnd': { marginRight: number } } } } } }
export const LoadingButton = (theme: Theme): LoadingButtonReturn => ({
  MuiLoadingButton: {
    styleOverrides: {
      root: {
        '&.MuiButton-text': {
          '& .MuiLoadingButton-startIconPendingStart': {
            marginLeft: 0,
          },
          '& .MuiLoadingButton-endIconPendingEnd': {
            marginRight: 0,
          },
        },
      },
    },
  },
});
