import React, { useEffect, useState, useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import { merge } from 'lodash';
import { observer } from 'mobx-react-lite';
import {
  Typography,
  Grid,
  Autocomplete,
  TextField,
  TextFieldProps,
  Card,
  Box, IconButton,
  useMediaQuery,
} from '@material-ui/core';

import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import DateFnsUtils from '@material-ui/lab/AdapterDateFns';
import ClearIcon from '@material-ui/icons/Clear';
import { useTheme } from '@material-ui/core/styles';
import { BaseOptionChart } from '../../../components';
import { useStore } from '../../../hooks';
import { Option } from '../../../types';
import {
  COMMON_PICKER_DATE_VIEW,
  COMMON_PICKER_MASK,
} from '../../../constants';
import { useDatePeriodFilter, useMultipleSelectFilter } from '../../Dashboard/hooks';

export const CustomerCareRegisteredCustomers: React.FC = observer(
  (): JSX.Element => {
    // Store
    const {
      consumers: { fetchConsumerCountryCount, countriesCount },
      serviceModels: { modelsAutoComplete: models },
      countriesModel: { countriesAutoComplete: countries },
    } = useStore();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // State
    const [modelId, setModelId] = useState<number | undefined>(undefined);

    // Custom hooks
    const {
      dateTo, dateFrom, handleChangeDateTo, handleChangeDateFrom,
    } = useDatePeriodFilter();
    const {
      ids: countryIds,
      handleChangeValues: handleChangeCountries,
    } = useMultipleSelectFilter();

    // Memoization
    const CHART_DATA = [
      { name: 'Count', data: countriesCount.map(({ count }) => count) },
    ];

    const chartOptions = merge(BaseOptionChart(), {
      plotOptions: { bar: { columnWidth: '16%' } },
      stroke: { show: false },
      xaxis: {
        categories: countriesCount.map(({ country }) => country),
      },
      tooltip: {
        y: {
          formatter: (val: number) => (!isMobile ? `${val} number of product registrations ` : `${val}`),
        },
      },
    });

    /// Handlers
    const handleChangeModelId = useCallback(
      // eslint-disable-next-line consistent-return
      (event: React.SyntheticEvent, selectedOption: Option<number> | null) => {
        if (!selectedOption) return setModelId(undefined);

        setModelId(selectedOption.value);
      },
      [setModelId],
    );

    // Fetch countries
    useEffect(() => {
      fetchConsumerCountryCount({
        modelId, dateTo, dateFrom, countryIds,
      });
    }, [modelId, dateTo, dateFrom, countryIds]);

    return (
      <Card>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <Typography variant="h5" gutterBottom>
            Registered products
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                multiple
                autoComplete={false}
                fullWidth
                options={countries}
                onChange={handleChangeCountries}
                getOptionLabel={(option: Option): string => option.label}
                renderInput={(params): JSX.Element => (
                  <TextField {...params} label="Countries" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                autoComplete={false}
                fullWidth
                options={models}
                onChange={handleChangeModelId}
                getOptionLabel={(option: Option): string => option.label}
                renderInput={(params): JSX.Element => (
                  <TextField {...params} label="Models" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={DateFnsUtils}>
                <DatePicker
                  inputFormat={COMMON_PICKER_DATE_VIEW}
                  mask={COMMON_PICKER_MASK}
                  views={['day']}
                  label="Date from"
                  value={dateFrom}
                  onChange={handleChangeDateFrom}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        onClick={(): void => handleChangeDateFrom(null)}
                        disabled={!dateFrom}
                        style={{ order: 1 }}
                      >
                        <ClearIcon color="disabled" fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  InputAdornmentProps={{
                    position: 'end',
                    style: { order: 2, marginLeft: 0 },
                  }}
                  renderInput={(params: TextFieldProps): JSX.Element => (
                    <TextField {...params} fullWidth disabled />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={DateFnsUtils}>
                <DatePicker
                  inputFormat={COMMON_PICKER_DATE_VIEW}
                  mask={COMMON_PICKER_MASK}
                  views={['day']}
                  label="Date to"
                  value={dateTo}
                  onChange={handleChangeDateTo}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        onClick={(): void => handleChangeDateTo(null)}
                        disabled={!dateTo}
                        style={{ order: 1 }}
                      >
                        <ClearIcon color="disabled" fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  InputAdornmentProps={{
                    position: 'end',
                    style: { order: 2, marginLeft: 0 },
                  }}
                  renderInput={(params: TextFieldProps): JSX.Element => (
                    <TextField {...params} fullWidth disabled />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={9} mb={9}>
              <ReactApexChart
                type="bar"
                series={CHART_DATA}
                options={chartOptions}
                fDate
                height={320}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  },
);
