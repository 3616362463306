import React, { useMemo, ReactNode } from 'react';
import { CssBaseline } from '@material-ui/core';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider,
} from '@material-ui/core/styles';
import { useSettings } from '../hooks';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import { ComponentsOverrides } from './overrides';
import shadows, { customShadows } from './shadows';
import { ThemePrimaryColor } from '../components';

type ThemeConfigProps = {
  children: ReactNode;
};

export const ThemeConfig:React.FC<ThemeConfigProps> = ({ children }) => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight],
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <ThemePrimaryColor>
          {children}
        </ThemePrimaryColor>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
