import {
  Instance, SnapshotOut, types, getEnv, flow, applySnapshot,
} from 'mobx-state-tree';
import {
  CityModel,
  ListParams,
  Option,
  CityItem,
  ListMetaDataModel,
} from '../types';
import { CityListData, CityValues } from '../types/cities';
import { mapEntityToOption } from '../utils';
import { COMMON_SCHEMA, DEFAULT_META } from '../constants';

export const CityStoreModel = types.model('CityStore')
  .props({
    items: types.optional(types.array(CityModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    fetchCities: flow(function* fetchCities(params: ListParams) {
      try {
        const res: CityListData = yield getEnv(self).api.fetchCities(params);
        const { items, meta } = res;
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        throw new Error('Error with getting cities');
      }
    }),
  }))
  .views((self) => ({
    getCityById(id): CityValues | undefined {
      if (id) {
        return self.items.find((city) => city.id === Number(id));
      }
      return undefined;
    },
    get citiesAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<CityItem>(item, COMMON_SCHEMA));
    },
  }));

/**
 * The AuthStore instance.
 */
export type CityStore = Instance<typeof CityStoreModel>

/**
 * The data of a AuthStore.
 */
export type CityStoreSnapShot = SnapshotOut<typeof CityStoreModel>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createCityStoreModel = () => types.optional(CityStoreModel, {});
