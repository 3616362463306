import React from 'react';
import {
  alpha, Theme, useTheme, styled,
} from '@material-ui/core/styles';
import { BoxProps } from '@material-ui/core';
import { ColorSchema } from '../../types';

type LabelColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

type LabelVariant = 'filled' | 'outlined' | 'ghost';

const RootStyle = styled('span')(
  ({
    theme,
    styleProps,
  }: {
    theme: Theme;
    styleProps: {
      color: LabelColor;
      variant: LabelVariant;
    };
  }) => {
    const isLight = theme.palette.mode === 'light';
    const { color, variant } = styleProps;

    type StyleFilledReturn = {
      color: string;
      backgroundColor: string;
    }
    const styleFilled = (fillColor: ColorSchema): StyleFilledReturn => ({
      color: theme.palette[fillColor].contrastText,
      backgroundColor: theme.palette[fillColor].main,
    });

    type StyleOutlinedReturn = {
      color: string;
      backgroundColor: string;
      border: string;
    }
    const styleOutlined = (outlineColor: ColorSchema):StyleOutlinedReturn => ({
      color: theme.palette[outlineColor].main,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette[outlineColor].main}`,
    });

    type StyleGhostReturn = {
      color: string;
      backgroundColor: string;
    }
    const styleGhost = (ghostColor: ColorSchema):StyleGhostReturn => ({
      color: theme.palette[ghostColor][isLight ? 'dark' : 'light'],
      backgroundColor: alpha(theme.palette[ghostColor].main, 0.16),
    });

    return {
      height: 22,
      minWidth: 22,
      lineHeight: 0,
      borderRadius: 8,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,

      ...(color !== 'default'
        ? {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) }),
        }
        : {
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500_32]}`,
          }),
          ...(variant === 'ghost' && {
            color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
            backgroundColor: theme.palette.grey[500_16],
          }),
        }),
    };
  },
);

interface LabelProps extends BoxProps {
  color?: LabelColor;
  variant?: LabelVariant;
}

export const Label:React.FC<LabelProps> = ({
  color = 'default', variant = 'ghost', children, sx,
}) => {
  const theme = useTheme();

  return (
    <RootStyle styleProps={{ color, variant }} sx={sx} theme={theme}>
      {children}
    </RootStyle>
  );
};
