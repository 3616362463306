import React, { useCallback, useMemo, useState } from 'react';
import { findIndex } from 'lodash';
import { Box, Grid } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import { Theme } from '@material-ui/core/styles';
import { LightboxModal } from '../../../components';
import { KnowledgeBaseFileType } from '../../../types';

type PostAttachmentsProps = {
  posts: KnowledgeBaseFileType[]
}

const BOX_STYLES: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  minHeight: '250px',
  maxHeight: '250px',
  objectFit: 'cover',
  cursor: 'pointer',
  borderRadius: 1,
};

export const PostAttachments:React.FC<PostAttachmentsProps> = ({ posts }) => {
  const [openLightbox, setOpenLightbox] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const handleOpenLightbox = useCallback((url: string): void => {
    const selectedImagesLightBox = findIndex(posts, (item) => item.preview === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImagesLightBox);
  }, []);
  const handleCloseLightbox = (): void => {
    setOpenLightbox(false);
  };

  const customImages = useMemo(() => posts.map(
    (file, index) => {
      if (index >= 1 && file.type.includes('image')) {
        return file.preview;
      }
      return '';
    },
  ), [posts]);

  return (
    <>
      <Grid container spacing={3} sx={{ marginTop: '10px' }}>
        {posts.map((file, index) => (
          index >= 1 && (
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                key={file.id}
                src={file.preview}
                onClick={(): void => handleOpenLightbox(file.preview)}
                sx={BOX_STYLES}
              />
            </Grid>
          )
        ))}
      </Grid>

      {!!selectedImage && (
        <LightboxModal
          images={customImages}
          photoIndex={selectedImage}
          setPhotoIndex={setSelectedImage}
          isOpen={openLightbox}
          onClose={handleCloseLightbox}
        />
      )}
    </>
  );
};
