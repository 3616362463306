import React from 'react';
import {
  Button, Dialog, DialogActions,
  DialogContent, DialogContentText,
} from '@material-ui/core';

const QUESTION = 'Are you sure?';

type ConfirmDialogProps = {
  callback(): void,
  onClose(): void,
  question?: string,
  isOpened: boolean
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  callback, onClose, isOpened, question = QUESTION,
}): JSX.Element => (
  <Dialog open={isOpened} onClose={onClose}>
    <DialogContent>
      <DialogContentText sx={{ textAlign: 'center' }}>
        {question}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>No</Button>
      <Button onClick={callback} autoFocus>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);
