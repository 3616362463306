import React, { ChangeEvent, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid, Button, Container, Stack, Pagination,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';
import { useStore } from '../../hooks';
import { PATH_DASHBOARD } from '../../app';
import {
  Page, HeaderBreadcrumbs, LoadingScreen, SearchNotFound, PermissionWrapper,
} from '../../components';
import { KnowledgeBaseSearch, KnowledgeBasePostCard } from './components';
import { Module, PermissionAction } from '../../constants';

const SEARCH_TIMEOUT = 400;
const FIRST_PAGE = 1;
const LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Knowledge base', href: PATH_DASHBOARD.knowledgeBases.list },
];

export const KnowledgeBasePosts:React.FC = observer(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_TIMEOUT);
  const { knowledgeBasesModel: { fetchKnowledgeBases, items, meta } } = useStore();
  const [page, setPage] = useState(meta.currentPage);
  const isReady = !!items && !!meta;

  const handlePageChange = (
    event: ChangeEvent<unknown>,
    newPage: number,
  ): void => { setPage(newPage); window.scrollTo(0, 0); };

  const handleFilter = (newFilter: string):void => {
    setSearchValue(newFilter);
  };

  useEffect(() => {
    fetchKnowledgeBases({ page });
  }, []);

  useEffect(() => {
    fetchKnowledgeBases({ title: debouncedSearchValue, page });
  }, [debouncedSearchValue, page, items]);

  return isReady ? (
    <Page title="Knowledge base: Posts | Minimal-UI">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Knowledge base"
          links={LINKS}
        />

        <Stack mb={5} spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <KnowledgeBaseSearch
            searchValue={searchValue}
            onSearchValue={handleFilter}
          />
          <PermissionWrapper
            action={PermissionAction.CREATE}
            module={Module.KNOWLEDGE_BASE}
          >
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.knowledgeBases.newPost}
              startIcon={<Icon icon={plusFill} />}
            >
              New Post
            </Button>
          </PermissionWrapper>
        </Stack>
        {items?.length ? (
          <>
            <Grid container spacing={3}>
              {items?.map((item) => (
                <KnowledgeBasePostCard
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  description={item.description}
                  preview={item.files[0]?.preview || ''}
                  type={item.files[0]?.type || ''}
                />
              ))}
            </Grid>
            <Stack mt={5} direction="row" alignItems="center" justifyContent="center">
              <Pagination
                count={meta.totalPages}
                page={meta.currentPage}
                defaultPage={FIRST_PAGE}
                onChange={handlePageChange}
              />
            </Stack>
          </>
        ) : (<SearchNotFound sx={{ padding: 8 }} />)}
      </Container>
    </Page>
  ) : (<LoadingScreen />);
});
