import { Instance, types } from 'mobx-state-tree';
import { CountryModel } from './CountryModel';

export const UserResponsibleCountryModel = types.model(
  'UserResponsibleCountryModel',
  {
    id: types.number,
    name: types.string,
  },
);

export const UserModel = types.model('UserModel', {
  id: types.number,
  name: types.string,
  surname: types.string,
  email: types.string,
  status: types.number,
  country: types.maybeNull(CountryModel),
  countries: types.optional(types.array(UserResponsibleCountryModel), []),
  customerCare: types.optional(types.boolean, false),
});

export type User = Instance<typeof UserModel>;

export type UserResponsibleCountry = Instance<
  typeof UserResponsibleCountryModel
>;
