import * as Yup from 'yup';

const rules = Yup.object({
  id: Yup.string().default(undefined),
  firstName: Yup.string().default(undefined),
  lastName: Yup.string().default(undefined),
  shopName: Yup.string().default(undefined),
  phone: Yup.string().default(undefined),
  email: Yup.string().default(undefined),
  status: Yup.string().default(undefined),
  countryId: Yup.number().default(undefined),
  modelId: Yup.number().default(undefined),
  dateFrom: Yup.string().nullable().default(null),
  dateTo: Yup.string().nullable().default(null),
});

export default rules;
