import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

// eslint-disable-next-line max-len
type SvhIconReturn = { MuiSvgIcon: { styleOverrides: { fontSizeLarge: { width: number; fontSize: string; height: number }; fontSizeSmall: { width: number; fontSize: string; height: number } } } }
export const SvgIcon = (theme: Theme): SvhIconReturn => ({
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        width: 20,
        height: 20,
        fontSize: 'inherit',
      },
      fontSizeLarge: {
        width: 32,
        height: 32,
        fontSize: 'inherit',
      },
    },
  },
});
