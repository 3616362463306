import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type TableReturn = { MuiTableCell: { styleOverrides: { head: { '&:last-of-type': { paddingRight: string; borderBottomRightRadius: number | string; borderTopRightRadius: number | string }; backgroundColor: string; color: string; '&:first-of-type': { borderBottomLeftRadius: number | string; paddingLeft: string; borderTopLeftRadius: number | string } }; root: { borderBottom: string }; stickyHeader: { backgroundColor: string; backgroundImage: string }; body: { '&:last-of-type': { paddingRight: string }; '&:first-of-type': { paddingLeft: string } } } }; MuiTablePagination: { styleOverrides: { toolbar: { height: number }; selectIcon: { width: number; marginTop: number; height: number }; select: { '&:focus': { borderRadius: number | string } }; root: { borderTop: string } } }; MuiTableRow: { styleOverrides: { root: { '&.Mui-selected': { backgroundColor: string; '&:hover': { backgroundColor: string } } } } } }
export const Table = (theme: Theme):TableReturn => ({
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: 'none',
      },
      head: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.neutral,
        '&:first-of-type': {
          paddingLeft: theme.spacing(3),
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        },
        '&:last-of-type': {
          paddingRight: theme.spacing(3),
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
      },
      stickyHeader: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
      },
      body: {
        '&:first-of-type': {
          paddingLeft: theme.spacing(3),
        },
        '&:last-of-type': {
          paddingRight: theme.spacing(3),
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        borderTop: `solid 1px ${theme.palette.divider}`,
      },
      toolbar: {
        height: 64,
      },
      select: {
        '&:focus': {
          borderRadius: theme.shape.borderRadius,
        },
      },
      selectIcon: {
        width: 20,
        height: 20,
        marginTop: 2,
      },
    },
  },
});
