import React from 'react';
import { styled } from '@material-ui/core/styles';

const RootStyle = styled('div')(() => ({
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

const TitleStyle = styled('h1')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
  },
}));

const CoverImgStyle = styled('img')(() => ({
  width: '100%',
  height: '100%',
  maxHeight: '600px',
  objectFit: 'cover',
}));

const CoverVideoStyle = styled('video')(() => ({
  width: '100%',
  height: '100%',
  maxHeight: '600px',
  objectFit: 'cover',
}));

type KnowledgeBasePostHeroProps = {
  title: string;
  preview: string;
  type: string;
};

export const KnowledgeBasePostHero:React.FC<KnowledgeBasePostHeroProps> = ({
  title,
  preview,
  type,
}): JSX.Element => (
  <RootStyle>
    <TitleStyle sx={{ typography: 'h2' }}>{title}</TitleStyle>
    {preview && (
      type?.includes('video') ? (
        <CoverVideoStyle autoPlay loop muted src={preview} />
      ) : (
        <CoverImgStyle alt="post cover" src={preview} />
      )
    )}
  </RootStyle>
);
