import React, { useState } from 'react';
import {
  Paper, Typography, Box,
} from '@material-ui/core';
import navigationOutline from '@iconify/icons-eva/navigation-outline';
import fileTextOutline from '@iconify/icons-eva/file-text-outline';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';
import { KanbanCountryDetails } from './KanbanCountryDetails';

type KanbanTaskCardProps = {
  id: number;
  name: string;
  count: string;
  status: string;
};

export const KanbanCountryCard:React.FC<KanbanTaskCardProps> = observer((
  {
    id,
    name,
    count,
    status,
  },
): JSX.Element => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const handleOpenDetails = (): void => {
    setOpenDetails(true);
  };

  const handleCloseDetails = (): void => {
    setOpenDetails(false);
  };

  return (
    <>
      <Paper
        sx={{
          px: 2,
          width: 1,
          position: 'relative',
          boxShadow: (theme): string => theme.customShadows.z1,
          '&:hover': {
            boxShadow: (theme): string => theme.customShadows.z16,
          },
        }}
        onClick={handleOpenDetails}
      >
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <Icon icon={navigationOutline} width={20} height={20} />
          <Typography
            noWrap
            variant="body2"
            sx={{ py: 2, pl: 1 }}
          >
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            cursor: 'pointer', display: 'flex', alignItems: 'center', pb: 2,
          }}
        >
          <Icon icon={fileTextOutline} width={20} height={20} />
          <Typography
            noWrap
            variant="body2"
            sx={{
              pl: 1,
            }}
          >
            Total claims:
          </Typography>
          <Typography
            noWrap
            variant="body2"
            sx={{ pl: 1 }}
          >
            {count}
          </Typography>
        </Box>
      </Paper>

      <KanbanCountryDetails
        id={id}
        name={name}
        count={count}
        status={status}
        isOpen={openDetails}
        handleClose={handleCloseDetails}
      />
    </>
  );
});
