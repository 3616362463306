import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------
type BreadcrumbsReturn = {
  MuiBreadcrumbs: { styleOverrides: { separator: { marginLeft: string; marginRight: string; }; }; };
}
export const Breadcrumbs = (theme: Theme): BreadcrumbsReturn => ({
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
  },
});
