import React from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@material-ui/core/styles';
import {
  Box,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      width: 240,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12,
      },
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: '1px !important',
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-of-type)': {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 'auto', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

type KnowledgeBaseSearchProps = {
    searchValue: string;
    onSearchValue: (newSearchValue: string) => void;
};

export const KnowledgeBaseSearch:React.FC<KnowledgeBaseSearchProps> = ({
  searchValue,
  onSearchValue,
}) => (
  <RootStyle>
    <SearchStyle
      value={searchValue}
      onChange={(e): void => onSearchValue(e.target.value)}
      placeholder="Search"
      startAdornment={(
        <InputAdornment position="start">
          <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
        </InputAdornment>
      )}
    />
  </RootStyle>
);
