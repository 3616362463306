import { ApiResponse } from 'apisauce';
import { getGeneralApiProblem } from './api-problem';

export type ApiErrorData = {
  message: string,
  statusCode: number,
  error: string,
}

export function checkResponse<T>(response: ApiResponse<T>): void {
  if (!response.ok) {
    const exception = getGeneralApiProblem<T>(response);
    if (exception) throw exception;
  }
}
