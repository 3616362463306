import { UserRole } from '../constants';

export enum InputTypes {
  TEXT = 'text',
  PASSWORD = 'password'
}

export enum TokenType {
  INVITATION,
  RESET,
}

export type RegisterFormValues = {
  name: string,
  surname: string,
  password: string,
  countryId: number,
}

export type RegisterData = {
  invitationToken: string,
} & RegisterFormValues;

export type LoginFormValues = {
  email: string,
  password: string,
}
export type ForgotPasswordValues = {
  email: string,
}

export type ResetPasswordValues = {
  password: string,
}

export type ResetPasswordData = {
  token: string,
} & ResetPasswordValues;

export type CheckTokenValues = {
  token: string,
  type: TokenType,
}

export type ChangePasswordValues = {
  currentPassword: string,
  newPassword: string,
}

export type ProfileValues = {
  name: string,
  surname: string,
  phone: string | null,
  email: string,
  notify: boolean,
  country: {
    id: number,
  }
}

export type AvatarValues = {
  fileId: string,
}

export type InviteValues = {
  email: string,
  role: UserRole,
}

export type AuthData = {
  accessToken: string,
}
