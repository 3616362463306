import { Theme } from '@material-ui/core/styles';

type AvatarReturn = {
  MuiAvatar: { styleOverrides: { colorDefault: { color: string; backgroundColor: string; }; }; };
  MuiAvatarGroup: { styleOverrides: { avatar: { fontSize: number; fontWeight: unknown; '&:first-of-type': {
    fontSize: number;
          color: string;
              backgroundColor: string;
        };
      };
    };
  };
}

export const Avatar = (theme: Theme): AvatarReturn => ({
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[400],
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      avatar: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        '&:first-of-type': {
          fontSize: 14,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter,
        },
      },
    },
  },
});
