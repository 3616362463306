import React from 'react';
import { Container } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../app';
// components
import { Page, HeaderBreadcrumbs } from '../../components';
import { CountriesForm } from './CountriesForm';

const BREADCRUMB_LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Countries list', href: PATH_DASHBOARD.countries.list },
  { name: 'Country' },
];

export const CountriesUpdate: React.FC = (): JSX.Element => (
  <Page title="Shops: Edit shop | Minimal-UI">
    <Container maxWidth="lg">
      <HeaderBreadcrumbs
        heading="Edit country"
        links={BREADCRUMB_LINKS}
      />
      <CountriesForm />
    </Container>
  </Page>
);
