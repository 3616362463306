import React from 'react';
import { HeadItem } from '../styles';

export const HeadItems: React.FC = (): JSX.Element => (
  <>
    <HeadItem style={{ minWidth: '5.5%', maxWidth: '5.5%' }}>
      Q-ty of complaints
    </HeadItem>
    <HeadItem style={{ minWidth: '8.5%', maxWidth: '8.5%' }}>
      Country
    </HeadItem>
    <HeadItem style={{ minWidth: '8.5%', maxWidth: '8.5%' }}>
      Model
    </HeadItem>
    <HeadItem style={{ minWidth: '6.5%', maxWidth: '6.5%' }}>
      Claim №
    </HeadItem>
    <HeadItem style={{ minWidth: '9%', maxWidth: '9%' }}>
      Part
    </HeadItem>
    <HeadItem style={{ minWidth: '13.7%', maxWidth: '13.7%' }}>Code</HeadItem>
    <HeadItem style={{ minWidth: '6%', maxWidth: '6%' }}>Warranty</HeadItem>
    <HeadItem style={{ maxWidth: '9%' }}>Way of solution</HeadItem>
    <HeadItem style={{ minWidth: '11.5%', maxWidth: '11.5%' }}>Spare parts</HeadItem>
    <HeadItem style={{ minWidth: '8%', maxWidth: '8%' }}>SP Q-ty</HeadItem>
    <HeadItem style={{ minWidth: '8%', maxWidth: '8%' }}>Price</HeadItem>
    <HeadItem style={{ minWidth: '7%', maxWidth: '7%' }}>Total per country</HeadItem>
  </>
);
