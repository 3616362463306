import React, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import {
  Box, Container, Tab, Tabs,
} from '@material-ui/core';
import { capitalCase } from 'change-case';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { HeaderBreadcrumbs, Page } from '../../components';
import { SHOP_TABS, ShopValues, LINKS } from './constants';
import { useStore } from '../../hooks';
import { QueryParams } from '../../types';

export const Shop: React.FC = observer((): JSX.Element => {
  const id = useParams<QueryParams>();
  const {
    shopsModel: {
      getShopById,
      fetchShop,
    },
  } = useStore();

  const shop = getShopById(id.id);

  const [currentTab, setCurrentTab] = useState<ShopValues>(SHOP_TABS[0].value);

  const handleChangeTab = useCallback((e: SyntheticEvent, value: ShopValues): void => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    if (!shop) {
      fetchShop(id);
    }
  }, []);

  return (
    <Page title="Shop">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Shop"
          links={LINKS}
        />

        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {SHOP_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Box sx={{ mb: 5 }} />
        {
          SHOP_TABS.map((
            tab,
          ) => tab.value === currentTab && <Box key={tab.value}>{tab.component}</Box>)
        }
      </Container>
    </Page>
  );
});
