import { Icon } from '@iconify/react';
import plusSquareOutline from '@iconify/icons-eva/plus-square-outline';
import minusSquareOutline from '@iconify/icons-eva/minus-square-outline';
import closeSquareOutline from '@iconify/icons-eva/close-square-outline';
import { Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const ICON_SIZE = { width: 20, height: 20 };

export const TreeView = (theme: Theme): unknown => ({
  MuiTreeView: {
    defaultProps: {
      // eslint-disable-next-line react/react-in-jsx-scope
      defaultCollapseIcon: <Icon icon={minusSquareOutline} {...ICON_SIZE} />,
      // eslint-disable-next-line react/react-in-jsx-scope
      defaultExpandIcon: <Icon icon={plusSquareOutline} {...ICON_SIZE} />,
      defaultEndIcon: (
      // eslint-disable-next-line react/react-in-jsx-scope
        <Box
          component={Icon}
          icon={closeSquareOutline}
          {...ICON_SIZE}
          sx={{ color: 'text.secondary' }}
        />
      ),
    },
  },
  MuiTreeItem: {
    styleOverrides: {
      // eslint-disable-next-line react/destructuring-assignment
      label: { ...theme.typography.body2 },
      iconContainer: { width: 'auto' },
    },
  },
});
