import React from 'react';
import { icons, IconNames } from '../../constants';

type IconProps = {
    name: IconNames,
    width?: number,
    height?: number,
}

export const Icon: React.FC<IconProps> = ({
  name, width = 24, height = 24, ...rest
}) => {
  const Svg = icons[name];

  return (
    <div>
      <Svg width={24} height={24} {...rest} />
    </div>
  );
};
