import React from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment, useMediaQuery,
} from '@material-ui/core';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '48%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

type ShopsListToolbarProps = {
    searchNameValue: string;
    searchEmailValue: string;
    onSearchName: (newSearchValue: string) => void;
    onSearchEmail: (newSearchValue: string) => void;
};

export const ShopsListToolbar:React.FC<ShopsListToolbarProps> = ({
  searchNameValue,
  searchEmailValue,
  onSearchName,
  onSearchEmail,
}): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <RootStyle sx={{ width: isMobile ? '100%' : '60%' }}>
      <SearchStyle
        value={searchNameValue}
        onChange={(e): void => onSearchName(e.target.value)}
        placeholder="Name"
        startAdornment={(
          <InputAdornment position="start">
            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
          </InputAdornment>
                )}
      />
      <SearchStyle
        value={searchEmailValue}
        onChange={(e): void => onSearchEmail(e.target.value)}
        placeholder="Email"
        startAdornment={(
          <InputAdornment position="start">
            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
          </InputAdornment>
                )}
      />
    </RootStyle>
  );
};
