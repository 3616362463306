import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useSearch, useStore } from '../../../hooks';
import {
  CommonListHead,
  CommonListToolbar,
  ConfirmDialog,
  HeaderBreadcrumbs,
  Page,
  Scrollbar,
  SearchNotFound,
} from '../../../components';
import { InviteUser } from '../Invite';
import { LINKS, TABLE_HEAD_LIST } from './constants';
import { UserItem } from './UserItem';
import { DEFAULT_ID, FIRST_PAGE } from '../../../constants';
import { useSorting } from '../../../hooks/useSorting';

export const UserList:React.FC = observer(() => {
  const {
    auth: {
      profile,
    },
    users: {
      meta,
      fetchUsers,
      items,
      deleteUser,
    },
  } = useStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { debouncedSearchValue, searchValue, setSearchValue } = useSearch();

  const [isOpenedInviteModal, setIsOpenedInviteModal] = useState<boolean>(false);
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(DEFAULT_ID);
  const [page, setPage] = useState<number>(FIRST_PAGE);

  const toggleInviteModal = (value: boolean) => (): void => {
    setIsOpenedInviteModal(value);
  };

  const toggleDeleteModal = (value: boolean) => (): void => {
    setIsOpenedDeleteModal(value);
  };

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  };

  const handleOpenDeleteModal = useCallback((id: number): void => {
    setUserId(id);
    setIsOpenedDeleteModal(true);
  }, []);

  const handleDelete = (): void => {
    deleteUser(userId);
    setIsOpenedDeleteModal(false);
    setUserId(DEFAULT_ID);
  };

  const {
    sort, order, orderBy, onClickSorting,
  } = useSorting();

  useEffect(() => {
    fetchUsers({ search: debouncedSearchValue, page, sort });
  }, [debouncedSearchValue, page, sort]);

  return (
    <Page title="User: List | Minimal-UI">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Users List"
          links={LINKS}
          action={(
            <Button
              variant="contained"
              onClick={toggleInviteModal(true)}
              startIcon={<Icon icon={plusFill} />}
            >
              Invite User
            </Button>
          )}
        />
        <Card>
          <CommonListToolbar
            searchValue={searchValue}
            onSearch={setSearchValue}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <CommonListHead
                  headListData={TABLE_HEAD_LIST}
                  order={order}
                  orderBy={orderBy}
                  onClick={onClickSorting}
                />
                <TableBody>
                  {items?.length ? (
                    items.map((item) => (
                      <UserItem
                        key={item.id}
                        data={item}
                        profileId={profile.id}
                        onDelete={handleOpenDeleteModal}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {!!items?.length && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={meta.totalItems}
              rowsPerPage={meta.itemsPerPage}
              page={meta.currentPage - 1}
              onPageChange={handlePageChange}
            />
          )}
        </Card>
      </Container>
      <InviteUser isOpened={isOpenedInviteModal} onClose={toggleInviteModal(false)} />
      <ConfirmDialog
        isOpened={isOpenedDeleteModal}
        onClose={toggleDeleteModal(false)}
        callback={handleDelete}
      />
    </Page>
  );
});
