import { Instance, types } from 'mobx-state-tree';
import {
  ReclamationStatus as ReclamationStatusEnum,
  WayOfSolution as WayOfSolutionEnum,
} from '../../constants';
import { StrollerPartModel } from './StrollerPartModel';
import { DeviationCodeModel } from './DeviationCodeModel';
import { BaseConsumerProps } from './ConsumerModel';
import { CommonModel } from './CommonModel';

const DEFAULT_PROPERTIES = {
  id: types.number,
  name: types.string,
};

const ManagerModel = types.model(DEFAULT_PROPERTIES);

const ReclamationStatus = types.enumeration('ReclamationStatus', [
  ReclamationStatusEnum.NEW,
  ReclamationStatusEnum.PENDING,
  ReclamationStatusEnum.RESOLVED,
  ReclamationStatusEnum.CLOSED,
]);

const WayOfSolution = types.enumeration('WayOfSolution', [
  WayOfSolutionEnum.NEW, WayOfSolutionEnum.REPAIR,
]);

const FileModel = types.model('ReclamationFileModel', {
  id: types.number,
  file: types.string,
  createdAt: types.string,
});

const SparePartModel = types.model('SparePartModel', {
  id: types.number,
  name: types.string,
  createdAt: types.string,
  updatedAt: types.string,
});

export const ReclamationChannelModel = types.model('ChannelModel', {
  id: types.number,
  lastMessageServiceType: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  viberChatId: types.maybeNull(types.string),
  telegramChatId: types.maybeNull(types.string),
  whatsAppChatId: types.maybeNull(types.string),
});

export const ReclamationItemModel = types.model({
  id: types.number,
  createdAt: types.string,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  partCodeDescriptions: types.optional(types.array(types.string), []),
  shop: types.maybeNull(types.model(DEFAULT_PROPERTIES)),
  country: types.model(DEFAULT_PROPERTIES),
  model: types.maybeNull(types.model(DEFAULT_PROPERTIES)),
  color: types.maybeNull(types.model(DEFAULT_PROPERTIES)),
  status: ReclamationStatus,
  files: types.optional(types.array(types.string), []),
  commentCount: types.string,
  notes: types.maybeNull(types.string),
  channel: types.maybeNull(ReclamationChannelModel),
});

export const ReclamationPartModel = types.model('ReclamationPartModel', {
  id: types.number,
  file: types.maybeNull(types.string),
  warranty: types.maybeNull(types.number),
  price: types.maybeNull(types.string),
  quantity: types.maybeNull(types.number),
  sparePart: types.maybeNull(SparePartModel),
  strollerPart: types.maybeNull(StrollerPartModel),
  wayOfSolution: types.maybeNull(WayOfSolution),
  createdAt: types.string,
  updatedAt: types.string,
  deletedAt: types.maybeNull(types.string),
  deviationCodes: types.optional(types.array(DeviationCodeModel), []),
  totalPrice: types.maybeNull(types.string),
});

export const ReclamationConsumerModel = types.model(BaseConsumerProps);

export const ReclamationModel = types.model({
  id: types.number,
  serialNumber: types.string,
  datePurchase: types.maybe(types.maybeNull(types.string)),
  description: types.maybeNull(types.string),
  check: types.string,
  userType: types.number,
  firstName: types.string,
  lastName: types.string,
  phone: types.string,
  email: types.maybeNull(types.string),
  durationOfUse: types.string,
  files: types.optional(types.array(FileModel), []),
  pickUpTrackingNumber: types.maybeNull(types.string),
  deliveryTrackingNumber: types.maybeNull(types.string),
  deliveryService: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
  manager: types.maybeNull(ManagerModel),
  managerComment: types.maybe(types.maybeNull(types.string)),
  status: ReclamationStatus,
  pickupService: types.maybeNull(types.string),
  pickUpDate: types.maybeNull(types.string),
  labourCost: types.maybeNull(types.string),
  deliveryCity: types.maybeNull(types.string),
  deliveryStreet: types.maybeNull(types.string),
  deliveryPostCode: types.maybeNull(types.string),
  deliveryCost: types.maybeNull(types.string),
  qualityReport: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.string,
  country: CommonModel,
  deliveryCountry: CommonModel,
  consumer: types.maybeNull(ReclamationConsumerModel),
  shop: types.maybeNull(CommonModel),
  model: CommonModel,
  color: CommonModel,
  parts: types.optional(types.array(ReclamationPartModel), []),
  totalPrice: types.maybeNull(types.string),
  dateSolution: types.maybeNull(types.string),
  channel: types.maybeNull(ReclamationChannelModel),
});

export const ReclamationCommentModel = types.model({
  id: types.number,
  comment: types.string,
  reclamation: types.model({
    id: types.number,
  }),
  author: types.model({
    id: types.number,
    name: types.string,
    surname: types.string,
  }),
  createdAt: types.string,
  updatedAt: types.string,
});

export const UnconnectedReclamationItemModel = types.model({
  id: types.number,
});

export const FiltersModel = types.model({
  id: types.maybe(types.string),
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  shopName: types.maybe(types.string),
  serialNumber: types.maybe(types.string),
  inRepair: types.maybe(types.number),
  status: types.maybe(types.string),
  countryId: types.maybe(types.number),
  modelId: types.maybe(types.number),
  dateFrom: types.maybeNull(types.string),
  dateTo: types.maybeNull(types.string),
});

export type ReclamationItem = Instance<typeof ReclamationItemModel>

export type Reclamation = Instance<typeof ReclamationModel>

export type ReclamationComment = Instance<typeof ReclamationCommentModel>

export type UnconnectedReclamationItem = Instance<typeof UnconnectedReclamationItemModel>

export type Filters = Instance<typeof FiltersModel>
