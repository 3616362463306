import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { LoadingButton } from '@material-ui/lab';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Stack,
  TextField,
} from '@material-ui/core';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { ShopParams, Option, ShopFormValues } from '../../types';
import rules from './rules';
import { useStore, useAutocomplete, useRequest } from '../../hooks';
import { getOption } from '../../utils';
import {
  List, DEFAULT_AUTOCOMPLETE_ID, PermissionAction, Module,
} from '../../constants';
import { PermissionWrapper } from '../../components';
import { StateWrapper } from '../../components/common/StateWrapper';

const DEFAULT_SHOP = {
  name: '',
  emails: [''],
  address: '',
  cityId: DEFAULT_AUTOCOMPLETE_ID,
};

export const ShopForm:React.FunctionComponent = observer(() => {
  const { shopsModel: { getShopById, updateShop, fetchShop } } = useStore();
  const id = useParams<ShopParams>();
  const currentShop = getShopById(id.id);
  const cities = useAutocomplete(List.Cities);

  const { isFetching, isError } = useRequest({
    action: fetchShop,
    params: id,
    condition: !currentShop,
  });

  const {
    control, handleSubmit, setValue, reset,
  } = useForm<ShopFormValues>({
    defaultValues: DEFAULT_SHOP,
    resolver: yupResolver(rules),
  });

  const onSubmit = async (values: ShopFormValues): Promise<void> => {
    await updateShop(id, values);
  };

  const handleChangeCityId = useCallback((_, data: Option): void => {
    setValue('cityId', data.value || DEFAULT_AUTOCOMPLETE_ID);
  }, []);
  const handleChangeEmails = useCallback((_, data: string): void => {
    setValue(_, data);
  }, []);

  useEffect(() => {
    if (currentShop) {
      reset({
        name: currentShop.name,
        emails: currentShop.emails,
        address: currentShop.address || '',
        cityId: currentShop.city?.id,
      });
    }
  }, [currentShop]);

  const isReady = !!currentShop && !!cities.length && !isFetching;

  return (
    <StateWrapper isError={isError} isReady={isReady}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <Controller
                    name="name"
                    control={control}
                    render={({
                      field: { onBlur, onChange, value }, fieldState: { error },
                    }): JSX.Element => (
                      <TextField
                        fullWidth
                        label="Name"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  {currentShop?.emails && currentShop?.emails?.map((item, index) => (
                    <Controller
                      name={`emails.${index}`}
                      control={control}
                      render={({
                        field: { onBlur, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Email"
                          value={value}
                          onChange={(
                            e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                          ): void => {
                            handleChangeEmails(`emails.${index}`, e.target.value);
                          }}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  ))}
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <Controller
                    name="address"
                    control={control}
                    render={({
                      field: { onBlur, onChange, value }, fieldState: { error },
                    }): JSX.Element => (
                      <TextField
                        fullWidth
                        label="Address"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="cityId"
                    control={control}
                    render={({
                      field: { value }, fieldState: { error },
                    }): JSX.Element => (
                      <Autocomplete
                        disableClearable
                        autoComplete={false}
                        fullWidth
                        value={getOption(cities, value)}
                        options={cities}
                        defaultValue={{
                          value: currentShop?.city?.id || DEFAULT_AUTOCOMPLETE_ID,
                          label: currentShop?.city?.name || '',
                        }}
                        onChange={handleChangeCityId}
                        getOptionLabel={(option: Option): string => option.label}
                        renderInput={(autocompleteParams): JSX.Element => (
                          <TextField
                            {...autocompleteParams}
                            label="City"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Stack>
                <PermissionWrapper
                  action={PermissionAction.EDIT}
                  module={Module.SHOPS}
                >
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained">
                      Save Changes
                    </LoadingButton>
                  </Box>
                </PermissionWrapper>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </form>
    </StateWrapper>
  );
});
