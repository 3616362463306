import { FieldPath } from 'react-hook-form';
import { ReclamationInfoValues } from '../../../types';

type DeviationCodesConfig = {
  id: number,
  name: FieldPath<ReclamationInfoValues>
}

export const DEVIATION_CODES_CONFIG: DeviationCodesConfig[] = [
  {
    id: 0,
    name: 'parts.0.deviationCodes.0.id',
  },
  {
    id: 1,
    name: 'parts.0.deviationCodes.1.id',
  },
  {
    id: 2,
    name: 'parts.0.deviationCodes.2.id',
  },
];
