import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------
type AutocompleteReturn ={
  MuiAutocomplete: { styleOverrides: { paper: { boxShadow: string; }; }; };
}
export const Autocomplete = (theme: Theme): AutocompleteReturn => ({
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        boxShadow: theme.customShadows.z20,
      },
    },
  },
});
