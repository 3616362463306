import React, { useEffect, useState } from 'react';
import {
  Card, CardHeader, TablePagination, useMediaQuery,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@material-ui/core/styles';
import { AccordionWrapper, NotFound, Scrollbar } from '../../../components';
import { useStore } from '../../../hooks';
import { GroupedClaimsToolbar } from './components/GroupedClaimsToolbar';
import { CustomItem } from './components/CustomItem';
import {
  Head, ItemContent, Item, ListWrapper, MainList, List, Container, ItemContentWrapper,
} from './styles';
import { SparePartItems } from './components/SparePartItems';
import { HeadItems } from './components/HeadItems';
import { FIRST_PAGE } from '../../../constants';

const MIN_WIDTH = '1235px';
const MOBILE_WIDTH = '1235px';
const DESKTOP_WIDTH = '100%';

export const GroupedClaims: React.FC = observer((): JSX.Element => {
  const [page, setPage] = useState<number>(FIRST_PAGE);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const {
    reports: {
      fetchAllCountriesReport,
      allCountriesReport: {
        items,
        meta,
      },
    },
  } = useStore();

  useEffect(() => {
    fetchAllCountriesReport({ page });
  }, [page]);

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  };

  return (
    <Card>
      <CardHeader title="Report 2" />
      <AccordionWrapper>
        <GroupedClaimsToolbar
          page={FIRST_PAGE}
        />
      </AccordionWrapper>
      <Scrollbar>
        <ListWrapper>
          <Head
            style={{
              minWidth: isMobile ? MOBILE_WIDTH : MIN_WIDTH,
              maxWidth: isMobile ? MOBILE_WIDTH : DESKTOP_WIDTH,
            }}
          >
            <HeadItems />
          </Head>
          {items.length ? (
            <MainList
              style={{
                minWidth: isMobile ? MOBILE_WIDTH : MIN_WIDTH,
                maxWidth: isMobile ? MOBILE_WIDTH : DESKTOP_WIDTH,
                overflowX: 'auto',
              }}
            >
              {items.map((country) => (
                <Item style={{ borderRight: '1px solid rgba(145, 158, 171, 0.16)' }}>
                  {!!country.models.length && (
                    <Container>
                      <ItemContentWrapper>
                        <ItemContent>
                          {country.complaintsQuantity}
                        </ItemContent>
                      </ItemContentWrapper>
                      <ItemContentWrapper isLeftBorder width={isTablet ? '105px' : '130px'}>
                        <ItemContent>
                          {country.name}
                        </ItemContent>
                      </ItemContentWrapper>
                    </Container>
                  )}
                  <List style={{ borderRight: '1px solid rgba(145, 158, 171, 0.16)' }}>
                    {country.models.map((model) => (
                      <CustomItem title={model.name} width="10.5%">
                        {model.claims.map((claim) => (
                          <CustomItem title={claim.id} width="9%">
                            {!!claim.parts.length && claim.parts.map((part) => (
                              <>
                                {part.name !== '' && (
                                  <CustomItem title={part.name} width="13.5%">
                                    {part.codes.map((code) => (
                                      <CustomItem title={code.name} width="24.5%">
                                        {part.sparePart && (
                                        <SparePartItems sparePart={part.sparePart} />
                                        )}
                                      </CustomItem>
                                    ))}
                                  </CustomItem>
                                )}
                              </>
                            ))}
                          </CustomItem>
                        ))}
                      </CustomItem>
                    ))}
                  </List>
                  <CustomItem title={country.totalPerCountry} width="100%" sx={{ minWidth: '100px !important' }} />
                </Item>
              ))}
            </MainList>
          ) : (<NotFound message="There are no grouped claims" sx={{ padding: '20px' }} />)}
        </ListWrapper>
      </Scrollbar>

      {!!items?.length && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={meta.totalItems}
          rowsPerPage={meta.itemsPerPage}
          page={meta.currentPage - 1}
          onPageChange={handlePageChange}
        />
      )}
    </Card>
  );
});
