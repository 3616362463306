import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type SkeletonReturn = {
  MuiSkeleton: {
    defaultProps: { animation: string }; styleOverrides: { root: { backgroundColor: string } }
  }
}
export const Skeleton = (theme: Theme): SkeletonReturn => ({
  MuiSkeleton: {
    defaultProps: {
      animation: 'wave',
    },

    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.neutral,
      },
    },
  },
});
