import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { SettingsProvider, StoreProvider } from '../contexts';
import { ThemeConfig } from '../theme';
import { NotistackProvider, Settings } from '../components';
import { Routing } from './Routing';

const App: React.FC = (): JSX.Element => (
  <BrowserRouter>
    <Toaster />
    <StoreProvider>
      <SettingsProvider>
        <ThemeConfig>
          <NotistackProvider>
            <Routing />
            <Settings />
          </NotistackProvider>
        </ThemeConfig>
      </SettingsProvider>
    </StoreProvider>
  </BrowserRouter>
);

export default App;
