import {
  applySnapshot,
  flow, getEnv, Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  KanbanStatusModel,
  KanbanReclamationModel,
  KanbanReclamationsParams,
  ListMetaDataModel, KanbanReclamationsListData,
} from '../types';
import { DEFAULT_META } from '../constants';

const DEFAULT_RECLAMATION = {
  id: 0,
  firstName: '',
  lastName: '',
  modelName: '',
  colorName: '',
  serialNumber: '',
  createdAt: '',
};

export const KanbanStore = types.model('KanbanStore')
  .props({
    statuses: types.optional(types.array(KanbanStatusModel), []),
    reclamations: types.optional(types.array(KanbanReclamationModel), [DEFAULT_RECLAMATION]),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    fetchKanbanStatuses: flow(function* fetchKanbanStatuses() {
      try {
        self.statuses = yield getEnv(self).api.fetchKanbanStatuses();
      } catch (e) {
        toast.error('Error with getting statuses');
      }
    }),
    fetchKanbanReclamations: flow(function* fetchKanbanReclamations(
      params: KanbanReclamationsParams,
    ) {
      try {
        const res: KanbanReclamationsListData = (
          yield getEnv(self).api.fetchKanbanReclamations(params)
        );
        const { items, meta } = res;
        applySnapshot(self.reclamations, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting reclamations');
      }
    }),
  }));

export type KanbanStore = Instance<typeof KanbanStore>

export type KanbanStoreSnapShot = SnapshotOut<typeof KanbanStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createKanbanStoreModel = () => types.optional(KanbanStore, {});
