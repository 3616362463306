import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { ConfigCountCard } from '../../../types';

type ClaimsCountCardProps = ConfigCountCard

export const ClaimsCountCard: React.FC<ClaimsCountCardProps> = ({
  title,
  value,
  color,
}): JSX.Element => (
  <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="h3">{value}</Typography>
    </Box>
  </Card>
);
