import React, { ReactNode, useState } from 'react';
import { Icon } from '@iconify/react';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { alpha, styled, useTheme } from '@material-ui/core/styles';
import {
  Box,
  BoxProps,
  Collapse,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { PermissionWrapper } from './PermissionWrapper';
import { Module, PermissionAction } from '../../constants';

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
  isShow?: boolean
};

const NavItem: React.FC<NavItemProps> = ({
  title,
  path,
  icon,
  info,
  children,
  isShow,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isActiveRoot = path ? !!matchPath(pathname, { path }) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = (): void => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          disableGutters
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((subItem) => {
                const { title: subTitle, path: subPath } = subItem;
                const isActiveSub = path ? !!matchPath(pathname, { path: subPath }) : false;

                return (
                  <ListItemStyle
                    disableGutters
                    key={subTitle}
                    component={RouterLink}
                    to={subPath}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (transitionTheme): string => transitionTheme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main',
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={subTitle} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      disableGutters
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
};

type NavItemConfig = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  module: Module;
  children?: {
    title: string;
    path: string;
  }[];
}

export interface NavConfigType {
  subheader: string;
  items: NavItemConfig[];
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: NavConfigType[];
}

export const NavSection: React.FC<NavSectionProps> = ({
  navConfig,
  isShow = true,
  ...other
}) => (
  <Box {...other}>
    {navConfig.map((list) => {
      const { subheader, items } = list;
      return (
        <List key={subheader} disablePadding>
          {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
          {items.map((item) => (
            <PermissionWrapper
              module={item.module}
              action={PermissionAction.SHOW}
            >
              <NavItem
                key={item.title}
                title={item.title}
                path={item.path}
                icon={item.icon}
                info={item.info}
                isShow={isShow}
              >
                {item.children}
              </NavItem>
            </PermissionWrapper>
          ))}
        </List>
      );
    })}
  </Box>
);
