export enum LanguagesEnum {
  English = 'en',
  Spanish = 'es',
  Italian = 'it',
  Poland = 'pl',
  Ukraine = 'uk',
  Russian = 'ru',
  German = 'de',
}

export const TRANSLATIONS = {
  [LanguagesEnum.English]: {
    title: 'Thank you for the feedback.',
    description: 'We appreciate the time you took to share your experience.',
  },
  [LanguagesEnum.Ukraine]: {
    title: 'Дякуємо за ваш відгук!',
    description: 'Ми вдячні за те, що ви поділилися своїм досвідом.',
  },
  [LanguagesEnum.Russian]: {
    title: 'Спасибо за ваш отзыв!',
    description: 'Мы благодарны за время, которое вы потратили, чтобы поделиться своим опытом.',
  },
  [LanguagesEnum.Poland]: {
    title: 'Dziękuję za informację zwrotną.',
    description: 'Dziękujemy za czas poświęcony na podzielenie się swoimi doświadczeniami.',
  },
  [LanguagesEnum.Italian]: {
    title: 'Grazie per il feedback.',
    description: 'Apprezziamo il tempo che lei ha dedicato a condividere la sua esperienza.',
  },
  [LanguagesEnum.German]: {
    title: 'Vielen Dank für Ihre Rückmeldung.',
    description: 'Wir schätzen die Zeit, die Sie sich genommen haben, um Ihre Erfahrungen zu teilen.',
  },
  [LanguagesEnum.Spanish]: {
    title: 'Gracias por el feedback.',
    description: 'Agradecemos el tiempo que se tomó para compartir su experiencia.',
  },
};
