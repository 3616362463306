import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { CommonMoreMenu } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { Consumer } from '../../../types';
import { useOnItemClick } from '../../../hooks/useOnItemClick';

type CELL = {
  name: string,
  render?: (data: Consumer, cell: CELL) => string,
}

type ConsumerItemProps = {
  data: Consumer,
}

const CELLS: CELL[] = [
  {
    name: 'id',
  },
  {
    name: 'firstName',
  },
  {
    name: 'lastName',
  },
  {
    name: 'email',
  },
  {
    name: 'country',
    render: (data, cell): string => data[cell.name]?.name || '-',
  },
  {
    name: 'model',
    render: (data, cell): string => data[cell.name]?.name || '-',
  },
];

const TableRowStyles = {
  cursor: 'pointer',
};

const UPDATING_LINK = PATH_DASHBOARD.consumers.root;

export const ConsumerItem: React.FC<ConsumerItemProps> = ({ data }): JSX.Element => {
  const { onItemClick } = useOnItemClick(UPDATING_LINK, data.id);

  return (
    <TableRow
      hover
      sx={TableRowStyles}
    >
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          onClick={onItemClick}
        >
          {cell.render ? cell.render(data, cell) : data[cell.name]}
        </TableCell>
      ))}
      <TableCell align="right">
        <CommonMoreMenu
          itemId={data.id}
          updatingLink={UPDATING_LINK}
        />
      </TableCell>
    </TableRow>
  );
};
