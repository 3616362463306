import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@material-ui/core';
import { useStore } from '../../../hooks';
import { CreateConsumerValues } from '../../../types';
import { PATH_DASHBOARD } from '../../../app';
import { HeaderBreadcrumbs, Page } from '../../../components';
import { DEFAULT_CONSUMER, LINKS } from './constants';
import { Form } from './Form';
import rules from './rules';

export const CreateConsumer: React.FC = observer((): JSX.Element => {
  const {
    consumers: {
      createConsumer,
    },
  } = useStore();

  const { push } = useHistory();

  const form = useForm<CreateConsumerValues>({
    defaultValues: DEFAULT_CONSUMER,
    resolver: yupResolver(rules),
  });

  const handleCreate = useCallback(async (values: CreateConsumerValues): Promise<void> => {
    await createConsumer(values);
    push(PATH_DASHBOARD.consumers.list);
  }, []);

  return (
    <Page title="Create consumer">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Create consumer"
          links={LINKS}
        />
        <FormProvider {...form}>
          <Form onAction={handleCreate} />
        </FormProvider>
      </Container>
    </Page>
  );
});
