import * as Yup from 'yup';
import { emailSchema, numberReqSchema, stringReqSchema } from '../../utils';

const rules = Yup.object({
  name: stringReqSchema('Name'),
  emails: Yup.array().of(emailSchema()).default(''),
  address: stringReqSchema('Address'),
  cityId: numberReqSchema('City'),
});

export default rules;
