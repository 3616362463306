import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type AccordionReturn = {
  MuiAccordionSummary: { styleOverrides: { root: { paddingRight: string; '&.Mui-disabled': { color: string; opacity: number; '& .MuiTypography-root': { color: string } }; paddingLeft: string }; expandIconWrapper: { color: string } } }; MuiAccordion: { styleOverrides: { root: { '&.Mui-expanded': { boxShadow: string; borderRadius: number | string }; '&.Mui-disabled': { backgroundColor: string } } } }
}
export const Accordion = (theme: Theme): AccordionReturn => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          boxShadow: theme.customShadows.z8,
          borderRadius: theme.shape.borderRadius,
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        '&.Mui-disabled': {
          opacity: 1,
          color: theme.palette.action.disabled,
          '& .MuiTypography-root': {
            color: 'inherit',
          },
        },
      },
      expandIconWrapper: {
        color: 'inherit',
      },
    },
  },
});
