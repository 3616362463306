import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Card,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core/styles';

import ReactApexChart from 'react-apexcharts';
import { merge } from 'lodash';
import { UserRole } from '../../../constants/roles';
import { useStore } from '../../../hooks';
import { Option } from '../../../types';
import { useMultipleSelectFilter } from '../../Dashboard/hooks';
import { BaseOptionChart } from '../../../components';
import { calcArrowAngle } from '../helper/calculate';

const CHART_DATA = [12, 12, 12, 12];
const MAX_RECLAMATION_TIME_IN_HOURS = 48;

export const ChartContainer = styled('div')(() => ({
  position: 'relative',
}));

export const ChartArrow = styled('span')(({ angle }: { angle: number }) => ({
  width: 1.5,
  height: 190,
  position: 'absolute',
  display: 'inline-block',
  left: 250,
  top: 30,
  background: 'black',
  transformOrigin: 'bottom',
  transform: `rotate(${angle}deg)`,
}));

export const ArrowLabel = styled('span')(() => ({
  padding: 10,
  whiteSpace: 'nowrap',
}));

export const CustomerCareAverageTimeProcessing: React.FC = observer(
  (): JSX.Element => {
    // Styles
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Store
    const {
      reports: {
        fetchAvgTimeProcessingReclamations,
        reclamationsCount: {
          avgTime: {
            totalAvgTime,
          },
        },
      },
      countriesModel: { countriesAutoComplete: countries },
      users: { fetchUsers, usersAutoComplete: managers },
    } = useStore();

    // Custom hooks
    // eslint-disable-next-line max-len
    const { ids: countryIds, handleChangeValues: handleChangeCountries } = useMultipleSelectFilter();
    const { ids: managerIds, handleChangeValues: handleChangeManagers } = useMultipleSelectFilter();

    // Fetching
    useEffect(() => {
      fetchUsers({ role: UserRole.TEAM_MEMBER });
    }, []);

    useEffect(() => {
      fetchAvgTimeProcessingReclamations({ countryIds, managerIds });
    }, [countryIds, managerIds]);

    const chartOptions = merge(BaseOptionChart(), {
      labels: ['0-12 hours', '12-24 hours', '24-36 hours', '36-48 hours'],
      stroke: { show: false },
      colors: [
        theme.palette.primary.main,
        theme.palette.success.light,
        theme.palette.warning.light,
        theme.palette.error.main,
      ],
      legend: { horizontalAlign: 'center' },
      plotOptions: {
        pie: {
          donut: {
            size: '90%',
            labels: {
              total: {
                show: true,
                showAlways: true,
                label: 'Average time of claim processing',
                formatter: () => `${totalAvgTime} hours`,
              },
              value: {
                label: `${totalAvgTime}`,
                offsetY: 50,
              },
              name: {
                offsetY: 30,
              },
            },
          },
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
    });

    return (
      <Card>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <Typography variant="h5" gutterBottom>
            Average time of claim processing
          </Typography>
          <Grid container>

            <Grid item xs={12} md={7}>
              <ChartContainer>
                <ReactApexChart
                  type="donut"
                  series={CHART_DATA}
                  options={chartOptions}
                  title="12"
                  style={{ maxWidth: 500 }}
                />
                {!isMobile && (
                  <ChartArrow
                    angle={calcArrowAngle(
                      totalAvgTime,
                      MAX_RECLAMATION_TIME_IN_HOURS,
                    )}
                  >
                    <ArrowLabel>{`${totalAvgTime}`}</ArrowLabel>
                  </ChartArrow>
                )}
              </ChartContainer>
            </Grid>

            <Grid
              container
              xs={12}
              md={5}
              mb={2}
              rowSpacing="10"
              alignSelf="flex-start"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} md={8}>
                <Autocomplete
                  multiple
                  autoComplete={false}
                  fullWidth
                  options={countries}
                  onChange={handleChangeCountries}
                  getOptionLabel={(option: Option): string => option.label}
                  renderInput={(params): JSX.Element => (
                    <TextField {...params} label="Country" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Autocomplete
                  multiple
                  autoComplete={false}
                  fullWidth
                  options={managers}
                  onChange={handleChangeManagers}
                  getOptionLabel={(option: Option): string => option.label}
                  renderInput={(params): JSX.Element => (
                    <TextField {...params} label="Team member" />
                  )}
                />
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </Card>
    );
  },
);
