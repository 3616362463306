import React from 'react';
import { Container } from '@material-ui/core';
import { PATH_DASHBOARD } from '../../app';
import { Page, HeaderBreadcrumbs } from '../../components';
import { KnowledgeBasePostForm } from './components';

const LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Knowledge base', href: PATH_DASHBOARD.knowledgeBases.list },
  { name: 'New Post' },
];

export const KnowledgeBaseNewPost:React.FC = () => (
  <Page title="Knowledge base: New Post | Minimal-UI">
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Create a new post"
        links={LINKS}
      />
      <KnowledgeBasePostForm isEdit={false} />
    </Container>
  </Page>
);
