import {
  applySnapshot, flow, getEnv,
  Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  DeviationCodeModel,
  ListMetaDataModel,
  DeviationCodeListData,
  DeviationCode,
  DeviationCodeValues,
  DeviationCodesListParams, Option,
} from '../types';
import { COMMON_SCHEMA, DEFAULT_META } from '../constants';
import { mapEntityToOption } from '../utils';

export const DeviationCodeStoreModel = types.model('DeviationCodeStore')
  .props({
    items: types.optional(types.array(DeviationCodeModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    fetchDeviationCodes: flow(function* fetchDeviationCodes(
      params: DeviationCodesListParams,
    ): Generator<Promise<DeviationCode[]>> {
      try {
        const res = yield getEnv(self).api.fetchDeviationCodes(params);
        const { items, meta } = res as DeviationCodeListData;
        applySnapshot(self.items, items);
        self.meta = meta;
        return items;
      } catch (e) {
        toast.error('Error with getting deviation codes');
        throw new Error('Error with getting deviation codes');
      }
    }),
    fetchDeviationCodeById: flow(function* fetchDeviationCodeById(id: string) {
      const deviationCode: DeviationCode = yield getEnv(self).api.fetchDeviationCodeById(id);
      applySnapshot(self.items, [deviationCode]);
    }),
    createDeviationCode: flow(function* createDeviationCode(data:DeviationCodeValues) {
      try {
        yield getEnv(self).api.createDeviationCode(data);
        toast.success('Deviation code was created successfully');
      } catch (e) {
        toast.error('Error with creating deviation code');
      }
    }),
    updateDeviationCode: flow(function* updateDeviationCode(id: string, data: DeviationCodeValues) {
      try {
        yield getEnv(self).api.updateDeviationCode(id, data);
        toast.success('Deviation code was updated successfully');
      } catch (e) {
        toast.error('Error with updating deviation code');
      }
    }),
  }))
  .actions((self) => ({
    deleteDeviationCode: flow(function* deleteDeviationCode(id: number) {
      try {
        yield getEnv(self).api.deleteDeviationCode(id);
        const params: DeviationCodesListParams = { page: self.meta.currentPage };
        self.fetchDeviationCodes(params);
        toast.success('Deviation code was deleted successfully');
      } catch (e) {
        toast.error('Error with deleting deviation code');
      }
    }),
  }))
  .views((self) => ({
    getDeviationCodeById(id: string): DeviationCode | undefined {
      return self.items.find((deviationCode) => deviationCode.id === Number(id));
    },
    get deviationCodesAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<DeviationCode>(item, COMMON_SCHEMA));
    },
  }));

export type DeviationCodeStore = Instance<typeof DeviationCodeStoreModel>

export type DeviationCodeStoreSnapShot = SnapshotOut<typeof DeviationCodeStoreModel>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createDeviationCodeStoreModel = () => types.optional(DeviationCodeStoreModel, {});
