import { Option } from '../types';
import { UserRole } from './roles';

const DEFAULT_ID = -1;

export enum ReclamationStatus {
  NEW = 'new',
  PENDING = 'pending',
  RESOLVED = 'resolved',
  CLOSED = 'closed',
}

export enum WayOfSolution {
  NEW = 'new one',
  REPAIR = 'reworked',
}

export enum ReclamationType {
  NONE = 0,
  CLIENT = 1,
  SHOP = 2,
}

export enum QualityReport {
  CLAIM_FROM_MARKET = 'claim from market',
  INTERNAL_CHECKING = 'internal checking',
}

export const RECLAMATION_TYPE = {
  [ReclamationType.NONE]: {
    title: 'None',
  },
  [ReclamationType.CLIENT]: {
    title: 'Client',
  },
  [ReclamationType.SHOP]: {
    title: 'Shop',
  },
};

export const RECLAMATION_STATUSES_BY_ROLE = {
  [UserRole.ADMIN]: [
    ReclamationStatus.NEW,
    ReclamationStatus.PENDING,
    ReclamationStatus.RESOLVED,
    ReclamationStatus.CLOSED,
  ],
  [UserRole.TEAM_MEMBER]: [
    ReclamationStatus.NEW,
    ReclamationStatus.PENDING,
    ReclamationStatus.RESOLVED,
  ],
  [UserRole.SERVICE_MANAGER]: [
    ReclamationStatus.PENDING,
    ReclamationStatus.RESOLVED,
    ReclamationStatus.CLOSED,
  ],
};

export const RECLAMATION_STATUSES: Option<string>[] = [
  { label: 'New', value: ReclamationStatus.NEW },
  { label: 'Pending', value: ReclamationStatus.PENDING },
  { label: 'Resolved', value: ReclamationStatus.RESOLVED },
  { label: 'Closed', value: ReclamationStatus.CLOSED },
];

export const RECLAMATION_STATUS = {
  [ReclamationStatus.NEW]: {
    title: 'New',
  },
  [ReclamationStatus.PENDING]: {
    title: 'Pending',
  },
  [ReclamationStatus.RESOLVED]: {
    title: 'Resolved',
  },
  [ReclamationStatus.CLOSED]: {
    title: 'Closed',
  },
};

export enum RECLAMATION_STATUS_LABELS {
  new = 'info',
  pending = 'warning',
  resolved = 'success',
  closed = 'default',
}

export const QUALITY_REPORTS: Option<string>[] = [
  { label: 'Claim for market', value: QualityReport.CLAIM_FROM_MARKET },
  { label: 'Internal checking', value: QualityReport.INTERNAL_CHECKING },
];

export const QUALITY_REPORT = {
  [QualityReport.CLAIM_FROM_MARKET]: {
    title: 'Claim for market',
  },
  [QualityReport.INTERNAL_CHECKING]: {
    title: 'Internal checking',
  },
};

export const WAY_OF_SOLUTIONS: Option<string>[] = [
  { label: 'New one', value: WayOfSolution.NEW },
  { label: 'Reworked', value: WayOfSolution.REPAIR },
];

export type Data = Option<number | string> | null;

export const DEFAULT_PART_DATA = {
  id: DEFAULT_ID,
  strollerPart: null,
  sparePart: null,
  deviationCodes: [],
  warranty: 0,
  price: '0.0',
  quantity: 0,
  wayOfSolution: WayOfSolution.NEW,
  createdAt: '',
  updatedAt: '',
  deletedAt: null,
  file: null,
  totalPrice: '0',
};

export const DEFAULT_PARTS_MODEL = [DEFAULT_PART_DATA, DEFAULT_PART_DATA, DEFAULT_PART_DATA];
