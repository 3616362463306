import { Icon } from '@iconify/react';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import roundReceipt from '@iconify/icons-ic/round-receipt';
import roundHistory from '@iconify/icons-ic/round-history';
import messageSquareOutline from '@iconify/icons-eva/message-square-outline';
import { PATH_DASHBOARD } from '../../app';
import { Link } from '../../types';
import { ContactInfo as AccountInfoTab } from './ContactInfo';
import { Registration as RegistrationTab } from './Registration';
import { TAB_ICON_PROPS } from '../../constants';
import { History as HistoryTab } from './History';
import { ConsumerChat as ConsumerChatTab } from './Chat';

export enum ConsumerValues {
  ContactInfo = 'contact_info',
  Registration = 'registration',
  History = 'history',
  Chat = 'chat',
}

type ConsumerTab = {
  value: ConsumerValues,
  icon: JSX.Element,
  component: JSX.Element,
}

export const CONSUMER_TABS: readonly Readonly<ConsumerTab>[] = [
  {
    value: ConsumerValues.ContactInfo,
    icon: <Icon icon={roundAccountBox} {...TAB_ICON_PROPS} />,
    component: <AccountInfoTab />,
  },
  {
    value: ConsumerValues.Registration,
    icon: <Icon icon={roundReceipt} {...TAB_ICON_PROPS} />,
    component: <RegistrationTab />,
  },
  {
    value: ConsumerValues.History,
    icon: <Icon icon={roundHistory} {...TAB_ICON_PROPS} />,
    component: <HistoryTab />,
  },
  {
    value: ConsumerValues.Chat,
    icon: <Icon icon={messageSquareOutline} {...TAB_ICON_PROPS} />,
    component: <ConsumerChatTab />,
  },
] as const;

export const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Customers list', href: PATH_DASHBOARD.consumers.list },
  { name: 'Customer' },
];
