import { Color, Period, ReclamationStatus } from '../constants';
import {
  ConfigCountCard,
  ReclamationsCountByPeriods,
  ReclamationsCountByStatuses,
} from '../types';

type Item = {
  readonly title: string,
  readonly key: ReclamationStatus | Period
  readonly color: Color
}

type Data = ReclamationsCountByPeriods | ReclamationsCountByStatuses

export function makeConfigCards(
  array: readonly Item[],
  data: Data,
): ConfigCountCard[] {
  return array.map(({
    title,
    key,
    color,
  }): ConfigCountCard => ({
    title,
    value: data[key],
    color,
  }));
}
