import React from 'react';
import { Paper, PaperProps, Typography } from '@material-ui/core';

interface NotFoundProps extends PaperProps {
    message?: string;
}

export const NotFound:React.FC<NotFoundProps> = ({ message = 'Not found', ...other }) => (
  <Paper {...other}>
    <Typography gutterBottom align="center" variant="subtitle1">
      {message}
    </Typography>
  </Paper>
);
