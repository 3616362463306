import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Container,
  Table,
  TableBody, TableCell,
  TableContainer,
  TablePagination, TableRow,
} from '@material-ui/core';

import { observer } from 'mobx-react-lite';
import { PATH_DASHBOARD } from '../../app';
import { useSearch, useStore } from '../../hooks';
import {
  CommonListHead,
  CommonListToolbar,
  HeaderBreadcrumbs,
  Page,
  Scrollbar, SearchNotFound,
} from '../../components';
import { CountriesItem } from './CountriesItem';
import { useSorting } from '../../hooks/useSorting';
import { FIRST_PAGE } from '../../constants';

const COUNTRIES_LIST_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'code', label: 'Code' },
  { id: 'status', label: 'Status' },
  { id: '' },
];
const BREADCRUMB_LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Countries list' },
];

export const CountriesList:React.FC = observer((): JSX.Element => {
  const { countriesModel: { fetchAllCountries, items, meta } } = useStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [page, setPage] = useState<number>(FIRST_PAGE);
  const { debouncedSearchValue, searchValue, setSearchValue } = useSearch();

  const {
    sort,
    order,
    orderBy,
    onClickSorting,
  } = useSorting();

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  }, []);

  const handleFilter = (newFilterValue: string):void => {
    setSearchValue(newFilterValue);
  };

  useEffect(() => {
    fetchAllCountries({ sort, page, search: debouncedSearchValue });
  }, [sort, page, debouncedSearchValue]);

  return (
    <Page title="Countries: List | Minimal-UI">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Countries List"
          links={BREADCRUMB_LINKS}
        />
        <Card>
          <CommonListToolbar
            onSearch={handleFilter}
            searchValue={searchValue}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <CommonListHead
                  headListData={COUNTRIES_LIST_HEAD}
                  order={order}
                  orderBy={orderBy}
                  onClick={onClickSorting}
                />
                <TableBody>
                  {items?.length ? (items?.map((item) => (
                    <CountriesItem
                      key={item.id}
                      data={item}
                    />
                  ))) : (
                    <TableRow>
                      <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            onPageChange={handlePageChange}
          />
        </Card>
      </Container>
    </Page>
  );
});
