import { Theme } from '@material-ui/core/styles';

type ButtonReturn = {
  MuiButton: { styleOverrides: { containedPrimary: { boxShadow: string }; textInherit: { '&:hover': { backgroundColor: string } }; containedInfo: { boxShadow: string }; containedInherit: { boxShadow: string; color: string; '&:hover': { backgroundColor: string } }; containedSuccess: { boxShadow: string }; root: { '&:hover': { boxShadow: string } }; containedError: { boxShadow: string }; containedSecondary: { boxShadow: string }; sizeLarge: { height: number }; outlinedInherit: { border: string; '&:hover': { backgroundColor: string } }; containedWarning: { boxShadow: string } } }
}
export const Button = (theme: Theme): ButtonReturn => ({
  MuiButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          boxShadow: 'none',
        },
      },
      sizeLarge: {
        height: 48,
      },
      // contained
      containedInherit: {
        color: theme.palette.grey[800],
        boxShadow: theme.customShadows.z8,
        '&:hover': {
          backgroundColor: theme.palette.grey[400],
        },
      },
      containedPrimary: {
        boxShadow: theme.customShadows.primary,
      },
      containedSecondary: {
        boxShadow: theme.customShadows.secondary,
      },
      containedInfo: {
        boxShadow: theme.customShadows.info,
      },
      containedSuccess: {
        boxShadow: theme.customShadows.success,
      },
      containedWarning: {
        boxShadow: theme.customShadows.warning,
      },
      containedError: {
        boxShadow: theme.customShadows.error,
      },
      // outlined
      outlinedInherit: {
        border: `1px solid ${theme.palette.grey[500_32]}`,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
      textInherit: {
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
  },
});
