import { Instance, types } from 'mobx-state-tree';

export const LanguageModel = types.model('LanguageModel', {
  id: types.number,
  url: types.string,
  name: types.string,
  status: types.number,
  sort: types.number,
});

export type Language = Instance<typeof LanguageModel>
