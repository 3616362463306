import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Typography,
  Grid,
  Autocomplete,
  TextField,
  TextFieldProps,
  Card,
  Box,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';

import ReactApexChart from 'react-apexcharts';
import { merge } from 'lodash';
import DateFnsUtils from '@material-ui/lab/AdapterDateFns';
import ClearIcon from '@material-ui/icons/Clear';
import { useStore } from '../../../hooks';
import {
  useMultipleSelectFilter,
  useDatePeriodFilter,
} from '../../Dashboard/hooks';
import { BaseOptionChart } from '../../../components';
import { Option } from '../../../types';
import {
  COMMON_PICKER_DATE_VIEW,
  COMMON_PICKER_MASK,
} from '../../../constants';
import {
  ArrowLabel,
  ChartArrow,
  ChartContainer,
} from './CustomerCareAverageTimeProcessing';
import { calcArrowAngle } from '../helper/calculate';

const CHART_DATA = [100];
const MAX_PROCESSED_RECLAMATION = 100;

export const CustomerCareProcessedReclamation: React.FC = observer(
  (): JSX.Element => {
    // Styles
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // State
    const [managerId, setManagerId] = useState<number | undefined>(undefined);

    // Store
    const {
      reports: {
        fetchReclamationsCount,
        reclamationsCount: { all },
      },
      countriesModel: { countriesAutoComplete: countries },
      serviceModels: { modelsAutoComplete: models },
      users: { usersAutoComplete: managers },
    } = useStore();

    // Custom hooks
    // eslint-disable-next-line max-len
    const { ids: countryIds, handleChangeValues: handleChangeCountries } = useMultipleSelectFilter();
    const { ids: modelIds, handleChangeValues: handleChangeModelIds } = useMultipleSelectFilter();
    const {
      dateTo, dateFrom, handleChangeDateTo, handleChangeDateFrom,
    } = useDatePeriodFilter();

    useEffect(() => {
      fetchReclamationsCount({
        countryIds,
        dateTo,
        dateFrom,
        modelIds,
        managerIds: managerId ? String(managerId) : '',
      });
    }, [countryIds, dateTo, dateFrom, modelIds, managerId]);

    /// Handlers
    const handleChangeManagerId = useCallback(
      // eslint-disable-next-line consistent-return
      (event: React.SyntheticEvent, selectedOption: Option<number> | null) => {
        if (!selectedOption) return setManagerId(undefined);

        setManagerId(selectedOption.value);
      },
      [setManagerId],
    );

    const chartOptions = merge(BaseOptionChart(), {
      labels: ['Total'],
      stroke: { show: false },
      colors: [
        theme.palette.primary.main,
        theme.palette.success.light,
        theme.palette.warning.light,
        theme.palette.error.main,
      ],
      legend: { horizontalAlign: 'center' },
      plotOptions: {
        pie: {
          donut: {
            size: '90%',
            labels: {
              total: {
                show: true,
                label: 'Number of processed reclamations',
                formatter: () => (managerId ? `${all} by ${managers.find(({ value }) => value === managerId)?.label}` : `${all} reclamations`),
              },
              value: {
                label: `${all}`,
                offsetY: 50,
              },
              name: {
                offsetY: 30,
              },
            },
          },
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
    });

    return (
      <Card>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <Typography variant="h5" gutterBottom>
            Number of processed reclamations
          </Typography>
          <Grid container>

            <Grid item xs={12} md={7} mb={2}>
              <ChartContainer>
                <ReactApexChart
                  type="donut"
                  series={CHART_DATA}
                  options={chartOptions}
                  title="12"
                  style={{ maxWidth: 500, marginTop: 30 }}
                  total={all}
                />
                {!isMobile && (
                <ChartArrow
                  angle={calcArrowAngle(
                    all,
                    (Math.trunc(all / MAX_PROCESSED_RECLAMATION) + 1) * MAX_PROCESSED_RECLAMATION,
                  )}
                >
                  <ArrowLabel>{`${all}`}</ArrowLabel>
                </ChartArrow>
                )}
              </ChartContainer>
            </Grid>

            <Grid container md={4} mb={2} rowSpacing="10" alignItems="flex-start" alignContent="flex-start">
              <Grid item xs={12} md={10}>
                <Autocomplete
                  multiple
                  autoComplete={false}
                  fullWidth
                  options={countries}
                  onChange={handleChangeCountries}
                  getOptionLabel={(option: Option): string => option.label}
                  renderInput={(params): JSX.Element => (
                    <TextField {...params} label="Countries" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <Autocomplete
                  multiple
                  autoComplete={false}
                  fullWidth
                  options={models}
                  onChange={handleChangeModelIds}
                  getOptionLabel={(option: Option): string => option.label}
                  renderInput={(params): JSX.Element => (
                    <TextField {...params} label="Models" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                  <DatePicker
                    inputFormat={COMMON_PICKER_DATE_VIEW}
                    mask={COMMON_PICKER_MASK}
                    views={['day']}
                    label="Date from"
                    value={dateFrom}
                    onChange={handleChangeDateFrom}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          onClick={(): void => handleChangeDateFrom(null)}
                          disabled={!dateFrom}
                          style={{ order: 1 }}
                        >
                          <ClearIcon color="disabled" fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    InputAdornmentProps={{
                      position: 'end',
                      style: { order: 2, marginLeft: 0 },
                    }}
                    renderInput={(params: TextFieldProps): JSX.Element => (
                      <TextField {...params} fullWidth disabled />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={10}>
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                  <DatePicker
                    inputFormat={COMMON_PICKER_DATE_VIEW}
                    mask={COMMON_PICKER_MASK}
                    views={['day']}
                    label="Date to"
                    value={dateTo}
                    onChange={handleChangeDateTo}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          onClick={(): void => handleChangeDateTo(null)}
                          disabled={!dateTo}
                          style={{ order: 1 }}
                        >
                          <ClearIcon color="disabled" fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    InputAdornmentProps={{
                      position: 'end',
                      style: { order: 2, marginLeft: 0 },
                    }}
                    renderInput={(params: TextFieldProps): JSX.Element => (
                      <TextField {...params} fullWidth disabled />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={10}>
                <Autocomplete
                  autoComplete={false}
                  fullWidth
                  options={managers}
                  onChange={handleChangeManagerId}
                  getOptionLabel={(option: Option): string => option.label}
                  renderInput={(params): JSX.Element => (
                    <TextField {...params} label="Team members" />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  },
);
