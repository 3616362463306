import { Instance, types } from 'mobx-state-tree';
import { CountryModel } from './CountryModel';

export const CityModel = types.model('CityModel', {
  id: types.maybe(types.number),
  name: types.string,
  status: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  externalId: types.string,
  country: types.maybe(types.maybeNull(CountryModel)),
});

export const CityMetaData = types.model({
  totalItems: types.number,
  itemCount: types.number,
  itemsPerPage: types.number,
  totalPages: types.number,
  currentPage: types.number,
});

export type CityItem = Instance<typeof CityModel>

export type City = Instance<typeof CityModel>
