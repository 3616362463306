import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { CommentForm } from '../CommentForm';
import { QueryParams, ReclamationCommentValues } from '../../../types';
import { useStore } from '../../../hooks';

export const AddComment: React.FC = observer((): JSX.Element => {
  const { id } = useParams<QueryParams>();
  const {
    reclamations: {
      createCommentByReclamationId,
    },
  } = useStore();

  const handleSubmit = useCallback(async (values: ReclamationCommentValues) => {
    await createCommentByReclamationId(id, values);
  }, []);

  return (
    <CommentForm onSubmit={handleSubmit} />
  );
});
