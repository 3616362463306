import {
  applySnapshot, flow, getEnv,
  Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  ListMetaDataModel,
  ListParams,
  StrollerPartModel,
  StrollerPart,
  StrollerPartsListData,
  StrollerPartValues,
  Option,
} from '../types';
import { COMMON_SCHEMA, DEFAULT_META } from '../constants';
import { mapEntityToOption } from '../utils';

export const StrollerPartsStore = types.model(' StrollerPartsStore')
  .props({
    items: types.optional(types.array(StrollerPartModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    fetchStrollerParts: flow(function* fetchDeviationCodes(params: ListParams) {
      try {
        const {
          items,
          meta,
        }: StrollerPartsListData = yield getEnv(self).api.fetchStrollerParts(params);
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting stroller parts');
      }
    }),
    fetchStrollerPartById: flow(function* fetchDeviationCodeById(id: string) {
      const strollerPart: StrollerPart = yield getEnv(self).api.fetchStrollerPartById(id);
      applySnapshot(self.items, [strollerPart]);
    }),
    createStrollerPart: flow(function* createStrollerPart(data:StrollerPartValues) {
      try {
        yield getEnv(self).api.createStrollerPart(data);
        toast.success('Stroller part was created successfully');
      } catch (e) {
        toast.error('Error with creating stroller part');
      }
    }),
    updateStrollerPart: flow(function* updateStrollerPart(id: string, data: StrollerPartValues) {
      try {
        yield getEnv(self).api.updateStrollerPart(id, data);
        toast.success('Stroller part was updated successfully');
      } catch (e) {
        toast.error('Error with updating stroller part');
      }
    }),
  }))
  .actions((self) => ({
    deleteStrollerPart: flow(function* deleteStrollerPart(id: number) {
      try {
        yield getEnv(self).api.deleteStrollerPart(id);
        const params = { page: self.meta.currentPage };
        self.fetchStrollerParts(params);
        toast.success('Stroller part was deleted successfully');
      } catch (e) {
        toast.error('Error with deleting stroller part');
      }
    }),
  }))
  .views((self) => ({
    getStrollerPartById(id: string): StrollerPart | undefined {
      return self.items.find((strollerPart) => strollerPart.id === Number(id));
    },
    get strollerPartsAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<StrollerPart>(item, COMMON_SCHEMA));
    },
  }));

export type StrollerPartStore = Instance<typeof StrollerPartsStore>

export type StrollerPartStoreSnapShot = SnapshotOut<typeof StrollerPartsStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createStrollerPartsStoreModel = () => types.optional(StrollerPartsStore, {});
