import React from 'react';
import { SxProps } from '@material-ui/system';
import { Theme } from '@material-ui/core/styles';
import { Item, ItemContent, List } from '../styles';

type CustomItemProps = {
    title: string | number | null,
    width: string,
    children?: React.ReactNode,
    sx?: SxProps<Theme>,
}

export const CustomItem: React.FC<CustomItemProps> = ({
  title,
  width,
  children,
  sx,
}) => (
  <Item>
    <ItemContent
      style={{ minWidth: width, maxWidth: width }}
      sx={sx}
    >
      {title}
    </ItemContent>
    <List>
      {children}
    </List>
  </Item>
);
