import React, { useState } from 'react';
import { styled, useTheme, Theme } from '@material-ui/core/styles';
import { Button, Collapse, Divider } from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Scrollbar } from '../../../components';

type RootStyleProps = {
    theme: Theme,
    maxHeight?: string,
    isCollapsed: boolean,
}

const RootStyle = styled('div')(({ theme, maxHeight, isCollapsed }: RootStyleProps) => ({
  maxHeight,
  display: 'flex',
  overflow: isCollapsed ? 'hidden' : 'visible',
  flexDirection: 'column',
}));

const CollapseButtonStyle = styled(Button)(({ theme }) => ({
  ...theme.typography.overline,
  height: 35,
  borderRadius: 0,
  padding: theme.spacing(1, 2),
  justifyContent: 'space-between',
  color: theme.palette.text.disabled,
}));

type AccordionProps = {
    title: string,
    isBorderBottom?: boolean,
    children: React.ReactNode,
    maxHeight?: string,
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  isBorderBottom = false,
  maxHeight,
  children,
}): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const handleClick = (): void => {
    setIsCollapsed((prevState) => !prevState);
  };
  const theme = useTheme();

  return (
    <RootStyle theme={theme} maxHeight={maxHeight} isCollapsed={isCollapsed}>
      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={handleClick}
        endIcon={(
          <Icon
            icon={isCollapsed ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        )}
      >
        {title}
      </CollapseButtonStyle>

      {isBorderBottom && !isCollapsed && <Divider />}

      <Scrollbar>
        <Collapse
          in={isCollapsed}
        >
          {isCollapsed && children}
        </Collapse>
      </Scrollbar>
    </RootStyle>
  );
};
