import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type SliderReturn = {
  MuiSlider: { defaultProps: { size: string }; styleOverrides: { valueLabel: { backgroundColor: string; borderRadius: number }; root: { '&.Mui-disabled': { color: string } }; markLabel: { color: string; fontSize: number } } }
}
export const Slider = (theme: Theme): SliderReturn => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
        markLabel: {
          fontSize: 13,
          color: theme.palette.text.disabled,
        },
        valueLabel: {
          borderRadius: 8,
          backgroundColor: theme.palette.grey[isLight ? 800 : 700],
        },
      },
    },
  };
};
