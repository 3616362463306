import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Card } from '@material-ui/core';
import { QueryParams } from '../../../types';
import { ChatWindow } from '../../Chat/ChatWindow';
import { useStore } from '../../../hooks';
import { NotFound } from '../../../components';

export const ConsumerChat: React.FC = observer((): JSX.Element => {
  const {
    consumers: {
      getConsumerById,
    },
  } = useStore();
  const { id } = useParams<QueryParams>();

  const consumer = getConsumerById(id);
  return consumer?.channel ? (
    <Card sx={{ height: '72vh', display: 'flex' }}>
      <ChatWindow channelId={consumer.channel?.id} />
    </Card>
  ) : (
    <Card sx={{
      height: '72vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <NotFound message="There are no any dialogs with customer" />
    </Card>
  );
});
