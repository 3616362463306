import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Table,
  TableContainer,
  // TablePagination,
  Container,
  TableBody, TablePagination, Button, TableCell, TableRow,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useHistory } from 'react-router-dom';
import {
  Page,
  HeaderBreadcrumbs,
  CommonListHead, Scrollbar, CommonListToolbar, SearchNotFound,
} from '../../../components';
import { StrollerPartsItem } from './StrollerPartsItem';
import { LINKS, TABLE_HEAD_LIST } from './constants';
import { useSearch, useStore } from '../../../hooks';
import { PATH_DASHBOARD } from '../../../app';
import { useSorting } from '../../../hooks/useSorting';
import { FIRST_PAGE } from '../../../constants';

export const StrollerPartsList:React.FC = observer((): JSX.Element => {
  const {
    strollerParts: {
      fetchStrollerParts,
      items,
      meta,
    },
  } = useStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { push } = useHistory();
  const [page, setPage] = useState<number>(FIRST_PAGE);
  const { debouncedSearchValue, searchValue, setSearchValue } = useSearch();

  const {
    sort,
    order,
    orderBy,
    onClickSorting,
  } = useSorting();

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  }, []);

  const handleFilter = (newFilterValue: string):void => {
    setSearchValue(newFilterValue);
  };

  useEffect(() => {
    fetchStrollerParts({ page, sort, search: debouncedSearchValue });
  }, [page, sort, debouncedSearchValue]);

  return (
    <Page title="Stroller parts list">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Stroller parts list"
          links={LINKS}
          action={(
            <Button
              variant="contained"
              onClick={(): void => push(PATH_DASHBOARD.strollerParts.create)}
              startIcon={<Icon icon={plusFill} />}
            >
              Create
            </Button>
          )}
        />
        <Card>
          <CommonListToolbar
            onSearch={handleFilter}
            searchValue={searchValue}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <CommonListHead
                  headListData={TABLE_HEAD_LIST}
                  order={order}
                  orderBy={orderBy}
                  onClick={onClickSorting}
                />
                <TableBody>
                  {items.length ? (
                    items.map((item) => (
                      <StrollerPartsItem
                        key={`stroller-parts-${item.id}`}
                        data={item}
                      />
                    ))) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <SearchNotFound />
                        </TableCell>
                      </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            onPageChange={handlePageChange}
          />
        </Card>
      </Container>
    </Page>
  );
});
