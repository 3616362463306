import React, { useEffect } from 'react';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-hot-toast';
import { DEFAULT_VALUES } from './constants';
import { UpdateClientValues } from '../../../../types/channel';
import rules from './rules';
import { useStore } from '../../../../hooks';
import { LoadingScreen } from '../../../../components';

type EditInfoProps = {
  isOpened: boolean,
  onClose: () => void,
}

export const EditInfo: React.FC<EditInfoProps> = observer(({ isOpened, onClose }): JSX.Element => {
  const {
    channels: {
      selectedChannel,
      updateClientInfo,
    },
  } = useStore();

  const {
    control, handleSubmit, reset,
  } = useForm<UpdateClientValues>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(rules),
  });

  useEffect(() => {
    if (selectedChannel) {
      reset({
        firstName: selectedChannel.firstName,
        lastName: selectedChannel.lastName,
      });
    }
  }, [selectedChannel.id]);

  const handleUpdate = async (values: UpdateClientValues): Promise<void> => {
    if (selectedChannel.clientId) {
      await updateClientInfo(selectedChannel?.clientId, values);
      onClose();
    } else {
      toast.error('There is no client id');
    }
  };

  return selectedChannel ? (
    <Dialog open={isOpened} onClose={onClose}>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <DialogTitle>Edit user info</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <Stack direction="column" spacing={{ xs: 3, sm: 2 }}>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({
                field: { onBlur, onChange, value }, fieldState: { error },
              }): JSX.Element => (
                <TextField
                  autoFocus
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label="First name"
                  value={value}
                  spellCheck={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({
                field: { onBlur, onChange, value }, fieldState: { error },
              }): JSX.Element => (
                <TextField
                  autoFocus
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label="Last name"
                  value={value}
                  spellCheck={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (<LoadingScreen />);
});
