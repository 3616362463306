import {
  applySnapshot,
  flow, getEnv,
  Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import {
  ServiceModelModel, ListMetaDataModel, ListParams,
  Option, ServiceModelsListData, ServiceModel,
} from '../types';
import { COMMON_SCHEMA, DEFAULT_META } from '../constants';
import { mapEntityToOption } from '../utils';

export const ServiceModelStore = types.model('ServiceModelStore')
  .props({
    items: types.optional(types.array(ServiceModelModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    fetchServiceModels: flow(function* fetchServiceModels(params: ListParams) {
      try {
        const res: ServiceModelsListData = yield getEnv(self).api.fetchServiceModels(params);
        const { items, meta } = res;
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        throw new Error('Error with getting service models');
      }
    }),
  }))
  .views((self) => ({
    get modelsAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<ServiceModel>(item, COMMON_SCHEMA));
    },
  }));

export type ServiceModelStore = Instance<typeof ServiceModelStore>

export type ServiceModelStoreSnapShot = SnapshotOut<typeof ServiceModelStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createServiceModelStoreModel = () => types.optional(ServiceModelStore, {});
