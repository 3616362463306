import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type ProgressReturn = {
  // eslint-disable-next-line max-len
  MuiLinearProgress: { styleOverrides: { colorPrimary: { backgroundColor: string }; bar: { borderRadius: number }; root: { overflow: string; borderRadius: number }; buffer: { backgroundColor: string } } }
}
export const Progress = (theme: Theme): ProgressReturn => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          overflow: 'hidden',
        },
        bar: {
          borderRadius: 4,
        },
        colorPrimary: {
          backgroundColor: theme.palette.primary[isLight ? 'lighter' : 'darker'],
        },
        buffer: {
          backgroundColor: 'transparent',
        },
      },
    },
  };
};
