import { Instance, types } from 'mobx-state-tree';
import { CommonModel, ListMetaDataModel } from './CommonModel';
import { ReclamationStatus } from '../../constants';

export const AllCountriesMetaDataModel = types.model({
  totalItems: types.number,
  itemCount: types.number,
  itemsPerPage: types.number,
  totalPages: types.number,
  currentPage: types.number,
  totalPriceForSelectedCountries: types.number,
});

const CodesReportModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  partId: types.number,
});

const SparePartReportModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  spareQuantity: types.number,
  price: types.string,
  solution: types.maybeNull(types.string),
  warranty: types.maybeNull(types.number),
});

const PartsReportModel = types.model({
  id: types.number,
  name: types.string,
  devCodeCount: types.number,
  claimId: types.number,
  sparePart: types.maybe(SparePartReportModel),
  codes: types.optional(types.array(CodesReportModel), []),
});

const ModelClaimsReportModel = types.model({
  id: types.number,
  modelId: types.number,
  devCodeCount: types.number,
  parts: types.optional(types.array(PartsReportModel), []),
});

const ServiceModelReportModel = types.model({
  id: types.number,
  name: types.string,
  devCodeCount: types.number,
  claims: types.optional(types.array(ModelClaimsReportModel), []),
});

export const GroupedClaimReportModel = types.model({
  id: types.number,
  name: types.string,
  devCodeCount: types.number,
  complaintsQuantity: types.number,
  totalPerCountry: types.number,
  models: types.optional(types.array(ServiceModelReportModel), []),
});

export const GroupedClaimsReportModel = types.model({
  items: types.optional(types.array(GroupedClaimReportModel), []),
  meta: AllCountriesMetaDataModel,
});

export const ClaimReportModel = types.model({
  id: types.number,
  date: types.string,
  description: types.maybeNull(types.string),
  serialNumber: types.string,
  finding: CommonModel,
  photos: types.optional(types.array(types.string), []),
  parts: types.optional(types.array(types.string), []),
  comments: types.maybe(types.string),
  solution: types.optional(types.array(types.string), []),
  labourCost: types.number,
  partsCost: types.maybeNull(types.number),
  logisticCost: types.number,
  sum: types.maybeNull(types.number),
});

export const ClaimsReportModel = types.model({
  items: types.optional(types.array(ClaimReportModel), []),
  meta: ListMetaDataModel,
});

export const ReclamationsCountByPeriodsModel = types.model({
  month: types.number,
  sixMonths: types.number,
  year: types.number,
});

export const ReclamationsCountByStatusesModel = types.model({
  [ReclamationStatus.NEW]: types.number,
  [ReclamationStatus.PENDING]: types.number,
  [ReclamationStatus.RESOLVED]: types.number,
  [ReclamationStatus.CLOSED]: types.number,
});

const AvgTimePerEntity = types.model({
  name: types.string,
  avgTime: types.number,
  isCountry: types.boolean,
});

export const AvgTimeModel = types.model({
  totalAvgTime: types.number,
  avgTimePerEntities: types.optional(types.array(AvgTimePerEntity), []),
});

export const ClosedReclamationCountItemModel = types.model({
  name: types.string,
  count: types.number,
  isCountry: types.boolean,
});

export const ReclamationsCountModel = types.model({
  periods: ReclamationsCountByPeriodsModel,
  statuses: ReclamationsCountByStatusesModel,
  closedClaims: types.optional(types.array(ClosedReclamationCountItemModel), []),
  all: types.number,
  avgTime: AvgTimeModel,
  totalAvgTime: types.number,
});

export const ReclamationCSATItemModel = types.model({
  rating: types.string,
  manager: types.string,
});

export const ReclamationsCSATModel = types.model({
  csat: types.optional(types.array(ReclamationCSATItemModel), []),
});

export type ClaimReport = Instance<typeof ClaimReportModel>

export type ClaimsReport = Instance<typeof ClaimsReportModel>

export type SparePartReport = Instance<typeof SparePartReportModel>

export type GroupedClaimReport = Instance<typeof GroupedClaimReportModel>

export type GroupedClaimsReport = Instance<typeof GroupedClaimsReportModel>

export type ReclamationsCountByPeriods = Instance<typeof ReclamationsCountByPeriodsModel>

export type ReclamationsCountByStatuses = Instance<typeof ReclamationsCountByStatusesModel>

export type AvgTime = Instance<typeof AvgTimeModel>

export type ClosedReclamationCountItem = Instance<typeof ClosedReclamationCountItemModel>
