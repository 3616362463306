import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Card,
  Grid, IconButton,
  Stack,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import { DatePicker, LoadingButton } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@material-ui/icons/Clear';
import {
  LoadingScreen, OpenWindowField, PermissionWrapper,
} from '../../../components';
import { useRequest, useStore } from '../../../hooks';
import rules from './rules';
import { formatPickerValue, getOption } from '../../../utils';
import { ConsumerRegistrationValues, Option } from '../../../types';
import {
  COMMON_PICKER_DATE_VIEW,
  DEFAULT_AUTOCOMPLETE_ID,
  DEFAULT_AUTOCOMPLETE_PARAMS,
  Module,
  PermissionAction,
} from '../../../constants';

type Params = {
  id: string
}

const DEFAULT_SHOP_ID = 13;

export const Registration: React.FC = observer((): JSX.Element => {
  const { id } = useParams<Params>();
  const {
    consumers: {
      getConsumerById,
      updateRegistration,
    },
    shopsModel: {
      fetchShops,
      shopsAutoComplete,
    },
    serviceModels: {
      modelsAutoComplete,
      fetchServiceModels,
    },
    serviceColors: {
      colorsAutoComplete,
      fetchServiceColors,
    },
    cities: {
      fetchCities,
      citiesAutoComplete,
    },
    countriesModel: {
      countriesAutoComplete,
      fetchCountries,
    },
  } = useStore();

  const { isFetching: isFetchingCountries } = useRequest({
    action: fetchCountries,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
  });

  const { isFetching: isFetchingShops } = useRequest({
    action: fetchShops,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
  });

  const { isFetching: isFetchingServiceModels } = useRequest({
    action: fetchServiceModels,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
  });

  const { isFetching: isFetchingCities } = useRequest({
    action: fetchCities,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
  });

  const { isFetching: isFetchingServiceColors } = useRequest({
    action: fetchServiceColors,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
  });

  const consumer = getConsumerById(id);

  const {
    handleSubmit, control, reset, setValue, formState: { isSubmitting },
  } = useForm<ConsumerRegistrationValues>({
    defaultValues: {
      modelId: DEFAULT_AUTOCOMPLETE_ID,
      colorId: DEFAULT_AUTOCOMPLETE_ID,
      serialNumber: '',
      price: '',
      sellerCountryId: DEFAULT_AUTOCOMPLETE_ID,
      sellerCityId: DEFAULT_AUTOCOMPLETE_ID,
      sellerShopId: DEFAULT_SHOP_ID,
      datePurchase: '',
      receiptPhotoLink: '',
    },
    resolver: yupResolver(rules),
  });

  const handleChangeSelectValue = useCallback((name: FieldPath<ConsumerRegistrationValues>) => (
    _,
    data: Option | null,
  ): void => {
    setValue(name, data?.value || DEFAULT_AUTOCOMPLETE_ID);
  }, []);

  useEffect(() => {
    if (consumer) {
      reset({
        modelId: consumer.model.id,
        colorId: consumer.color.id,
        price: consumer.price,
        serialNumber: consumer.serialNumber,
        sellerCountryId: consumer.sellerCountry.id,
        sellerCityId: consumer.sellerCity.id,
        sellerShopId: consumer.sellerShop.id,
        datePurchase: consumer.datePurchase,
        receiptPhotoLink: consumer.receiptPhotoLink,
      });
    }
  }, [consumer]);

  const isReady = !!consumer
    && !isFetchingServiceColors
    && !isFetchingCountries
    && !isFetchingCities
    && !isFetchingShops
    && !isFetchingServiceModels;

  const handleUpdateRegistration = async (data: ConsumerRegistrationValues): Promise<void> => {
    await updateRegistration({ id, data });
  };

  return isReady ? (
    <form onSubmit={handleSubmit(handleUpdateRegistration)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  disabled
                  label="Customer ID"
                  defaultValue={consumer.id}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled
                    inputFormat={COMMON_PICKER_DATE_VIEW}
                    views={['day']}
                    label="Date of creation"
                    value={consumer.createdAt}
                    onChange={(): void => {}}
                    renderInput={(props: TextFieldProps): JSX.Element => (
                      <TextField
                        {...props}
                        fullWidth
                        margin="normal"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="modelId"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(modelsAutoComplete, value)}
                      options={modelsAutoComplete}
                      onChange={handleChangeSelectValue('modelId')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Model"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="colorId"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(colorsAutoComplete, value)}
                      options={colorsAutoComplete}
                      onChange={handleChangeSelectValue('colorId')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Color"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="serialNumber"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Serial number"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="price"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Price"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="sellerCountryId"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      defaultValue={{
                        label: consumer.sellerCountry.name || '',
                        value: consumer.sellerCountry.id || DEFAULT_AUTOCOMPLETE_ID,
                      }}
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(countriesAutoComplete, value)}
                      options={countriesAutoComplete}
                      onChange={handleChangeSelectValue('sellerCountryId')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Country"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="sellerCityId"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      defaultValue={{
                        label: consumer.sellerCity.name || '',
                        value: consumer.sellerCity.id || DEFAULT_AUTOCOMPLETE_ID,
                      }}
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(citiesAutoComplete, value)}
                      options={citiesAutoComplete}
                      onChange={handleChangeSelectValue('sellerCityId')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="City"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="sellerShopId"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      defaultValue={{
                        label: consumer.sellerShop.name || '',
                        value: consumer.sellerShop.id || DEFAULT_AUTOCOMPLETE_ID,
                      }}
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(shopsAutoComplete, value || consumer.sellerShop.id)}
                      options={shopsAutoComplete}
                      onChange={handleChangeSelectValue('sellerShopId')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Shop"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="datePurchase"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat={COMMON_PICKER_DATE_VIEW}
                        views={['day']}
                        label="Date purchase"
                        value={value}
                        onChange={(e): void => {
                          onChange(formatPickerValue(e));
                        }}
                        InputProps={{
                          startAdornment: (
                            <IconButton
                              onClick={(): void => onChange(null)}
                              disabled={!value}
                              style={{ order: 1 }}
                            >
                              <ClearIcon color="disabled" fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        InputAdornmentProps={{
                          position: 'end',
                          style: { order: 2, marginLeft: 0 },
                        }}
                        renderInput={(props: TextFieldProps): JSX.Element => (
                          <TextField
                            {...props}
                            fullWidth
                            margin="normal"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="receiptPhotoLink"
                  control={control}
                  render={({
                    field: { value },
                  }): JSX.Element => (
                    <OpenWindowField value={value} disabled label="Receipt" />
                  )}
                />
              </Stack>
              <PermissionWrapper
                action={PermissionAction.EDIT}
                module={Module.CONSUMERS}
              >
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Save Changes
                  </LoadingButton>
                </Box>
              </PermissionWrapper>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  ) : (<LoadingScreen />);
});
