import { Theme } from '@material-ui/core/styles';

type CardReturn = {
  MuiCardHeader: {
    defaultProps: {
      subheaderTypographyProps: { variant: string; marginTop: string };
      titleTypographyProps: { variant: string }
    };
    styleOverrides: {
      root: {
        padding: string }
    }
  };
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: string
      }
    }
  };
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: string; borderRadius: number | string; position: string; zIndex: number
      }
    }
  }
}
export const Card = (theme: Theme):CardReturn => ({
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: theme.customShadows.z16,
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        zIndex: 0, // Fix Safari overflow: hidden with border radius
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: { variant: 'h6' },
      subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) },
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
      },
    },
  },
});
