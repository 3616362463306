import * as yup from 'yup';
import {
  stringReqSchema,
  numberReqSchema,
  priceSchema,
  stringSchema,
} from '../../../utils';

const rules = yup.object({
  modelId: numberReqSchema('Model'),
  colorId: numberReqSchema('Color'),
  price: priceSchema(),
  serialNumber: stringReqSchema('Serial number'),
  sellerCountryId: numberReqSchema('Country'),
  sellerCityId: numberReqSchema('City'),
  sellerShopId: numberReqSchema('Shop'),
  datePurchase: stringReqSchema('Date purchase'),
  receiptPhotoLink: stringSchema(),
});

export default rules;
