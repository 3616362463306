import React, { useCallback, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { HeaderBreadcrumbs, Page } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { Form } from '../Form';
import {
  DeviationCodeValues, Link, QueryParams,
} from '../../../types';
import { useRequest, useStore } from '../../../hooks';
import { DEFAULT_AUTOCOMPLETE_ID } from '../../../constants';
import rules from '../Form/rules';
import { StateWrapper } from '../../../components/common/StateWrapper';

const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Deviation codes', href: PATH_DASHBOARD.deviationCodes.list },
  { name: 'Edit' },
];

export const UpdateDeviationCode: React.FC = observer((): JSX.Element => {
  const { id } = useParams<QueryParams>();
  const { push } = useHistory();
  const {
    deviationCodes: {
      getDeviationCodeById,
      updateDeviationCode,
      fetchDeviationCodeById,
    },
  } = useStore();

  const deviationCode = getDeviationCodeById(id);

  const form = useForm<DeviationCodeValues>({
    defaultValues: {
      name: '',
      code: '',
      class: '',
      status: DEFAULT_AUTOCOMPLETE_ID,
    },
    resolver: yupResolver(rules),
  });

  const handleUpdate = useCallback(async (values: DeviationCodeValues): Promise<void> => {
    await updateDeviationCode(id, values);
    push(PATH_DASHBOARD.deviationCodes.list);
  }, [id]);

  const { isFetching, isError } = useRequest({
    action: fetchDeviationCodeById,
    params: id,
    condition: !deviationCode,
  });

  useEffect(() => {
    if (deviationCode) {
      form.reset({
        name: deviationCode.name,
        code: deviationCode.code,
        class: deviationCode.class,
        status: deviationCode.status,
        strollerPart: {
          id: deviationCode.strollerPart?.id,
        },
      });
    }
  }, [deviationCode]);

  const isReady = !!deviationCode && !isFetching;

  return (
    <Page title="Edit deviation code">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Edit deviation code"
          links={LINKS}
        />
        <FormProvider {...form}>
          <StateWrapper isError={isError} isReady={isReady}>
            <Form onAction={handleUpdate} />
          </StateWrapper>
        </FormProvider>
      </Container>
    </Page>
  );
});
