import {
  ForgotPassword, Login, Page404, Register, ResetPassword, Feedback,
} from '../pages';
import { PATH_AUTH, PATH_PAGE, RouteConfig } from './routesConfig';

export const UNPROTECTED_ROUTES: RouteConfig[] = [
  {
    exact: true,
    path: '/',
    component: Login,
  },
  {
    exact: false,
    path: PATH_AUTH.login,
    component: Login,
  },
  {
    exact: false,
    path: PATH_AUTH.register,
    component: Register,
  },
  {
    exact: false,
    path: PATH_AUTH.forgotPassword,
    component: ForgotPassword,
  },
  {
    exact: false,
    path: PATH_AUTH.resetPassword,
    component: ResetPassword,
  },
  {
    exact: false,
    path: PATH_PAGE.feedback,
    component: Feedback,
  },
  {
    exact: true,
    path: '*',
    component: Page404,
  },
];
