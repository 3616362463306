import React from 'react';
import {
  IconButton, TextField, TextFieldProps,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker } from '@material-ui/lab';
import { COMMON_PICKER_DATE_VIEW, COMMON_PICKER_MASK } from '../../constants';

type CustomDatePickerProps = {
  label: string,
  value: string | null,
  handleChange: (e: string | null) => void,
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  value,
  handleChange,
}): JSX.Element => (
  <DatePicker
    inputFormat={COMMON_PICKER_DATE_VIEW}
    mask={COMMON_PICKER_MASK}
    views={['day']}
    label={label}
    value={value}
    onChange={(e): void => {
      handleChange(e);
    }}
    InputProps={{
      startAdornment: (
        <IconButton
          onClick={(): void => handleChange(null)}
          disabled={!value}
          style={{ order: 1 }}
        >
          <ClearIcon color="disabled" fontSize="small" />
        </IconButton>
      ),
    }}
    InputAdornmentProps={{
      position: 'end',
      style: { order: 2, marginLeft: 0 },
    }}
    renderInput={(params: TextFieldProps): JSX.Element => (
      <TextField {...params} fullWidth size="small" />
    )}
  />
);
