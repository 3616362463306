import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Chip, Divider,
} from '@material-ui/core';
import { Message } from '../../../../types';
import { useStore } from '../../../../hooks';
import { fMessengerDividerDate } from '../../../../utils';
import { MessageCard } from './MessageCard';
import { UpdateMessageForm } from './UpdateMessageForm';

type MessageItemProps = {
  message: Message,
  isDateDivider: boolean,
  index: number,
}

export const MessageItem: React.FC<MessageItemProps> = observer(({
  message,
  isDateDivider,
  index,
}) => {
  const {
    channels: {
      selectedChannel,
    },
  } = useStore();

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleChangeEditState = (value: boolean) => (): void => setIsEdit(value);

  return (
    <>
      { isEdit ? (
        <UpdateMessageForm message={message} onChangeEditState={handleChangeEditState} />
      ) : (
        <MessageCard message={message} onChangeEditState={handleChangeEditState} />
      )}
      {isDateDivider && (
      <Divider>
        <Chip label={fMessengerDividerDate(message.createdAt)} />
      </Divider>
      )}
      {index + 1 === selectedChannel.unreadMessagesCount && (
      <Divider sx={{
        marginBottom: '5px',
        '&::before': {
          borderColor: (theme): string => theme.palette.error.main,
        },
        '&::after': {
          borderColor: (theme): string => theme.palette.error.main,
        },
      }}
      >
        <Chip
          label="Unread messages"
          sx={{
            background: (theme): string => theme.palette.error.light,
            color: (theme): string => theme.palette.grey[700],
          }}
        />
      </Divider>
      )}
    </>
  );
});
