import React, { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import {
  Menu, MenuItem, IconButton, ListItemIcon, ListItemText, PopoverOrigin,
} from '@material-ui/core';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

const ICON_SIZE_SM = 20;
const ICON_SIZE_MD = 24;
const ELEMENT_POSITION: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

type CommonMoreMenuProps = {
  itemId: number,
  updatingLink: string,
  onDelete?: (id: number) => void,
  isPermissionToDelete?: boolean,
};

export const CommonMoreMenu:React.FC<CommonMoreMenuProps> = ({
  itemId,
  updatingLink,
  onDelete,
  isPermissionToDelete = true,
}): JSX.Element => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleMenu = (value) => (): void => setIsOpen(value);

  const handleClick = (): void => {
    if (onDelete) {
      onDelete(itemId);
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={toggleMenu(true)}>
        <Icon icon={moreVerticalFill} width={ICON_SIZE_SM} height={ICON_SIZE_SM} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={toggleMenu(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={ELEMENT_POSITION}
        transformOrigin={ELEMENT_POSITION}
      >
        <MenuItem
          component={RouterLink}
          to={`${updatingLink}/${itemId}`}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={ICON_SIZE_MD} height={ICON_SIZE_MD} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {onDelete && isPermissionToDelete && (
        <MenuItem onClick={handleClick} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={ICON_SIZE_MD} height={ICON_SIZE_MD} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        )}
      </Menu>
    </>
  );
};
