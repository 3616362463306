import React from 'react';
import { CustomItem } from './CustomItem';
import { SparePartReport } from '../../../../types';

type SparePartItemsProps = {
    sparePart: SparePartReport;
}

export const SparePartItems: React.FC<SparePartItemsProps> = ({
  sparePart,
}): JSX.Element => (
  <CustomItem
    title={(sparePart.warranty === 1 && 'Yes')
        || (sparePart.warranty === 0 && 'No')
        || '-'}
    width="14%"
  >
    <CustomItem
      title={sparePart.solution}
      width="24%"
      sx={{ textTransform: 'capitalize' }}
    >
      <CustomItem title={sparePart.name} width="42%">
        <CustomItem
          title={sparePart.spareQuantity}
          width="49%"
        >
          <CustomItem title={sparePart.price} width="90%" />
        </CustomItem>
      </CustomItem>
    </CustomItem>
  </CustomItem>
);
