import React, { useCallback } from 'react';
import { Container } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { HeaderBreadcrumbs, Page } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { Form } from '../Form';
import { DeviationCodeValues, Link } from '../../../types';
import { DEFAULT_AUTOCOMPLETE_ID } from '../../../constants';
import rules from '../Form/rules';
import { useStore } from '../../../hooks';

const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Deviation codes', href: PATH_DASHBOARD.deviationCodes.list },
  { name: 'New' },
];

export const CreateDeviationCode: React.FC = observer((): JSX.Element => {
  const {
    deviationCodes: {
      createDeviationCode,
    },
  } = useStore();

  const { push } = useHistory();

  const form = useForm<DeviationCodeValues>({
    defaultValues: {
      name: '',
      code: '',
      class: '',
      status: DEFAULT_AUTOCOMPLETE_ID,
      strollerPart: {
        id: DEFAULT_AUTOCOMPLETE_ID,
      },
    },
    resolver: yupResolver(rules),
  });

  const handleCreate = useCallback(async (values: DeviationCodeValues): Promise<void> => {
    await createDeviationCode(values);
    push(PATH_DASHBOARD.deviationCodes.list);
  }, []);

  return (
    <Page title="Create deviation code">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Create deviation code"
          links={LINKS}
        />
        <FormProvider {...form}>
          <Form onAction={handleCreate} />
        </FormProvider>
      </Container>
    </Page>
  );
});
