import { alpha, Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type PaginationReturn = {
  MuiPaginationItem: { styleOverrides: { outlined: { border: string }; root: { '&.Mui-selected': { fontWeight: '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset' | 'bold' | 'normal' | number | 'bolder' | 'lighter' | undefined } }; outlinedPrimary: { '&.Mui-selected': { border: string; backgroundColor: string } }; textPrimary: { '&.Mui-selected': { backgroundColor: string; color: string; '&:hover, &.Mui-focusVisible': { backgroundColor: string } } } } }
}
export const Pagination = (theme: Theme): PaginationReturn => ({
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      textPrimary: {
        '&.Mui-selected': {
          color: theme.palette.primary.main,
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: `${alpha(theme.palette.primary.main, 0.24)} !important`,
          },
        },
      },
      outlined: {
        border: `1px solid ${theme.palette.grey[500_32]}`,
      },
      outlinedPrimary: {
        '&.Mui-selected': {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          border: `1px solid ${alpha(theme.palette.primary.main, 0.24)}`,
        },
      },
    },
  },
});
