import {
  applySnapshot,
  flow,
  getEnv,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  User,
  UserModel,
  ListMetaDataModel,
  UserData,
  UsersListData,
  Option,
  UsersParams,
  InviteValues,
} from '../types';
import { DEFAULT_META, COMMON_SCHEMA } from '../constants';
import { mapEntityToOption } from '../utils';
import { ApiErrorData } from '../services/api/api.helpers';

export const UserStoreModel = types.model('UserStore')
  .props({
    items: types.optional(types.array(UserModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    inviteUser: flow(function* inviteUser(values: InviteValues) {
      try {
        yield getEnv(self).api.inviteUser(values);
        toast.success('Invitation was sent');
      } catch (e) {
        const error = e as ApiErrorData;
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error('Error with sending invitation');
        }
      }
    }),
    fetchUsers: flow(function* fetchUsers(params: UsersParams) {
      try {
        const { items, meta }: UsersListData = yield getEnv(self).api.fetchUsers(params);
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with fetching users');
      }
    }),
    fetchUser: flow(function* fetchUser(id: string) {
      const user: User = yield getEnv(self).api.fetchUser(id);
      applySnapshot(self.items, [user]);
    }),
    updateUser: flow(function* updateUser({ id, data }: UserData) {
      try {
        yield getEnv(self).api.updateUser({ id, data });
        toast.success('User was updated successfully');
      } catch (e) {
        toast.error('Error with updating user');
      }
    }),
  }))
  .actions((self) => ({
    deleteUser: flow(function* deleteUser(id: number) {
      try {
        yield getEnv(self).api.deleteUser(id);
        const params = { page: self.meta.currentPage };
        self.fetchUsers(params);
        toast.success('User was deleted successfully');
      } catch (e) {
        toast.error('Error with deleting user');
      }
    }),
  }))
  .views((self) => ({
    getUserById(id): User | undefined {
      return self.items.find((user) => user.id === Number(id));
    },
    get usersAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<User>(item, COMMON_SCHEMA));
    },
  }));

/**
 * The AuthStore instance.
 */
export type UserStore = Instance<typeof UserStoreModel>

/**
 * The data of a AuthStore.
 */
export type UserStoreSnapShot = SnapshotOut<typeof UserStoreModel>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createUserStoreModel = () => types.optional(UserStoreModel, {});
