import { Instance, types } from 'mobx-state-tree';
import { MessageTypes } from '../../constants';

const MessageType = types.enumeration('MessageType', [
  MessageTypes.TEXT, MessageTypes.PICTURE, MessageTypes.VIDEO,
]);

export const ChannelItemModel = types.model('ChannelItemModel', {
  id: types.number,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  clientId: types.number,
  avatar: types.maybeNull(types.string),
  content: types.maybe(types.string),
  createdAt: types.string,
  unreadMessagesCount: types.maybeNull(types.number),
  messageType: MessageType,
});

export type ChannelItem = Instance<typeof ChannelItemModel>

const ChannelFileModel = types.model('ChannelFileModel', {
  id: types.string,
  file: types.string,
  createdAt: types.string,
});

const RecordingModel = types.model('RecordingModel', {
  id: types.number,
  url: types.maybeNull(types.string),
  createdAt: types.string,
});

export const SelectedChannelModel = types.model('SelectedChannelModel', {
  id: types.number,
  firstName: types.string,
  lastName: types.string,
  avatar: types.maybeNull(types.string),
  email: types.maybe(types.union(types.string, types.null)),
  address: types.maybe(types.union(types.string, types.null)),
  phone: types.maybe(types.union(types.string, types.null)),
  reclamationId: types.maybeNull(types.number),
  clientId: types.maybeNull(types.number),
  files: types.optional(types.array(ChannelFileModel), []),
  unreadMessagesCount: types.maybeNull(types.number),
  recordings: types.optional(types.array(RecordingModel), []),
});

export type SelectedChannel = Instance<typeof SelectedChannelModel>

export type ChannelFile = Instance<typeof ChannelFileModel>
