import React, { useCallback } from 'react';
import { Container } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { HeaderBreadcrumbs, Page } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { Form } from '../Form';
import { SparePartValues } from '../../../types';
import rules from '../Form/rules';
import { useStore } from '../../../hooks';
import { DEFAULT_SPARE_PART } from '../List/constants';
import { LINKS } from './constants';

export const CreateSparePart: React.FC = observer((): JSX.Element => {
  const {
    spareParts: {
      createSparePart,
    },
  } = useStore();

  const { push } = useHistory();

  const form = useForm<SparePartValues>({
    defaultValues: DEFAULT_SPARE_PART,
    resolver: yupResolver(rules),
  });

  const handleCreate = useCallback(async (values: SparePartValues): Promise<void> => {
    await createSparePart(values);
    push(PATH_DASHBOARD.spareParts.list);
  }, []);

  return (
    <Page title="Create spare part">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Create spare part"
          links={LINKS}
        />
        <FormProvider {...form}>
          <Form onAction={handleCreate} />
        </FormProvider>
      </Container>
    </Page>
  );
});
