import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type ContainerReturn = {
  MuiContainer: { styleOverrides: { root: Record<string, never> } }
}
export const Container = (theme: Theme): ContainerReturn => ({
  MuiContainer: {
    styleOverrides: {
      root: {},
    },
  },
});
