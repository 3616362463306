import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const CoverLogoStyle = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

export const Logo:React.FC<BoxProps> = ({ sx }): JSX.Element => (
  <Box sx={{ width: 150, height: 60, ...sx }}>
    <CoverLogoStyle src="/static/logo.png" alt="Logo" />
  </Box>
);
