import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import {
  Box, Button, Typography, Container,
} from '@material-ui/core';
import React from 'react';
import { Page } from './Page';
import { SeverErrorIllustration } from '../../assets';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  width: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

const TITLE = 'Error';
const DESCRIPTION = 'Something went wrong';

type ErrorPageProps = {
    title: string,
    description: string,
    goBack: boolean,
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  title = TITLE,
  description = DESCRIPTION,
  goBack = true,
}): JSX.Element => (
  <RootStyle title="Page error">
    <Container>
      <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
        <Typography variant="h3" paragraph>
          {title}
        </Typography>
        <Typography sx={{ color: 'textSecondary' }}>
          {description}
        </Typography>

        <SeverErrorIllustration />
        {goBack && (
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
        )}
      </Box>
    </Container>
  </RootStyle>
);
