import React from 'react';
import { Divider } from '@material-ui/core';
import { UserInfo } from './UserInfo';
import { AttachmentList } from './Attacment';
import { RecordingList } from './Recording';

export const SidebarContent:React.FC = () => (
  <>
    <div>
      <UserInfo />
    </div>
    <Divider />
    <AttachmentList />
    <Divider />
    <RecordingList />
  </>
);
