import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import {
  AccordionWrapper,
  CommonListHead, ConfirmDialog,
  HeaderBreadcrumbs,
  NotFound,
  Page,
  ReclamationItem, Scrollbar,
} from '../../../components';
import { LINKS, TABLE_HEAD_LIST } from './constants';
import { useStore, usePrevious } from '../../../hooks';
import { ReclamationToolbar } from '../Toolbar';
import { ReclamationsFilters } from '../../../types';
import { DEFAULT_ID, FIRST_PAGE } from '../../../constants';

const EMPTY_LIST_MESSAGE = 'There are no claims on this filter(s)';

export const ReclamationList:React.FC = observer((): JSX.Element => {
  const {
    reclamations: {
      deleteReclamation,
      fetchReclamations,
      getFilters: commonFilters,
      items,
      currentPage: internalCurrentPage,
      changePage,
      meta: {
        totalItems,
        currentPage,
        itemsPerPage,
      },
    },
  } = useStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [filters, setFilters] = useState<ReclamationsFilters>({ ...commonFilters });
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState<boolean>(false);
  const [reclamationId, setReclamationId] = useState<number>(DEFAULT_ID);

  const prevFilters = usePrevious<ReclamationsFilters>(filters);
  const firstRender = useRef(true);

  const toggleDeleteModal = useCallback((value: boolean) => (): void => {
    setIsOpenedDeleteModal(value);
  }, []);

  const handleOpenDeleteModal = useCallback((id: number): void => {
    setReclamationId(id);
    setIsOpenedDeleteModal(true);
  }, []);

  const handleDelete = useCallback((): void => {
    deleteReclamation(reclamationId);
    setIsOpenedDeleteModal(false);
    setReclamationId(DEFAULT_ID);
  }, [reclamationId]);

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    changePage(newPage + 1);
  }, []);

  useEffect(() => {
    // check if filters were changed
    if (prevFilters !== filters && !firstRender.current) {
      fetchReclamations({ page: FIRST_PAGE, ...filters });
    } else {
      fetchReclamations({ page: internalCurrentPage, ...filters });
    }
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [internalCurrentPage, filters, firstRender.current]);

  return (
    <>
      <Page title="Claims list">
        <Container maxWidth={false}>
          <HeaderBreadcrumbs
            heading="Claims List"
            links={LINKS}
          />
          <Card>
            <AccordionWrapper>
              <ReclamationToolbar
                setFilters={setFilters}
              />
            </AccordionWrapper>
            <TableContainer>
              <Scrollbar>
                <Table>
                  <CommonListHead headListData={TABLE_HEAD_LIST} align="center" />
                  <TableBody>
                    {items.length ? (
                      items.map((item) => (
                        <ReclamationItem
                          key={item.id}
                          data={item}
                          onDelete={handleOpenDeleteModal}
                        />
                      ))) : (
                        <TableRow>
                          <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                            <NotFound message={EMPTY_LIST_MESSAGE} />
                          </TableCell>
                        </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            {!!items.length && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalItems}
              rowsPerPage={itemsPerPage}
              page={currentPage - 1}
              onPageChange={handlePageChange}
            />
            )}
          </Card>
        </Container>
      </Page>
      <ConfirmDialog
        isOpened={isOpenedDeleteModal}
        onClose={toggleDeleteModal(false)}
        callback={handleDelete}
      />
    </>
  );
});
