import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { getTokenFromStorage } from '../utils/localStorage';
import { PATH_AUTH } from './routesConfig';
import { DashboardLayout } from '../layouts/dashboard';
import { Module, PermissionAction } from '../constants';
import { PermissionWrapper } from '../components';

type PrivateRouteProps = {
  component: React.ComponentType,
  path: string,
  exact?: boolean,
  module: Module,
  action: PermissionAction,
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  path,
  exact = false,
  module,
  action,
  ...props
}): JSX.Element => {
  const location = useLocation();
  const token = getTokenFromStorage();
  return token ? (
    <DashboardLayout>
      <PermissionWrapper
        action={action}
        module={module}
        isErrorPage
      >
        <Route
          path={path}
          exact={exact}
          component={Component}
          {...props}
        />
      </PermissionWrapper>
    </DashboardLayout>
  ) : (
    <Redirect
      to={{
        pathname: PATH_AUTH.login,
        state: { from: location },
      }}
    />
  );
};
