import { useState } from 'react';
import { useDebounce } from 'use-debounce';

const SEARCH_TIMEOUT = 400;

type UseSearch = {
  debouncedSearchValue: string,
  searchValue: string,
  setSearchValue: (newSearchValue: string) => void
}

export const useSearch = (): UseSearch => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_TIMEOUT);

  return {
    debouncedSearchValue,
    searchValue,
    setSearchValue,
  };
};
