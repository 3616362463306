import {
  applySnapshot,
  flow, getEnv,
  Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import { ModuleModel, ModulesListData } from '../types';
import { Module, PermissionAction, UserRole } from '../constants';

type CheckGrantPermission = {
  module: Module,
  action: PermissionAction,
  role: UserRole
}

export const ModuleStore = types.model('ModuleStore')
  .props({
    modules: types.array(ModuleModel),
  })
  .actions((self) => ({
    fetchModules: flow(function* fetchModules() {
      try {
        const modules: ModulesListData = yield getEnv(self).api.fetchModules();
        applySnapshot(self.modules, modules);
      } catch (e) {
        toast.error('Error with getting modules and permissions');
      }
    }),
  }))
  .views((self) => ({
    checkGrantPermission({ module, action, role }: CheckGrantPermission): boolean {
      const selectedModule = self.modules.find((item) => item.label === module);
      if (selectedModule) {
        const permission = selectedModule.permissions.find((item) => item.permission === action);
        if (permission) {
          return !!permission.roles.find((item) => item === role);
        }
        return false;
      }
      return false;
    },
  }));

export type ModuleStore = Instance<typeof ModuleStore>

export type ModuleStoreSnapShot = SnapshotOut<typeof ModuleStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createModuleStoreModel = () => types.optional(ModuleStore, {});
