import React from 'react';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';

import { CommonMoreMenu } from '../../components';
import { Country } from '../../types';
import { PATH_DASHBOARD } from '../../app';
import { COUNTRY_STATUS } from '../../constants';
import { useOnItemClick } from '../../hooks/useOnItemClick';

type CELL = {
  name: string,
  render?: (data: Country, cell: CELL) => string,
}

const CELLS: CELL[] = [
  {
    name: 'id',
  },
  {
    name: 'name',
  },
  {
    name: 'code',
  },
  {
    name: 'status',
    render: (data, cell): string => COUNTRY_STATUS[data[cell.name]].title,
  },
];

type CountryItemProps = {
  data: Country,
}

const TableRowStyles = {
  cursor: 'pointer',
};

const UPDATING_LINK = PATH_DASHBOARD.countries.root;

export const CountriesItem:React.FC<CountryItemProps> = ({
  data,
}): JSX.Element => {
  const { onItemClick } = useOnItemClick(UPDATING_LINK, data.id);

  return (
    <TableRow
      hover
      sx={TableRowStyles}
    >
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          onClick={onItemClick}
        >
          {cell.render ? cell.render(data, cell) : data[cell.name]}
        </TableCell>
      ))}
      <TableCell align="right">
        <CommonMoreMenu
          itemId={data.id}
          updatingLink={UPDATING_LINK}
        />
      </TableCell>
    </TableRow>
  );
};
