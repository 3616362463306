import React, { ReactNode } from 'react';
import { useLocalStorage } from '../../hooks';
import {
  ThemeMode, ThemeColor,
} from '../../types';
import { PRIMARY_COLOR, DEFAULT_STATE } from './constants';
import { SettingsContext } from './SettingsContext';

const SetColor = (themeColor: ThemeColor): ThemeColor => {
  const DEFAULT = PRIMARY_COLOR[0];
  const PURPLE = PRIMARY_COLOR[1];
  const CYAN = PRIMARY_COLOR[2];
  const BLUE = PRIMARY_COLOR[3];
  const ORANGE = PRIMARY_COLOR[4];
  const RED = PRIMARY_COLOR[5];
  let color;

  switch (themeColor) {
    case 'purple':
      color = PURPLE;
      break;
    case 'cyan':
      color = CYAN;
      break;
    case 'blue':
      color = BLUE;
      break;
    case 'orange':
      color = ORANGE;
      break;
    case 'red':
      color = RED;
      break;
    default:
      color = DEFAULT;
  }
  return color;
};

type SettingsProviderProps = {
  children: ReactNode;
};

export const SettingsProvider:React.FC<SettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: DEFAULT_STATE.themeMode,
    themeColor: DEFAULT_STATE.themeColor,
  });

  const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSettings({
      ...settings,
      themeMode: (event.target as HTMLInputElement).value as ThemeMode,
    });
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSettings({
      ...settings,
      themeColor: (event.target as HTMLInputElement).value as ThemeColor,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        onChangeMode,
        onChangeColor,
        setColor: SetColor(settings.themeColor),
        colorOption: PRIMARY_COLOR.map((color) => ({
          name: color.name,
          value: color.main,
        })),
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
