import { PATH_DASHBOARD } from '../../app';
import { SvgIconStyle } from '../../components';
import { Module } from '../../constants';
import { NavConfigType } from '../../components/common/NavSection';

const getIcon = (name: string): JSX.Element => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
);

const ICONS = {
  banking: getIcon('ic_banking'),
  blog: getIcon('ic_blog'),
  booking: getIcon('ic_booking'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const sidebarConfig: NavConfigType[] = [
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.kanban,
        module: Module.DASHBOARD,
      },
      {
        title: 'reports',
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.analytics,
        module: Module.REPORTS,
      },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'Anex Team',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
        module: Module.USERS,
      },
      {
        title: 'Claims',
        path: PATH_DASHBOARD.reclamations.list,
        icon: ICONS.blog,
        module: Module.RECLAMATIONS,
      },
      {
        title: 'Customers',
        path: PATH_DASHBOARD.consumers.list,
        icon: ICONS.blog,
        module: Module.CONSUMERS,
      },
      {
        title: 'Registered strollers',
        path: PATH_DASHBOARD.strollers.list,
        icon: ICONS.booking,
        module: Module.CONSUMERS,
      },
      {
        title: 'Customer Care',
        path: PATH_DASHBOARD.customerCare,
        icon: ICONS.dashboard,
        module: Module.CUSTOMER_CARE,

      },
      {
        title: 'Knowledge base',
        path: PATH_DASHBOARD.knowledgeBases.list,
        icon: ICONS.banking,
        module: Module.KNOWLEDGE_BASE,
      },
      {
        title: 'Shops',
        path: PATH_DASHBOARD.shops.list,
        icon: ICONS.blog,
        module: Module.SHOPS,
      },
      {
        title: 'Countries',
        path: PATH_DASHBOARD.countries.list,
        icon: ICONS.blog,
        module: Module.COUNTRIES,
      },
      {
        title: 'Deviation codes',
        path: PATH_DASHBOARD.deviationCodes.list,
        icon: ICONS.blog,
        module: Module.DEVIATION_CODES,
      },
      {
        title: 'Stroller Parts',
        path: PATH_DASHBOARD.strollerParts.list,
        icon: ICONS.blog,
        module: Module.STROLLER_PARTS,
      },
      {
        title: 'Spare Parts',
        path: PATH_DASHBOARD.spareParts.list,
        icon: ICONS.blog,
        module: Module.SPARE_PARTS,
      },
    ],
  },
  {
    subheader: 'app',
    items: [
      {
        title: 'chat',
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
        module: Module.CHAT,
      },
      {
        title: 'kanban',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban,
        module: Module.KANBAN,
      },
    ],
  },
];

export default sidebarConfig;
