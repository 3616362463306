import {
  applySnapshot,
  flow, getEnv, Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  Country, CountryModel, ListMetaDataModel,
  CountryListData, CountryValues, ListParams, Option, CountryListParams,
} from '../types';
import {
  COMMON_SCHEMA, CountryStatus, DEFAULT_AUTOCOMPLETE_PARAMS, DEFAULT_META,
} from '../constants';
import { mapEntityToOption } from '../utils';

export const CountryStore = types.model('CountryStore')
  .props({
    items: types.optional(types.array(CountryModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
    officeCountries: types.optional(types.array(CountryModel), []),
  })
  .actions((self) => ({
    fetchAllCountries: flow(function* fetchAllCountries(params: ListParams) {
      try {
        const { items, meta }: CountryListData = yield getEnv(self).api.fetchAllCountries(params);
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting all countries');
      }
    }),
    fetchCountries: flow(function* fetchCountries(params: CountryListParams) {
      try {
        const { items, meta }: CountryListData = yield getEnv(self).api.fetchCountries(params);
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting countries');
      }
    }),
    fetchOfficeCountries: flow(function* fetchOfficeCountries() {
      try {
        const { items }: CountryListData = yield getEnv(self).api.fetchCountries({
          ...DEFAULT_AUTOCOMPLETE_PARAMS,
          onlyOffice: true,
        });
        applySnapshot(self.officeCountries, items);
      } catch (e) {
        toast.error('Error with getting countries for select office');
      }
    }),
    fetchCountry: flow(function* fetchCountry(id: string) {
      const data = yield getEnv(self).api.fetchCountry(id);
      applySnapshot(self.items, [data]);
    }),
    updateCountry: flow(function* updateCountry(id: string, values: CountryValues) {
      try {
        yield getEnv(self).api.updateCountry(id, values);
        toast.success('Country was updated');
      } catch (e) {
        toast.error('Error with updating country');
      }
    }),
  }))
  .views((self) => ({
    getCountryById(id): Country | undefined {
      if (id) {
        return self.items.find((country) => country.id === Number(id));
      }
      return undefined;
    },
    getOfficeCountryById(id): Country | undefined {
      if (id) {
        return self.officeCountries.find((country) => country.id === Number(id));
      }
      return undefined;
    },
    get countriesAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<Country>(item, COMMON_SCHEMA));
    },
    get officeCountriesAutoComplete(): Option[] {
      return self.officeCountries.map((item) => mapEntityToOption<Country>(item, COMMON_SCHEMA));
    },
    get countriesForChartFilters(): Option[] {
      return self.items
        .filter((item) => item.showInCharts && item.status === CountryStatus.ACTIVE)
        .map((item) => mapEntityToOption<Country>(item, COMMON_SCHEMA));
    },
  }));

export type CountryStore = Instance<typeof CountryStore>

export type ShopStoreSnapShot = SnapshotOut<typeof CountryStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createCountryStoreModel = () => types.optional(CountryStore, {});
