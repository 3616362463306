import { useState } from 'react';
import { fIso8601, isValidDate } from '../../../utils';

type UseDatePeriodFilter = {
  dateTo: string | null,
  handleChangeDateTo: (newDate: string | null) => void
  dateFrom: string | null,
  handleChangeDateFrom: (newDate: string | null) => void
}

export const useDatePeriodFilter = (): UseDatePeriodFilter => {
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [dateFrom, setDateFrom] = useState<string | null>(null);

  const handleChangeDateTo = (newDate: string | null): void => {
    if (isValidDate(newDate) || !newDate) {
      const date = newDate ? fIso8601(newDate) : null;
      setDateTo(date);
    }
  };
  const handleChangeDateFrom = (newDate: string | null): void => {
    if (isValidDate(newDate) || !newDate) {
      const date = newDate ? fIso8601(newDate) : null;
      setDateFrom(date);
    }
  };
  return {
    dateTo,
    handleChangeDateTo,
    dateFrom,
    handleChangeDateFrom,
  };
};
