import { Instance, types } from 'mobx-state-tree';
import { MessageTypes } from '../../constants';

const Type = types.enumeration('Type', [
  MessageTypes.TEXT, MessageTypes.PICTURE, MessageTypes.VIDEO,
]);

const MessageAuthorModel = types.model('MessageAuthorModel', {
  id: types.number,
  name: types.string,
  surname: types.string,
  avatar: types.maybe(types.string),
});

const MessageFileModel = types.model('MessageFileModel', {
  id: types.string,
  file: types.string,
  createdAt: types.string,
});

export const MessageModel = types.model('MessageModel', {
  id: types.number,
  content: types.string,
  externalMessageId: types.union(types.string, types.number, types.null),
  createdAt: types.string,
  updatedAt: types.string,
  type: Type,
  author: types.maybeNull(MessageAuthorModel),
  files: types.optional(types.array(MessageFileModel), []),
  channelId: types.maybe(types.number),
});

export type Message = Instance<typeof MessageModel>

export type MessageFile = Instance<typeof MessageFileModel>
