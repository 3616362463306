import { PATH_DASHBOARD } from '../../../app';
import { HeadCell } from '../../../types/grid';
import { Link } from '../../../types';

export const TABLE_HEAD_LIST: readonly HeadCell[] = [
  { id: 'id', label: '#' },
  { id: 'name', label: 'Name' },
  { id: 'surname', label: 'Surname' },
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },
  { id: 'country', label: 'Country' },
  { id: 'more_menu', label: '' },
] as const;

export const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'List' },
];
