import React from 'react';
import { LoadingScreen } from './LoadingScreen';
import { Page404 } from '../../pages';
import { ResponseStatuses } from '../../constants';
import { ErrorPage } from './ErrorPage';

type StateWrapperProps = {
    children: React.ReactNode,
    isError: number | null,
    isReady: boolean,
}

const FORBIDDEN_STATUS = {
  title: 'Forbidden',
  description: 'You don`t have enough permissions',
  goBack: true,
};

export const StateWrapper: React.FC<StateWrapperProps> = ({
  children,
  isError,
  isReady,
}) => {
  if (isError === ResponseStatuses.NOT_FOUND) {
    return (
      <Page404 />
    );
  }
  if (isError === ResponseStatuses.FORBIDDEN) {
    return (
      <ErrorPage
        title={FORBIDDEN_STATUS.title}
        description={FORBIDDEN_STATUS.description}
        goBack={FORBIDDEN_STATUS.goBack}
      />
    );
  }
  if (!isReady) {
    return (
      <LoadingScreen />
    );
  }

  return (
    <>{children}</>
  );
};
