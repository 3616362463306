import React from 'react';
import {
  Box, Card, Grid, Stack,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from '../../../../hooks';
import { PasswordInput } from '../../../../components';
import { ChangePasswordValues } from '../../../../types';
import rules from './rules';

const DEFAULT_VALUES = {
  currentPassword: '',
  newPassword: '',
};

export const ChangePasswordForm: React.FC = observer((): JSX.Element => {
  const { handleSubmit, control, reset } = useForm<ChangePasswordValues>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(rules),
  });
  const { auth } = useStore();

  const handleChangePassword = (data: ChangePasswordValues): void => {
    auth.changePassword(data);
    reset();
  };

  return (
    <Grid container spacing={3} sx={{ py: 1 }}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <form onSubmit={handleSubmit(handleChangePassword)}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="currentPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <PasswordInput
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={error}
                      label="Current password"
                    />
                  )}
                />
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <PasswordInput
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={error}
                      label="New password"
                    />
                  )}
                />
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={false}>
                  Reset password
                </LoadingButton>
              </Box>
            </form>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
});
