import React, { useCallback, useEffect } from 'react';
import { DatePicker, LoadingButton, LocalizationProvider } from '@material-ui/lab';
import {
  Autocomplete, Box, Card, Grid, IconButton, Stack, TextField, TextFieldProps,
} from '@material-ui/core';

import { Controller, FieldPath, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import DateFnsUtils from '@material-ui/lab/AdapterDateFns';
import ClearIcon from '@material-ui/icons/Clear';
import { Option, QueryParams, StrollerFormValues } from '../../types';
import rules from './rules';
import { useAutocomplete, useRequest, useStore } from '../../hooks';
import { OpenWindowField, PermissionWrapper } from '../../components';
import { formatPickerValue, getOption } from '../../utils';
import {
  COMMON_PICKER_DATE_VIEW, DEFAULT_AUTOCOMPLETE_ID, List, Module, PermissionAction,
} from '../../constants';
import { StateWrapper } from '../../components/common/StateWrapper';

export const StrollersForm:React.FunctionComponent = observer(
  () => {
    const { strollersModel: { updateStroller, getStrollerById, fetchStroller } } = useStore();
    const { id } = useParams<QueryParams>();
    const currentStroller = getStrollerById(id);

    const { isFetching, isError } = useRequest({
      action: fetchStroller,
      params: id,
      condition: !currentStroller,
    });

    const {
      control, handleSubmit, setValue, reset,
    } = useForm<StrollerFormValues>({
      defaultValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        price: '',
        countryId: DEFAULT_AUTOCOMPLETE_ID,
        city: '',
        modelId: DEFAULT_AUTOCOMPLETE_ID,
        serialNumber: '',
        colorId: DEFAULT_AUTOCOMPLETE_ID,
        datePurchase: '',
        sellerCountryId: DEFAULT_AUTOCOMPLETE_ID,
        sellerCityId: DEFAULT_AUTOCOMPLETE_ID,
        sellerShopId: DEFAULT_AUTOCOMPLETE_ID,
        createdAt: '',
      },
      resolver: yupResolver(rules),
    });

    const cities = useAutocomplete(List.Cities);
    const serviceColors = useAutocomplete(List.Colors);
    const serviceModels = useAutocomplete(List.Models);
    const countries = useAutocomplete(List.Countries);
    const shops = useAutocomplete(List.Shops);

    const onSubmit = async (values: StrollerFormValues): Promise<void> => {
      await updateStroller(id, values);
    };

    const handleChangeSelectValue = useCallback((name: FieldPath<StrollerFormValues>) => (
      _,
      data: Option | null,
    ): void => {
      setValue(name, data?.value || DEFAULT_AUTOCOMPLETE_ID);
    }, []);

    useEffect(() => {
      if (currentStroller) {
        reset({
          firstName: currentStroller.firstName,
          lastName: currentStroller.lastName,
          email: currentStroller.email,
          phone: currentStroller.phone,
          price: currentStroller.price,
          countryId: currentStroller.country.id,
          city: currentStroller.city,
          modelId: currentStroller.model.id,
          serialNumber: currentStroller.serialNumber,
          colorId: currentStroller.color.id,
          datePurchase: currentStroller.datePurchase,
          sellerCountryId: currentStroller.sellerCountry.id,
          sellerCityId: currentStroller.sellerCity.id,
          sellerShopId: currentStroller.sellerShop.id,
          createdAt: currentStroller.createdAt,
        });
      }
    }, [currentStroller]);

    const isReady = !!currentStroller
      && !!countries.length
      && !!serviceModels.length
      && !!serviceColors.length
      && !!shops.length
      && !!cities.length
      && !isFetching;

    return (
      <StateWrapper isError={isError} isReady={isReady}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="First Name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Last Name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Email"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="phone"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Phone"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="countryId"
                      control={control}
                      render={({
                        field: { value },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          fullWidth
                          value={getOption(countries, value)}
                          options={countries}
                          onChange={handleChangeSelectValue('countryId')}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Country" />}
                        />
                      )}
                    />
                    <Controller
                      name="city"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="City"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="modelId"
                      control={control}
                      render={({
                        field: { value },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          defaultValue={{
                            value: (currentStroller && currentStroller.model.id)
                                || DEFAULT_AUTOCOMPLETE_ID,
                            label: (currentStroller && currentStroller.model.name) || '',
                          }}
                          fullWidth
                          value={getOption(serviceModels, value)}
                          options={serviceModels}
                          onChange={handleChangeSelectValue('modelId')}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Model" />}
                        />
                      )}
                    />
                    <Controller
                      name="serialNumber"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Serial number"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="colorId"
                      control={control}
                      render={({
                        field: { value },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          defaultValue={{
                            value: (currentStroller && currentStroller.color.id)
                                || DEFAULT_AUTOCOMPLETE_ID,
                            label: (currentStroller && currentStroller.color.name) || '',
                          }}
                          autoComplete={false}
                          fullWidth
                          value={getOption(serviceColors, value)}
                          options={serviceColors}
                          onChange={handleChangeSelectValue('colorId')}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Color" />}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="sellerCountryId"
                      control={control}
                      render={({
                        field: { value },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          defaultValue={{
                            value: (currentStroller && currentStroller.sellerCountry.id)
                              || DEFAULT_AUTOCOMPLETE_ID,
                            label: (currentStroller && currentStroller.sellerCountry.name) || '',
                          }}
                          autoComplete={false}
                          fullWidth
                          value={getOption(countries, value)}
                          options={countries}
                          onChange={handleChangeSelectValue('sellerCountryId')}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Seller country" />}
                        />
                      )}
                    />
                    <Controller
                      name="sellerCityId"
                      control={control}
                      render={({
                        field: { value },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          fullWidth
                          defaultValue={{
                            value: currentStroller?.sellerCity.id || DEFAULT_AUTOCOMPLETE_ID,
                            label: currentStroller?.sellerCity.name || '',
                          }}
                          value={getOption(cities, value)}
                          options={cities}
                          onChange={handleChangeSelectValue('sellerCityId')}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Seller city" />}
                        />
                      )}
                    />
                    <Controller
                      name="sellerShopId"
                      control={control}
                      render={({
                        field: { value },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          fullWidth
                          defaultValue={{
                            value: currentStroller?.sellerShop.id || DEFAULT_AUTOCOMPLETE_ID,
                            label: currentStroller?.sellerShop.name || '',
                          }}
                          value={getOption(shops, value)}
                          options={shops}
                          onChange={handleChangeSelectValue('sellerShopId')}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Seller shop" />}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item sm={4} xs={12}>
                        <Controller
                          name="price"
                          control={control}
                          render={({
                            field: { onBlur, onChange, value }, fieldState: { error },
                          }): JSX.Element => (
                            <TextField
                              fullWidth
                              label="Price"
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Controller
                          name="datePurchase"
                          control={control}
                          render={({
                            field: { onChange, value },
                          }): JSX.Element => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                inputFormat={COMMON_PICKER_DATE_VIEW}
                                views={['day']}
                                label="Date purchase"
                                value={value}
                                onChange={(e): void => {
                                  onChange(formatPickerValue(e));
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <IconButton
                                      onClick={(): void => onChange(null)}
                                      disabled={!value}
                                      style={{ order: 1 }}
                                    >
                                      <ClearIcon color="disabled" fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                                InputAdornmentProps={{
                                  position: 'end',
                                  style: { order: 2, marginLeft: 0 },
                                }}
                                renderInput={(params: TextFieldProps): JSX.Element => (
                                  <TextField {...params} fullWidth size="medium" />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <OpenWindowField value={currentStroller?.receiptPhotoLink} disabled label="Receipt" />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item sm={4} xs={12}>
                        <Controller
                          name="createdAt"
                          control={control}
                          render={({
                            field: { onChange },
                          }): JSX.Element => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                disabled
                                inputFormat={COMMON_PICKER_DATE_VIEW}
                                views={['day']}
                                label="Date of creation"
                                value={currentStroller?.createdAt}
                                onChange={(e): void => {
                                  onChange(e ? formatPickerValue(e) : '');
                                }}
                                renderInput={(params: TextFieldProps): JSX.Element => (
                                  <TextField {...params} fullWidth size="medium" />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <PermissionWrapper
                    action={PermissionAction.EDIT}
                    module={Module.CONSUMERS}
                  >
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton type="submit" variant="contained">
                        Save Changes
                      </LoadingButton>
                    </Box>
                  </PermissionWrapper>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </form>
      </StateWrapper>
    );
  },
);
