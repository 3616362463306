import * as yup from 'yup';
import { DECIMAL_REG_EXP, PHONE_REG_EXP, STRING_REG_EXP } from './regExp';

const FIRST_NAME_ERROR = 'Name is required';
const LAST_NAME_ERROR = 'Surname is required';

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function nameSchema(msg: string) {
  return yup.string().required(msg).matches(STRING_REG_EXP, 'Input only letters');
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function emailSchema() {
  return yup.string().required('Please enter an email').email('Email is not valid');
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function passwordSchema() {
  return yup.string().required('Password is required');
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function phoneSchema() {
  return yup.string().matches(PHONE_REG_EXP, 'Phone is not valid').nullable().default('');
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function userCountrySchema() {
  return yup.object({
    id: yup.number().required('Country is required'),
  });
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function userAvatarSchema() {
  return yup.object({
    id: yup.string().nullable().default(null),
  });
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function firstNameSchema() {
  return nameSchema(FIRST_NAME_ERROR);
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function surnameSchema() {
  return nameSchema(LAST_NAME_ERROR);
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function stringSchema() {
  return yup.string().default('').notRequired();
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function stringReqSchema(message: string) {
  return yup.string().required(`${message} is required`);
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function phoneReqSchema(message: string) {
  return yup.string().required(`${message} is required`).matches(PHONE_REG_EXP, 'Start to enter phone number from + and digits');
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function onlyStringReqSchema(message: string) {
  return yup.string().required(`${message} is required`);
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function numberReqSchema(message: string) {
  return yup.number().required().min(0, `${message} is required`);
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function numberSchema() {
  return yup.number();
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function objectIdSchema(message: string) {
  return yup.object({
    id: numberReqSchema(message),
  });
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function nullableStringSchema(max = 4000) {
  return yup.string().nullable().default(null).max(max, 'Too many symbols');
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function nullableOnlyStringSchema() {
  return yup.string()
    .nullable()
    .default(null);
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function nullableNumberSchema(min = 0) {
  return yup.number()
    .typeError('You must specify a number')
    .min(min, `Min value is ${min}.`)
    .nullable()
    .default(0);
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function objectNullableIdSchema() {
  return yup.object({
    id: nullableNumberSchema(),
  });
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function decimalSchema() {
  return yup.string().matches(DECIMAL_REG_EXP, 'The field should have digits only').nullable().default('0.0');
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function priceSchema() {
  return yup.string().matches(DECIMAL_REG_EXP, 'The field should have digits only').required('Price is required');
}
