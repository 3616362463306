import React from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { motion } from 'framer-motion';
import fileFill from '@iconify/icons-eva/file-fill';
import { MIconButton, varFadeInRight } from '../../../components';

type FileAttachmentProps = {
    name: string,
    onRemove(): void
}

export const FileAttachment: React.FC<FileAttachmentProps> = ({ name, onRemove }): JSX.Element => (
  <Box
    component={motion.div}
    {...varFadeInRight}
    sx={{
      p: 0,
      m: 0.5,
      width: '100%',
      height: 50,
      border: 'none',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Icon icon={fileFill} width={28} height={28} />
    <Typography
      variant="subtitle2"
      sx={{
        position: 'absolute',
        marginLeft: '30px',
        width: '90%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {name}
    </Typography>
    <Box sx={{ top: 10, right: 10, position: 'absolute' }}>
      <MIconButton
        size="small"
        onClick={onRemove}
        sx={{
          p: '2px',
          color: 'common.white',
          bgcolor: (theme): string => alpha(theme.palette.grey[900], 0.72),
          '&:hover': {
            bgcolor: (theme): string => alpha(theme.palette.grey[900], 0.48),
          },
        }}
      >
        <Icon icon={closeFill} />
      </MIconButton>
    </Box>
  </Box>
);
