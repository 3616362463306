import {
  applySnapshot, flow, getEnv, Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import { NotificationsModel } from '../types/models/NotificationModel';
import { NotificationsListData } from '../types/notifications';
import { ListParams } from '../types';

export const DEFAULT_STORE = {
  notifications: {},
  isMessagesRead: false,
  unreadCount: 0,
  unreadNotifications: {},
};

export const NotificationsStoreModel = types.model('NotificationsStoreModel')
  .props({
    notifications: types.optional(NotificationsModel, {}),
    isMessagesRead: types.boolean,
    unreadCount: types.maybeNull(types.number),
    unreadNotifications: types.optional(NotificationsModel, {}),
  })
  .actions((self) => ({
    fetchNotifications: flow(function* fetchNotifications(params: ListParams) {
      try {
        const { items, meta }: NotificationsListData = yield getEnv(self)
          .api.fetchNotifications(params);
        applySnapshot(self.notifications.items, items);
        self.notifications.meta = meta;
      } catch (e) {
        toast.error('Error with getting notifications');
      }
    }),
    postNotificationToken: flow(function* postNotificationToken(data: string) {
      try {
        yield getEnv(self).api.postNotificationToken(data);
      } catch (e) {
        toast.error('Error with created token');
      }
    }),
    markReadNotifications: flow(function* markReadNotifications() {
      try {
        yield getEnv(self).api.markReadNotifications();
        self.isMessagesRead = true;
      } catch (e) {
        toast.error('Error with marking notification as read');
      }
    }),
    markReadNotification: flow(function* markReadNotification(id: number) {
      try {
        const count: number = yield getEnv(self).api.markReadNotification(id);
        self.unreadCount = count || 0;
      } catch (e) {
        console.error(e);
      }
    }),
    fetchUnreadCount: flow(function* fetchUnreadCount() {
      try {
        self.unreadCount = yield getEnv(self).api.fetchUnreadCount();
      } catch (e) {
        toast.error('Error with getting unread count');
      }
    }),
    fetchUnreadNotifications: flow(function* fetchUnreadNotifications() {
      try {
        const { items, meta } = yield getEnv(self).api.fetchUnreadNotifications();
        applySnapshot(self.unreadNotifications.items, items);
        self.unreadNotifications.meta = meta;
      } catch (e) {
        toast.error('Error with getting unread notifications');
      }
    }),
  }));

export type NotificationsStore = Instance<typeof NotificationsStoreModel>

export type NotificationsStoreSnapShot = SnapshotOut<typeof NotificationsStoreModel>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createNotificationsStoreModel = () => types
  .optional(NotificationsStoreModel, DEFAULT_STORE);
