import { styled } from '@material-ui/core/styles';

export const ListWrapper = styled('div')(() => ({
  minWidth: '100%',
  maxWidth: '100%',
}));

export const Head = styled('ul')(({ theme }) => ({
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  width: '100%',
  backgroundColor: theme.palette.mode === 'light' ? '#F4F6F8' : 'rgba(145, 158, 171, 0.16)',
  borderRadius: '8px',
}));

export const HeadItem = styled('li')(({ theme }) => ({
  listStyle: 'none',
  fontSize: '14px',
  color: theme.palette.mode === 'light' ? '#637381' : '#919EAB',
  fontWeight: 600,
  borderLeft: '1px solid transparent',
  textAlign: 'center',
}));

export const MainList = styled('ul')(({ theme }) => ({
  padding: theme.spacing(0, 3, 0, 3),
  width: '100%',
  minWidth: '100%',
  maxWidth: '100%',
}));

export const List = styled('ul')(() => ({
  width: '100%',
}));

export const Item = styled('li')(() => ({
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  borderLeft: '1px solid rgba(145, 158, 171, 0.16)',
  borderBottom: '1px solid rgba(145, 158, 171, 0.16)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const ItemContent = styled('div')(() => ({
  whiteSpace: 'break-spaces',
  margin: '10px 0',
  fontSize: '12px',
  fontWeight: 400,
  textAlign: 'center',
}));

export const Divider = styled('span')(() => ({
  height: 'calc(100% - 80px)',
  backgroundColor: 'rgba(145, 158, 171, 0.05)',
  width: '1px',
  position: 'absolute',
  left: '105px',
  top: '80px',
}));

export const Container = styled('div')(() => ({
  display: 'flex',
  alignSelf: 'stretch',
}));

type ItemContentWrapperProps = {
  isLeftBorder?: boolean;
  width?: string;
};

export const ItemContentWrapper = styled('div')(
  ({ isLeftBorder = false, width = '80px' }: ItemContentWrapperProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width,
    borderLeft: isLeftBorder ? '1px solid rgba(145, 158, 171, 0.16)' : 'none',
  }),
);
