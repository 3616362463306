import React, { useCallback } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import {
  Card, Grid, CardContent,
} from '@material-ui/core';
import { PATH_DASHBOARD } from '../../../app';

const CardMediaStyle = styled('div')(() => ({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
  '&:hover': {
    opacity: '0.9',
  },
}));

const TitleStyle = styled(RouterLink)(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 'auto',
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(1),
  color: theme.palette.text.disabled,
}));

const CoverImgStyle = styled('img')(() => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
}));

const CoverVideoStyle = styled('video')(() => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
}));

type KnowledgeBasePostCardProps = {
  id: number;
  title: string;
  description: string;
  preview: string;
  type: string;
};

const PostCardStyles = {
  cursor: 'pointer',
  boxShadow: (theme): string => theme.customShadows.z1,
  '&:hover': {
    backgroundColor: 'rgba(145, 158, 171, 0.08)',
    boxShadow: (theme): string => theme.customShadows.z16,
  },
};

export const KnowledgeBasePostCard:React.FC<KnowledgeBasePostCardProps> = ({
  id,
  title,
  description,
  preview,
  type,
}) => {
  const { push } = useHistory();
  const linkToPost = `${PATH_DASHBOARD.knowledgeBases.post_root}/${id}`;

  const onClick = useCallback((): void => push(linkToPost), [linkToPost]);

  return (
    <Grid item xs={12} sm={4} md={4}>
      <Card onClick={onClick} sx={{ position: 'relative', ...PostCardStyles }}>
        <CardMediaStyle>
          {type?.includes('video') ? (
            <CoverVideoStyle src={preview || '/static/placeholder.svg'} />
          ) : (
            <CoverImgStyle alt={title} src={preview || '/static/placeholder.svg'} />
          )}
        </CardMediaStyle>
        <CardContent
          sx={{ pt: 3 }}
        >
          <TitleStyle to="#">
            {title}
          </TitleStyle>

          <InfoStyle>
            {description}
          </InfoStyle>
        </CardContent>
      </Card>
    </Grid>
  );
};
