export enum List {
    Countries = 'countries',
    Models = 'models',
    Cities = 'cities',
    Colors = 'colors',
    Shops = 'shops',
    Strollers = 'strollers',
    StrollerParts = 'strollerParts',
    DeviationCodes = 'deviationCodes',
    Reclamations = 'reclamations',
    UnsettedReclamations = 'unsettedReclamations',
}

export const DEFAULT_AUTOCOMPLETE_PARAMS = {
  limit: 200,
};
