import React, { ReactNode, useMemo } from 'react';
import {
  alpha, ThemeProvider, createTheme, useTheme,
} from '@material-ui/core/styles';
import { useSettings } from '../../hooks';
import { ComponentsOverrides } from '../../theme/overrides';

type ThemePrimaryColorProps = {
  children: ReactNode;
};

export const ThemePrimaryColor:React.FC<ThemePrimaryColorProps> = ({ children }) => {
  const defaultTheme = useTheme();
  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: setColor,
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
      },
    }),
    [setColor, defaultTheme],
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
