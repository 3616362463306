import { Theme } from '@material-ui/core/styles';

export const ControlLabel = (theme: Theme): unknown => ({
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        ...theme.typography.body2,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginTop: theme.spacing(1),
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.disabled,
      },
    },
  },
});
