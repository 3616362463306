import {
  applySnapshot,
  flow,
  getEnv,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  Consumer,
  ConsumerContactInfoData,
  ConsumerContactInfoValues,
  ConsumerModel,
  ConsumerRegistrationData,
  ConsumerRegistrationValues,
  ConsumersListData,
  ListMetaDataModel,
  ConsumersParams,
  UpdateItemProps,
  ReclamationsParams,
  ReclamationsListData,
  HistoryModel,
  ConsumerCountryCountParams,
  ConsumerCountryCount,
  ConsumerCountryCountModel,
  CreateConsumerValues,
} from '../types';
import { DEFAULT_META, DEFAULT_ID } from '../constants';

export const ConsumerStoreModel = types
  .model('ConsumerStore')
  .props({
    items: types.optional(types.array(ConsumerModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
    history: types.optional(HistoryModel, {}),
    countriesCount: types.optional(types.array(ConsumerCountryCountModel), []),
  })
  .actions((self) => ({
    updateConsumerItem<T>({
      res,
      successMessage,
      id,
    }: UpdateItemProps<T>): void {
      const consumerId = self.items.findIndex((item) => item.id === Number(id));
      if (consumerId !== DEFAULT_ID) {
        applySnapshot(self.items[consumerId], {
          ...self.items[consumerId],
          ...res,
        });
        toast.success(successMessage);
      }
    },
  }))
  .actions((self) => ({
    fetchConsumers: flow(function* fetchConsumers(
      params: ConsumersParams = {},
    ) {
      try {
        const { items, meta }: ConsumersListData = yield getEnv(
          self,
        ).api.fetchConsumers(params);
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting customers');
      }
    }),
    createConsumer: flow(function* createConsumer(data: CreateConsumerValues) {
      try {
        yield getEnv(self).api.createConsumer(data);
        toast.success('Consumer was created successfully');
      } catch (e) {
        toast.error('Error with creating consumer');
      }
    }),
    fetchConsumer: flow(function* fetchConsumer(id: string) {
      const consumer: Consumer = yield getEnv(self).api.fetchConsumer(id);
      applySnapshot(self.items, [consumer]);
    }),
    fetchConsumerCountryCount: flow(function* fetchConsumerCountryCount(
      params: ConsumerCountryCountParams = {},
    ) {
      try {
        const countriesCount: ConsumerCountryCount[] = yield getEnv(
          self,
        ).api.fetchConsumerCountryCount(params);
        applySnapshot(self.countriesCount, countriesCount);
      } catch (e) {
        toast.error('Error with getting customers countries count');
      }
    }),
    updateContactInfo: flow(function* updateAccountInfo({
      id,
      data,
    }: ConsumerContactInfoData) {
      try {
        const res: ConsumerContactInfoValues = yield getEnv(
          self,
        ).api.updateConsumerContactInfo({ id, data });
        self.updateConsumerItem<ConsumerContactInfoValues>({
          res,
          successMessage: 'Contact info was update successfully',
          id,
        });
      } catch (e) {
        toast.error('Error with updating account info');
      }
    }),
    updateRegistration: flow(function* updateRegistration({
      id,
      data,
    }: ConsumerRegistrationData) {
      try {
        const res: ConsumerRegistrationValues = yield getEnv(
          self,
        ).api.updateConsumerRegistration({ id, data });
        self.updateConsumerItem<ConsumerRegistrationValues>({
          res,
          successMessage: 'Registration was update successfully',
          id,
        });
      } catch (e) {
        toast.error('Error with updating registration');
      }
    }),
    getConsumerHistory: flow(function* fetchReclamations(
      params: ReclamationsParams = {},
    ) {
      try {
        const { items, meta }: ReclamationsListData = yield getEnv(
          self,
        ).api.fetchReclamations(params);
        applySnapshot(self.history.items, items);
        self.history.meta = meta;
      } catch (e) {
        toast.error('Error with getting the history of the customer');
      }
    }),
  }))
  .views((self) => ({
    getConsumerById(id: string): Consumer | undefined {
      return self.items.find((consumer) => consumer.id === Number(id));
    },
  }));

export type ConsumerStore = Instance<typeof ConsumerStoreModel>;

export type ConsumerStoreSnapshot = SnapshotOut<typeof ConsumerStoreModel>;

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createConsumerStoreModel = () => types.optional(ConsumerStoreModel, {});
