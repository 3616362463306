import { Box } from '@material-ui/core';
import { Icon } from '@iconify/react';
import fileFill from '@iconify/icons-eva/file-fill';
import { Icon as IconComponent } from '../components';
import { FILE_NAME_GER_EXP, IMAGE_REG_EXP, VIDEO_REG_EXP } from './regExp';
import {
  FORMAT_IMG,
  FORMAT_EXCEL,
  FORMAT_ILLUSTRATOR,
  FORMAT_PDF,
  FORMAT_PHOTOSHOP,
  FORMAT_POWERPOINT,
  FORMAT_VIDEO,
  FORMAT_WORD,
  IconNames,
} from '../constants';
import { DividedFiles } from '../types';

export const getFileType = (fileUrl = ''): string => fileUrl.split('.').pop() || '';

export function getFileName(fileUrl: string): string {
  return fileUrl.substring(fileUrl.lastIndexOf('/') + 1).replace(FILE_NAME_GER_EXP, '');
}

export const getFileFullName = (fileUrl: string): string | undefined => fileUrl.split('/').pop();

export const getFileUrl = (fileName: string): string => `${process.env.REACT_APP_API_URL}files/${fileName}`;

const getIcon = (name: IconNames): JSX.Element => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <Box
    component={(): JSX.Element => <IconComponent name={name} />}
    sx={{ width: 28, height: 28 }}
  />
);

export const getFileThumb = (fileUrl: string): JSX.Element => {
  let thumb;
  switch (fileUrl.includes(getFileType(fileUrl))) {
    case FORMAT_IMG.includes(getFileType(fileUrl)):
      // eslint-disable-next-line react/react-in-jsx-scope
      thumb = <Box component="img" src={fileUrl} alt={fileUrl} sx={{ width: 1, height: 1 }} />;
      break;
    case FORMAT_VIDEO.includes(getFileType(fileUrl)):
      thumb = getIcon(IconNames.VIDEO);
      break;
    case FORMAT_WORD.includes(getFileType(fileUrl)):
      thumb = getIcon(IconNames.WORD);
      break;
    case FORMAT_EXCEL.includes(getFileType(fileUrl)):
      thumb = getIcon(IconNames.EXCEL);
      break;
    case FORMAT_POWERPOINT.includes(getFileType(fileUrl)):
      thumb = getIcon(IconNames.POWERPOINT);
      break;
    case FORMAT_PDF.includes(getFileType(fileUrl)):
      thumb = getIcon(IconNames.PDF);
      break;
    case FORMAT_PHOTOSHOP.includes(getFileType(fileUrl)):
      thumb = getIcon(IconNames.PHOTOSHOP);
      break;
    case FORMAT_ILLUSTRATOR.includes(getFileType(fileUrl)):
      thumb = getIcon(IconNames.AI);
      break;
    default:
      // eslint-disable-next-line react/react-in-jsx-scope
      thumb = <Box component={Icon} icon={fileFill} sx={{ width: 28, height: 28 }} />;
  }
  return thumb;
};

export const divideFilesByExt = (files: string[] = []): DividedFiles => {
  const images: string[] = [];
  const videos: string[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const file of files) {
    if (IMAGE_REG_EXP.test(file)) {
      images.push(file);
    } else if (VIDEO_REG_EXP.test(file)) {
      videos.push(file);
    }
  }
  return {
    images, videos,
  };
};
