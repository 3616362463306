import { Option } from '../types';

export const COMMON_PICKER_DATE_VIEW = 'yyyy/MM/dd';

export const COMMON_PICKER_MASK = '____/__/__';

export enum CommonStatus {
  YES = 1,
  NO = 0
}

export const COMMON_OPTIONS: Option[] = [
  { label: 'Yes', value: CommonStatus.YES },
  { label: 'No', value: CommonStatus.NO },
];

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export const SORTING_ORDER_OPTIONS: Option<SortingOrder>[] = [
  { label: 'ASC', value: SortingOrder.ASC },
  { label: 'DESC', value: SortingOrder.DESC },
];

export enum Color {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export const FIRST_PAGE = 1;
