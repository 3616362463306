import React from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion } from '../Accordion';
import { useStore } from '../../../../hooks';
import { AttachmentItem } from './AttachmentItem';
import { getFileUrl } from '../../../../utils';

export const AttachmentList:React.FC = observer(() => {
  const {
    channels: {
      selectedChannel,
    },
  } = useStore();

  return (
    <Accordion
      title={`Attachments (${selectedChannel.files.length})`}
      maxHeight="210px"
    >
      {selectedChannel.files.map(({ file, createdAt }) => (
        <AttachmentItem fileUrl={getFileUrl(file)} createdAt={createdAt} />
      ))}
    </Accordion>
  );
});
