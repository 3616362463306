import React from 'react';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';

import { PATH_DASHBOARD } from '../../../app';
import { CommonMoreMenu } from '../../../components';
import { DEFAULT_ID } from '../../../constants';
import { KanbanReclamation } from '../../../types';
import { useOnItemClick } from '../../../hooks/useOnItemClick';
import { fDate } from '../../../utils';

type CELL = {
  name: string,
  render?: (data: KanbanReclamation, cell: CELL) => string,
}

type KanbanReclamationItemProps = {
  data: KanbanReclamation,
}

const CELLS: CELL[] = [
  {
    name: 'id',
  },
  {
    name: 'firstName',
  },
  {
    name: 'lastName',
  },
  {
    name: 'modelName',
  },
  {
    name: 'colorName',
  },
  {
    name: 'serialNumber',
  },
  {
    name: 'createdAt',
    render: (data, cell): string => fDate(data[cell.name]) || '-',
  },
];

const TableRowStyles = {
  cursor: 'pointer',
};

const UPDATING_LINK = PATH_DASHBOARD.reclamations.root;

export const KanbanReclamationItem:React.FC<KanbanReclamationItemProps> = ({
  data,
}): JSX.Element => {
  const { onItemClick } = useOnItemClick(UPDATING_LINK, data.id);

  return (
    <TableRow
      sx={TableRowStyles}
      hover
    >
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          onClick={onItemClick}
        >
          {cell.render ? cell.render(data, cell) : data[cell.name]}
        </TableCell>
      ))}
      <TableCell align="right">
        <CommonMoreMenu
          itemId={data.id || DEFAULT_ID}
          updatingLink={PATH_DASHBOARD.reclamations.root}
        />
      </TableCell>
    </TableRow>
  );
};
