import React from 'react';
import { Icon } from '@iconify/react';
import externalLinkOutline from '@iconify/icons-eva/external-link-outline';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';

type OpenWindowFieldProps = {
  disabled: boolean;
  value?: string;
  label?: string,
};

export const OpenWindowField:React.FC<OpenWindowFieldProps> = ({
  value,
  disabled = false,
  label = '',
  ...other
}): JSX.Element => {
  const handleClick = (): void => {
    const newWindow = window.open(value, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <TextField
      fullWidth
      value={value}
      label={label}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClick}>
              <Icon icon={externalLinkOutline} width={24} height={24} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};
