import React from 'react';
import { Avatar, useTheme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import { Theme } from '@material-ui/core/styles';
import { AvatarColor } from '../../types';

type CustomAvatarProps = {
  color?: AvatarColor,
  sx?: SxProps<Theme>,
  src?: string,
  children: React.ReactNode,
}

export const CustomAvatar: React.FC<CustomAvatarProps> = ({
  color = 'default',
  sx,
  src,
  children,
}): JSX.Element => {
  const theme = useTheme();

  return color !== 'default' ? (
    <Avatar
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
        ...sx,
      }}
      src={src}
      alt="Avatar"
    />
  ) : (
    <Avatar sx={sx} src={src} alt="Avatar">
      {children}
    </Avatar>
  );
};
