import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Box, Button, Container, Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Link as RouterLink } from 'react-router-dom';
import { Page } from '../../../components';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useStore } from '../../../hooks';
import { SentIcon } from '../../../assets';
import { TokenType } from '../../../types';
import { VerifyingWrapper } from '../VerifyingWrapper';
import { PATH_AUTH } from '../../../app';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export const ResetPassword: React.FC = observer((): JSX.Element => {
  const { auth } = useStore();
  return (
    <VerifyingWrapper tokenType={TokenType.RESET}>
      <RootStyle title="Reset Password">
        <Container>
          <Box sx={{ maxWidth: 480, mx: 'auto' }}>
            {!auth.isResetPassword ? (
              <>
                <Typography variant="h6" sx={{ textAlign: 'center' }} paragraph>
                  Please, enter the new password
                </Typography>
                <ResetPasswordForm />
              </>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <SentIcon />
                <Typography variant="h3" gutterBottom>
                  Password was changed successfully
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  component={RouterLink}
                  to={PATH_AUTH.login}
                  sx={{ mt: 5 }}
                >
                  Back
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </RootStyle>
    </VerifyingWrapper>
  );
});
