import React from 'react';
import { Container } from '@material-ui/core';
import { HeaderBreadcrumbs, Page } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { UpdateForm } from './UpdateForm';
import { Link } from '../../../types';

const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Users', href: PATH_DASHBOARD.user.list },
  { name: 'Edit' },
];

export const UpdateUser: React.FC = (): JSX.Element => (
  <Page title="Edit user">
    <Container maxWidth="lg">
      <HeaderBreadcrumbs
        heading="Edit user"
        links={LINKS}
      />
      <UpdateForm />
    </Container>
  </Page>
);
