import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type TabsReturn = { MuiTab: { styleOverrides: { root: { padding: number; '&:not(:last-of-type)': { marginRight: string }; '&.Mui-selected': { color: string }; '@media (min-width: 600px)': { minWidth: number }; borderTopRightRadius: number | string; fontWeight: '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset' | 'bold' | 'normal' | number | 'bolder' | 'lighter' | undefined; borderTopLeftRadius: number | string }; wrapper: { whiteSpace: string; flexDirection: string }; labelIcon: { minHeight: number; '& > *:first-of-type': { marginRight: string; marginBottom: number }; flexDirection: string }; textColorInherit: { color: string; opacity: number } } }; MuiTabScrollButton: { styleOverrides: { root: { borderRadius: string; width: number } } }; MuiTabPanel: { styleOverrides: { root: { padding: number } } } }
export const Tabs = (theme: Theme): TabsReturn => ({
  MuiTab: {
    styleOverrides: {
      root: {
        padding: 0,
        fontWeight: theme.typography.fontWeightMedium,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        '&.Mui-selected': {
          color: theme.palette.text.primary,
        },
        '&:not(:last-of-type)': {
          marginRight: theme.spacing(5),
        },
        '@media (min-width: 600px)': {
          minWidth: 48,
        },
      },
      labelIcon: {
        minHeight: 48,
        flexDirection: 'row',
        '& > *:first-of-type': {
          marginBottom: 0,
          marginRight: theme.spacing(1),
        },
      },
      wrapper: {
        flexDirection: 'row',
        whiteSpace: 'nowrap',
      },
      textColorInherit: {
        opacity: 1,
        color: theme.palette.text.secondary,
      },
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTabScrollButton: {
    styleOverrides: {
      root: {
        width: 48,
        borderRadius: '50%',
      },
    },
  },
});
