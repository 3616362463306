import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------
type BadgeReturn = {
  MuiBadge: { styleOverrides: { dot: { width: number; height: number; borderRadius: string; }; }; };
}
export const Badge = (theme: Theme): BadgeReturn => ({
  MuiBadge: {
    styleOverrides: {
      dot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
      },
    },
  },
});
