import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { Theme } from '@material-ui/core/styles';

type SelectReturn = {
  MuiSelect: {
    defaultProps: {
      IconComponent: unknown & { muiName: string }
    };
    styleOverrides: { root: unknown }
  }
}
export const Select = (theme: Theme): SelectReturn => ({
  MuiSelect: {
    defaultProps: {
      IconComponent: ExpandMoreRoundedIcon,
    },

    styleOverrides: {
      root: {},
    },
  },
});
