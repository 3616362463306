import { Link } from '../../../types';
import { PATH_DASHBOARD } from '../../../app';

const DEFAULT_AUTOCOMPLETE_ID = -1;
export const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Customers list', href: PATH_DASHBOARD.consumers.list },
  { name: 'New' },
];

export const DEFAULT_CONSUMER = {
  firstName: '',
  lastName: '',
  phone: '+',
  email: '',
  city: '',
  country: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  model: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  color: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  price: '',
  serialNumber: '',
  sellerCountry: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  sellerCity: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  shop: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  datePurchase: '',
};
