import React, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import {
  Box, Container, Tab, Tabs,
} from '@material-ui/core';
import { capitalCase } from 'change-case';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { HeaderBreadcrumbs, Page } from '../../components';
import { CONSUMER_TABS, ConsumerValues, LINKS } from './constants';
import { useRequest, useStore } from '../../hooks';
import { QueryParams } from '../../types';
import { StateWrapper } from '../../components/common/StateWrapper';

export const Consumer: React.FC = observer((): JSX.Element => {
  const { id } = useParams<QueryParams>();
  const {
    consumers: {
      getConsumerById,
      fetchConsumer,
    },
  } = useStore();

  const consumer = getConsumerById(id);

  const [currentTab, setCurrentTab] = useState<ConsumerValues>(CONSUMER_TABS[0].value);

  const handleChangeTab = useCallback((e: SyntheticEvent, value: ConsumerValues): void => {
    setCurrentTab(value);
  }, []);

  const { isFetching, isError } = useRequest({
    action: fetchConsumer,
    params: id,
    condition: !consumer,
  });

  useEffect(() => {
    fetchConsumer(id);
  }, [currentTab]);

  return (
    <StateWrapper isError={isError} isReady={!isFetching}>
      <Page title="Customer">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading="Customer"
            links={LINKS}
          />

          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleChangeTab}
          >
            {CONSUMER_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
          <Box sx={{ mb: 5 }} />
          {
          CONSUMER_TABS.map((
            tab,
          ) => tab.value === currentTab && <Box key={tab.value}>{tab.component}</Box>)
        }
        </Container>
      </Page>
    </StateWrapper>
  );
});
