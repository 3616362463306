import React, { useEffect } from 'react';
import {
  Box, Container, Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Page } from '../../components';
import { ComingSoonIllustration } from '../../assets';
import { TRANSLATIONS } from '../../constants';

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

type QueryParams = {
  lang: string,
}

export const Feedback: React.FC = (): JSX.Element => {
  const { lang } = useParams<QueryParams>();

  useEffect(() => {
    // hide settings button in the feedback page
    const btn = document.getElementById('settings-button');
    if (btn) {
      btn.style.display = 'none';
    }
  }, []);

  return (
    <RootStyle title="Feedback">
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            {TRANSLATIONS[lang].title}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {TRANSLATIONS[lang].description}
          </Typography>
          <ComingSoonIllustration />
        </Box>
      </Container>
    </RootStyle>
  );
};
