import React, { useEffect, useState } from 'react';
import { RootStore } from '../../models';
import { StoreContext } from './StoreContext';
import { setupRootStore } from '../../models/setup';

type StoreProviderProps = {
  children: React.ReactNode
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const [store, setStore] = useState<RootStore | undefined>();

  const setupStore = async (): Promise<void> => {
    const storeModel = await setupRootStore();
    setStore(storeModel);
  };

  useEffect(() => {
    setupStore();
  }, []);

  if (!store) return null;
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
