import { Instance, types } from 'mobx-state-tree';

export const KanbanCountryModel = types.model('KanbanCountryModel', {
  id: types.number,
  name: types.string,
  reclamation_count: types.string,
});

export const KanbanReclamationModel = types.model('KanbanReclamationModel', {
  id: types.number,
  firstName: types.string,
  lastName: types.string,
  modelName: types.string,
  colorName: types.string,
  serialNumber: types.string,
  createdAt: types.string,
});

export const KanbanStatusModel = types.model('KanbanModel', {
  name: types.string,
  countries: types.optional(types.array(KanbanCountryModel), []),
});

export type KanbanStatus = Instance<typeof KanbanStatusModel>
export type KanbanCountry = Instance<typeof KanbanCountryModel>
export type KanbanReclamation = Instance<typeof KanbanReclamationModel>
