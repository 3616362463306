import React from 'react';
import {
  TextField,
  Stack,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForgotPasswordValues } from '../../../types';
import { useStore } from '../../../hooks';
import rules from './rules';

export const ForgotPasswordForm: React.FC = (): JSX.Element => {
  const { auth } = useStore();
  const {
    control, handleSubmit,
  } = useForm<ForgotPasswordValues>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(rules),
  });

  return (
    <form onSubmit={handleSubmit(auth.sendResetRequest)}>
      <Stack spacing={3}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({
            field: { onBlur, onChange, value }, fieldState: { error },
          }): JSX.Element => (
            <TextField
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              fullWidth
              type="text"
              label="Email address"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Send request
        </LoadingButton>
      </Stack>
    </form>
  );
};
