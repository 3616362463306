import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Autocomplete,
  Card,
  Grid, IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import clock from '@iconify/icons-eva/clock-outline';
import { alpha, styled } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import DateFnsUtils from '@material-ui/lab/AdapterDateFns';
import { useStore } from '../../../hooks';
import {
  useDatePeriodFilter,
  useMultipleSelectFilter,
  useWarrantyFilter,
} from '../hooks';
import { Option } from '../../../types';
import {
  COMMON_OPTIONS,
  COMMON_PICKER_DATE_VIEW,
  COMMON_PICKER_MASK,
} from '../../../constants';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(3, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0,
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

export const AllClaimsCount: React.FC = observer(() => {
  const {
    reports: {
      reclamationsCount: { all },
      fetchReclamationsCount,
    },
    serviceModels: { modelsAutoComplete: models },
    deviationCodes: { deviationCodesAutoComplete: deviationCodes },
  } = useStore();

  const {
    dateTo, dateFrom, handleChangeDateTo, handleChangeDateFrom,
  } = useDatePeriodFilter();

  const { warranty, handleChangeWarranty } = useWarrantyFilter();

  const { ids: modelIds, handleChangeValues: handleChangeModelIds } = useMultipleSelectFilter();

  const {
    ids: deviationCodeIds,
    handleChangeValues: handleChangeDeviationCodeIds,
  } = useMultipleSelectFilter();

  useEffect(() => {
    fetchReclamationsCount({
      dateTo,
      dateFrom,
      modelIds,
      deviationCodeIds,
      warranty: warranty?.value,
    });
  }, [dateTo, dateFrom, modelIds, deviationCodeIds, warranty]);
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Number of claims
      </Typography>
      <Grid display="flex" container spacing={3} direction="row">
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={12} mb={2}>
            <Autocomplete
              multiple
              autoComplete={false}
              fullWidth
              options={deviationCodes}
              onChange={handleChangeDeviationCodeIds}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(params): JSX.Element => (
                <TextField {...params} label="Deviation codes" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} mb={2}>
            <Autocomplete
              multiple
              autoComplete={false}
              fullWidth
              options={models}
              onChange={handleChangeModelIds}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(params): JSX.Element => (
                <TextField {...params} label="Models" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} mb={2}>
            <Autocomplete
              autoComplete={false}
              fullWidth
              value={warranty}
              options={COMMON_OPTIONS}
              onChange={handleChangeWarranty}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(params): JSX.Element => (
                <TextField {...params} label="Warranty" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} mb={2}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DatePicker
                inputFormat={COMMON_PICKER_DATE_VIEW}
                mask={COMMON_PICKER_MASK}
                views={['day']}
                label="Date from"
                value={dateFrom}
                onChange={handleChangeDateFrom}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      onClick={(): void => handleChangeDateFrom(null)}
                      disabled={!dateFrom}
                      style={{ order: 1 }}
                    >
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                  style: { order: 2, marginLeft: 0 },
                }}
                renderInput={(params: TextFieldProps): JSX.Element => (
                  <TextField
                    {...params}
                    fullWidth
                    disabled
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={12} mb={2}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DatePicker
                inputFormat={COMMON_PICKER_DATE_VIEW}
                mask={COMMON_PICKER_MASK}
                views={['day']}
                label="Date to"
                value={dateTo}
                onChange={handleChangeDateTo}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      onClick={(): void => {
                        handleChangeDateTo(null);
                      }}
                      disabled={!dateTo}
                      style={{ order: 1 }}
                    >
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                  style: { order: 2, marginLeft: 0 },
                }}
                renderInput={(params: TextFieldProps): JSX.Element => (
                  <TextField {...params} fullWidth disabled />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <RootStyle>
            <IconWrapperStyle>
              <Icon icon={clock} width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{all}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              All
            </Typography>
          </RootStyle>
        </Grid>
      </Grid>
    </>
  );
});
