import { Socket } from 'socket.io-client';
import { Api } from '../services/api';
import { socket } from '../utils';

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    // create each service
    this.api = new Api();
    this.socket = socket;
  }

  async setup(): Promise<void> {
    // allow each service to setup
    // if (__DEV__) {}
    await this.api.setup();
  }

  api: Api

  socket: Socket
}
