import React, { useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { Page, PermissionWrapper } from '../../components';
import { Claims } from './Claims';
import { GroupedClaims } from './GroupedClaims';
import { Module, PermissionAction } from '../../constants';

export const Reports:React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="General: Reports | Minimal-UI">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PermissionWrapper
            action={PermissionAction.SHOW}
            module={Module.REPORTS}
          >
            <Grid item xs={12} md={12} lg={12}>
              <Claims />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <GroupedClaims />
            </Grid>
          </PermissionWrapper>
        </Grid>
      </Container>
    </Page>
  );
};
