import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Typography, useMediaQuery,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { useTheme } from '@material-ui/core/styles';

type AccordionWrapperProps = {
    children: React.ReactNode,
}

export const AccordionWrapper: React.FC<AccordionWrapperProps> = ({
  children,
}): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return isMobile ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}
      >
        <Typography variant="subtitle1" sx={{ width: '100%', flexShrink: 0 }}>
          Search and filter
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  ) : <>{children}</>;
};
