import React from 'react';
import {
  ListItemText,
  Stack,
  Typography, useMediaQuery,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@material-ui/core/styles';
import { fDateTime, fToNow } from '../../../utils';
import { Notification } from '../../../types/models/NotificationModel';
import { RedirectRoutes } from '../../../constants';
import { useOnItemClick } from '../../../hooks/useOnItemClick';

type NotificationItemProps = {
    data: Notification,
}

const MainBoxStyles = {
  cursor: 'pointer',
  padding: '10px',
  '&:hover': { backgroundColor: 'rgba(145, 158, 171, 0.08)' },
  margin: '0 !important',
};

const OverdueMainBoxStyles = {
  cursor: 'pointer',
  padding: '10px',
  '&:hover': { backgroundColor: 'rgba(211, 47, 47, .1)', borderColor: 'rgba(211, 47, 47, .3)' },
  margin: '0 !important',
  backgroundColor: 'rgba(211, 47, 47, .2)',
  border: '1px solid rgba(211, 47, 47, .2)',
};

export const NotificationItem: React.FunctionComponent<NotificationItemProps> = observer(({
  data,
}): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const redirectRoute = RedirectRoutes[data?.notificationType];
  const { onItemClick } = useOnItemClick(redirectRoute, data.itemId);

  return (
    <Stack
      onClick={onItemClick}
      sx={data.isOverdue ? OverdueMainBoxStyles : MainBoxStyles}
      direction={isMobile ? 'column' : 'row'}
      alignItems="none"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" spacing={4}>
        <ListItemText
          primary={(
            <>
              <Typography variant="subtitle2">
                {data.title}
              </Typography>
              <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                {data.body}
              </Typography>
            </>
                    )}
        />
      </Stack>
      <Typography
        variant="caption"
        title={fDateTime(data.createdAt)}
        noWrap
        sx={{
          padding: '0 16px',
          flexShrink: 0,
          marginTop: isMobile ? 'none' : '25px',
          color: 'text.secondary',
        }}
      >
        {fToNow(data.createdAt)}
      </Typography>
    </Stack>
  );
});
