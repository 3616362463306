import { Color, Period as PeriodEnum } from '../../../constants';

type Period = {
    readonly title: string,
    readonly key: PeriodEnum,
    readonly color: Color
}

export const PERIODS: readonly Period[] = [
  {
    title: 'Per month',
    key: PeriodEnum.MONTH,
    color: Color.PRIMARY,
  },
  {
    title: 'Per 6 months',
    key: PeriodEnum.SIX_MONTHS,
    color: Color.PRIMARY,
  },
  {
    title: 'Per year',
    key: PeriodEnum.YEAR,
    color: Color.PRIMARY,
  },
] as const;
