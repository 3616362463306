import { HeadCell } from '../../../types/grid';
import { PATH_DASHBOARD } from '../../../app';
import { Link } from '../../../types';

export const TABLE_HEAD_LIST: readonly HeadCell[] = [
  { id: 'id', label: '#' },
  { id: 'created_at', label: 'Date of reclamation' },
  { id: 'name', label: 'Name' },
  { id: 'model', label: 'Model' },
  { id: 'part_code_descriptions', label: 'Part code descriptions' },
  { id: 'shop_name', label: 'Shop name' },
  { id: 'country', label: 'Country' },
  { id: 'status', label: 'Status' },
  { id: 'clients_comment', label: 'Client`s comment' },
  { id: 'files', label: 'Files' },
  { id: 'commentCount', label: 'Comments' },
  { id: 'more_menu', label: '' },
] as const;

export const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'List' },
];
