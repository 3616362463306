import React, { useCallback, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexChart from 'apexcharts';
import { Box, Card, CardHeader } from '@material-ui/core';
import { merge } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { BaseOptionChart } from '../../charts';
import { fNumber } from '../../../utils';

type BarChartProps = {
  id: string,
  title: string,
  subtitle: string,
  data: number[],
  categories: string[],
  metaData: boolean[],
}

const MIN_HEIGHT_CHART = 150;
const HEIGHT_ROW = 70;

// eslint-disable-next-line max-len
const calculateChartHeight = (dataCount: number): number => (dataCount * HEIGHT_ROW > MIN_HEIGHT_CHART ? dataCount * HEIGHT_ROW : MIN_HEIGHT_CHART);

export const BarChart:React.FC<BarChartProps> = ({
  id,
  title,
  subtitle,
  data,
  categories,
  metaData,
}) :JSX.Element => {
  const theme = useTheme();

  const setColors = useCallback((index, meta) => {
    if (meta[index]) {
      return theme.palette.primary.main;
    }
    return theme.palette.primary.light;
  }, [theme]);

  // need to use inside of component, because there are hooks in the BaseOptionChart
  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      id,
    },
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    dataLabels: {
      textAnchor: 'end',
      enabled: true,
      style: {
        fontSize: '10px',
        colors: ['#fff'],
      },
    },
    colors: [
      ({
        dataPointIndex,
        w: { config: { meta } },
      }): string => setColors(dataPointIndex, meta),
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories,
    },
    meta: metaData,
  });

  // update options, if some property of the theme was changed
  useEffect(() => {
    ApexChart.exec(id, 'updateOptions', {
      colors: [({
        dataPointIndex,
        w: { config: { meta } },
      }): string => setColors(dataPointIndex, meta)],
    });
  }, [theme]);

  return (
    <Card>
      <CardHeader title={title} subheader={subtitle} />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={[{ data }]}
          options={chartOptions}
          height={calculateChartHeight(data.length)}
        />
      </Box>
    </Card>
  );
};
