import { Theme } from '@material-ui/core/styles';

type LinkReturn = {
  MuiLink: { defaultProps: { underline: string }; styleOverrides: { root: Record<string, never> } }
}
export const Link = (theme: Theme): LinkReturn => ({
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },

    styleOverrides: {
      root: {},
    },
  },
});
