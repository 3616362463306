import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Table,
  Container,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';
import { PATH_DASHBOARD } from '../../app';
import { useStore } from '../../hooks';
import {
  Page,
  HeaderBreadcrumbs,
  SearchNotFound,
  CommonListHead,
  LoadingScreen, Scrollbar,
} from '../../components';
import { ShopItem } from './ShopItem';
import { ShopsListToolbar } from './ShopsListToolbar';
import { useSorting } from '../../hooks/useSorting';
import { FIRST_PAGE } from '../../constants';

const SEARCH_TIMEOUT = 400;
const SHOPS_LIST_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'address', label: 'Address' },
  { id: 'emails', label: 'Email' },
  { id: 'city', label: 'City' },
  { id: 'country', label: 'Country' },
  { id: 'moreMenu', label: '' },
];
const BREADCRUMB_LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Shops list' },
];

export const ShopsList:React.FC = observer((): JSX.Element => {
  const { shopsModel: { fetchShops, items, meta } } = useStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [searchNameValue, setSearchNameValue] = useState<string>('');
  const [searchEmailValue, setSearchEmailValue] = useState<string>('');
  const [debouncedSearchNameValue] = useDebounce(searchNameValue, SEARCH_TIMEOUT);
  const [debouncedSearchEmailValue] = useDebounce(searchEmailValue, SEARCH_TIMEOUT);

  const [page, setPage] = useState<number>(FIRST_PAGE);

  const {
    sort,
    order,
    orderBy,
    onClickSorting,
  } = useSorting();

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  }, []);

  const handleFilterByName = (newFilterName: string):void => {
    setSearchNameValue(newFilterName);
  };
  const handleFilterByEmail = (newFilterEmail: string):void => {
    setSearchEmailValue(newFilterEmail);
  };

  useEffect(() => {
    fetchShops({});
  }, []);
  useEffect(() => {
    fetchShops({
      name: debouncedSearchNameValue,
      email: debouncedSearchEmailValue,
      page,
      sort,
    });
  }, [debouncedSearchNameValue, debouncedSearchEmailValue, page, sort]);

  const isReady = !!items && !!meta;

  return isReady ? (
    <Page title="Shops: List | Minimal-UI">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Shops List"
          links={BREADCRUMB_LINKS}
        />

        <Card>
          <ShopsListToolbar
            searchNameValue={searchNameValue}
            searchEmailValue={searchEmailValue}
            onSearchName={handleFilterByName}
            onSearchEmail={handleFilterByEmail}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <CommonListHead
                  headListData={SHOPS_LIST_HEAD}
                  order={order}
                  orderBy={orderBy}
                  onClick={onClickSorting}
                />
                <TableBody>
                  {items?.length ? (
                    items?.map((item) => (
                      <ShopItem
                        key={`shops-${item.id}`}
                        data={item}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {!!items?.length && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            onPageChange={handlePageChange}
          />
          )}
        </Card>
      </Container>
    </Page>
  ) : (<LoadingScreen />);
});
