import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from '@material-ui/core';
import { FileAttachment, LightboxModal } from '../../../components';
import { divideFilesByExt } from '../../../utils';

type FilesViewProps = {
  files: string[],
}

export const FilesView: React.FC<FilesViewProps> = ({ files }) => {
  const [isOpenedLightBoxModal, setIsOpenedLightBoxModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const handleOpenLightBoxModal = useCallback((index: number) => (): void => {
    setIsOpenedLightBoxModal(true);
    setSelectedImage(index);
  }, []);

  const handleCloseLightboxModal = useCallback((): void => {
    setIsOpenedLightBoxModal(false);
  }, []);

  const {
    images,
    videos,
  } = divideFilesByExt(files);

  return (
    <Stack direction="column" spacing={1}>
      {images.map((imgSrc: string, index: number): JSX.Element => (
        <img
          role="presentation"
          key={uuidv4()}
          src={imgSrc}
          alt={`breakage - ${index + 1}`}
          onClick={handleOpenLightBoxModal(index)}
          style={{
            cursor: 'pointer',
            width: 50,
            height: 50,
          }}
        />
      ))}
      {videos.map((videoSrc) => (
        <FileAttachment
          key={`${uuidv4()}-${videoSrc}`}
          fileUrl={videoSrc}
        />
      ))}
      <LightboxModal
        images={images}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={isOpenedLightBoxModal}
        onClose={handleCloseLightboxModal}
      />
    </Stack>
  );
};
