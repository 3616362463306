import {
  applySnapshot,
  cast, flow, getEnv, getParent, Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  ChannelFile,
  ChannelItem,
  ChannelItemModel,
  ListMetaDataModel,
  Message,
  SelectedChannelModel,
  UnconnectedReclamationValues,
} from '../types';
import {
  DEFAULT_CHANNEL, DEFAULT_ID, DEFAULT_META, FIRST_PAGE, SocketEventTypesEnum,
} from '../constants';
import {
  ChannelListData, ChannelManagerData, ChannelManagerRes, ChannelsListParams, UpdateClientValues,
} from '../types/channel';
import { RootStore } from './RootStore';
import { updateMetaAfterAddingNewItem } from '../utils';

const ChannelsArray = types.optional(types.array(ChannelItemModel), []);

export const ChannelStoreModel = types.model('ChannelStore')
  .props({
    items: ChannelsArray,
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
    selectedChannel: types.optional(SelectedChannelModel, DEFAULT_CHANNEL),
  })
  .actions((self) => ({
    fetchChannels: flow(function* fetchChannels(params: ChannelsListParams) {
      try {
        const { items, meta }: ChannelListData = yield getEnv(self).api.fetchChannels(params);
        if (params.search || params.page === FIRST_PAGE) {
          self.items.replace(items);
        } else {
          self.items = cast([...self.items, ...items]);
        }
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting channels');
      }
    }),
    fetchChannelById: flow(function* fetchChannelById(id: string) {
      try {
        getParent<RootStore>(self).messages.reset();
        self.selectedChannel = yield getEnv(self).api.fetchChannelById(id);
      } catch (e) {
        toast.error('Error with getting channel by id');
      }
    }),
    connectChannelToManager: flow(function* connectChannelToManager(data: ChannelManagerData) {
      try {
        const {
          channelId,
        }: ChannelManagerRes = yield getEnv(self).api.connectChannelToManager(data);
        const itemIndex = self.items.findIndex((item) => item.id === channelId);
        self.items[itemIndex].unreadMessagesCount = 0;
      } catch (e) {
        toast.error('Error with connect channel to the user');
      }
    }),
    saveChannelFromSocket(channel: ChannelItem): void {
      self.items = cast([channel, ...self.items]);
      self.meta = updateMetaAfterAddingNewItem(self.meta);
    },
    moveChannelToTheTop(data: Message): void {
      const profileId = getParent<RootStore>(self).auth.profile.id;
      const neededItem = self.items.find((item) => item.id === data.channelId);
      if (neededItem) {
        let unreadMessagesCount: number | null = null;
        if (neededItem.unreadMessagesCount !== null && profileId !== data.author?.id) {
          unreadMessagesCount = neededItem.unreadMessagesCount + 1;
        }
        const newItem = {
          ...neededItem,
          content: data.content,
          messageType: data.type,
          createdAt: data.createdAt,
          unreadMessagesCount,
        };
        const filteredItems = self.items.filter((item) => item.id !== newItem.id);
        self.items = cast([newItem, ...filteredItems]);
      }
    },
    increaseUnreadMessagesCount(): void {
      if (self.selectedChannel.unreadMessagesCount !== null) {
        self.selectedChannel.unreadMessagesCount += 1;
      }
    },
    addItemsToChannelAttachments(data: ChannelFile[]): void {
      self.selectedChannel.files = cast([...data, ...self.selectedChannel.files]);
    },
    readMessagesEvent(data: ChannelManagerData): void {
      getEnv(self).socket.emit(SocketEventTypesEnum.READ_MESSAGES, data);
    },
    resetUnreadMessages(data: ChannelManagerRes): void {
      const itemIndex = self.items.findIndex((item) => item.id === data.channelId);
      if (itemIndex > DEFAULT_ID) {
        self.items[itemIndex].unreadMessagesCount = 0;
      }
    },
    resetUnreadMessagesCountInSelectedChannel(): void {
      if (self.selectedChannel.unreadMessagesCount !== null) {
        self.selectedChannel.unreadMessagesCount = 0;
      }
    },
    resetSelectedChannel(): void {
      applySnapshot(self.selectedChannel, DEFAULT_CHANNEL);
    },
    setChannelToReclamation: flow(function* setChannelToReclamation(
      id: string,
      data: UnconnectedReclamationValues,
    ) {
      try {
        const { reclamationId } = yield getEnv(self).api.setChannelToReclamation(id, data);
        self.selectedChannel.reclamationId = reclamationId;
        toast.success('Channel was set successfully');
      } catch (e) {
        toast.error('Error with set channel to reclamation');
      }
    }),
    updateClientInfo: flow(function* updateClientInfo(id: number, data: UpdateClientValues) {
      try {
        const {
          id: clientId,
          firstName,
          lastName,
        } = yield getEnv(self).api.updateClientInfo(id, data);
        self.selectedChannel.clientId = clientId;
        self.selectedChannel.firstName = firstName;
        self.selectedChannel.lastName = lastName;
        const itemIndex = self.items.findIndex((item) => item.clientId === clientId);
        if (itemIndex > DEFAULT_ID) {
          self.items[itemIndex].firstName = firstName;
          self.items[itemIndex].lastName = lastName;
        }
        toast.success('Client was updated successfully');
      } catch (e) {
        toast.error('Error with updating client');
      }
    }),
  }))
  .views((self) => ({
    getChannelById(id: string): ChannelItem | undefined {
      if (id) {
        return self.items.find((channel) => channel.id === Number(id));
      }
      return undefined;
    },
  }));

export type ChannelStore = Instance<typeof ChannelStoreModel>

export type ChannelStoreSnapShot = SnapshotOut<typeof ChannelStoreModel>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createChannelStoreModel = () => types.optional(ChannelStoreModel, {});
