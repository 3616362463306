import { ReactComponent as AiFile } from '../assets/images/file/file_type_ai.svg';
import { ReactComponent as ExcelFile } from '../assets/images/file/file_type_excel.svg';
import { ReactComponent as PdfFile } from '../assets/images/file/file_type_pdf.svg';
import { ReactComponent as PhotoshopFile } from '../assets/images/file/file_type_photoshop.svg';
import { ReactComponent as PowerPointFile } from '../assets/images/file/file_type_powerpoint.svg';
import { ReactComponent as VideoFile } from '../assets/images/file/file_type_video.svg';
import { ReactComponent as WordFile } from '../assets/images/file/file_type_word.svg';

export enum IconNames {
  AI = 'ai',
  EXCEL = 'excel',
  PDF = 'pdf',
  PHOTOSHOP = 'photoshop',
  POWERPOINT = 'powerpoint',
  VIDEO = 'video',
  WORD = 'word',
}

export const icons = {
  [IconNames.AI]: AiFile,
  [IconNames.EXCEL]: ExcelFile,
  [IconNames.PDF]: PdfFile,
  [IconNames.PHOTOSHOP]: PhotoshopFile,
  [IconNames.POWERPOINT]: PowerPointFile,
  [IconNames.VIDEO]: VideoFile,
  [IconNames.WORD]: WordFile,
};
