import React, {
  SyntheticEvent, useCallback, useEffect, useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Checkbox,
  FormLabel,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRequest, useStore } from '../../../hooks';
import rules from './rules';
import {
  Option,
  QueryParams,
  UserResponsibleCountry,
  UserValues,
} from '../../../types';
import {
  COMMON_SCHEMA,
  DEFAULT_AUTOCOMPLETE_ID,
  DEFAULT_AUTOCOMPLETE_PARAMS,
  USER_STATUSES,
} from '../../../constants';
import { getOption, mapEntityToOption } from '../../../utils';
import { PATH_DASHBOARD } from '../../../app';
import { StateWrapper } from '../../../components/common/StateWrapper';

export const UpdateForm: React.FC = observer((): JSX.Element => {
  const { id } = useParams<QueryParams>();
  const { push } = useHistory();
  const {
    users: { getUserById, fetchUser, updateUser },
    auth: { profile: { id: profileId }, getProfile },
    countriesModel: {
      countriesAutoComplete,
      fetchCountries,
      fetchOfficeCountries,
      officeCountriesAutoComplete,
    },
  } = useStore();

  const user = getUserById(id);

  const {
    control, handleSubmit, setValue, reset,
  } = useForm<UserValues>({
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      status: DEFAULT_AUTOCOMPLETE_ID,
      country: {
        id: DEFAULT_AUTOCOMPLETE_ID,
      },
      countries: [],
      customerCare: false,
    },
    resolver: yupResolver(rules),
  });

  const handleChangeSelectValue = useCallback(
    (name: FieldPath<UserValues>) => (_, data: Option | null): void => {
      const value = typeof data?.value !== 'undefined' ? data?.value : DEFAULT_AUTOCOMPLETE_ID;
      setValue(name, value);
    },
    [],
  );

  const handleCountriesChange = useCallback(
    (event: SyntheticEvent, values): void => {
      const customCountries = values.map((item) => ({ id: item.value }));
      setValue('countries', customCountries);
    },
    [],
  );

  const handleUpdate = async (values: UserValues): Promise<void> => {
    await updateUser({
      id,
      data: values,
    });

    if (id === String(profileId)) {
      getProfile();
    }

    push(PATH_DASHBOARD.user.list);
  };

  const defaultCountries = useMemo(
    function () {
      return user?.countries.map(function (country) {
        return mapEntityToOption<UserResponsibleCountry>(
          country,
          COMMON_SCHEMA,
        );
      });
    },
    [user?.countries],
  );

  const { isFetching, isError } = useRequest({
    action: fetchUser,
    params: id,
    condition: !user,
  });

  const { isFetching: isFetchingCountries } = useRequest({
    action: fetchCountries,
    params: DEFAULT_AUTOCOMPLETE_PARAMS,
  });

  const { isFetching: isFetchingOfficeCountries } = useRequest({
    action: fetchOfficeCountries,
    condition: !officeCountriesAutoComplete.length,
  });

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        surname: user.surname,
        email: user.email,
        status: user.status,
        country: {
          id: user.country?.id,
        },
        countries: user.countries,
        customerCare: user.customerCare,
      });
    }
  }, [user]);

  const isReady = !!user && !isFetchingCountries && !isFetchingOfficeCountries && !isFetching;

  return (
    <StateWrapper isError={isError} isReady={isReady}>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({
                      field: { onBlur, onChange, value },
                      fieldState: { error },
                    }): JSX.Element => (
                      <TextField
                        fullWidth
                        label="Name"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="surname"
                    control={control}
                    render={({
                      field: { onBlur, onChange, value },
                      fieldState: { error },
                    }): JSX.Element => (
                      <TextField
                        fullWidth
                        label="Surname"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Controller
                    name="email"
                    control={control}
                    render={({
                      field: { onBlur, onChange, value },
                      fieldState: { error },
                    }): JSX.Element => (
                      <TextField
                        fullWidth
                        label="Email"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { value } }): JSX.Element => (
                      <Autocomplete
                        disableClearable
                        autoComplete={false}
                        fullWidth
                        value={getOption(USER_STATUSES, value)}
                        options={USER_STATUSES}
                        onChange={handleChangeSelectValue('status')}
                        getOptionLabel={(option: Option): string => option.label}
                        renderInput={(params): JSX.Element => (
                          <TextField {...params} label="Status" />
                        )}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Controller
                    name="country.id"
                    control={control}
                    render={({ field: { value } }): JSX.Element => (
                      <Autocomplete
                        disableClearable
                        autoComplete={false}
                        fullWidth
                        value={getOption(officeCountriesAutoComplete, value)}
                        options={officeCountriesAutoComplete}
                        onChange={handleChangeSelectValue('country.id')}
                        getOptionLabel={(option: Option): string => option.label}
                        renderInput={(params): JSX.Element => (
                          <TextField {...params} label="Country" />
                        )}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Controller
                    name="countries"
                    control={control}
                    render={(): JSX.Element => (
                      <Autocomplete
                        multiple
                        freeSolo
                        autoComplete={false}
                        fullWidth
                        defaultValue={defaultCountries}
                        options={countriesAutoComplete}
                        onChange={handleCountriesChange}
                        getOptionLabel={(option: Option): string => option.label}
                        renderInput={(params): JSX.Element => (
                          <TextField {...params} label="Countries" />
                        )}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Controller
                    name="customerCare"
                    control={control}
                    render={({ field: { value, onChange } }): JSX.Element => (
                      <FormLabel>
                        <Checkbox checked={value} onChange={onChange} />
                        Can use customer care
                      </FormLabel>
                    )}
                  />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={false}
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </form>
    </StateWrapper>
  );
});
