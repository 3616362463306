import * as yup from 'yup';
import { emailSchema } from '../../../utils';
import { UserRole } from '../../../constants';

const rules = yup.object({
  email: emailSchema(),
  role: yup.mixed().oneOf(Object.values(UserRole)).required('Role is required'),

});

export default rules;
