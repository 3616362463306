import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { merge } from 'lodash';
import { observer } from 'mobx-react-lite';
import {
  Typography, Grid, Card, Box,
} from '@material-ui/core';
import { BaseOptionChart } from '../../../components';
import { useStore } from '../../../hooks';

export const CustomerCareCSAT: React.FC = observer((): JSX.Element => {
  // Store
  const {
    reports: {
      reclamationsCSAT: { csat },
      fetchReclamationsCSAT,
    },
  } = useStore();

  const CHART_DATA = [
    {
      name: 'Count',
      data: csat.map(({ rating }) => Number(rating)),
    },
  ];

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    stroke: { show: false },
    xaxis: {
      categories: csat.map(({ manager }) => manager),
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val} stars`,
      },
    },
  });

  useEffect(() => {
    fetchReclamationsCSAT();
  }, []);

  return (
    <Card>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Typography variant="h5" gutterBottom>
          CSAT
        </Typography>

        <Grid item xs={12} md={9} mb={9}>
          <ReactApexChart
            type="bar"
            series={CHART_DATA}
            options={chartOptions}
            fDate
            height={320}
          />
        </Grid>
      </Box>
    </Card>
  );
});
