import { Instance, types } from 'mobx-state-tree';
import { StrollerPartModel } from './StrollerPartModel';

export const DeviationCodeModel = types.model({
  id: types.number,
  name: types.string,
  code: types.maybeNull(types.string),
  class: types.maybeNull(types.string),
  status: types.number,
  strollerPart: types.maybeNull(StrollerPartModel),
});

export type DeviationCode = Instance<typeof DeviationCodeModel>
