import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Avatar, Box, Divider, IconButton, Link, Typography,
} from '@material-ui/core';
import pinFill from '@iconify/icons-eva/pin-fill';
import phoneFill from '@iconify/icons-eva/phone-fill';
import emailFill from '@iconify/icons-eva/email-fill';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import editFill from '@iconify/icons-eva/edit-fill';
import { makeName } from '../../../utils';
import { useStore } from '../../../hooks';
import { Accordion } from './Accordion';
import { PATH_DASHBOARD } from '../../../app';
import { EditInfo } from './EditInfo';

const RowStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(1.5, 0),
}));

const RowIconStyle = styled(Icon)(({ theme }) => ({
  width: 16,
  height: 16,
  marginTop: 4,
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const RowTextStyle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: 160,
  wordWrap: 'break-word',
  ...theme.typography.body2,
}));

export const UserInfo: React.FC = observer(() => {
  const {
    channels: {
      selectedChannel,
    },
  } = useStore();

  const [isOpenedEditModal, setIsOpenedEditModal] = useState<boolean>(false);

  const toggleEditModal = (value: boolean) => (): void => {
    setIsOpenedEditModal(value);
  };

  return (
    <>
      <Box
        sx={{
          pt: 4,
          pb: 3,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Avatar
          alt="User avatar"
          src={selectedChannel?.avatar || undefined}
          sx={{ width: 96, height: 96 }}
        />
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="subtitle1">{makeName(selectedChannel.firstName, selectedChannel.lastName)}</Typography>
        </Box>
      </Box>
      <Divider />
      <Accordion title="Information">
        <Box sx={{ px: 2.5, pb: 1 }}>
          <RowStyle>
            <RowIconStyle icon={pinFill} />
            <RowTextStyle>{selectedChannel.address}</RowTextStyle>
            <IconButton
              onClick={toggleEditModal(true)}
              sx={{ padding: 0 }}
            >
              <Icon icon={editFill} width={20} height={24} />
            </IconButton>
          </RowStyle>
          <RowStyle>
            <RowIconStyle icon={phoneFill} />
            <RowTextStyle>{selectedChannel.phone}</RowTextStyle>
          </RowStyle>
          <RowStyle>
            <RowIconStyle icon={emailFill} />
            <RowTextStyle>{selectedChannel.email}</RowTextStyle>
          </RowStyle>
          {selectedChannel.reclamationId && (
          <RowStyle>
            <RowTextStyle>
              <Link to={`${PATH_DASHBOARD.reclamations.root}/${selectedChannel.reclamationId}`} component={RouterLink}>
                {`Claim ${selectedChannel.reclamationId}`}
              </Link>
            </RowTextStyle>
          </RowStyle>
          )}
        </Box>
      </Accordion>
      <EditInfo isOpened={isOpenedEditModal} onClose={toggleEditModal(false)} />
    </>
  );
});
