import { RootStoreModel, RootStore } from './RootStore';
import { Environment } from './environment';

/**
 * The key we'll be saving our state as within async storage.
 */
// const ROOT_STATE_STORAGE_KEY = 'root'

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment(): Promise<Environment> {
  const env = new Environment();
  await env.setup();
  return env;
}

/**
 * Setup the root state.
 */
export async function setupRootStore(): Promise<RootStore> {
  const env = await createEnvironment();

  return RootStoreModel.create({}, env);
}
