import React from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import {
  Box, Button, Typography, Container,
} from '@material-ui/core';
import { MotionContainer, varBounceIn, Page } from '../../components';
import { PageNotFoundIllustration } from '../../assets';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export const Page404: React.FC = () => (
  <RootStyle title="404 Page Not Found | Minimal-UI">
    <Container>
      <MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounceIn}>
            <Typography variant="h3" paragraph>
              Sorry, page not found!
            </Typography>
          </motion.div>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
            Be sure to check your spelling.
          </Typography>

          <motion.div variants={varBounceIn}>
            <PageNotFoundIllustration />
          </motion.div>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </Box>
      </MotionContainer>
    </Container>
  </RootStyle>
);
