import React, {
  SyntheticEvent,
  useCallback,
  useEffect, useMemo,
  useState,
} from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Stack,
  TextField, Box,
  Typography, FormControlLabel, Switch, Autocomplete,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import { uploadFile, uploadVideo } from '../../../services';
import { UploadMultiFile } from '../../../components';
import {
  CommonItem,
  KnowledgeBaseFileType,
  KnowledgeBaseFormValues,
  KnowledgeBaseParams,
  Option,
} from '../../../types';
import rules from './rules';
import { useAutocomplete, useRequest, useStore } from '../../../hooks';
import { PATH_DASHBOARD } from '../../../app';
import { COMMON_SCHEMA, List } from '../../../constants';
import { mapEntityToOption } from '../../../utils';
import { StateWrapper } from '../../../components/common/StateWrapper';

const MAX_SIZE = 50000000;
const ACCEPT_TYPES = 'image/png, image/gif, image/jpeg, image/jpg, video/mp4, video/quicktime, video/mov';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

type Item = { id?: string }
type FileIds = Item[]

export const KnowledgeBasePostForm: React.FC<{isEdit?: boolean}> = observer(({ isEdit }) => {
  const [preview, setPreview] = useState(false);
  const [files, setFiles] = useState<KnowledgeBaseFileType[]>([]);
  const [filesIds, setFilesIds] = useState<FileIds>([]);
  const history = useHistory();
  const id = useParams<KnowledgeBaseParams>();
  const {
    knowledgeBasesModel: {
      fetchKnowledgeBase,
      getKnowledgeBaseById,
      updateKnowledgeBase,
      createKnowledgeBase,
    },
  } = useStore();

  const strollerParts = useAutocomplete(List.StrollerParts);

  const currentPost = getKnowledgeBaseById(id.id);

  const {
    control, handleSubmit, reset, setValue,
  } = useForm<KnowledgeBaseFormValues>({
    defaultValues: {
      title: '',
      description: '',
      content: '',
      strollerParts: [],
    },
    resolver: yupResolver(rules),
  });

  const handleDropMultiFile = useCallback(async (acceptedFiles) => {
    try {
      const readyFiles: KnowledgeBaseFileType[] = [];
      const ids: FileIds = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const file of acceptedFiles) {
        const videoFile = file?.type.includes('video');
        // eslint-disable-next-line no-await-in-loop
        const fileData = !videoFile ? await uploadFile(file) : await uploadVideo(file);
        ids.push({ id: fileData?.id || '' });
        readyFiles.push({
          preview: URL.createObjectURL(file),
          id: fileData?.id,
          name: file.name,
          type: file.type,
          size: file.size,
          ...file,
        });
      }
      setFilesIds(ids);
      setFiles(readyFiles);
    } catch (e) {
      toast.error('Error with uploading file');
    }
  },
  [setFiles, uploadFile, uploadVideo]);

  const handleRemoveAll = useCallback((): void => {
    setFiles([]);
    setFilesIds([]);
  }, []);

  const handleRemove = (file: KnowledgeBaseFileType): void => {
    const filteredItems = files.filter((_file) => _file !== file);
    const filteredIds = filteredItems.map((item) => ({ id: item.id }));
    setFiles(filteredItems);
    setFilesIds(filteredIds);
  };

  const handleStrollerPartsChange = useCallback((
    event: SyntheticEvent,
    values,
  ): void => {
    setValue('strollerParts', values.map((part) => ({ id: part.value })));
  }, []);

  const defaultStrollerParts = useMemo(function () {
    return currentPost?.strollerParts.map(function (part) {
      return mapEntityToOption<CommonItem>(part, COMMON_SCHEMA);
    });
  }, [currentPost?.strollerParts]);

  const onSubmit = async (values: KnowledgeBaseFormValues): Promise<void> => {
    const customValues = {
      files: filesIds,
      ...values,
    };
    if (isEdit) {
      await updateKnowledgeBase(id, customValues);
    } else {
      await createKnowledgeBase(customValues);
    }
    history.push(PATH_DASHBOARD.knowledgeBases.list);
  };

  const { isFetching, isError } = useRequest({
    action: fetchKnowledgeBase,
    params: id,
    condition: !currentPost && isEdit,
  });

  useEffect(() => {
    reset({
      title: currentPost?.title,
      description: currentPost?.description,
      content: currentPost?.content,
      strollerParts: currentPost?.strollerParts,
    });
    if (currentPost?.files) {
      const filteredIds = currentPost?.files.map((item) => ({ id: item.id }));
      setFiles(currentPost?.files);
      setFilesIds(filteredIds);
    }
  }, [currentPost]);

  const isReady = (isEdit && !!currentPost && !isFetching) || (!isEdit && !isFetching);

  return (
    <StateWrapper isError={isError} isReady={isReady}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Post Title"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Controller
                  name="description"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={5}
                      label="Description"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Controller
                  name="content"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      multiline
                      minRows={5}
                      maxRows={15}
                      label="Content"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="strollerParts"
                  control={control}
                  render={(): JSX.Element => (
                    <Autocomplete
                      multiple
                      freeSolo
                      autoComplete={false}
                      fullWidth
                      options={strollerParts}
                      defaultValue={defaultStrollerParts}
                      onChange={handleStrollerPartsChange}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => <TextField {...params} label="Stroller parts" />}
                    />
                  )}
                />
                <div>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                    <LabelStyle>Files</LabelStyle>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={preview}
                          onChange={(event): void => setPreview(event.target.checked)}
                        />
                      )}
                      label="Show Preview"
                    />
                  </Box>
                  <UploadMultiFile
                    accept={ACCEPT_TYPES}
                    files={files}
                    maxSize={MAX_SIZE}
                    onDrop={handleDropMultiFile}
                    onRemove={handleRemove}
                    onRemoveAll={handleRemoveAll}
                    showPreview={preview}
                  />
                </div>
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained">
                  Save Changes
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </form>
    </StateWrapper>
  );
});
