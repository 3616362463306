import * as yup from 'yup';
import {
  decimalSchema,
  nullableNumberSchema,
  nullableStringSchema,
  objectNullableIdSchema,
  objectIdSchema,
  nullableOnlyStringSchema, stringReqSchema,
} from '../../../utils';
import {
  DEFAULT_ID,
  ReclamationStatus,
} from '../../../constants';

const rules = yup.object({
  country: objectIdSchema('Country'),
  model: objectIdSchema('Model'),
  color: objectIdSchema('Color'),
  description: nullableStringSchema(),
  shop: yup.object({
    id: yup.number().nullable().default(null),
  }),
  deliveryCity: nullableOnlyStringSchema(),
  deliveryStreet: nullableStringSchema(),
  deliveryPostCode: nullableStringSchema(),
  deliveryCountry: objectIdSchema('Delivery country'),
  managerComment: nullableStringSchema(),
  manager: objectNullableIdSchema(),
  pickUpTrackingNumber: nullableStringSchema(),
  pickupService: nullableStringSchema(),
  pickUpDate: nullableStringSchema(),
  deliveryTrackingNumber: nullableStringSchema(),
  deliveryService: nullableStringSchema(),
  deliveryCost: nullableStringSchema(),
  labourCost: nullableStringSchema(),
  qualityReport: nullableStringSchema(),
  status: yup.mixed<ReclamationStatus>().oneOf(Object.values(ReclamationStatus)).required('Status is required'),
  parts: yup.array().of(yup.object({
    id: nullableNumberSchema(DEFAULT_ID),
    strollerPart: objectNullableIdSchema(),
    sparePart: objectNullableIdSchema(),
    deviationCodes: yup.array().of(objectNullableIdSchema()).default([]),
    warranty: yup.number().nullable().default(null),
    price: decimalSchema(),
    quantity: nullableNumberSchema(),
    wayOfSolution: nullableStringSchema(),
  })).default([]),
  dateSolution: nullableStringSchema(),
  serialNumber: stringReqSchema('Serial number'),
  datePurchase: nullableStringSchema(),
  notes: nullableStringSchema(),
  firstName: nullableStringSchema(),
  lastName: nullableStringSchema(),
  phone: nullableStringSchema(),
  email: nullableStringSchema(),
});

export default rules;
