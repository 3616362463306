const LIMIT = 10;

export function makeName(firstName: string | null, lastName: string | null): string {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  return '-';
}

type Meta = {
currentPage: number,
  itemCount: number,
  itemsPerPage: number,
  totalItems: number,
  totalPages: number
}

export function updateMetaAfterAddingNewItem(meta: Meta, limit = LIMIT): Meta {
  const newMeta = {
    currentPage: meta.currentPage,
    itemCount: meta.itemCount,
    itemsPerPage: meta.itemsPerPage,
    totalItems: meta.totalItems,
    totalPages: meta.totalPages,
  };
  newMeta.totalItems += 1;
  if (newMeta.itemCount === limit) {
    newMeta.itemCount = 1;
    newMeta.currentPage += 1;
    newMeta.totalPages += 1;
  } else {
    newMeta.itemCount += 1;
  }
  if (newMeta.totalPages === 0) {
    newMeta.totalPages += 1;
  }
  return newMeta;
}
