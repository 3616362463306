import React from 'react';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import clockFill from '@iconify/icons-eva/clock-fill';
import backspaceFill from '@iconify/icons-eva/backspace-fill';
import { observer } from 'mobx-react-lite';
import paperPlaneOutline from '@iconify/icons-eva/paper-plane-outline';
import { fDateTime, fToNow } from '../../utils';
import { Notification } from '../../types/models/NotificationModel';
import { RedirectRoutes } from '../../constants';
import { useOnItemClick } from '../../hooks/useOnItemClick';

type NotificationPopoverItemProps = {
    data: Notification,
    onClick: (id: number) => () => void,
}

const MainBoxStyles = {
  cursor: 'pointer',
  '&:hover': { backgroundColor: 'rgba(145, 158, 171, 0.08)' },
};

const OverdueMainBoxStyles = {
  cursor: 'pointer',
  padding: '10px',
  '&:hover': { backgroundColor: 'rgba(211, 47, 47, .1)', borderColor: 'rgba(211, 47, 47, .3)' },
  margin: '0 !important',
  backgroundColor: 'rgba(211, 47, 47, .2)',
  border: '1px solid rgba(211, 47, 47, .2)',
};

export const NotificationPopoverItem: React.
    FunctionComponent<NotificationPopoverItemProps> = observer(({
      data,
      onClick,
    }) => {
      const redirectRoute = RedirectRoutes[data?.notificationType];
      const { onItemClick } = useOnItemClick(redirectRoute, data.itemId);

      return (
        <Box display="flex" alignItems="center" sx={data.isOverdue ? OverdueMainBoxStyles : MainBoxStyles}>
          <Box
            key={data.id}
            onClick={onItemClick}
            sx={{
              display: 'flex',
              py: 1.5,
              px: 2.5,
              mt: '1px',

            }}
          >
            <ListItemIcon sx={{ alignItems: 'center' }}>
              <Icon width="26px" icon={paperPlaneOutline} />
            </ListItemIcon>
            <ListItemText
              sx={{ width: '220px' }}
              primary={(
                <>
                  <Typography variant="subtitle2">
                    {data.title}
                  </Typography>
                  <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                    {data.body}
                  </Typography>
                </>
                )}
              secondary={(
                <Typography
                  variant="caption"
                  sx={{
                    mt: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'text.disabled',
                  }}
                  title={fDateTime(data.createdAt)}
                >
                  <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
                  {fToNow(data.createdAt)}
                </Typography>
                )}
            />

          </Box>
          <IconButton
            sx={{ zIndex: 1000, width: '40px', height: '40px' }}
            edge="end"
            aria-label="delete"
            onClick={onClick(data.id)}
          >
            <Icon icon={backspaceFill} />
          </IconButton>
        </Box>
      );
    });
