import React from 'react';
import { Container } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { PATH_DASHBOARD } from '../../../app';
import { useStore } from '../../../hooks';
import { Page, HeaderBreadcrumbs, LoadingScreen } from '../../../components';
import { ProfileForm } from './ProfileForm';
import { ChangePasswordForm } from './ChangePassword';
import { DEFAULT_ID } from '../../../constants';

type Link = {
    name: string,
    href: string,
}

const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Profile', href: PATH_DASHBOARD.profile },
];

export const Profile: React.FC = observer((): JSX.Element => {
  const { auth } = useStore();

  return (
    <Page title="Profile">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Profile"
          links={LINKS}
        />
        {auth.profile.id !== DEFAULT_ID ? (
          <>
            <ProfileForm />
            <ChangePasswordForm />
          </>
        ) : <LoadingScreen />}
      </Container>
    </Page>
  );
});
