import { Grid, Skeleton } from '@material-ui/core';

export const SkeletonLoad = (
  <>
    {[...Array(3)].map((_) => (
      <Grid item xs={12} md={3} key={_}>
        <Skeleton
          variant="rectangular"
          width="100%"
          sx={{ paddingTop: '115%', borderRadius: 2 }}
        />
      </Grid>
    ))}
  </>
);
