import { SyntheticEvent, useCallback, useState } from 'react';
import { Option } from '../../../types';

type UseMultipleSelectFilter = {
  ids: string,
  handleChangeValues: (
      event: SyntheticEvent,
      newValues: (string | Option)[],
  ) => void,
  handleDisabledOption: (option: Option) => boolean
}

export const useMultipleSelectFilter = (): UseMultipleSelectFilter => {
  const [ids, setIds] = useState<string>('');
  const [values, setValues] = useState<Option[]>([]);

  const handleChangeValues = useCallback((event: SyntheticEvent, newValues): void => {
    const newIds = newValues.map((item) => item.value).join(',');
    setIds(newIds);
    setValues(newValues);
  }, []);

  // eslint-disable-next-line max-len
  const handleDisabledOption = (option: Option): boolean => !!values.find((value) => value.value === option.value);

  return {
    ids,
    handleChangeValues,
    handleDisabledOption,
  };
};
