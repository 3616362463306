import React, { MutableRefObject, useEffect, useRef } from 'react';

type ClearableAutocompleteProps = {
  children: (ref: MutableRefObject<HTMLSelectElement | null>) => React.ReactNode,
  value: string | number | undefined | null,
}

export const ClearableAutocomplete: React.FC<ClearableAutocompleteProps> = ({
  children,
  value,
}): JSX.Element => {
  const ref = useRef<HTMLSelectElement | null>(null);
  useEffect(() => {
    if (typeof value === 'undefined' || value === null) {
      const clearableBtn: HTMLElement = ref.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
      clearableBtn?.click();
      const input: HTMLInputElement = ref.current?.getElementsByClassName('MuiOutlinedInput-input')[0] as HTMLInputElement;
      input?.blur();
    }
  },
  [value]);
  return (
    <>
      {children(ref)}
    </>
  );
};
