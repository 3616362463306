import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

// eslint-disable-next-line max-len
type TooltipReturn = { MuiTooltip: { styleOverrides: { arrow: { color: string }; tooltip: { backgroundColor: string } } } }
export const Tooltip = (theme: Theme): TooltipReturn => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[isLight ? 800 : 700],
        },
        arrow: {
          color: theme.palette.grey[isLight ? 800 : 700],
        },
      },
    },
  };
};
