import * as yup from 'yup';
import {
  stringReqSchema,
} from '../../../utils';

const rules = yup.object({
  comment: stringReqSchema('Comment'),
});

export default rules;
