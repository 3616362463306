import React, { useCallback, useMemo } from 'react';
import {
  Autocomplete,
  Box, Card, Grid, Stack, TextField,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { DeviationCodeValues, Option } from '../../../types';
import { getOption } from '../../../utils';
import { DEVIATION_CODE_STATUSES, List } from '../../../constants';
import { useAutocomplete } from '../../../hooks';

type UnionName = 'name' | 'code' | 'class' | 'status' | 'strollerPart.id'

type RenderProps = {
  value: number | null,
  label: string,
  handleChange: (_, data: Option | null,) => void,
  error: FieldError | undefined
  options: Option[]
}

type ConfigItem = {
  name: UnionName,
  label: string,
  render?: ({
    value, label, handleChange, error,
  }: RenderProps) => JSX.Element
}

type FormProps = {
  onAction: (values: DeviationCodeValues) => Promise<void>,
}

const CONFIG: ConfigItem[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'code',
    label: 'Code',
  },
  {
    name: 'class',
    label: 'Class',
  },
  {
    name: 'strollerPart.id',
    label: 'Part',
    render: ({
      value,
      handleChange,
      error,
      label,
      options,
      // added this condition, because select from library is not re-renders on update sometimes
    }): JSX.Element => (value ? (
      <Autocomplete
        disableClearable
        autoComplete={false}
        fullWidth
        value={getOption(options, value)}
        options={options}
        onChange={handleChange}
        getOptionLabel={(option: Option): string => option.label}
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            label={label}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
    ) : <></>),
  },
];

export const Form: React.FunctionComponent<FormProps> = observer(({ onAction }): JSX.Element => {
  const {
    control, handleSubmit, formState: { isSubmitting }, setValue,
  } = useFormContext();

  const strollerParts = useAutocomplete(List.StrollerParts);

  const options = useMemo(() => ({
    status: DEVIATION_CODE_STATUSES,
    'strollerPart.id': strollerParts,
  }), [strollerParts]);

  const handleChangeSelectValue = useCallback((name: UnionName) => (
    _,
    data: Option | null,
  ): void => {
    setValue(name, data?.value);
  }, []);

  return strollerParts.length ? (
    <form onSubmit={handleSubmit(onAction)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {CONFIG.map(({ name, label, render }): JSX.Element => (
                <Stack key={name} direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <Controller
                    name={name}
                    control={control}
                    render={({
                      field: { onBlur, onChange, value }, fieldState: { error },
                    }): JSX.Element => (render ? render({
                      value: Number(value),
                      handleChange: handleChangeSelectValue(name),
                      error,
                      label,
                      options: options[name],
                    }) : (
                      <TextField
                        fullWidth
                        label={label}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!error}
                        helperText={error?.message}
                      />
                    ))}
                  />
                </Stack>
              ))}
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  ) : (<></>);
});
