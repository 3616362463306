import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Table,
  Container,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { PATH_DASHBOARD } from '../../app';
import { useStore } from '../../hooks';
import {
  Page,
  HeaderBreadcrumbs,
  SearchNotFound,
  CommonListHead,
  LoadingScreen,
  AccordionWrapper, Scrollbar,
} from '../../components';
import { StrollersItem } from './StrollersItem';
import { StrollerListToolbar } from './StrollerListToolbar';
import { useSorting } from '../../hooks/useSorting';
import { FIRST_PAGE } from '../../constants';

const STROLLERS_LIST_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'firstName', label: 'Name' },
  { id: 'lastName', label: 'Surname' },
  { id: 'country', label: 'Country' },
  { id: 'model', label: 'Stroller model' },
  { id: 'color', label: 'Stroller color' },
  { id: 'moreMenu', label: '' },
];
const BREADCRUMB_LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Registered strollers list' },
];

export const StrollersList:React.FC = observer((): JSX.Element => {
  const {
    strollersModel: {
      fetchStrollers,
      items,
      meta,
    },
  } = useStore();

  const [page, setPage] = useState<number>(FIRST_PAGE);

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  }, []);

  const {
    sort,
    order,
    orderBy,
    onClickSorting,
  } = useSorting('-created_at');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchStrollers({
      page,
      sort,
    });
  }, [page, sort]);

  const isReady = !!items && !!meta;

  return isReady ? (
    <Page title="Registered Strollers: List | Minimal-UI">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Registered Strollers List"
          links={BREADCRUMB_LINKS}
        />
        <Card>
          <AccordionWrapper>
            <StrollerListToolbar
              sort={sort}
            />
          </AccordionWrapper>
          <TableContainer>
            <Scrollbar>
              <Table>
                <CommonListHead
                  headListData={STROLLERS_LIST_HEAD}
                  order={order}
                  orderBy={orderBy}
                  onClick={onClickSorting}
                />
                <TableBody>
                  {items?.length ? (
                    items.map((item) => (
                      <StrollersItem
                        key={item.id}
                        data={item}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {!!items?.length && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            onPageChange={handlePageChange}
          />
          )}
        </Card>
      </Container>
    </Page>
  ) : (<LoadingScreen />);
});
