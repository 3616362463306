import * as yup from 'yup';
import { CountryFormValues } from '../../types';
import {
  numberReqSchema,
  onlyStringReqSchema,
} from '../../utils';

const rules: yup.SchemaOf<CountryFormValues> = yup.object({
  name: onlyStringReqSchema('Name'),
  locales: yup.array().default([]),
  code: onlyStringReqSchema('Code'),
  status: numberReqSchema('Status'),
  showInCharts: yup.boolean().required('Show in charts is required'),
  languageId: numberReqSchema('Language').typeError('Language is required'),
});

export default rules;
