import React from 'react';
import {
  Box,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Stack,
  Typography, useMediaQuery,
  Zoom,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import paperPlaneOutline from '@iconify/icons-eva/paper-plane-outline';
import { useTheme } from '@material-ui/system';
import { NotificationProps } from '../../../types/notifications';

const lightMode = {
  color: '#fff',
  backgroundColor: '#212B36',
};

const darkMode = {
  color: 'rgba(0, 0, 0, 0.87)',
  backgroundColor: 'rgb(250, 250, 250)',
};

const notificationStyles = {
  light: lightMode,
  dark: darkMode,
};

export const Notification: React.FC<NotificationProps> = ({
  isShow,
  messageData,
  onClose,
}): JSX.Element => {
  const { palette: { mode }, breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Snackbar
      open={isShow}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onClose}
      TransitionComponent={Zoom}
    >
      <Paper sx={{
        padding: '10px',
        display: 'flex',
        boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
        fontWeight: '400',
        ...notificationStyles[mode],
      }}
      >
        <Stack
          direction="row"
          alignItems="center"
          width={isMobile ? '90%' : '350px'}
        >
          <ListItemIcon sx={{ alignItems: 'center', padding: '5px' }}>
            <Icon width="26px" icon={paperPlaneOutline} />
          </ListItemIcon>
          <ListItemText
            sx={{ width: isMobile ? '60%' : '300px' }}
            primary={(
              <Box flexDirection="column">
                <Box>
                  <Typography variant="subtitle2">
                    {messageData?.data?.title}
                  </Typography>
                  <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                    {messageData?.data?.body}
                  </Typography>
                </Box>
                <Link
                  href={messageData?.data?.link}
                  variant="body2"
                >
                  Click
                </Link>
              </Box>
                    )}
          />
        </Stack>
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5, height: '28px', width: '28px' }}
          onClick={onClose}
        >
          <Icon icon={closeFill} width={20} height={20} />
        </IconButton>
      </Paper>
    </Snackbar>
  );
};
