import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Box, ListItemButton, ListItemAvatar, Avatar, ListItemText,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ChannelItem as ChannelItemType } from '../../../types';
import { fDateTime, makeName } from '../../../utils';
import { PATH_DASHBOARD } from '../../../app';
import { BadgeStatus } from '../../../components';
import { MessageTypes } from '../../../constants';

const AVATAR_SIZE = 48;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  transition: theme.transitions.create('all'),
}));

const AvatarWrapperStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': { borderRadius: '50%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' },
}));

type ChannelItemProps = {
  isSelected: boolean;
  channel: ChannelItemType;
  isOpenedSidebar: boolean,
};

export const ChannelItem:React.FC<ChannelItemProps> = observer(({
  isSelected,
  channel,
  isOpenedSidebar,
}) => {
  const { push } = useHistory();

  const handleSelectChannel = (): void => {
    push(`${PATH_DASHBOARD.chat.root}/${channel.id}`);
  };

  const content = channel.messageType === MessageTypes.TEXT ? channel.content || 'Conversation started' : 'File';

  return (
    <RootStyle
      role="presentation"
      disableGutters
      onClick={handleSelectChannel}
      sx={{
        ...(isSelected && { bgcolor: 'action.selected' }),
      }}
    >
      <ListItemAvatar>
        <Box>
          <AvatarWrapperStyle className="avatarWrapper">
            <Avatar alt={channel.firstName || 'Client'} src={channel.avatar || undefined} />
          </AvatarWrapperStyle>
        </Box>
      </ListItemAvatar>

      {isOpenedSidebar && (
        <>
          <ListItemText
            primary={makeName(channel.firstName, channel.lastName)}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondary={content}
            secondaryTypographyProps={{
              noWrap: true,
              variant: 'body2',
              color: 'textSecondary',
            }}
          />

          <Box
            sx={{
              ml: 2,
              height: 44,
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                mb: 1.25,
                fontSize: 12,
                lineHeight: '22px',
                whiteSpace: 'nowrap',
                color: 'text.disabled',
              }}
            >
              {fDateTime(new Date(channel.createdAt))}
            </Box>
            {channel.unreadMessagesCount ? (<BadgeStatus status="unread" size="large">{channel.unreadMessagesCount}</BadgeStatus>) : (<></>)}
          </Box>
        </>
      )}
    </RootStyle>
  );
});
