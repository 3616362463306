import { useState, useEffect } from 'react';

export const useOffSetTop = (top: number): boolean => {
  const [offsetTop, setOffSetTop] = useState<boolean>(false);
  const isTop = top || 100;

  useEffect(() => {
    window.onscroll = (): void => {
      if (window.pageYOffset > isTop) {
        setOffSetTop(true);
      } else {
        setOffSetTop(false);
      }
    };
    return (): void => {
      window.onscroll = null;
    };
  }, [isTop]);

  return offsetTop;
};

// Usage
// const offset = useOffSetTop(100);
