import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type StepperReturn = { MuiStepConnector: { styleOverrides: { line: { borderColor: string } } } }
export const Stepper = (theme: Theme): StepperReturn => ({
  MuiStepConnector: {
    styleOverrides: {
      line: {
        borderColor: theme.palette.divider,
      },
    },
  },
});
