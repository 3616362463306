import { Instance, types } from 'mobx-state-tree';
import { LocalesModel } from './CommonModel';
import { ServiceModelModel } from './ServiceModelModel';

export const StrollerPartModel = types.model('StrollerPartModel', {
  id: types.number,
  name: types.string,
  ordering: types.number,
  locales: types.maybe(types.array(LocalesModel)),
  models: types.maybe(types.array(ServiceModelModel)),
  createdAt: types.string,
  updatedAt: types.string,
  deletedAt: types.maybeNull(types.string),
});

export type StrollerPart = Instance<typeof StrollerPartModel>
