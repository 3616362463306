import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  TextField,
  Autocomplete,
  useMediaQuery, Grid,
} from '@material-ui/core';
import {
  LocalizationProvider,
} from '@material-ui/lab';
import DateFnsUtils from '@material-ui/lab/AdapterDateFns';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';
import { getOption, isValidDate } from '../../utils';
import { Option, StrollerListToolbarProps } from '../../types';
import { useAutocomplete, useStore } from '../../hooks';
import { List } from '../../constants';
import { CustomDatePicker } from '../../components/CustomDatePicker';

const SEARCH_TIMEOUT = 400;

const RootStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: '0px !important',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export const StrollerListToolbar:React.FC<StrollerListToolbarProps> = observer(({
  sort,
}): JSX.Element => {
  const { strollersModel: { fetchStrollers } } = useStore();

  const [searchIdValue, setSearchIdValue] = useState<string>('');
  const [searchNameValue, setSearchNameValue] = useState<string>('');
  const [searchEmailValue, setSearchEmailValue] = useState<string>('');
  const [searchPhoneValue, setSearchPhoneValue] = useState<string>('');
  const [searchModelValue, setSearchModelValue] = useState<number | null>(null);
  const [searchCountryValue, setSearchCountryValue] = useState<number | null>(null);
  const [searchSerialNumberValue, setSearchSerialNumberValue] = useState<string>('');
  // eslint-disable-next-line max-len
  const [searchPurchaseFrom, setSearchPurchaseFrom] = useState<string | null>(null);
  const [searchPurchaseTo, setSearchPurchaseTo] = useState<string | null>(null);

  const [debouncedSearchIdValue] = useDebounce(searchIdValue, SEARCH_TIMEOUT);
  const [debouncedSearchNameValue] = useDebounce(searchNameValue, SEARCH_TIMEOUT);
  const [debouncedSearchEmailValue] = useDebounce(searchEmailValue, SEARCH_TIMEOUT);
  const [debouncedSearchPhoneValue] = useDebounce(searchPhoneValue, SEARCH_TIMEOUT);
  const [debouncedSearchSerialNumberValue] = useDebounce(searchSerialNumberValue, SEARCH_TIMEOUT);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const serviceModels = useAutocomplete(List.Models);
  const countries = useAutocomplete(List.Countries);

  const handleFilterById = useCallback((newFilterId: string):void => {
    setSearchIdValue(newFilterId);
  }, []);
  const handleFilterByName = useCallback((newFilterName: string):void => {
    setSearchNameValue(newFilterName);
  }, []);
  const handleFilterByEmail = useCallback((newFilterEmail: string):void => {
    setSearchEmailValue(newFilterEmail);
  }, []);
  const handleFilterByPhone = useCallback((newFilterPhone: string):void => {
    setSearchPhoneValue(newFilterPhone);
  }, []);
  const handleFilterByModel = useCallback((newFilterModel: number | null):void => {
    setSearchModelValue(newFilterModel);
  }, []);
  const handleFilterByCountry = useCallback((newFilterCountry: number | null):void => {
    setSearchCountryValue(newFilterCountry);
  }, []);
  const handleFilterBySerialNumber = useCallback((newFilterSerialNumber: string):void => {
    setSearchSerialNumberValue(newFilterSerialNumber);
  }, []);
  const handleFilterByPurchaseFrom = useCallback((newFilterPurchase: string | null):void => {
    if (isValidDate(newFilterPurchase) || !newFilterPurchase) {
      setSearchPurchaseFrom(newFilterPurchase);
    }
  }, []);
  const handleFilterByPurchaseTo = useCallback((newFilterPurchase: string | null):void => {
    if (isValidDate(newFilterPurchase) || !newFilterPurchase) {
      setSearchPurchaseTo(newFilterPurchase);
    }
  }, []);

  useEffect(() => {
    fetchStrollers({
      id: debouncedSearchIdValue,
      name: debouncedSearchNameValue,
      email: debouncedSearchEmailValue,
      phone: debouncedSearchPhoneValue,
      modelId: searchModelValue,
      countryId: searchCountryValue,
      serialNumber: debouncedSearchSerialNumberValue,
      datePurchaseFrom: searchPurchaseFrom,
      datePurchaseTo: searchPurchaseTo,
      sort,
    });
  }, [
    debouncedSearchIdValue,
    debouncedSearchNameValue,
    debouncedSearchEmailValue,
    debouncedSearchPhoneValue,
    searchModelValue,
    searchCountryValue,
    debouncedSearchSerialNumberValue,
    searchPurchaseFrom,
    searchPurchaseTo,
    sort,
  ]);

  return (
    <>
      <RootStyle sx={{ paddingTop: '25px', paddingBottom: '25px' }}>
        <Grid container spacing={3}>
          <Grid item spacing={4} xs={12} md={4}>
            <SearchStyle
              size="small"
              value={searchIdValue}
              onChange={(e): void => handleFilterById(e.target.value)}
              placeholder="ID..."
              startAdornment={(
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
                )}
            />
          </Grid>
          <Grid item spacing={4} xs={12} md={4}>
            <SearchStyle
              size="small"
              value={searchNameValue}
              onChange={(e): void => handleFilterByName(e.target.value)}
              placeholder="Name or Surname..."
              startAdornment={(
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
                    )}
            />
          </Grid>
          <Grid item spacing={4} xs={12} md={4}>
            <SearchStyle
              size="small"
              value={searchEmailValue}
              onChange={(e): void => handleFilterByEmail(e.target.value)}
              placeholder="Email..."
              startAdornment={(
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
            )}
            />
          </Grid>
        </Grid>
      </RootStyle>
      <RootStyle sx={{ paddingBottom: '25px' }}>
        <Grid container spacing={3}>
          <Grid item spacing={4} xs={12} md={4}>
            <SearchStyle
              size="small"
              value={searchPhoneValue}
              onChange={(e): void => handleFilterByPhone(e.target.value)}
              placeholder="Phone number..."
              startAdornment={(
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
            )}
            />
          </Grid>
          <Grid item spacing={4} xs={12} md={4}>
            <SearchStyle
              size="small"
              value={searchSerialNumberValue}
              onChange={(e): void => handleFilterBySerialNumber(e.target.value)}
              placeholder="SE..."
              startAdornment={(
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
                )}
            />
          </Grid>
          <Grid item spacing={4} xs={12} md={4}>
            <Autocomplete
              size="small"
              autoComplete={false}
              fullWidth
              value={searchModelValue ? getOption(serviceModels, searchModelValue) : null}
              options={serviceModels}
              onChange={(e, data: Option | null): void => handleFilterByModel(data?.value || null)}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Model" />}
            />
          </Grid>
        </Grid>
      </RootStyle>
      <RootStyle sx={{ paddingBottom: '25px' }}>
        <Grid container spacing={3}>
          <Grid item spacing={4} xs={12} md={8}>
            <LocalizationProvider dateAdapter={DateFnsUtils} sx={{ marginTop: isMobile ? 10 : 0 }}>
              <Grid container spacing={3}>
                <Grid item spacing={3} xs={12} md={6}>
                  <CustomDatePicker
                    label="Date from"
                    value={searchPurchaseFrom}
                    handleChange={handleFilterByPurchaseFrom}
                  />
                </Grid>
                <Grid item spacing={3} xs={12} md={6}>
                  <CustomDatePicker
                    label="Date to"
                    value={searchPurchaseTo}
                    handleChange={handleFilterByPurchaseTo}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item spacing={4} xs={12} md={4}>
            <Autocomplete
              size="small"
              autoComplete={false}
              fullWidth
              value={searchCountryValue ? getOption(countries, searchCountryValue) : null}
              options={countries}
              onChange={(e, data: Option | null): void => {
                handleFilterByCountry(data?.value || null);
              }}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Country" />}
            />
          </Grid>
        </Grid>
      </RootStyle>
    </>
  );
});
