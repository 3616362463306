import React, { useCallback, useEffect, useState } from 'react';
import {
  LoadingButton, TabContext, TabList, TabPanel,
} from '@material-ui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Tab,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';
import {
  CountryFormValues,
  CountryValues,
  Language,
  Option,
  QueryParams,
} from '../../types';
import rules from './rules';
import { useRequest, useStore } from '../../hooks';
import { COMMON_SCHEMA, COUNTRY_STATUSES, DEFAULT_AUTOCOMPLETE_ID } from '../../constants';
import { getOption, mapEntityToOption } from '../../utils';
import { StateWrapper } from '../../components/common/StateWrapper';

const DEFAULT_VALUE = 0;
const DEFAULT_TAB = '0';

export const CountriesForm:React.FunctionComponent = observer(
  () => {
    const {
      countriesModel: {
        updateCountry, fetchCountry, getCountryById,
      },
      languages: {
        fetchLanguages,
        items,
      },
    } = useStore();
    const [tabValue, setTabValue] = useState<string>(DEFAULT_TAB);
    const { id } = useParams<QueryParams>();
    const currentCountry = getCountryById(id);
    const languagesAutocomplete = items.map((item) => mapEntityToOption<Language>(
      item,
      COMMON_SCHEMA,
    ));

    const {
      control, handleSubmit, setValue, reset,
    } = useForm<CountryFormValues>({
      defaultValues: {
        name: '',
        code: '',
        locales: items.map((language) => ({
          id: language.id,
          name: '',
          language: language.url,
        })),
        status: DEFAULT_VALUE,
        languageId: DEFAULT_VALUE,
        showInCharts: false,
      },
      resolver: yupResolver(rules),
    });

    const handleChangeStatus = useCallback((
      _, data: Option | null,
    ): void => {
      // TODO: MOBx see that LocalesModel is the main model (not CountryModel), need to fix it
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue('status', data?.value || DEFAULT_VALUE);
    }, []);

    useEffect(() => {
      if (items.length === 0) {
        fetchLanguages();
      }
    }, [items]);

    const { isFetching, isError } = useRequest({
      action: fetchCountry,
      params: id,
      condition: !currentCountry,
    });

    useEffect(() => {
      if (currentCountry) {
        let locales = items.map((language) => ({
          name: '',
          language: language.url,
        }));
        if (currentCountry?.locales?.length) {
          locales = uniqBy([...currentCountry.locales, ...locales], 'language');
        }
        reset({
          name: currentCountry.name,
          code: currentCountry.code,
          status: currentCountry.status,
          locales,
          languageId: currentCountry.languageId,
          showInCharts: currentCountry.showInCharts,
        });
      }
    }, [currentCountry]);

    const onSubmit = async (values: CountryValues): Promise<void> => {
      const externalId = currentCountry?.externalId || '';
      const customValues: CountryValues = {
        ...values,
        externalId,
      };
      await updateCountry(id, customValues);
    };

    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    }, []);

    const handleChangeSelectValue = useCallback((
      _,
      data: Option | null,
    ): void => {
      setValue('languageId', data?.value || DEFAULT_VALUE);
    }, []);

    const isReady = !!currentCountry
        && !!items.length
        && !!languagesAutocomplete.length
        && !isFetching;
    return (
      <StateWrapper isError={isError} isReady={isReady}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="name"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <TabContext value={tabValue}>
                      <TabList onChange={handleChangeTab} variant="scrollable">
                        {items.map((language, index) => (
                          <Tab
                            key={language.name}
                            label={language.name}
                            value={String(index)}
                          />
                        ))}
                      </TabList>

                      <TabPanel key={tabValue} value={tabValue}>
                        <Controller
                          name={`locales.${Number(tabValue)}.name`}
                          control={control}
                          render={({
                            field: { onBlur, value, onChange }, fieldState: { error },
                          }): JSX.Element => (
                            <TextField
                              fullWidth
                              label="Name"
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </TabPanel>
                    </TabContext>
                    <Controller
                      name="code"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Code"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="status"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          autoComplete={false}
                          fullWidth
                          value={getOption(COUNTRY_STATUSES, value)}
                          options={COUNTRY_STATUSES}
                          onChange={handleChangeStatus}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(params): JSX.Element => (
                            <TextField {...params} label="Status" error={!!error} helperText={error?.message} />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="languageId"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          fullWidth
                          value={getOption(languagesAutocomplete, value)}
                          defaultValue={{
                            label: currentCountry?.language?.name || '',
                            value: currentCountry?.language?.id || DEFAULT_AUTOCOMPLETE_ID,
                          }}
                          options={languagesAutocomplete}
                          onChange={handleChangeSelectValue}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(autocompleteParams): JSX.Element => (
                            <TextField
                              {...autocompleteParams}
                              label="Language"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="showInCharts"
                      control={control}
                      render={({ field: { value, onChange } }): JSX.Element => (
                        <FormControlLabel
                          label="Show in charts"
                          control={(
                            <Checkbox
                              checked={!!value}
                              onChange={onChange}
                            />
                        )}
                        />
                      )}
                    />
                  </Stack>

                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained">
                      Save Changes
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </form>
      </StateWrapper>
    );
  },
);
