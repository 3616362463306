import React from 'react';
import { Grid } from '@material-ui/core';
import { ClaimsCountByPeriod } from '../ClaimsCountByPeriod';
import { ClaimsCountByStatus } from '../ClaimsCountByStatus';
import { ClosedClaimsCount } from '../ClosedClaimsCount';
import { AvgTimeProcessingClaims } from '../AvgTimeProcessingClaims';
import { AllClaimsCount } from '../AllClaimsCount';
import { PermissionWrapper } from '../../../components';
import { Module, PermissionAction } from '../../../constants';

export const AdminView: React.FC = () => (
  <>
    <PermissionWrapper
      action={PermissionAction.SHOW}
      module={Module.CHARTS}
    >
      <Grid item xs={12} md={12} lg={12}>
        <ClaimsCountByPeriod />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <ClaimsCountByStatus />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <ClosedClaimsCount />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AvgTimeProcessingClaims />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AllClaimsCount />
      </Grid>
    </PermissionWrapper>
  </>
);
