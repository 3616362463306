import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@material-ui/core/styles';
import { usePrevious, useStore } from '../../../hooks';
import { DEFAULT_ID, FIRST_PAGE } from '../../../constants';
import { LoadingScreen } from '../../../components';
import { MessageItem } from './MessageItem/MessageItem';

const ScrollableDiv = styled('div')(({ theme }) => ({
  overflow: 'auto',
  margin: '24px',
  display: 'flex',
  flexDirection: 'column-reverse',
  height: '100%',
}));

export const MessageList: React.FC = observer((): JSX.Element => {
  const {
    messages: {
      items,
      meta: {
        totalPages,
        currentPage,
      },
      fetchMessages,
    },
    channels: {
      selectedChannel,
    },
  } = useStore();

  const [page, setPage] = useState<number>(FIRST_PAGE);
  const prevChannelId = usePrevious<number>(selectedChannel.id);
  const handleInfiniteScroll = (): void => {
    setPage(((prevState) => prevState + 1));
  };

  useEffect(() => {
    if (selectedChannel.id && selectedChannel.id !== DEFAULT_ID) {
      fetchMessages({
        channelId: selectedChannel.id, page,
      });
    }
  }, [selectedChannel.id, page]);

  useEffect(() => {
    if (prevChannelId !== selectedChannel.id) {
      setPage(FIRST_PAGE);
    }
  }, [selectedChannel.id]);

  const showDateDivider = useCallback((firstDate: string, secondDate: string): boolean => {
    if (!secondDate) return false;
    return new Date(firstDate).getDate() !== new Date(secondDate).getDate();
  }, []);

  return (
    <ScrollableDiv
      id="scrollableMessagesDiv"
    >
      {items.length ? (
        <InfiniteScroll
          dataLength={items.length}
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          next={handleInfiniteScroll}
          hasMore={totalPages !== currentPage}
          loader={<LoadingScreen />}
          scrollableTarget="scrollableMessagesDiv"
          inverse
        >
          {items.map((item, index, array) => (
            <MessageItem
              message={item}
              isDateDivider={showDateDivider(item.createdAt, array[index + 1]?.createdAt)}
              index={index}
            />
          ))}
        </InfiniteScroll>
      ) : (<></>)}
    </ScrollableDiv>
  );
});
