export const ROUTES = {
  CITIES: {
    ROOT: 'cities',
  },
  USERS: {
    ROOT: 'users',
    INVITE: 'users/invite',
  },
  AUTH: {
    LOGIN: 'auth/login',
    REGISTER: 'auth/register',
    SEND_RESET: 'auth/send-reset-password',
    RESET_PASSWORD: 'auth/reset-password',
    CHECK_TOKEN: 'auth/check-token',
    PROFILE: 'auth/profile',
    CHANGE_PASSWORD: 'auth/password',
    AVATAR: 'auth/avatar',
  },
  SHOPS: {
    ROOT: 'shops',
  },
  COUNTRIES: {
    ROOT: 'countries',
    ROOT_ALL: 'countries/all',
  },
  STROLLERS: {
    ROOT: 'consumers',
  },
  FILES: {
    UPLOAD_VIDEO: 'files/upload/video',
    UPLOAD_IMAGE: 'files/upload/image',
    UPLOAD_IMAGES: 'files/upload/images',
    GET: 'files',
    GET_VIDEO: 'files/stream',
  },
  CONSUMERS: {
    ROOT: 'consumers',
    CONTACT_INFO: 'contact-info',
    REGISTRATION: 'registration',
    COUNT_BY_COUNTRY: 'count-by-country',
    CREATE: 'by-manager',
  },
  SERVICE_MODELS: {
    ROOT: 'service-model',
  },
  SERVICE_COLORS: {
    ROOT: 'service-color',
  },
  MODULES: {
    ROOT: 'modules',
  },
  KNOWLEDGE_BASES: {
    ROOT: 'knowledge-bases',
    COUNT: 'knowledge-bases/count',
    HINTS: 'knowledge-bases/hints',
  },
  RECLAMATIONS: {
    ROOT: 'reclamations',
    COMMENTS: 'comments',
    INFO: 'info',
    STATUS: 'status',
    CSAT: 'csat',
    AUTOCOMPLETE: 'autocomplete',
  },
  DEVIATION_CODES: {
    ROOT: 'deviation-code',
  },
  STROLLER_PARTS: {
    ROOT: 'stroller-parts',
  },
  SPARE_PARTS: {
    ROOT: 'spare-parts',
  },
  LANGUAGES: {
    ROOT: 'languages',
  },
  REPORTS: {
    ALL_CLAIMS: 'reclamations/reports/all-claims',
    ALL_CLAIMS_PDF: 'reclamations/reports/all-claims/pdf',
    FILE_ALL_CLAIMS: 'reclamations/reports/all-claims/statistics',
    RECLAMATIONS_COUNT_BY_PERIODS: 'reclamations/count-by-periods',
    RECLAMATIONS_COUNT_BY_STATUSES: 'reclamations/count-by-statuses',
    ALL_COUNTRIES: 'reclamations/reports/by-countries',
    ALL_COUNTRIES_PDF: 'reclamations/reports/by-countries/pdf',
    FILE_ALL_COUNTRIES: 'reclamations/reports/by-countries/statistics',
    CLOSED_RECLAMATIONS_COUNT: 'reclamations/count-closed',
    RECLAMATIONS_COUNT: 'reclamations/count',
    AVG_TIME_PROCESSING: 'reclamations/avg-time-processing',
  },
  KANBAN: {
    LIST: 'reclamations/kanban/status-countries',
    RECLAMATIONS: 'reclamations/kanban/reclamations',
  },
  NOTIFICATIONS: {
    ROOT: 'notifications',
    TOKEN: 'token',
    READ: 'read',
    UNREAD_COUNT: 'unread-count',
    UNREAD: 'unread',
  },
  CHANNELS: {
    ROOT: 'channels',
    RESET_UNREAD_MESSAGES: 'reset-unread-messages',
    CONNECT_CHANNEL_TO_MANAGER: 'connect-channel-to-manager',
    SET_CHANNEL_TO_RECLAMATION: 'set-reclamation',
  },
  MESSAGES: {
    ROOT: 'messages',
    SEND: 'send',
  },
  CLIENTS: {
    ROOT: 'clients',
  },
};
