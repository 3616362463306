import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { PATH_DASHBOARD } from '../../../app';
import { useOnItemClick } from '../../../hooks/useOnItemClick';
import { HintsItem } from '../../../types';

type HintItemProps = {
    data: HintsItem,
}

const TableRowStyles = {
  cursor: 'pointer',
};

type CELL = {
    name: string,
}

const CELLS: CELL[] = [
  {
    name: 'title',
  },
  {
    name: 'description',
  },
];

const UPDATING_LINK = PATH_DASHBOARD.knowledgeBases.post_root;

export const HintItem: React.FC<HintItemProps> = ({
  data,
}): JSX.Element => {
  const { onItemClick } = useOnItemClick(UPDATING_LINK, data.id);

  return (
    <TableRow
      hover
      sx={TableRowStyles}
    >
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          align="center"
          onClick={onItemClick}
        >
          {data[cell.name]}
        </TableCell>
      ))}
    </TableRow>
  );
};
