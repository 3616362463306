import { SxProps } from '@material-ui/system';
import { Theme } from '@material-ui/core/styles';

export const ICON_DIMENSION = 20;

export const RECLAMATION_LIST_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'modelName', label: 'Model' },
  { id: 'colorName', label: 'Color' },
  { id: 'serialNumber', label: 'Serial number' },
  { id: 'createdAt', label: 'Date of creation' },
  { id: 'moreMenu', label: '' },
];

export const HEADER_TITLE_STYLES = {
  py: 2,
  pl: 1,
};

export const PAPER_STYLES = {
  px: 2, bgcolor: 'grey.5008', width: '100%', minWidth: '215px',
};

export const HEAD_NAME_STYLES: SxProps<Theme> = {
  textTransform: 'capitalize',
};
