import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { CommonMoreMenu } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { StrollerPart } from '../../../types';
import { useOnItemClick } from '../../../hooks/useOnItemClick';

type CELL = {
    name: string,
    render?: (data: StrollerPart, cell: CELL) => JSX.Element,
}

type StrollerPartsItemProps = {
    data: StrollerPart,
}

const CELLS: CELL[] = [
  {
    name: 'id',
  },
  {
    name: 'name',
  },
  {
    name: 'ordering',
  },
  {
    name: 'models',
    render: (data, cell): JSX.Element => (
      data[cell.name].length ? (data[cell.name].map((item) => (
        <div style={{ display: 'block', whiteSpace: 'nowrap' }}>{item.name || '-'}</div>
      ))) : ('-')
    ),
  },
];

const TableRowStyles = {
  cursor: 'pointer',
};

const UPDATING_LINK = PATH_DASHBOARD.strollerParts.root;

export const StrollerPartsItem: React.FC<StrollerPartsItemProps> = ({
  data,
}): JSX.Element => {
  const { onItemClick } = useOnItemClick(UPDATING_LINK, data.id);

  return (
    <TableRow
      hover
      sx={TableRowStyles}
    >
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          onClick={onItemClick}
        >
          {cell.render ? cell.render(data, cell) : data[cell.name]}
        </TableCell>
      ))}
      <TableCell align="right">
        <CommonMoreMenu
          itemId={data.id}
          updatingLink={UPDATING_LINK}
        />
      </TableCell>
    </TableRow>
  );
};
