import { Option } from '../types';

export enum CountryStatus {
    ACTIVE = 1,
    INACTIVE = 0,
}

export const COUNTRY_STATUSES: Option[] = [
  { label: 'Active', value: CountryStatus.ACTIVE },
  { label: 'Inactive', value: CountryStatus.INACTIVE },
];

export const COUNTRY_STATUS = {
  [CountryStatus.ACTIVE]: {
    title: 'Active',
  },
  [CountryStatus.INACTIVE]: {
    title: 'Inactive',
  },
};
