import * as yup from 'yup';
import {
  emailSchema,
  firstNameSchema,
  objectIdSchema,
  phoneReqSchema,
  priceSchema,
  stringReqSchema,
  surnameSchema,
} from '../../../utils';

const rules = yup.object({
  firstName: firstNameSchema(),
  lastName: surnameSchema(),
  phone: phoneReqSchema('Phone'),
  email: emailSchema(),
  city: stringReqSchema('City'),
  country: objectIdSchema('Country'),
  model: objectIdSchema('Model'),
  color: objectIdSchema('Color'),
  price: priceSchema(),
  serialNumber: stringReqSchema('Serial number'),
  sellerCountry: objectIdSchema('Country'),
  sellerCity: objectIdSchema('City'),
  shop: objectIdSchema('Shop'),
  datePurchase: stringReqSchema('Date purchase'),
});

export default rules;
