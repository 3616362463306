import React from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion } from '../Accordion';
import { useStore } from '../../../../hooks';
import { RecordingItem } from './RecordingItem';

export const RecordingList:React.FC = observer(() => {
  const {
    channels: {
      selectedChannel,
    },
  } = useStore();

  return (
    <Accordion
      title={`Recordings (${selectedChannel.recordings.length})`}
      isBorderBottom
      maxHeight="200px"
    >
      {selectedChannel.recordings.map(({ id, url, createdAt }) => (
        <RecordingItem key={id} url={url} createdAt={createdAt} />
      ))}
    </Accordion>
  );
});
