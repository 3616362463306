import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Autocomplete, TextField } from '@material-ui/core';
import { FieldError, FieldPath } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { getOption } from '../../../utils';
import { Option, ReclamationInfoValues } from '../../../types';
import { Data } from '../../../constants';

type DefaultSelectProps = {
    name: FieldPath<ReclamationInfoValues>,
    options: Option[],
    value: number | null | undefined,
    defaultValue?: Option,
    onChange: (name: FieldPath<ReclamationInfoValues>) => (_, data: Data) => void,
    error: FieldError | undefined,
    label: string,
    disabled?: boolean,
    disableClearable?: boolean,
}

export const DefaultSelect: React.FC<DefaultSelectProps> = ({
  name,
  options = [],
  value,
  onChange,
  error,
  label,
  defaultValue,
  disabled = false,
  disableClearable = false,
}): JSX.Element => {
  const [internalValue, setInternalValue] = useState<number | null | undefined>(value);
  // when we set value first time,
  // handleChangeInternalInputValue check that internal input value is not empty
  // and rewrite value on correct
  // when we click on clear in stroller part autocomplete,
  // internalInputValue is empty, and we don't rewrite this value
  const firstRender = useRef(true);
  const handleChangeSelectValue = useCallback((
    _,
    data: Option | null,
  ): void => {
    onChange(name)(_, data);
    setInternalValue(data?.value || null);
  }, [name]);

  useEffect(() => {
    if (!firstRender.current) {
      if (!value) {
        setInternalValue(null);
      }
    } else {
      firstRender.current = false;
    }
  }, [value]);

  return (
    <Autocomplete
      autoComplete={false}
      disableClearable={disableClearable}
      fullWidth
      value={getOption(options, internalValue)}
      defaultValue={defaultValue}
      options={options}
      onChange={handleChangeSelectValue}
      getOptionLabel={(option: Option): string => option.label}
      renderOption={(props, option): JSX.Element => (
        <li {...props} key={uuidv4()}>
          {option.label}
        </li>
      )}
      disabled={disabled}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};
