import React, { FormEvent, useCallback, useState } from 'react';
import {
  IconButton, Box, TextField, Typography, Stack,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { styled } from '@material-ui/core/styles';
import roundSend from '@iconify/icons-ic/round-send';
import { toast } from 'react-hot-toast';
import { Message } from '../../../../types';
import { fDateTime } from '../../../../utils';
import { useStore } from '../../../../hooks';

type UpdateMessageFormProps = {
  message: Message,
  onChangeEditState: (value: boolean) => () => void,
}

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

export const UpdateMessageForm: React.FC<UpdateMessageFormProps> = ({
  onChangeEditState,
  message,
}): JSX.Element => {
  const {
    messages: {
      updateMessage,
    },
  } = useStore();

  const [messageForUpdate, setMessageForUpdate] = useState<string>(message.content);

  const handleSend = useCallback(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const isIncorrectMessageForUpdate = !messageForUpdate
        || messageForUpdate.length === 0
        || message.content === messageForUpdate;
    if (isIncorrectMessageForUpdate) {
      toast.error('Message can\'t be edited');
      return;
    }
    await updateMessage(message.id, {
      content: messageForUpdate,
    });
    onChangeEditState(false)();
  }, [messageForUpdate, message.content]);

  return (
    <Box sx={{ ml: 6, mb: 3, marginRight: '25px' }}>
      <form onSubmit={handleSend}>
        <InfoStyle noWrap variant="caption" sx={{ justifyContent: 'flex-end' }}>
                &nbsp;
          {fDateTime(new Date(message.createdAt))}
          {' '}
        </InfoStyle>
        <Stack direction="row" alignItems="center">
          <TextField
            fullWidth
            value={messageForUpdate}
            onChange={(e): void => setMessageForUpdate(e.target.value)}
            size="small"
            placeholder="Write a comment…"
            sx={{
              mr: 1,
              '& fieldset': {
                borderWidth: '1px !important',
                borderColor: (theme): string => `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
          <IconButton type="submit">
            <Icon icon={roundSend} width={24} height={24} />
          </IconButton>
          <IconButton onClick={onChangeEditState(false)}>
            <Icon
              icon={closeFill}
              width={24}
              height={24}
            />
          </IconButton>
        </Stack>
      </form>
    </Box>
  );
};
