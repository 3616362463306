import { omit } from 'lodash';
import { ReclamationInfoValues, ReclamationPartValues, ReclamationStatusValues } from '../types';
import {
  DEFAULT_AUTOCOMPLETE_ID, DEFAULT_ID, DEFAULT_PART, ReclamationStatus,
} from '../constants';

export function parseReclamationParts(parts: ReclamationPartValues[]): ReclamationPartValues[] {
  return parts
    .filter((part) => part?.strollerPart?.id)
    .map((part) => {
      const newPart = part.id ? part : omit(part, 'id');
      return {
        ...newPart,
        deviationCodes: newPart.deviationCodes?.filter((code) => code.id),
        sparePart: {
          ...newPart.sparePart,
          id: newPart.sparePart?.id || null,
        },
      };
    });
}

export const fillParts = (parts: ReclamationPartValues[]): ReclamationPartValues[] => {
  let filledParts: ReclamationPartValues[] = [];
  for (let i = 0; i <= 2; i += 1) {
    if (parts[i] && parts[i].id !== DEFAULT_ID) {
      filledParts = [...filledParts, parts[i]];
    } else {
      filledParts = [...filledParts, DEFAULT_PART];
    }
  }
  return filledParts;
};

export function getUpdatingStatusFieldsFromReclamationValues(
  object: ReclamationInfoValues,
  array: Array<string>,
): ReclamationStatusValues {
  const newObj: ReclamationStatusValues = {
    status: ReclamationStatus.NEW,
    deliveryCity: null,
    deliveryStreet: null,
    deliveryPostCode: null,
    deliveryCountry: {
      id: DEFAULT_AUTOCOMPLETE_ID,
    },
    pickUpTrackingNumber: null,
    pickupService: null,
    pickUpDate: null,
    deliveryTrackingNumber: null,
    deliveryService: null,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const item of array) {
    newObj[item] = object[item];
  }
  return newObj;
}
