import * as Yup from 'yup';
import {
  emailSchema,
  firstNameSchema,
  numberReqSchema,
  onlyStringReqSchema,
  phoneSchema,
  priceSchema,
  stringReqSchema,
  surnameSchema,
} from '../../utils';

const rules = Yup.object({
  firstName: firstNameSchema(),
  lastName: surnameSchema(),
  email: emailSchema(),
  phone: phoneSchema(),
  price: priceSchema(),
  countryId: numberReqSchema('Country'),
  city: onlyStringReqSchema('City'),
  modelId: numberReqSchema('Model'),
  serialNumber: stringReqSchema('Serial number'),
  datePurchase: stringReqSchema('Date purchase'),
  colorId: numberReqSchema('Color'),
  sellerCountryId: numberReqSchema('SellerCountry'),
  sellerCityId: numberReqSchema('Seller city'),
  sellerShopId: numberReqSchema('Seller shop'),
  createdAt: stringReqSchema('Date of creation'),
});

export default rules;
