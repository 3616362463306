import { Option, SparePartOption } from '../types';

/**
 *
 * @param entity {object}
 * @param schema {array<string>} array of two strings, first is key for label, second for value
 */
export function mapEntityToOption<T>(entity: T, schema: Array<string>): Option {
  return ({
    label: entity[schema[0]],
    value: entity[schema[1]],
  });
}

/**
 *
 * @param array {array} array of objects
 * @param prop {string} prop, which should be parsed to string
 */
export function mapArrayToString<T>(array: Array<T>, prop = 'name'): string {
  return array?.map((part) => part[prop]).join(', ') || '-';
}

/**
 *
 * @param entity {object}
 * @param schema {array<string>} array of three strings, first for label,
 * second for value, third for price
 */
export function mapSparePartToOption<T>(entity: T, schema: Array<string>): SparePartOption {
  return ({
    label: entity[schema[0]],
    value: entity[schema[1]],
    price: entity[schema[2]],
  });
}
