import { Theme } from '@material-ui/core/styles';

type InputReturn = { MuiInputBase: { styleOverrides: { input: { '&:-webkit-autofill': { '-webkit-box-shadow': string; }; '&::placeholder': { color: string; opacity: number } }; root: { '&.Mui-disabled': { '& svg': { color: string } } } } }; MuiInput: { styleOverrides: { underline: { '&:before': { borderBottomColor: string } } } }; MuiOutlinedInput: { styleOverrides: { root: { '&.Mui-disabled': { '& .MuiOutlinedInput-notchedOutline': { borderColor: string } }; '& .MuiOutlinedInput-notchedOutline': { borderColor: string } } } }; MuiFilledInput: { styleOverrides: { underline: { '&:before': { borderBottomColor: string } }; root: { backgroundColor: string; '&.Mui-disabled': { backgroundColor: string }; '&:hover': { backgroundColor: string }; '&.Mui-focused': { backgroundColor: string } } } } }
export const Input = (theme: Theme): InputReturn => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          '& svg': { color: theme.palette.text.disabled },
        },
      },
      input: {
        '&::placeholder': {
          opacity: 1,
          color: theme.palette.text.disabled,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 100px ${theme.palette.background.default} inset !important`,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottomColor: theme.palette.grey[500_56],
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.grey[500_12],
        '&:hover': {
          backgroundColor: theme.palette.grey[500_16],
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.action.focus,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      underline: {
        '&:before': {
          borderBottomColor: theme.palette.grey[500_56],
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[500_32],
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.action.disabledBackground,
          },
        },
      },
    },
  },
});
