import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Stack } from '@material-ui/core';
import { useStore } from '../../../hooks';
import { QueryParams } from '../../../types';
import { CommentItem } from './CommentItem';
import { AddComment } from './AddComment';

export const Comments: React.FC = observer(() => {
  const { id } = useParams<QueryParams>();

  const {
    reclamations: {
      getCommentsByReclamationId,
      comments,
    },
  } = useStore();

  useEffect(() => {
    getCommentsByReclamationId(id);
  }, []);

  return (
    <>
      <Stack spacing={1.5}>
        {comments.map((comment) => (
          <CommentItem
            key={comment.id}
            id={comment.id}
            name={comment.author.name}
            surname={comment.author.surname}
            authorId={comment.author.id}
            createdAt={comment.createdAt}
            updatedAt={comment.updatedAt}
            content={comment.comment}
          />
        ))}
      </Stack>
      <AddComment />
    </>
  );
});
