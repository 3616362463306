import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, FieldPath, useFormContext } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Stack,
  TextField, TextFieldProps, Typography,
} from '@material-ui/core';
import { DatePicker, LoadingButton } from '@material-ui/lab';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { CreateConsumerValues, Option } from '../../../types';
import { COMMON_PICKER_DATE_VIEW, DEFAULT_AUTOCOMPLETE_ID, List } from '../../../constants';
import { formatPickerValue, getOption } from '../../../utils';
import { useAutocomplete } from '../../../hooks';

type FormProps = {
    onAction: (values: CreateConsumerValues) => Promise<void>,
}

export const Form: React.FC<FormProps> = observer(({ onAction }): JSX.Element => {
  const {
    control, handleSubmit, formState: { isSubmitting }, setValue,
  } = useFormContext();

  const countries = useAutocomplete(List.Countries);
  const models = useAutocomplete(List.Models);
  const colors = useAutocomplete(List.Colors);
  const cities = useAutocomplete(List.Cities);
  const shops = useAutocomplete(List.Shops);

  const handleChangeSelectValue = useCallback((name: FieldPath<CreateConsumerValues>) => (_,
    data: Option | null): void => {
    setValue(name, data?.value || DEFAULT_AUTOCOMPLETE_ID);
  }, []);

  return (
    <form onSubmit={handleSubmit(onAction)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>Contact Info</Typography>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Name"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Surname"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="phone"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Phone number"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Email address"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="city"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="City"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="country.id"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(countries, value)}
                      options={countries}
                      onChange={handleChangeSelectValue('country.id')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Country"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Stack spacing={3}>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary', paddingTop: '24px' }}>Registration</Typography>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="model.id"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(models, value)}
                      options={models}
                      onChange={handleChangeSelectValue('model.id')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Model"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="color.id"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(colors, value)}
                      options={colors}
                      onChange={handleChangeSelectValue('color.id')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Color"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="serialNumber"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Serial number"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="price"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Price"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="sellerCountryId"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(countries, value)}
                      options={countries}
                      onChange={handleChangeSelectValue('sellerCountry.id')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Country"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="sellerCity.id"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(cities, value)}
                      options={cities}
                      onChange={handleChangeSelectValue('sellerCity.id')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="City"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="shop.id"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(shops, value)}
                      options={shops}
                      onChange={handleChangeSelectValue('shop.id')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Shop"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="datePurchase"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat={COMMON_PICKER_DATE_VIEW}
                        views={['day']}
                        label="Date purchase"
                        value={value}
                        onChange={(e): void => {
                          onChange(formatPickerValue(e));
                        }}
                        renderInput={(props: TextFieldProps): JSX.Element => (
                          <TextField
                            {...props}
                            fullWidth
                            margin="normal"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
});
