import { Color, ReclamationStatus } from '../../../constants';

type Status = {
    readonly title: string,
    readonly key: ReclamationStatus,
    readonly color: Color
}

export const STATUSES: readonly Status[] = [
  {
    title: 'New',
    key: ReclamationStatus.NEW,
    color: Color.PRIMARY,
  },
  {
    title: 'Pending',
    key: ReclamationStatus.PENDING,
    color: Color.INFO,
  },
  {
    title: 'Resolved',
    key: ReclamationStatus.RESOLVED,
    color: Color.SECONDARY,
  },
  {
    title: 'Closed',
    key: ReclamationStatus.CLOSED,
    color: Color.WARNING,
  },
] as const;
