import { Theme } from '@material-ui/core/styles';

type FabReturn = { MuiFab: {
  defaultProps: { color: string };
  variants: { style: { boxShadow: string; '&:hover': { backgroundColor: string } }; props: { color: string } }[];
  styleOverrides: {
    root: {
      boxShadow: string;
      '&:hover': { boxShadow: string; backgroundColor: string } };
    extended: { '& svg': { marginRight: string } }; primary: Record<string, never>
  }
} }
export const Fab = (theme: Theme): FabReturn => ({
  MuiFab: {
    defaultProps: {
      color: 'primary',
    },

    variants: [
      {
        props: { color: 'primary' },
        style: {
          boxShadow: theme.customShadows.primary,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
    ],

    styleOverrides: {
      root: {
        boxShadow: theme.customShadows.z8,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.palette.grey[400],
        },
      },
      primary: {},
      extended: {
        '& svg': {
          marginRight: theme.spacing(1),
        },
      },
    },
  },
});
