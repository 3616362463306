import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Box, Stack, Container, Typography,
} from '@material-ui/core';
import { Page } from '../../../components';
import { LoginForm } from './LoginForm';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export const Login: React.FC = (): JSX.Element => (
  <RootStyle title="Login | Minimal-UI">
    <Container maxWidth="sm">
      <ContentStyle>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              Sign In
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Box>
        </Stack>
        <LoginForm />
      </ContentStyle>
    </Container>
  </RootStyle>
);
