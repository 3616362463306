import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { ResponseStatuses } from '../constants';
import { GeneralApiProblem } from '../services/api/api-problem';

type UseRequestPayload<T> = {
  action(params?: T): Promise<void>,
  params?: T,
  condition?: boolean,
}

type UseRequestParams = {
  isFetching: boolean,
  isError: number | null,
}

const NOT_FOUND = 'not-found';
const FORBIDDEN = 'forbidden';

export function useRequest<T>({
  action,
  params,
  condition = true,
}: UseRequestPayload<T>): UseRequestParams {
  const [isFetching, setIsFetching] = useState<boolean>(true); // set true to prevent re-renders
  const [isError, setIsError] = useState<number | null>(null);

  useEffect(() => {
    async function fetch(): Promise<void> {
      setIsFetching(true);
      if (condition) {
        try {
          await action(params);
        } catch (e) {
          const error = e as GeneralApiProblem;
          if (error.kind === NOT_FOUND) {
            setIsError(ResponseStatuses.NOT_FOUND);
          } else if (error.kind === FORBIDDEN) {
            setIsError(ResponseStatuses.FORBIDDEN);
          } else {
            toast.error('Error with getting item');
            console.error(e);
          }
        }
      }
      setIsFetching(false);
    }
    fetch();
  }, []);
  return { isFetching, isError };
}
