import React from 'react';
import { Container } from '@material-ui/core';
import { PATH_DASHBOARD } from '../../app';
import { Page, HeaderBreadcrumbs } from '../../components';
import { StrollersForm } from './StrollersForm';

const BREADCRUMB_LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Registered strollers list', href: PATH_DASHBOARD.strollers.list },
  { name: 'Registered stroller' },
];

export const StrollersUpdate: React.FC = (): JSX.Element => (
  <Page title="Strollers: Edit registered stroller | Minimal-UI">
    <Container maxWidth="lg">
      <HeaderBreadcrumbs
        heading="Edit registered stroller"
        links={BREADCRUMB_LINKS}
      />
      <StrollersForm />
    </Container>
  </Page>
);
