import React, { useState } from 'react';
import {
  Card, CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import {
  AccordionWrapper,
  CommonListHead,
  NotFound,
} from '../../../components';
import { TABLE_HEAD_LIST } from './constants';
import { useStore } from '../../../hooks';
import { ClaimsItem } from './ClaimsItem';
import { ClaimsToolbar } from './ClaimsToolbar';
import { FIRST_PAGE } from '../../../constants';

const NOT_FOUND_MESSAGE = 'There are no result on this filter(s)';

export const Claims: React.FC = observer((): JSX.Element => {
  const {
    reports: {
      allClaimsReport: {
        items,
        meta,
      },
    },
  } = useStore();

  const [page, setPage] = useState<number>(FIRST_PAGE);

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  };

  return (
    <Card>
      <CardHeader title="Report 1" />
      <AccordionWrapper>
        <ClaimsToolbar
          page={page}
        />
      </AccordionWrapper>
      <TableContainer>
        <Table>
          <CommonListHead headListData={TABLE_HEAD_LIST} />
          <TableBody>
            {items?.length ? (
              <>
                {items.map((item) => (
                  <ClaimsItem
                    key={item.id}
                    data={item}
                  />
                ))}

                <TableRow>
                  <TableCell colSpan={13} />
                </TableRow>
              </>

            ) : (
              <TableRow>
                <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                  <NotFound message={NOT_FOUND_MESSAGE} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!items?.length && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={meta.totalItems}
          rowsPerPage={meta.itemsPerPage}
          page={meta.currentPage - 1}
          onPageChange={handlePageChange}
        />
      )}
    </Card>
  );
});
