import React, { useCallback, useEffect } from 'react';
import {
  Autocomplete, Grid, IconButton, TextField, TextFieldProps,
} from '@material-ui/core';
import DateFnsUtils from '@material-ui/lab/AdapterDateFns';
import {
  LoadingButton, LocalizationProvider, DatePicker,
} from '@material-ui/lab';
import {
  Controller, FieldPath, useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@material-ui/icons/Clear';
import { formatPickerValue, getOption, isValidDate } from '../../../utils';
import {
  COMMON_PICKER_DATE_VIEW,
  COMMON_PICKER_MASK,
  DEFAULT_RECLAMATION_FILTERS,
  List,
  RECLAMATION_STATUSES,
  FIRST_PAGE, DEFAULT_AUTOCOMPLETE_ID,
} from '../../../constants';
import {
  Filters, Option, ReclamationsFilters, ReclamationsFormValues,
} from '../../../types';
import { useAutocomplete, useStore } from '../../../hooks';
import rules from './rules';
import { ClearableAutocomplete } from '../../../components';

type ReclamationToolbarProps = {
    setFilters: (params: ReclamationsFilters) => void,
}

export const ReclamationToolbar: React.FC<ReclamationToolbarProps> = ({
  setFilters,
}): JSX.Element => {
  const countries = useAutocomplete(List.Countries);
  const models = useAutocomplete(List.Models);

  const {
    reclamations: {
      changeFilters,
      getFilters: commonFilters,
      changePage,
    },
  } = useStore();

  const {
    control, setValue, handleSubmit, reset,
  } = useForm<ReclamationsFormValues>({
    defaultValues: { ...commonFilters },
    resolver: yupResolver(rules),
  });

  const handleChangeSelectValue = useCallback(
    function callback(name: FieldPath<ReclamationsFormValues>) {
      return function setter(
        _,
        data: Option<number | string> | null,
      ): void {
        setValue(name, data?.value);
      };
    }, [],
  );

  const handleFilter = (values: ReclamationsFilters): void => {
    const params = {} as Filters;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(values)) {
      if (value && value !== '') {
        params[key] = value;
      }
    }
    setFilters(params);
    changeFilters(params);
  };

  const handleReset = useCallback((): void => {
    reset(DEFAULT_RECLAMATION_FILTERS);
    changeFilters(DEFAULT_RECLAMATION_FILTERS);
    setFilters({});
    changePage(FIRST_PAGE);
  }, []);

  useEffect(() => {
    reset({
      ...commonFilters,
    });
  }, [commonFilters]);

  return commonFilters && countries.length ? (
    <form onSubmit={handleSubmit(handleFilter)}>
      <Grid
        container
        xs={12}
        spacing={3}
        sx={{
          ml: '2px', mt: '2px', mr: '2px', mb: '26px', paddingRight: '26px',
        }}
      >
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="id"
            render={({
              field: { onBlur, onChange, value }, fieldState: { error },
            }): JSX.Element => (
              <TextField
                fullWidth
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label="Id"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { onBlur, onChange, value }, fieldState: { error },
            }): JSX.Element => (
              <TextField
                fullWidth
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label="First name"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="lastName"
            render={({
              field: { onBlur, onChange, value }, fieldState: { error },
            }): JSX.Element => (
              <TextField
                fullWidth
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label="Last name"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="shopName"
            render={({
              field: { onBlur, onChange, value }, fieldState: { error },
            }): JSX.Element => (
              <TextField
                fullWidth
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label="Shop name"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="phone"
            render={({
              field: { onBlur, onChange, value }, fieldState: { error },
            }): JSX.Element => (
              <TextField
                fullWidth
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label="Phone"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { onBlur, onChange, value }, fieldState: { error },
            }): JSX.Element => (
              <TextField
                fullWidth
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label="Email"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="status"
            render={({
              field: { value }, fieldState: { error },
            }): JSX.Element => (
              <ClearableAutocomplete value={value}>
                {(ref): JSX.Element => (
                  <Autocomplete
                    ref={ref}
                    autoComplete={false}
                    fullWidth
                    value={getOption<string>(RECLAMATION_STATUSES, value)}
                    options={RECLAMATION_STATUSES}
                    onChange={handleChangeSelectValue('status')}
                    getOptionLabel={(option: Option<string>): string => option.label}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        {...params}
                        size="small"
                        label="Status"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </ClearableAutocomplete>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="countryId"
            control={control}
            render={({
              field: { value }, fieldState: { error },
            }): JSX.Element => (
              <ClearableAutocomplete value={value}>
                {(ref): JSX.Element => (
                  <Autocomplete
                    ref={ref}
                    fullWidth
                    autoComplete={false}
                    value={getOption(countries, Number(value))}
                    options={countries}
                    defaultValue={{
                      label: getOption(countries, Number(value))?.label || '',
                      value: getOption(countries, Number(value))?.value || DEFAULT_AUTOCOMPLETE_ID,
                    }}
                    onChange={handleChangeSelectValue('countryId')}
                    getOptionLabel={(option: Option): string => option.label}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        {...params}
                        size="small"
                        label="Country"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </ClearableAutocomplete>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="modelId"
            control={control}
            render={({
              field: { value }, fieldState: { error },
            }): JSX.Element => (
              <ClearableAutocomplete value={value}>
                {(ref): JSX.Element => (
                  <Autocomplete
                    ref={ref}
                    autoComplete={false}
                    fullWidth
                    value={getOption(models, Number(value))}
                    options={models}
                    onChange={handleChangeSelectValue('modelId')}
                    getOptionLabel={(option: Option): string => option.label}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        {...params}
                        size="small"
                        label="Model"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </ClearableAutocomplete>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="dateFrom"
            control={control}
            render={({
              field: { value, onChange },
            }): JSX.Element => (
              <LocalizationProvider dateAdapter={DateFnsUtils}>
                <DatePicker
                  inputFormat={COMMON_PICKER_DATE_VIEW}
                  mask={COMMON_PICKER_MASK}
                  views={['day']}
                  label="Date from"
                  value={value}
                  onChange={(e): void => {
                    if (isValidDate(e)) {
                      onChange(formatPickerValue(e));
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        onClick={(): void => onChange(null)}
                        disabled={!value}
                        style={{ order: 1 }}
                      >
                        <ClearIcon color="disabled" fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  InputAdornmentProps={{
                    position: 'end',
                    style: { order: 2, marginLeft: 0 },
                  }}
                  renderInput={(params: TextFieldProps): JSX.Element => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="dateTo"
            control={control}
            render={({
              field: { value, onChange },
            }): JSX.Element => (
              <LocalizationProvider dateAdapter={DateFnsUtils}>
                <DatePicker
                  inputFormat={COMMON_PICKER_DATE_VIEW}
                  mask={COMMON_PICKER_MASK}
                  views={['day']}
                  label="Date to"
                  value={value}
                  onChange={(e): void => {
                    if (isValidDate(e)) {
                      onChange(formatPickerValue(e));
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        onClick={(): void => onChange(null)}
                        disabled={!value}
                        style={{ order: 1 }}
                      >
                        <ClearIcon color="disabled" fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  InputAdornmentProps={{
                    position: 'end',
                    style: { order: 2, marginLeft: 0 },
                  }}
                  renderInput={(params: TextFieldProps): JSX.Element => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <LoadingButton fullWidth type="submit" variant="contained">
            Filter
          </LoadingButton>
        </Grid>
        <Grid item xs={12} md={2}>
          <LoadingButton fullWidth onClick={handleReset} variant="outlined">
            Reset
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  ) : (<></>);
};
