import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { UNPROTECTED_ROUTES } from './unprotectedRoutes';
import { PROTECTED_ROUTES } from './protectedRoutes';
import { PrivateRoute } from './PrivateRoute';

// todo: add isProtected and make one config

export const Routing: React.FC = (): JSX.Element => (
  <Switch>
    {PROTECTED_ROUTES.map((item) => (
      <PrivateRoute
        key={item.path}
        {...item}
      />
    ))}
    {UNPROTECTED_ROUTES.map((item) => (
      <Route
        key={item.path}
        {...item}
      />
    ))}
  </Switch>
);
