import React, { useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { HeaderBreadcrumbs, Page } from '../../components';
import { PATH_DASHBOARD } from '../../app';
import { CustomerCareRegisteredCustomers } from './components/CustomerCareRegisteredCustomers';
import { CustomerCareAverageTimeProcessing } from './components/CustomerCareAverageTimeProcessing';
import { CustomerCareProcessedReclamation } from './components/CustomerCareProcessedReclamation';
import { CustomerCareCSAT } from './components/CustomerCareCSAT';
import { useAutocomplete } from '../../hooks';
import { List } from '../../constants';

const BREADCRUMB_LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Customer Care' },
];

export const CustomerCare: React.FC = () => {
  useAutocomplete(List.Countries);
  useAutocomplete(List.Models);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="Customer Care | Minimal-UI">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Customer Care" links={BREADCRUMB_LINKS} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <CustomerCareRegisteredCustomers />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomerCareAverageTimeProcessing />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomerCareProcessedReclamation />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomerCareCSAT />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
