import React, {
  ChangeEvent,
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import {
  Toolbar,
  TextField,
  Autocomplete,
  Grid, Checkbox, FormControlLabel, Typography,
} from '@material-ui/core';
import { toast } from 'react-hot-toast';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';
import { LoadingButton } from '@material-ui/lab';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import { mapEntityToOption } from '../../../../utils';
import {
  ClaimsListToolbarProps, DeviationCode, Option,
} from '../../../../types';
import { useAutocomplete, useStore } from '../../../../hooks';
import { COMMON_SCHEMA, List } from '../../../../constants';
import { getFileByCountriesReport, getFileByCountriesReportPdf } from '../../../../services';
import { WAY_OF_SOLUTION } from '../../Claims/ClaimsToolbar';

const LIMIT = 100;
const DEFAULT_PARAMS = { limit: LIMIT };

const RootStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: '0px !important',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 0, 3),
}));

const SEARCH_TIMEOUT = 400;

export const GroupedClaimsToolbar:React.FC<ClaimsListToolbarProps> = observer(({
  page,
}): JSX.Element => {
  const {
    deviationCodes: { fetchDeviationCodes },
    reports: {
      fetchAllCountriesReport,
      allCountriesReport: { items, meta },
    },
  } = useStore();

  const [deviationCodes, setDeviationCodes] = useState<Option[]>([]);
  const serviceModels = useAutocomplete(List.Models);
  const countries = useAutocomplete(List.Countries);
  const parts = useAutocomplete(List.StrollerParts);

  const [warrantyChecked, setWarrantyChecked] = useState<boolean>(false);
  const [searchCountry, setSearchCountry] = useState<number[] | null>(null);
  const [searchModel, setSearchModel] = useState<number[] | null>(null);
  const [searchPart, setSearchPart] = useState<number[] | null>(null);
  const [searchDeviationCode, setSearchDeviationCode] = useState<number[] | null>(null);
  const [searchSolution, setSearchSolution] = useState<string | undefined>('');
  const [isGeneratedFile, setIsGeneratedFile] = useState<boolean>(true);
  const [isGeneratedPdfFile, setIsGeneratedPdfFile] = useState<boolean>(true);

  const [debouncedSearchSolution] = useDebounce(searchSolution, SEARCH_TIMEOUT);

  const onFilterCountry = useCallback((newFilterCountry: number[] | null):void => {
    setSearchCountry(newFilterCountry);
  }, []);
  const onFilterModel = useCallback((newFilterModel: number[] | null):void => {
    setSearchModel(newFilterModel);
  }, []);
  const onFilterPart = useCallback((newFilterPart: number[] | null):void => {
    setSearchPart(newFilterPart);
  }, []);
  const onFilterDeviationCode = useCallback(
    (newFilterDeviationCode: number[] | null):void => {
      setSearchDeviationCode(newFilterDeviationCode);
    }, [],
  );
  const onFilterByWarranty = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newFilterWarranty: boolean):void => {
      setWarrantyChecked(newFilterWarranty);
    }, [],
  );

  const handleCountriesChange = useCallback((event: SyntheticEvent, values): void => {
    const customCountries = values.map((item) => item.value);
    onFilterCountry(customCountries);
  }, []);
  const handleModelsChange = useCallback((event: SyntheticEvent, values): void => {
    const customModels = values.map((item) => item.value);
    onFilterModel(customModels);
  }, []);
  const handlePartsChange = useCallback((event: SyntheticEvent, values): void => {
    const customParts = values.map((item) => item.value);
    onFilterPart(customParts);
  }, []);
  const handleDeviationCodesChange = useCallback((event: SyntheticEvent, values): void => {
    const customDeviationCodes = values.map((item) => item.value);
    onFilterDeviationCode(customDeviationCodes);
  }, []);

  const fetchDeviationCodesAutocomplete = useCallback(async () => {
    try {
      const codes = await fetchDeviationCodes({
        ...DEFAULT_PARAMS,
      }) as DeviationCode[];
      const options = codes.map((code) => mapEntityToOption<DeviationCode>(code, COMMON_SCHEMA));
      setDeviationCodes(options);
    } catch (e) {
      toast.error('Error with fetching deviation codes autocomplete');
    }
  }, []);

  const handleGenerateReportFile = useCallback(async (): Promise<void> => {
    setIsGeneratedFile(false);
    const result = await getFileByCountriesReport({
      warranty: Number(warrantyChecked) || null,
      countryId: searchCountry,
      solution: debouncedSearchSolution,
      modelId: searchModel,
      partId: searchPart,
      deviationCodeId: searchDeviationCode,
    });
    if (result) {
      setIsGeneratedFile(true);
      const buffer = new Int8Array(result.buffer.data);
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([buffer], { type: result.mimeType }),
      );
      a.setAttribute('download', 'all_countries_report.xlsx');
      document.body.appendChild(a);
      a.click();
    }
  }, [
    searchModel,
    warrantyChecked,
    searchCountry,
    searchPart,
    searchDeviationCode,
    debouncedSearchSolution,
  ]);

  const handleGenerateReportFilePdf = useCallback(async (): Promise<void> => {
    setIsGeneratedPdfFile(false);
    const result = await getFileByCountriesReportPdf({
      warranty: Number(warrantyChecked) || null,
      countryId: searchCountry,
      solution: debouncedSearchSolution,
      modelId: searchModel,
      partId: searchPart,
      deviationCodeId: searchDeviationCode,
    });
    if (result) {
      setIsGeneratedPdfFile(true);
      const link = `${process.env.REACT_APP_API_URL}files/pdf/${result}`;
      window.open(link, '_blank');
    }
  }, [
    searchModel,
    warrantyChecked,
    searchCountry,
    searchPart,
    searchDeviationCode,
    debouncedSearchSolution,
  ]);

  useEffect(() => {
    fetchDeviationCodesAutocomplete();
  }, []);

  useEffect(() => {
    fetchAllCountriesReport({
      warranty: Number(warrantyChecked) || null,
      countryId: searchCountry,
      solution: debouncedSearchSolution,
      modelId: searchModel,
      partId: searchPart,
      deviationCodeId: searchDeviationCode,
      page,
    });
  }, [
    searchModel,
    warrantyChecked,
    searchCountry,
    searchPart,
    searchDeviationCode,
    debouncedSearchSolution,
    page,
  ]);

  return (
    <>
      <RootStyle sx={{ paddingTop: '25px', paddingBottom: '25px', paddingRight: '0px !important' }}>
        <Grid
          container
          xs={12}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              size="small"
              autoComplete={false}
              fullWidth
              options={serviceModels}
              onChange={handleModelsChange}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Model" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              size="small"
              autoComplete={false}
              fullWidth
              options={countries}
              onChange={handleCountriesChange}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Country" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              size="small"
              autoComplete={false}
              fullWidth
              options={parts}
              onChange={handlePartsChange}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Part" />}
            />
          </Grid>
        </Grid>
      </RootStyle>
      <RootStyle sx={{ paddingBottom: '25px', paddingRight: '0px !important' }}>
        <Grid
          container
          xs={12}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              size="small"
              autoComplete={false}
              fullWidth
              options={deviationCodes}
              onChange={handleDeviationCodesChange}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Deviation code" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              size="small"
              autoComplete={false}
              fullWidth
              options={WAY_OF_SOLUTION}
              onChange={(e, value): void => setSearchSolution(value?.label)}
              getOptionLabel={(option: Option<string>): string => option.label}
              renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Solution" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              label="Show items with warranty"
              control={(
                <Checkbox
                  checked={warrantyChecked}
                  onChange={onFilterByWarranty}
                />
                )}
            />
          </Grid>
        </Grid>
      </RootStyle>
      <RootStyle sx={{ paddingBottom: '25px', paddingRight: '0px !important' }}>
        <Grid
          container
          xs={12}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <LoadingButton
              disabled={!items.length}
              variant="contained"
              startIcon={<Icon icon={fileTextFill} />}
              onClick={handleGenerateReportFile}
              loading={!isGeneratedFile}
            >
              Generate Excel file
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <LoadingButton
              disabled={!items.length}
              variant="contained"
              startIcon={<Icon icon={fileTextFill} />}
              onClick={handleGenerateReportFilePdf}
              loading={!isGeneratedPdfFile}
            >
              Generate PDF file
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" align="right">
              Total price for all countries:
              {' '}
              {meta.totalPriceForSelectedCountries}
            </Typography>
          </Grid>
        </Grid>
      </RootStyle>
    </>
  );
});
