import { Theme } from '@material-ui/core/styles';

type ButtonGroupReturn = {
  MuiButtonGroup: { variants: ({ style: { boxShadow: string }; props: { color: string; variant: string } } | { style: { boxShadow: string }; props: { color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'; variant: string } } | { style: { boxShadow: string; '& .MuiButtonGroup-grouped.Mui-disabled': { borderColor: string; color: string; '&.MuiButton-contained': { backgroundColor: string } } }; props: { disabled: boolean } })[]; styleOverrides: { root: { '&:hover': { boxShadow: string } } } }
}
export const ButtonGroup = (theme: Theme): ButtonGroupReturn => {
  type StyleContainedReturn = {
    props: { variant: string; color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'; };
    style: { boxShadow: string; };
  }
  const styleContained = (
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error',
  ): StyleContainedReturn => ({
    props: { variant: 'contained', color },
    style: { boxShadow: theme.customShadows[color] },
  });

  return {
    MuiButtonGroup: {
      variants: [
        {
          props: { variant: 'contained', color: 'inherit' },
          style: { boxShadow: theme.customShadows.z8 },
        },
        styleContained('primary'),
        styleContained('secondary'),
        styleContained('info'),
        styleContained('success'),
        styleContained('warning'),
        styleContained('error'),

        {
          props: { disabled: true },
          style: {
            boxShadow: 'none',
            '& .MuiButtonGroup-grouped.Mui-disabled': {
              color: theme.palette.action.disabled,
              borderColor: `${theme.palette.action.disabledBackground} !important`,
              '&.MuiButton-contained': {
                backgroundColor: theme.palette.action.disabledBackground,
              },
            },
          },
        },
      ],

      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  };
};
