import { PATH_DASHBOARD } from '../app';

export const LOCAL_STORAGE_DEVICE_TOKEN = 'deviceToken';

export const MAXIMUM_NOTIFICATIONS = 5;

export const RedirectRoutes = {
  user: PATH_DASHBOARD.user.root,
  reclamation: PATH_DASHBOARD.reclamations.root,
  message: PATH_DASHBOARD.chat.root,
};
