import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Container, Link, Typography } from '@material-ui/core';
import { PATH_AUTH } from '../../../app';
import { AuthLayout } from '../../../layouts/AuthLayout';
import { MHidden, Page } from '../../../components';
import { RegisterForm } from './RegisterForm';
import { TokenType } from '../../../types';
import { VerifyingWrapper } from '../VerifyingWrapper';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export const Register: React.FC = (): JSX.Element => (
  <VerifyingWrapper tokenType={TokenType.INVITATION}>
    <RootStyle title="Register | Minimal-UI">
      <AuthLayout>
        Already have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.login}>
          Login
        </Link>
      </AuthLayout>
      <Container>
        <ContentStyle>
          <RegisterForm />
          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Already have an account?&nbsp;
              <Link to={PATH_AUTH.login} component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  </VerifyingWrapper>
);
