import { Icon } from '@iconify/react';
import starFill from '@iconify/icons-eva/star-fill';
import { Theme } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';

// ----------------------------------------------------------------------

const ICON_SMALL = { width: 20, height: 20 };
const ICON_LARGE = { width: 28, height: 28 };

const ICON = (
  // eslint-disable-next-line react/react-in-jsx-scope
  <SvgIcon>
    {/* eslint-disable-next-line react/react-in-jsx-scope */}
    <Icon icon={starFill} />
  </SvgIcon>
);

type RatingReturn = {
  MuiRating: { defaultProps: { emptyIcon: JSX.Element; icon: JSX.Element }; styleOverrides: { root: { '&.Mui-disabled': { opacity: number } }; sizeSmall: { '& svg': { width: number; height: number } }; iconEmpty: { color: string }; sizeLarge: { '& svg': { width: number; height: number } } } }
}
export const Rating = (theme: Theme): RatingReturn => ({
  MuiRating: {
    defaultProps: {
      emptyIcon: ICON,
      icon: ICON,
    },

    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          opacity: 0.48,
        },
      },
      iconEmpty: { color: theme.palette.grey[500_48] },
      sizeSmall: { '& svg': { ...ICON_SMALL } },
      sizeLarge: { '& svg': { ...ICON_LARGE } },
    },
  },
});
