import { DEFAULT_ID } from './defaultValues';

export enum MessageTypes {
    TEXT = 'text',
    PICTURE = 'picture',
    VIDEO = 'video'
}

export const DEFAULT_CHANNEL = {
  id: DEFAULT_ID,
  firstName: '',
  lastName: '',
  avatar: null,
  email: null,
  address: null,
  phone: null,
  unreadMessagesCount: 0,
  files: [],
};

export enum SocketEventTypesEnum {
    CONVERSATION_CREATED = 'conversation_created',
    NEW_MESSAGE = 'new_message',
    RESET_UNREAD = 'reset_unread',
    READ_MESSAGES = 'read_messages',
    EDIT_MESSAGE = 'edit_message',
}
