import {
  flow,
  getEnv,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  AvatarValues,
  ChangePasswordValues,
  CheckTokenValues,
  ForgotPasswordValues,
  LoginFormValues,
  ProfileModel,
  ProfileValues,
  RegisterFormValues,
  ResetPasswordData,
} from '../types';
import { removeTokenFromStorage, setTokenToStorage } from '../utils/localStorage';
import { DEFAULT_PROFILE } from '../constants';

export const DEFAULT_STORE = {
  isAuth: false,
  isSentResetEmail: false,
  isResetPassword: false,
  profile: DEFAULT_PROFILE,
  isSetSocket: false,
};

export const AuthStoreModel = types.model('AuthStore')
  .props({
    isAuth: types.boolean,
    isSentResetEmail: types.boolean,
    isResetPassword: types.boolean,
    profile: ProfileModel,
    isSetSocket: types.boolean,
  })
  .actions((self) => ({
    login: flow(function* login(data: LoginFormValues, callback: () => void) {
      try {
        const res = yield getEnv(self).api.login(data);
        if (res.accessToken) {
          setTokenToStorage(res.accessToken);
          getEnv(self).api.setAuthHeader();
          self.isAuth = true;
          callback();
        }
      } catch (e) {
        toast.error('Invalid credentials');
      }
    }),
    register: flow(function* register(data: RegisterFormValues, callback: () => void) {
      try {
        const res = yield getEnv(self).api.register(data);
        if (res.accessToken) {
          setTokenToStorage(res.accessToken);
          getEnv(self).api.setAuthHeader();
          self.isAuth = true;
          callback();
        }
      } catch (e) {
        toast.error('Error with registration');
      }
    }),
    sendResetRequest: flow(function* sendResetRequest(data: ForgotPasswordValues) {
      try {
        yield getEnv(self).api.sendReset(data);
        self.isSentResetEmail = true;
      } catch (e) {
        toast.error('Error with sending reset link');
      }
    }),
    resetPassword: flow(function* resetPassword(data: ResetPasswordData) {
      try {
        yield getEnv(self).api.resetPassword(data);
        self.isResetPassword = true;
      } catch (e) {
        toast.error('Error with sending reset password');
      }
    }),
    checkToken: flow(function* checkToken(data: CheckTokenValues) {
      try {
        return yield getEnv(self).api.checkToken(data);
      } catch (e) {
        return e;
      }
    }),
    getProfile: flow(function* getProfile() {
      try {
        const data = yield getEnv(self).api.getProfile();
        self.profile = { ...data };
        self.isAuth = true;
      } catch (e) {
        toast.error('Error with getting profile data');
      }
    }),
    updateProfile: flow(function* updateProfile(values: ProfileValues) {
      try {
        const data = yield getEnv(self).api.updateProfile(values);
        self.profile = { ...self.profile, ...data };
        toast.success('Profile data was updated successfully');
      } catch (e) {
        toast.error('Error with updating profile');
      }
    }),
    uploadAvatar: flow(function* updateAvatar(values: AvatarValues) {
      try {
        self.profile.avatar = yield getEnv(self)
          .api.uploadAvatar(values);
        toast.success('Avatar uploaded successfully');
      } catch (e) {
        toast.error('Error with updating avatar');
      }
    }),
    removeAvatar: flow(function* removeAvatar() {
      try {
        yield getEnv(self).api.removeAvatar();
        self.profile.avatar = {
          id: null,
          file: null,
        };
        toast.success('Avatar was removed successfully');
      } catch (e) {
        toast.error('Error with removing avatar');
      }
    }),
    changePassword: flow(function* changePassword(data: ChangePasswordValues) {
      try {
        yield getEnv(self).api.changePassword(data);
        toast.success('Password was successfully changed');
      } catch (e) {
        toast.error('Error with changing password');
      }
    }),
    logOut(callback: () => void): void {
      try {
        removeTokenFromStorage();
        getEnv(self).socket.disconnect();
        self.isAuth = false;
        self.profile = DEFAULT_PROFILE;
        self.isSetSocket = false;
        callback();
      } catch (e) {
        toast.error('Something went wrong with log out');
      }
    },
    setSocket(): void {
      self.isSetSocket = true;
    },
  }));

/**
 * The AuthStore instance.
 */
export type AuthStore = Instance<typeof AuthStoreModel>

/**
 * The data of a AuthStore.
 */
export type AuthStoreSnapshot = SnapshotOut<typeof AuthStoreModel>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createAuthStoreModel = () => types.optional(AuthStoreModel, DEFAULT_STORE);
