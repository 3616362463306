import React, { memo } from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Box, InputAdornment, OutlinedInput, Toolbar, useMediaQuery,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';

type Config = {
  id: number,
  filterValue: string,
  setFilterValue: (newFilterValue: string) => void,
  placeholder: string,
}

type CommonFilterProps = {
  config: Config[]
  width?: string,
  children?: React.ReactNode,
}

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '48%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export const CommonFilters: React.FC<CommonFilterProps> = memo(({
  config,
  children,
  width,
}): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <RootStyle sx={{ width: width ?? isMobile ? '100%' : '60%' }}>
      {config.map(({
        id, filterValue, setFilterValue, placeholder,
      }) => (
        <SearchStyle
          key={id}
          value={filterValue}
          onChange={(e): void => setFilterValue(e.target.value)}
          placeholder={placeholder}
          startAdornment={(
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          )}
        />
      ))}
      {children}
    </RootStyle>
  );
});
