import * as yup from 'yup';
import {
  firstNameSchema,
  passwordSchema,
  surnameSchema,
} from '../../../utils';

const rules = yup.object({
  name: firstNameSchema(),
  surname: surnameSchema(),
  password: passwordSchema(),
  countryId: yup.number().required('Country is required'),
});

export default rules;
