export const PARTS_CONFIG = [
  {
    id: 0,
  },
  {
    id: 1,
  },
  {
    id: 2,
  },
];

export const SERVICE_MANAGER_UPDATE_FIELDS = [
  'status',
  'deliveryCountry',
  'deliveryCity',
  'deliveryStreet',
  'deliveryPostCode',
  'deliveryTrackingNumber',
  'deliveryService',
  'pickUpTrackingNumber',
  'pickupService',
  'pickUpDate',
];
