import React, { useCallback, useState } from 'react';
import {
  IconButton,
  Paper, Stack, Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import editOutline from '@iconify/icons-eva/edit-outline';
import closeFill from '@iconify/icons-eva/close-fill';
import { isEqual } from 'date-fns';
import { fDateTime } from '../../../utils';
import { useStore } from '../../../hooks';
import { QueryParams, ReclamationCommentValues } from '../../../types';
import { CommentForm } from '../CommentForm';

type CommentItemProps = {
  id: number,
  name: string,
  surname: string,
  authorId: number,
  content: string,
  createdAt: string,
  updatedAt: string,
}

export const CommentItem: React.FC<CommentItemProps> = observer(({
  id,
  name,
  surname,
  authorId,
  content,
  createdAt,
  updatedAt,
}): JSX.Element => {
  const { id: reclamationId } = useParams<QueryParams>();

  const {
    reclamations: {
      updateCommentByReclamationId,
    },
    auth: {
      profile,
    },
  } = useStore();

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleChangeEditState = (value: boolean) => (): void => setIsEdit(value);

  const isUpdated = !isEqual(new Date(createdAt), new Date(updatedAt));
  const isAuthor = authorId === profile.id;

  const handleSubmit = useCallback(async (values: ReclamationCommentValues) => {
    await updateCommentByReclamationId(reclamationId, id, values);
    setIsEdit(false);
  }, []);

  return !isEdit ? (
    <Stack direction="row" spacing={2}>
      <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ sm: 'center' }}
          justifyContent="space-between"
          sx={{ mb: 0.5 }}
        >
          <Typography variant="subtitle2">
            {name}
            {' '}
            {surname}
          </Typography>
          <Stack direction="row">
            <Typography variant="caption" sx={{ color: 'text.disabled' }}>
              {isUpdated && '(updated)'}
              {' '}
              {fDateTime(updatedAt)}
            </Typography>
            {isAuthor && (
            <Icon
              onClick={handleChangeEditState(true)}
              icon={editOutline}
              style={{
                cursor: 'pointer',
              }}
              width={16}
              height={16}
            />
            )}
          </Stack>
        </Stack>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {content}
        </Typography>
      </Paper>
    </Stack>
  )
    : (
      <CommentForm
        onSubmit={handleSubmit}
        comment={content}
        render={(): JSX.Element => (
          <IconButton onClick={handleChangeEditState(false)}>
            <Icon
              icon={closeFill}
              width={24}
              height={24}
            />
          </IconButton>
        )}
      />
    );
});
