import { ReclamationStatus } from './reclamation';
import {
  ReclamationsCountByPeriodsData,
  ReclamationsCountByStatusesData,
} from '../types';

export enum Period {
  MONTH = 'month',
  SIX_MONTHS = 'sixMonths',
  YEAR = 'year'
}

export const DEFAULT_RECLAMATIONS_COUNT_BY_PERIODS: ReclamationsCountByPeriodsData = {
  [Period.MONTH]: 0,
  [Period.SIX_MONTHS]: 0,
  [Period.YEAR]: 0,
};

export const DEFAULT_RECLAMATIONS_COUNT_BY_STATUSES: ReclamationsCountByStatusesData = {
  [ReclamationStatus.NEW]: 0,
  [ReclamationStatus.PENDING]: 0,
  [ReclamationStatus.RESOLVED]: 0,
  [ReclamationStatus.CLOSED]: 0,
};

export const DEFAULT_AVG_TIME = {
  totalAvgTime: 0,
  avgTimePerEntities: [],
};
