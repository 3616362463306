import {
  Instance, SnapshotOut, types, getEnv, flow, cast, getParent, applySnapshot,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  ListMetaDataModel,
  Message,
  MessageModel,
  MessageListData,
  MessageListParams,
  SendMessageData,
  UpdatedMessage, UpdateMessageData,
} from '../types';
import { DEFAULT_META, MessageTypes } from '../constants';
import { RootStore } from './RootStore';
import { updateMetaAfterAddingNewItem } from '../utils';

export const MessageStoreModel = types.model('MessageStore')
  .props({
    items: types.optional(types.array(MessageModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    editMessageById(data: UpdatedMessage): void {
      const itemIndex = self.items.findIndex((item) => item.id === data.id);
      if (itemIndex !== -1) {
        applySnapshot(self.items[itemIndex], {
          ...self.items[itemIndex],
          content: data.content,
          updatedAt: data.updatedAt,
          files: data.files,
        });
      }
    },
  }))
  .actions((self) => ({
    fetchMessages: flow(function* fetchChannels(params: MessageListParams) {
      try {
        const { items, meta }: MessageListData = yield getEnv(self).api.fetchMessages(params);
        self.items = cast([...self.items, ...items]);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting messages');
      }
    }),
    sendMessage: flow(function* sendMessage(data:SendMessageData) {
      try {
        const message = yield getEnv(self).api.sendMessage(data);
        const {
          addItemsToChannelAttachments,
          resetUnreadMessagesCountInSelectedChannel,
          moveChannelToTheTop,
        } = getParent<RootStore>(self).channels;
        self.items = cast([message, ...self.items]);
        self.meta = updateMetaAfterAddingNewItem(self.meta);
        resetUnreadMessagesCountInSelectedChannel();
        moveChannelToTheTop(message);
        if (message.type === MessageTypes.VIDEO || message.type === MessageTypes.PICTURE) {
          addItemsToChannelAttachments(message.files);
        }
      } catch (e) {
        toast.error('Error with sending message');
      }
    }),
    updateMessage: flow(function* updateMessage(id: number, data: UpdateMessageData) {
      try {
        const message = yield getEnv(self).api.updateMessage(id, data);
        self.editMessageById(message);
      } catch (e) {
        toast.error('Error with updating message');
      }
    }),
    saveMessageFromSocket(message: Message): void {
      const {
        channels: {
          selectedChannel: {
            id: channelId,
          },
          increaseUnreadMessagesCount,
          addItemsToChannelAttachments,
          readMessagesEvent,
        },
        auth: {
          profile,
        },
      } = getParent<RootStore>(self);

      if (channelId === message.channelId) { // set only, if chat is opened now
        self.items = cast([message, ...self.items]);
        self.meta = updateMetaAfterAddingNewItem(self.meta);
        increaseUnreadMessagesCount();
        if (message.type === MessageTypes.VIDEO || message.type === MessageTypes.PICTURE) {
          addItemsToChannelAttachments(message.files);
        }
      }
      if (!document.hidden) { // if page is not hidden, read messages
        readMessagesEvent({ channelId, userId: profile.id });
      }
    },
    reset(): void {
      self.items = cast([]);
      self.meta = DEFAULT_META;
    },
    editMessageById(data: UpdatedMessage): void {
      const itemIndex = self.items.findIndex((item) => item.id === data.id);
      if (itemIndex !== -1) {
        applySnapshot(self.items[itemIndex], {
          ...self.items[itemIndex],
          content: data.content,
          updatedAt: data.updatedAt,
          files: data.files,
        });
      }
    },
  }));

export type MessageStore = Instance<typeof MessageStoreModel>

export type MessageStoreSnapShot = SnapshotOut<typeof MessageStoreModel>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createMessageStoreModel = () => types.optional(MessageStoreModel, {});
