import { HeadCell } from '../../../types/grid';
import { Link } from '../../../types';

export const TABLE_HEAD_LIST: readonly HeadCell[] = [
  { id: 'title', label: 'Title' },
  { id: 'description', label: 'Description' },
] as const;

export const LINKS: Link[] = [];

export const EMPTY_LIST_MESSAGE = 'There are no hints for this claims';
