import { useCallback, useState } from 'react';
import { SortingOrder } from '../constants';

type SortingProps = {
  sort: string
  order: SortingOrder
  orderBy: string
  onClickSorting: (label: string) => void
}

export const useSorting = (defaultField = ''): SortingProps => {
  const [sort, setSort] = useState<string>(defaultField);
  const [order, setOrder] = useState<SortingOrder>(SortingOrder.ASC);
  const [orderBy, setOrderBy] = useState('orderBy');

  const onClickSorting = useCallback((label: string): void => {
    setOrderBy(label);
    setSort(order === SortingOrder.ASC ? `-${label}` : label);
    setOrder((prevValue) => (
      prevValue === SortingOrder.DESC ? SortingOrder.ASC : SortingOrder.DESC
    ));
  }, [order]);

  return {
    sort,
    order,
    orderBy,
    onClickSorting,
  };
};
