import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

// eslint-disable-next-line max-len
type ListsReturn = { MuiListItemText: { styleOverrides: { root: { marginBottom: number; marginTop: number }; multiline: { marginBottom: number; marginTop: number } } }; MuiListItemIcon: { styleOverrides: { root: { marginRight: string; color: string; minWidth: string } } }; MuiListItemAvatar: { styleOverrides: { root: { marginRight: string; minWidth: string } } } }
export const Lists = (theme: Theme): ListsReturn => ({
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit',
        minWidth: 'auto',
        marginRight: theme.spacing(2),
      },
    },
  },
  MuiListItemAvatar: {
    styleOverrides: {
      root: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        marginTop: 0,
        marginBottom: 0,
      },
      multiline: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
});
