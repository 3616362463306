import React, { FormEvent, useCallback, useState } from 'react';
import { Icon } from '@iconify/react';
import roundSend from '@iconify/icons-ic/round-send';
import attach2Fill from '@iconify/icons-eva/attach-2-fill';
import { styled } from '@material-ui/core/styles';
import {
  Box, Divider, IconButton, Input,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { MessageTypes } from '../../../constants';
import { FileAttachment } from './FileAttachment';
import { uploadFile, uploadVideo } from '../../../services';
import { FileData } from '../../../types';

const RootStyle = styled('form')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}));

const ACCEPT_TYPES = 'image/png, image/gif, image/jpeg, image/jpg, video/mp4, video/quicktime, video/mov';

export const MessageInput:React.FC = observer(() => {
  const {
    messages: {
      sendMessage,
    },
    auth: {
      profile,
    },
    channels: {
      selectedChannel,
    },
  } = useStore();

  const [message, setMessage] = useState<string>('');
  // use for src, should be undefined instead of null
  const [file, setFile] = useState<File | null>(null);

  const handleAttach = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as EventTarget & { files: FileList };
    const tempFile = target.files[0];
    setFile(tempFile);
  }, []);

  const handleSend = useCallback(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!message && !file) {
      return;
    }
    if (file) {
      const isVideo = file.type.includes('video');
      // eslint-disable-next-line max-len
      const fileData: FileData | undefined = !isVideo ? await uploadFile(file) : await uploadVideo(file);
      if (fileData?.id) {
        sendMessage({
          content: message,
          type: !isVideo ? MessageTypes.PICTURE : MessageTypes.VIDEO,
          authorId: profile.id,
          channelId: selectedChannel.id,
          fileId: fileData.id,
        });
      }
    } else {
      sendMessage({
        content: message,
        type: MessageTypes.TEXT,
        authorId: profile.id,
        channelId: selectedChannel.id,
      });
    }
    setMessage('');
    setFile(null);
  }, [file, message]);

  const handleRemove = useCallback((): void => {
    setFile(null);
  }, [file]);

  const handleClick = useCallback((e): void => {
    const target = e.target as HTMLInputElement;
    target.value = '';
  }, []);

  return (
    <>
      {file && (
      <>
        <FileAttachment name={file?.name || ''} onRemove={handleRemove} />
        <Divider />
      </>
      )}
      <RootStyle onSubmit={handleSend}>
        <Input
          fullWidth
          value={message}
          disableUnderline
          onChange={(e): void => setMessage(e.target.value)}
          placeholder="Type a message"
          sx={{ height: '100%' }}
        />
        <Box sx={{ flexShrink: 0, mr: 1.5, '& > *': { mx: 0.5 } }}>
          <IconButton htmlFor="message-file" component="label" size="small">
            <Icon icon={attach2Fill} width={24} height={24} />
          </IconButton>
        </Box>
        <Divider orientation="vertical" flexItem />
        <IconButton color="primary" disabled={!message && !file} type="submit" sx={{ mx: 1 }}>
          <Icon icon={roundSend} width={24} height={24} />
        </IconButton>
        <input
          accept={ACCEPT_TYPES}
          id="message-file"
          type="file"
          style={{ display: 'none' }}
          onChange={handleAttach}
          onClick={handleClick}
        />
      </RootStyle>
    </>
  );
});
