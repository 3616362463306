import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Stack, TextField, Autocomplete } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterFormValues, Option, RegisterData } from '../../../types';
import rules from './rules';
import { useRequest, useStore } from '../../../hooks';
import { PATH_DASHBOARD } from '../../../app';
import { PasswordInput } from '../../../components';
import { getOption } from '../../../utils';

type QueryParams = {
  token: string,
}

const DEFAULT_COUNTRY_VALUE = 1;

export const RegisterForm: React.FC = observer((): JSX.Element => {
  const {
    auth,
    countriesModel: {
      fetchOfficeCountries,
      officeCountriesAutoComplete,
    },
  } = useStore();
  const { push } = useHistory();
  const { token } = useParams<QueryParams>();

  const {
    control, setValue, handleSubmit,
  } = useForm<RegisterFormValues>({
    defaultValues: {
      name: '',
      surname: '',
      password: '',
      countryId: officeCountriesAutoComplete[0]?.value,
    },
    resolver: yupResolver(rules),
  });

  const handleChangeCountryId = useCallback((_, data: Option | null): void => {
    setValue('countryId', data?.value || DEFAULT_COUNTRY_VALUE);
  }, []);

  const handleRegister = (values: RegisterFormValues): void => {
    const data: RegisterData = { ...values, invitationToken: token };
    auth.register(data, () => push(PATH_DASHBOARD.root));
  };

  useRequest({
    action: fetchOfficeCountries,
    condition: !officeCountriesAutoComplete.length,
  });

  return (
    <form noValidate onSubmit={handleSubmit(handleRegister)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({
              field: { onBlur, onChange, value }, fieldState: { error },
            }): JSX.Element => (
              <TextField
                inputProps={{
                  autocomplete: 'given-name',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                fullWidth
                label="First name"
                type=""
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="surname"
            control={control}
            defaultValue=""
            render={({
              field: { onBlur, onChange, value }, fieldState: { error },
            }): JSX.Element => (
              <TextField
                inputProps={{
                  autocomplete: 'family-name',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                fullWidth
                label="Last name"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Stack>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({
            field: { onBlur, onChange, value }, fieldState: { error },
          }): JSX.Element => (
            <PasswordInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={error}
              // autoComplete="ViewCrunch"
              label="Password"
            />
          )}
        />
        <Controller
          name="countryId"
          control={control}
          render={({
            field: { value },
          }): JSX.Element => (
            <Autocomplete
              disableClearable
              autoComplete={false}
              fullWidth
              value={getOption(officeCountriesAutoComplete, value)}
              options={officeCountriesAutoComplete}
              onChange={handleChangeCountryId}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(params): JSX.Element => <TextField {...params} label="Country" />}
            />
          )}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Register
        </LoadingButton>
      </Stack>
    </form>
  );
});
