import React from 'react';
import { Card, Container } from '@material-ui/core';
import { PATH_DASHBOARD } from '../../app';
import { Page, HeaderBreadcrumbs } from '../../components';
import { ChatWindow } from './ChatWindow';
import { ChannelList } from './Channels';

export const Chat:React.FC = () => (
  <Page title="Chat | Minimal-UI">
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Chat"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Chat' }]}
      />
      <Card sx={{ height: '76vh', display: 'flex' }}>
        <ChannelList />
        <ChatWindow />
      </Card>
    </Container>
  </Page>
);
