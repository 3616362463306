import React, {
  useCallback,
  useEffect,
  useMemo, useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  TextFieldProps, IconButton, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import {
  Controller, FieldPath, useForm, FormProvider,
} from 'react-hook-form';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { DatePicker, LoadingButton } from '@material-ui/lab';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import { styled } from '@material-ui/core/styles';
import { useAutocomplete, useRequest, useStore } from '../../../hooks';
import { PARTS_CONFIG, SERVICE_MANAGER_UPDATE_FIELDS } from './constants';
import {
  COMMON_PICKER_DATE_VIEW,
  COMMON_PICKER_MASK,
  Data,
  DEFAULT_AUTOCOMPLETE_ID,
  List,
  QUALITY_REPORT,
  QUALITY_REPORTS,
  QualityReport,
  RECLAMATION_STATUSES,
  RECLAMATION_STATUSES_BY_ROLE,
  RECLAMATION_TYPE,
  DEFAULT_RECLAMATION, UserRole, DEFAULT_PARTS,
} from '../../../constants';
import {
  Option,
  QueryParams,
  ReclamationInfoValues,
  ReclamationPartValues, ReclamationStatusValues,
} from '../../../types';
import {
  FileAttachment, LightboxModal, OpenWindowField, Scrollbar,
} from '../../../components';
import {
  divideFilesByExt,
  fDate,
  fillParts,
  formatPickerValue,
  getOption,
  getUpdatingStatusFieldsFromReclamationValues,
  isValidDate,
  parseReclamationParts,
} from '../../../utils';
import rules from './rules';
import { ReclamationPart } from '../ReclamationPart';
import { Comments } from '../Comments';
import { StateWrapper } from '../../../components/common/StateWrapper';
import { PATH_DASHBOARD } from '../../../app';

export const DisabledTextField = styled(TextField)({
  '.Mui-disabled .MuiOutlinedInput-input': {
    '-webkit-text-fill-color': 'rgba(0, 0, 0) !important', // (default alpha is 0.38)
  },
});

const LIMIT = 100;

export const Information: React.FC = observer((): JSX.Element => {
  const { push } = useHistory();

  const {
    reclamations: {
      reclamation,
      getReclamationById,
      updateReclamation,
      updateReclamationStatus,
      isRealConsumer,
    },
    shopsModel: {
      fetchShops,
      shopsAutoComplete: shops,
    },
    serviceColors: {
      fetchServiceColors,
      colorsAutoComplete: colors,
    },
    users: {
      fetchUsers,
      usersAutoComplete: users,
    },
    auth: {
      profile: {
        role,
      },
    },
  } = useStore();

  const { id } = useParams<QueryParams>();

  const form = useForm<ReclamationInfoValues>({
    defaultValues: DEFAULT_RECLAMATION,
    resolver: yupResolver(rules),
  });

  const [isOpenedLightBoxModal, setIsOpenedLightBoxModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const handleOpenLightBoxModal = useCallback((index: number) => (): void => {
    setIsOpenedLightBoxModal(true);
    setSelectedImage(index);
  }, []);

  const handleCloseLightboxModal = useCallback((): void => {
    setIsOpenedLightBoxModal(false);
  }, []);

  const {
    control, reset, setValue, handleSubmit, formState: { isSubmitting },
  } = form;

  const fileUrls: Array<string> | undefined = useMemo(() => reclamation?.files.map(
    (file) => file.file,
  ),
  [reclamation]);

  const {
    images,
    videos,
  } = divideFilesByExt(fileUrls);

  const handleChangeSelectValue = useCallback((name: FieldPath<ReclamationInfoValues>) => (
    _,
    data: Data,
  ): void => {
    const value = data?.value !== 'undefined' ? data?.value : null;
    setValue(name, value);
  }, []);

  const handleChangeCountry = useCallback((_,
    data: Option | null) => {
    setValue('country.id', data?.value || DEFAULT_AUTOCOMPLETE_ID);
    fetchShops({ countryId: data?.value });
    fetchUsers({ responsible_country_ids: data?.value });
  }, []);

  const handleChangeManager = useCallback((_,
    data: Option | null) => {
    setValue('manager.id', data?.value || null);
  }, []);

  const handleChangeModel = useCallback((_,
    data: Option | null) => {
    setValue('model.id', data?.value || DEFAULT_AUTOCOMPLETE_ID);
    setValue('parts', DEFAULT_PARTS);
    fetchServiceColors({ modelId: data?.value, limit: LIMIT });
  }, []);

  const resetValues = (): void => {
    if (reclamation) {
      reset({
        country: {
          id: reclamation.country.id,
        },
        model: {
          id: reclamation.model.id,
        },
        color: {
          id: reclamation.color.id,
        },
        description: reclamation.description || '',
        shop: {
          id: reclamation.shop?.id,
        },
        deliveryCity: reclamation.deliveryCity || '',
        deliveryStreet: reclamation.deliveryStreet || '',
        deliveryPostCode: reclamation.deliveryPostCode || '',
        deliveryCost: reclamation.deliveryCost || '',
        deliveryCountry: {
          id: reclamation.deliveryCountry.id,
        },
        managerComment: reclamation.managerComment || '',
        manager: {
          id: reclamation.manager?.id || null,
        },
        pickUpTrackingNumber: reclamation.pickUpTrackingNumber || '',
        pickupService: reclamation.pickupService || '',
        pickUpDate: reclamation.pickUpDate,
        deliveryTrackingNumber: reclamation.deliveryTrackingNumber || '',
        deliveryService: reclamation.deliveryService || '',
        labourCost: reclamation.labourCost || '',
        qualityReport: reclamation.qualityReport,
        status: reclamation.status,
        parts: fillParts(reclamation.parts),
        dateSolution: reclamation.dateSolution,
        serialNumber: reclamation.serialNumber,
        notes: reclamation.notes,
        datePurchase: reclamation?.datePurchase,
        firstName: reclamation?.firstName,
        lastName: reclamation?.lastName,
        phone: reclamation?.phone,
        email: reclamation?.email,
      });
    }
  };

  const countries = useAutocomplete(List.Countries);
  const models = useAutocomplete(List.Models);
  const strollerParts = useAutocomplete(List.StrollerParts);

  const statusesByRole: Option<string>[] = useMemo(() => {
    const allowedStatuses = RECLAMATION_STATUSES_BY_ROLE[role] || [];
    return RECLAMATION_STATUSES.filter(({ value }) => allowedStatuses.includes(value));
  }, [role]);

  const isServiceManager = role === UserRole.SERVICE_MANAGER;

  const handleUpdateReclamation = useCallback(async (
    values: ReclamationInfoValues,
  ): Promise<void> => {
    if (!isServiceManager) {
      const parsedSpareParts: ReclamationPartValues[] = parseReclamationParts(values.parts);
      await updateReclamation(id, { ...values, parts: [...parsedSpareParts] });
      resetValues();
    } else {
      const data: ReclamationStatusValues = getUpdatingStatusFieldsFromReclamationValues(
        values,
        SERVICE_MANAGER_UPDATE_FIELDS,
      );
      await updateReclamationStatus(id, data);
    }
    push(PATH_DASHBOARD.reclamations.list);
  }, [isServiceManager]);

  const { isFetching, isError } = useRequest({
    action: getReclamationById,
    params: id,
    condition: reclamation?.id !== Number(id),
  });

  useEffect(() => {
    if (reclamation) {
      fetchShops({ countryId: reclamation.country.id, limit: LIMIT });
      fetchServiceColors({ modelId: reclamation.model.id, limit: LIMIT });
      fetchUsers({ responsible_country_ids: reclamation.country.id, limit: LIMIT });
      resetValues();
    }
  }, [reclamation]);

  const isReady = !!reclamation
    && !!countries?.length
    && !!models?.length
    && !!colors?.length
    && !!strollerParts?.length
    && !isFetching;

  return (
    <StateWrapper isError={isError} isReady={isReady}>
      <FormProvider {...form}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Id"
                      disabled
                      value={reclamation?.id}
                    />
                    <Controller
                      name="status"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          fullWidth
                          value={getOption<string>(RECLAMATION_STATUSES, value)}
                          options={statusesByRole}
                          onChange={handleChangeSelectValue('status')}
                          getOptionLabel={(option: Option<string>): string => option.label}
                          renderInput={(params): JSX.Element => (
                            <TextField
                              {...params}
                              label="Status"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="model.id"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          autoComplete={false}
                          disableClearable
                          fullWidth
                          value={getOption(models, value)}
                          options={models}
                          onChange={handleChangeModel}
                          getOptionLabel={(option: Option): string => option.label}
                          disabled={isServiceManager}
                          renderInput={(params): JSX.Element => (
                            <TextField
                              {...params}
                              label="Model"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="color.id"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          autoComplete={false}
                          fullWidth
                          disableClearable
                          value={getOption(colors, value)}
                          defaultValue={{
                            label: reclamation?.color?.name || '',
                            value: reclamation?.color?.id || DEFAULT_AUTOCOMPLETE_ID,
                          }}
                          options={colors}
                          onChange={handleChangeSelectValue('color.id')}
                          getOptionLabel={(option: Option): string => option.label}
                          disabled={isServiceManager}
                          renderInput={(params): JSX.Element => (
                            <TextField
                              {...params}
                              label="Color"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="country.id"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          fullWidth
                          value={getOption(countries, value)}
                          options={countries}
                          onChange={handleChangeCountry}
                          getOptionLabel={(option: Option): string => option.label}
                          disabled={isServiceManager}
                          renderInput={(params): JSX.Element => (
                            <TextField
                              {...params}
                              label="Country"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="shop.id"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          autoComplete={false}
                          fullWidth
                          value={getOption(shops, value)}
                          defaultValue={{
                            label: reclamation?.shop?.name || '',
                            value: reclamation?.shop?.id || DEFAULT_AUTOCOMPLETE_ID,
                          }}
                          options={shops}
                          onChange={handleChangeSelectValue('shop.id')}
                          getOptionLabel={(option: Option): string => option.label}
                          disabled={isServiceManager}
                          renderInput={(params): JSX.Element => (
                            <TextField
                              {...params}
                              label="Shop"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <TextField
                      fullWidth
                      label="Creation date"
                      disabled
                      value={reclamation?.createdAt ? fDate(reclamation?.createdAt) : ''}
                    />
                    <TextField
                      fullWidth
                      label="Type of user"
                      disabled
                      value={reclamation && (RECLAMATION_TYPE[reclamation?.userType].title)}
                    />
                    <Controller
                      name="manager.id"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          fullWidth
                          defaultValue={{
                            label: reclamation?.manager?.name || '',
                            value: reclamation?.manager?.id || DEFAULT_AUTOCOMPLETE_ID,
                          }}
                          value={getOption(users, value)}
                          options={users}
                          onChange={handleChangeManager}
                          disabled={isServiceManager}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(params): JSX.Element => (
                            <TextField
                              {...params}
                              label="Manager"
                              error={!!error}
                              helperText={error?.message}
                              disabled={isServiceManager}
                            />
                          )}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Usage time"
                      disabled
                      value={`${reclamation?.durationOfUse}`}
                    />
                    <Controller
                      name="serialNumber"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Serial number"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                          disabled={isServiceManager}
                        />
                      )}
                    />
                    <Controller
                      name="labourCost"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Labour cost"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                          disabled={isServiceManager}
                        />
                      )}
                    />
                    <Controller
                      name="qualityReport"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          autoComplete={false}
                          fullWidth
                          value={getOption<string>(QUALITY_REPORTS, value)}
                          defaultValue={{
                            label: QUALITY_REPORT[reclamation?.qualityReport || QualityReport.CLAIM_FROM_MARKET].title || '',
                            value: reclamation?.qualityReport || '',
                          }}
                          options={QUALITY_REPORTS}
                          onChange={handleChangeSelectValue('qualityReport')}
                          disabled={isServiceManager}
                          getOptionLabel={(option: Option<string>): string => option.label}
                          renderInput={(params): JSX.Element => (
                            <TextField
                              {...params}
                              label="Quality report"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="description"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          multiline
                          rows={5}
                          label="Description"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                          disabled={isServiceManager}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="notes"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <DisabledTextField
                          fullWidth
                          multiline
                          rows={5}
                          label="Client`s comment"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                          disabled
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      label="Closing date"
                      disabled
                      value={fDate(reclamation?.dateSolution || '')}
                      fullWidth
                    />
                    <Controller
                      name="datePurchase"
                      control={control}
                      render={({
                        field: { onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat={COMMON_PICKER_DATE_VIEW}
                            mask={COMMON_PICKER_MASK}
                            views={['day']}
                            label="Date purchase"
                            value={value}
                            onChange={(e): void => {
                              if (isValidDate(e)) {
                                onChange(formatPickerValue(e));
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  onClick={(): void => onChange(null)}
                                  disabled={!value}
                                  style={{ order: 1 }}
                                >
                                  <ClearIcon color="disabled" fontSize="small" />
                                </IconButton>
                              ),
                            }}
                            InputAdornmentProps={{
                              position: 'end',
                              style: { order: 2, marginLeft: 0 },
                            }}
                            renderInput={(props: TextFieldProps): JSX.Element => (
                              <TextField
                                {...props}
                                fullWidth
                                margin="normal"
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <OpenWindowField
                      label="Receipt"
                      value={reclamation?.check}
                      disabled
                    />
                  </Stack>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>Delivery</Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="deliveryCountry.id"
                      control={control}
                      render={({
                        field: { value }, fieldState: { error },
                      }): JSX.Element => (
                        <Autocomplete
                          disableClearable
                          autoComplete={false}
                          fullWidth
                          value={getOption(countries, value)}
                          options={countries}
                          onChange={handleChangeSelectValue('deliveryCountry.id')}
                          getOptionLabel={(option: Option): string => option.label}
                          renderInput={(params): JSX.Element => (
                            <TextField
                              {...params}
                              label="Delivery country"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="deliveryCity"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Delivery city"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="deliveryStreet"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Delivery street"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="deliveryPostCode"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Delivery postal code"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="deliveryTrackingNumber"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Delivery tracking number"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="deliveryService"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Delivery service"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="deliveryCost"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Logistic cost"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="firstName"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="First name"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                          disabled={isServiceManager}
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Last name"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                          disabled={isServiceManager}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="phone"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Phone number"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                          disabled={isServiceManager}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Email"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                          disabled={isServiceManager}
                        />
                      )}
                    />
                    <FormControlLabel
                      sx={{ minWidth: '32.5%' }}
                      control={<Checkbox checked={isRealConsumer} disabled={isServiceManager} />}
                      label="Registered stroller"
                    />
                  </Stack>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>Pick up</Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Controller
                      name="pickUpTrackingNumber"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Pick up tracking number"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="pickupService"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <TextField
                          fullWidth
                          label="Pick up service"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="pickUpDate"
                      control={control}
                      render={({
                        field: { onChange, value }, fieldState: { error },
                      }): JSX.Element => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat={COMMON_PICKER_DATE_VIEW}
                            mask={COMMON_PICKER_MASK}
                            views={['day']}
                            label="Pick up date"
                            value={value}
                            onChange={(e): void => {
                              if (isValidDate(e)) {
                                onChange(formatPickerValue(e));
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  onClick={(): void => onChange(null)}
                                  disabled={!value}
                                  style={{ order: 1 }}
                                >
                                  <ClearIcon color="disabled" fontSize="small" />
                                </IconButton>
                              ),
                            }}
                            InputAdornmentProps={{
                              position: 'end',
                              style: { order: 2, marginLeft: 0 },
                            }}
                            renderInput={(props: TextFieldProps): JSX.Element => (
                              <TextField
                                {...props}
                                fullWidth
                                margin="normal"
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Stack>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>Files</Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    {images.map((imageSrc, index) => (
                      <FileAttachment
                        key={`${uuidv4()}-${imageSrc}`}
                        fileUrl={imageSrc}
                        onClick={handleOpenLightBoxModal(index)}
                      />
                    ))}
                    {videos.map((videoSrc) => (
                      <FileAttachment
                        key={`${uuidv4()}-${videoSrc}`}
                        fileUrl={videoSrc}
                      />
                    ))}
                  </Stack>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>Parts</Typography>
                  <Scrollbar>
                    <Stack direction="row" spacing={{ xs: 3, sm: 2 }} sx={{ marginTop: '6px' }}>
                      {PARTS_CONFIG.map((part) => (
                        <Stack spacing={{ xs: 3, sm: 2 }} width="100%">
                          <ReclamationPart
                            id={part.id}
                            handleChangeSelectValue={handleChangeSelectValue}
                          />
                        </Stack>
                      ))}
                    </Stack>
                  </Scrollbar>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      value={reclamation?.totalPrice || ''}
                      disabled
                      label="Total price"
                    />
                  </Stack>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton onClick={handleSubmit(handleUpdateReclamation)} type="button" variant="contained" loading={isSubmitting}>
                      Save Changes
                    </LoadingButton>
                  </Box>
                  <Comments />
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <LightboxModal
        images={images}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={isOpenedLightBoxModal}
        onClose={handleCloseLightboxModal}
      />
    </StateWrapper>
  );
});
