import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type TimeLineReturn = {
  MuiTimelineDot: { styleOverrides: { root: { boxShadow: string } } };
  MuiTimelineConnector: { styleOverrides: { root: { backgroundColor: string } } }
}
export const Timeline = (theme: Theme): TimeLineReturn => ({
  MuiTimelineDot: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },

  MuiTimelineConnector: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.divider,
      },
    },
  },
});
