import React, { useCallback } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CommonMoreMenu, Label } from '../common';
import { PATH_DASHBOARD } from '../../app';
import { ReclamationItem as ReclamationItemType } from '../../types';
import { fDate, makeName } from '../../utils';
import { RECLAMATION_STATUS, RECLAMATION_STATUS_LABELS, UserRole } from '../../constants';
import { useStore } from '../../hooks';

type ReclamationItemProps = {
    data: ReclamationItemType,
    onDelete?: (id: number) => void,
}

const TableRowStyles = {
  cursor: 'pointer',
};

type CELL = {
    name: string,
    render?: (data: ReclamationItemType, cell: CELL) => string | JSX.Element,
    minWidth?: number,
}

const CELLS: CELL[] = [
  {
    name: 'id',
  },
  {
    name: 'createdAt',
    render: (data, cell): string => fDate(data[cell.name]),
  },
  {
    name: 'name',
    render: (data): string => makeName(data.firstName, data.lastName),
  },
  {
    name: 'model',
    render: (data, cell): string => data[cell.name]?.name || '-',
  },
  {
    name: 'partCodeDescriptions',
    render: (data, cell): string => data[cell.name].join(', ') || '-',
  },
  {
    name: 'shop',
    render: (data, cell): string => data[cell.name]?.name || '-',
  },
  {
    name: 'country',
    render: (data, cell): string => data[cell.name]?.name || '-',
  },
  {
    name: 'status',
    render: (data, cell): JSX.Element => (
      <Label
        color={RECLAMATION_STATUS_LABELS[data[cell.name]]}
        variant="ghost"
      >
        {RECLAMATION_STATUS[data[cell.name]]?.title}
      </Label>
    ),
  },
  {
    name: 'notes',
    render: (data, cell): string => data[cell.name] || '-',
    minWidth: 120,
  },
  {
    name: 'files',
    render: (data, cell): JSX.Element => data[cell.name].map((item, index) => (
      <>
        <a href={item} rel="noreferrer" target="_blank">
          {`File-${index + 1}`}
        </a>
        <br />
      </>
    )),
  },
  {
    name: 'commentCount',
    render: (data, cell): JSX.Element => (
      <Label
        variant="ghost"
        color="error"
      >
        {data[cell.name]}
      </Label>
    ),
  },
];

const TAG_LINK = 'A';
const UPDATING_LINK = PATH_DASHBOARD.reclamations.root;

export const ReclamationItem: React.FC<ReclamationItemProps> = observer(({
  data, onDelete,
}): JSX.Element => {
  const { push } = useHistory();

  const {
    auth: {
      profile,
    },
  } = useStore();

  const handleClick = useCallback((e: React.MouseEvent<HTMLTableCellElement>): void => {
    const target = e.target as HTMLTableCellElement;
    if (target.tagName !== TAG_LINK) {
      push(`${UPDATING_LINK}/${data.id}`);
    }
  }, []);

  return (
    <TableRow
      hover
      sx={TableRowStyles}
    >
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          align="center"
          onClick={handleClick}
        >
          {cell.render ? cell.render(data, cell) : data[cell.name]}
        </TableCell>
      ))}
      <TableCell>
        <CommonMoreMenu
          itemId={data.id}
          updatingLink={PATH_DASHBOARD.reclamations.root}
          onDelete={onDelete}
          isPermissionToDelete={profile.role === UserRole.ADMIN}
        />
      </TableCell>
    </TableRow>
  );
});
