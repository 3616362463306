import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import {
  Box,
  List,
  Badge,
  Button,
  Divider,
  Typography,
  ListSubheader, useMediaQuery,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@material-ui/core/styles';
import closeFill from '@iconify/icons-eva/close-fill';
import {
  MenuPopover,
  MIconButton,
  NotFound,
  Scrollbar,
} from '../../components';
import { useStore } from '../../hooks';
import { NotificationPopoverItem } from './NotificationsPopoverItem';
import { MAXIMUM_NOTIFICATIONS } from '../../constants';

export const NotificationsPopover:React.FC = observer(() => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    notifications: {
      unreadNotifications: {
        items,
      },
      fetchUnreadNotifications,
      fetchUnreadCount,
      unreadCount,
      markReadNotification,
    },
  } = useStore();

  useEffect(() => {
    fetchUnreadNotifications();
    fetchUnreadCount();
  }, [unreadCount]);

  const handleOpen = ():void => {
    setOpen(true);
  };

  const handleClose = ():void => {
    setOpen(false);
  };

  const onClickMarkRead = useCallback((id: number) => (
  ): void => {
    markReadNotification(id);
  }, []);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={unreadCount} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: isMobile ? '90%' : '360px',
        }}
      >
        <Box sx={{
          display: 'flex', alignItems: 'center', py: 2, px: 2.5,
        }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">Notifications</Typography>
              {isMobile && (
                <Icon
                  icon={closeFill}
                  onClick={handleClose}
                  width={24}
                />
              )}
            </Box>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have
              {' '}
              {unreadCount}
              {' '}
              unread messages
            </Typography>
          </Box>
        </Box>

        <Divider />
        {items.length ? (
          <Scrollbar sx={{ height: { xs: 360, sm: 630 } }}>
            <List
              disablePadding
              subheader={(
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  New
                </ListSubheader>
              )}
            >
              {items.slice(0, MAXIMUM_NOTIFICATIONS).map((item) => (
                <NotificationPopoverItem
                  key={item.id}
                  data={item}
                  onClick={onClickMarkRead}
                />
              ))}
            </List>
          </Scrollbar>
        ) : (
          <NotFound
            sx={{ p: 2 }}
            message="You don`t have new notifications"
          />
        )}

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="/dashboard/app">
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
});
