import { Icon } from '@iconify/react';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import roundQuestionAnswer from '@iconify/icons-ic/round-question-answer';
import messageSquareOutline from '@iconify/icons-eva/message-square-outline';
import { Link } from '../../types';
import { PATH_DASHBOARD } from '../../app';
import { TAB_ICON_PROPS } from '../../constants';
import { Information as InformationTab } from './Information';
import { Hints as HintsTab } from './Hints';
import { ReclamationChat } from './Chat';

export enum ReclamationValues {
  Information = 'information',
  Hints = 'hints',
  Chat = 'chat'
}

type ReclamationTab = {
  value: ReclamationValues,
  icon: JSX.Element,
  component: JSX.Element,
}

export const RECLAMATION_TABS: readonly Readonly<ReclamationTab>[] = [
  {
    value: ReclamationValues.Information,
    icon: <Icon icon={roundAccountBox} {...TAB_ICON_PROPS} />,
    component: <InformationTab />,
  },
  {
    value: ReclamationValues.Hints,
    icon: <Icon icon={roundQuestionAnswer} {...TAB_ICON_PROPS} />,
    component: <HintsTab />,
  },
  {
    value: ReclamationValues.Chat,
    icon: <Icon icon={messageSquareOutline} {...TAB_ICON_PROPS} />,
    component: <ReclamationChat />,
  },
] as const;

export const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Claims', href: PATH_DASHBOARD.reclamations.list },
  { name: 'Claim' },
];
