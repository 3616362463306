import * as yup from 'yup';
import {
  numberReqSchema, stringReqSchema,
} from '../../../utils';

const rules = yup.object({
  name: stringReqSchema('Name'),
  ordering: numberReqSchema('Order'),
  locales: yup.array(),
});

export default rules;
