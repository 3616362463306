import React from 'react';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';

import { Shop } from '../../types';
import { PATH_DASHBOARD } from '../../app';
import { CommonMoreMenu } from '../../components';
import { DEFAULT_ID } from '../../constants';
import { useOnItemClick } from '../../hooks/useOnItemClick';

type CELL = {
  name: string,
  render?: (data: Shop, cell: CELL) => string,
}

type ShopItemProps = {
  data: Shop,
}

const CELLS: CELL[] = [
  {
    name: 'id',
  },
  {
    name: 'name',
  },
  {
    name: 'address',
  },
  {
    name: 'emails',
    render: (data, cell): string => (data[cell.name].length ? data[cell.name].map((item) => (
      <span
        style={{ marginTop: 5, display: 'block' }}
      >
        {item}
      </span>
    )) : '-'),
  },
  {
    name: 'city',
    render: (data, cell): string => data[cell.name]?.name || '-',
  },
  {
    name: 'city',
    render: (data, cell): string => data[cell.name]?.country?.name || '-',
  },
];

const TableRowStyles = {
  cursor: 'pointer',
};

const UPDATING_LINK = PATH_DASHBOARD.shops.root;

export const ShopItem:React.FC<ShopItemProps> = ({
  data,
}): JSX.Element => {
  const { onItemClick } = useOnItemClick(UPDATING_LINK, data.id);

  return (
    <TableRow
      hover
      sx={TableRowStyles}
    >
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          onClick={onItemClick}
        >
          {cell.render ? cell.render(data, cell) : data[cell.name]}
        </TableCell>
      ))}
      <TableCell align="right">
        <CommonMoreMenu
          itemId={data.id || DEFAULT_ID}
          updatingLink={UPDATING_LINK}
        />
      </TableCell>
    </TableRow>
  );
};
