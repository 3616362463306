import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Container,
  Table,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableBody, Button, Autocomplete, TextField, Toolbar, OutlinedInput, Grid, InputAdornment, Box,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useHistory } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  CommonListHead,
  HeaderBreadcrumbs,
  Page,
  SearchNotFound,
  Scrollbar,
} from '../../../components';
import { LINKS, TABLE_HEAD_LIST } from './constants';
import { useAutocomplete, useStore } from '../../../hooks';
import { ConsumerItem } from './ConsumerItem';
import { useSorting } from '../../../hooks/useSorting';
import { FIRST_PAGE, List } from '../../../constants';
import { PATH_DASHBOARD } from '../../../app';
import { getOption } from '../../../utils';
import { Option } from '../../../types';

const SEARCH_TIMEOUT = 400;

const RootStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: '0px !important',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export const ConsumerList:React.FC = observer((): JSX.Element => {
  const {
    consumers: {
      fetchConsumers,
      items,
      meta,
    },
  } = useStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { push } = useHistory();
  const countries = useAutocomplete(List.Countries);

  const [page, setPage] = useState<number>(FIRST_PAGE);
  const [filterName, setFilterName] = useState<string>('');
  const [filterEmail, setFilterEmail] = useState<string>('');
  const [filterCountry, setFilterCountry] = useState<number | null>(null);
  const [debouncedFilterName] = useDebounce(filterName, SEARCH_TIMEOUT);
  const [debouncedFilterEmail] = useDebounce(filterEmail, SEARCH_TIMEOUT);

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  }, []);

  const {
    sort, order, orderBy, onClickSorting,
  } = useSorting('-created_at');

  useEffect(() => {
    fetchConsumers({
      name: debouncedFilterName,
      email: debouncedFilterEmail,
      countryId: filterCountry,
      page,
      sort,
    });
  }, [debouncedFilterEmail, page, debouncedFilterName, sort, filterCountry]);

  return (
    <Page title="Customer list">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Customer List"
          links={LINKS}
          action={(
            <Button
              variant="contained"
              onClick={(): void => push(PATH_DASHBOARD.consumers.create)}
              startIcon={<Icon icon={plusFill} />}
            >
              Create
            </Button>
          )}
        />
        <Card>
          <RootStyle sx={{ paddingTop: '25px', paddingBottom: '25px' }}>
            <Grid container spacing={3}>
              <Grid item spacing={4} xs={12} md={4}>
                <SearchStyle
                  size="medium"
                  value={filterName}
                  onChange={(e): void => setFilterName(e.target.value)}
                  placeholder="Name or Surname..."
                  startAdornment={(
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                    )}
                />
              </Grid>
              <Grid item spacing={4} xs={12} md={4}>
                <SearchStyle
                  size="medium"
                  value={filterEmail}
                  onChange={(e): void => setFilterEmail(e.target.value)}
                  placeholder="Email"
                  startAdornment={(
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                    )}
                />
              </Grid>
              <Grid item spacing={4} xs={12} md={4}>
                <Autocomplete
                  size="medium"
                  autoComplete={false}
                  fullWidth
                  value={filterCountry ? getOption(countries, filterCountry) : null}
                  options={countries}
                  onChange={(e, data: Option | null): void => {
                    setFilterCountry(data?.value || null);
                  }}
                  getOptionLabel={(option: Option): string => option.label}
                  renderInput={(autocompleteParams): JSX.Element => <TextField {...autocompleteParams} label="Country" />}
                />
              </Grid>
            </Grid>
          </RootStyle>
          <TableContainer>
            <Scrollbar>
              <Table>
                <CommonListHead
                  headListData={TABLE_HEAD_LIST}
                  order={order}
                  orderBy={orderBy}
                  onClick={onClickSorting}
                />
                <TableBody>
                  {items?.length ? (
                    items.map((item) => (
                      <ConsumerItem
                        key={`customer-${item.id}`}
                        data={item}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {!!items?.length && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            onPageChange={handlePageChange}
          />
          )}
        </Card>
      </Container>
    </Page>
  );
});
