import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import { useCollapseDrawer, useStore } from '../../hooks';
import { DashboardNavbar } from './DashboardNavbar';
import { DashboardSidebar } from './DashboardSidebar';
import { DEFAULT_ID, LOCAL_STORAGE_DEVICE_TOKEN } from '../../constants';
import {
  getItemFromStorage,
  getTokenFromStorage,
} from '../../utils/localStorage';
import { PATH_AUTH } from '../../app';
import { generateToken, onMessageListener } from '../../firebaseInit';
import { Notification } from './notifications/Notification';
import { MessageData } from '../../types/notifications';
import { LoadingScreen } from '../../components';
import { WarningNotification } from './notifications/WarningNotification';
import { socket, initSocket } from '../../utils';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const DashboardLayout:React.FC = observer(({ children }) => {
  const theme = useTheme();
  const store = useStore();

  const {
    auth: {
      profile,
      getProfile,
      setSocket,
      isSetSocket,
    },
    permissions: {
      modules,
      fetchModules,
    },
  } = store;

  const { pathname } = useLocation();
  const { push } = useHistory();

  const { collapseClick } = useCollapseDrawer();
  const [isOpened, setIsOpened] = useState<boolean>(false);

  useEffect(() => {
    if (store && !isSetSocket) {
      socket.auth = {
        token: `Bearer ${getTokenFromStorage()}`,
      };
      socket.connect();
      initSocket({ store });
      setSocket();
    }
  }, [store, isSetSocket]);

  // dashboard re-renders each time,
  // when we change route, so we check if profile was got from back-end
  useEffect(() => {
    if (profile.id === DEFAULT_ID) {
      getProfile();
    }
  }, [profile.id]);

  useEffect(() => {
    if (!modules?.length) {
      fetchModules();
    }
  }, [modules]);

  useEffect(() => {
    const token = getTokenFromStorage();
    if (!token) {
      push(PATH_AUTH.login);
    }
  }, [pathname]);

  // checking token, post it on backend and firebase message listener for push notifications
  const [isShow, setIsShow] = useState(false);
  const [messageData, setMessageData] = useState<MessageData | null>({
    data: null,
    notification: null,
  });

  const [token, setToken] = useState('');
  const {
    notifications: {
      postNotificationToken,
      fetchUnreadCount,
    },
  } = useStore();

  useEffect(() => {
    const deviceToken = getItemFromStorage(LOCAL_STORAGE_DEVICE_TOKEN);
    if (!deviceToken) {
      generateToken(setToken);
    }
  }, []);

  useEffect(() => {
    if (token) {
      postNotificationToken(token);
    }
  }, [token]);

  onMessageListener().then((payload) => {
    setIsShow(true);
    setMessageData({ notification: payload.notification || null, data: payload.data || null });
    fetchUnreadCount();
  }).catch((err) => console.log('failed: ', err));

  const onCloseNotification = (): void => {
    setIsShow(false);
  };

  return profile.id !== DEFAULT_ID ? (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={(): void => setIsOpened(true)} />
      <DashboardSidebar isOpenSidebar={isOpened} onCloseSidebar={(): void => setIsOpened(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && {
            ml: '102px',
          }),
        }}
      >
        {children}
      </MainStyle>
      {messageData?.data?.isOverdue ? (
        <WarningNotification
          isShow={isShow}
          messageData={messageData}
          onClose={onCloseNotification}
        />
      ) : (
        <Notification
          isShow={isShow}
          messageData={messageData}
          onClose={onCloseNotification}
        />
      )}
    </RootStyle>
  ) : (<LoadingScreen isFullScreen />);
});
