import { Instance, types } from 'mobx-state-tree';
import { ListMetaDataModel } from './CommonModel';
import { DEFAULT_META } from '../../constants';

export const NotificationItemModel = types.model({
  id: types.number,
  title: types.string,
  body: types.string,
  icon: types.maybeNull(types.string),
  isForAll: types.boolean,
  itemId: types.string,
  notificationType: types.string,
  createdAt: types.string,
  isOverdue: types.boolean,
});

export const NotificationsModel = types.model('NotificationsModel', {
  items: types.optional(types.array(NotificationItemModel), []),
  meta: types.optional(ListMetaDataModel, DEFAULT_META),
});

export type Notification = Instance<typeof NotificationItemModel>
