import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Drawer,
  Paper,
  useMediaQuery,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Icon } from '@iconify/react';
import { styled, useTheme } from '@material-ui/core/styles';
import peopleFill from '@iconify/icons-eva/people-fill';
import { useParams } from 'react-router-dom';
import { useSearch, useStore } from '../../../hooks';
import { FIRST_PAGE } from '../../../constants';
import { QueryParams } from '../../../types';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { MainList } from './MainList';

const RootStyle = styled('div')(({ theme }) => ({
  width: '40%',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  transition: theme.transitions.create('width'),
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const ChannelButtonStyle = styled('div')(({ theme }) => ({
  left: 0,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  outline: '0px',
  margin: '0px',
  flex: '0 0 auto',
  padding: '8px',
  overflow: 'visible',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  zIndex: 9,
  position: 'absolute',
  top: '81px',
  width: theme.spacing(4),
  height: theme.spacing(4),
  color: 'rgb(255, 255, 255)',
  boxShadow: theme.customShadows.z8,
  border: '0px',
  borderTopRightRadius: theme.shape.borderRadius,
  backgroundColor: 'rgb(0, 171, 85)',
  borderBottomRightRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  '&:hover': { backgroundColor: 'rgba(22, 138, 80, 0.76)' },
}));

export const ChannelList:React.FC = observer(() => {
  const {
    channels: {
      fetchChannels,
      meta: {
        totalPages,
        currentPage,
      },
    },
    auth: {
      profile,
    },
  } = useStore();

  const theme = useTheme();
  const { id } = useParams<QueryParams>();
  const channelsSideBarRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { debouncedSearchValue, setSearchValue, searchValue } = useSearch();
  const [page, setPage] = useState<number>(FIRST_PAGE);
  const [isOpenedSidebar, setIsOpenedSidebar] = useState<boolean>(true);
  const forbidScroll = debouncedSearchValue || totalPages === currentPage;

  const handleInfiniteScroll = (): void => {
    setPage(((prevState) => prevState + 1));
  };

  useEffect(() => {
    if (isMobile) {
      return setIsOpenedSidebar(false);
    }
    return setIsOpenedSidebar(true);
  }, [isMobile]);

  useEffect(() => {
    if (debouncedSearchValue) {
      setPage(FIRST_PAGE);
    }
    fetchChannels({ search: debouncedSearchValue, page, managerId: profile.id });
  }, [debouncedSearchValue, page, profile.id]);

  const toggleOpenSidebar = useCallback((): void => setIsOpenedSidebar((prev) => !prev), []);

  useOnClickOutside(channelsSideBarRef, toggleOpenSidebar);

  return isMobile ? (
    <RootStyle sx={{ width: 0 }}>
      <ChannelButtonStyle onClick={toggleOpenSidebar}>
        <Icon
          width={16}
          height={16}
          icon={peopleFill}
        />
      </ChannelButtonStyle>
      <Drawer anchor="left" open={isOpenedSidebar} variant="temporary">
        <Paper ref={channelsSideBarRef} sx={{ width: '320px', height: '100%' }}>
          <MainList
            isOpenedSidebar={isOpenedSidebar}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            toggleOpenSidebar={toggleOpenSidebar}
            handleInfiniteScroll={handleInfiniteScroll}
            forbidScroll={forbidScroll}
            id={id}
          />
        </Paper>
      </Drawer>
    </RootStyle>
  ) : (
    <RootStyle sx={{ ...(!isOpenedSidebar && { width: 96 }) }}>
      <MainList
        isOpenedSidebar={isOpenedSidebar}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        toggleOpenSidebar={toggleOpenSidebar}
        handleInfiniteScroll={handleInfiniteScroll}
        forbidScroll={forbidScroll}
        id={id}
      />
    </RootStyle>
  );
});
