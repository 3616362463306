import React from 'react';
import {
  Box,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Stack,
  Typography,
  Zoom,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import alertTriangleOutline from '@iconify/icons-eva/alert-triangle-outline';
import closeFill from '@iconify/icons-eva/close-fill';
import { NotificationProps } from '../../../types/notifications';

const warningNotificationStyles = {
  padding: '10px',
  display: 'flex',
  boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
  fontWeight: '400',
  color: '#fff',
  backgroundColor: 'rgb(211, 47, 47)',
};

export const WarningNotification: React.FC<NotificationProps> = ({
  isShow,
  messageData,
  onClose,
}): JSX.Element => (
  <Snackbar
    open={isShow}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    onClose={onClose}
    TransitionComponent={Zoom}
  >
    <Paper sx={warningNotificationStyles}>
      <Stack direction="row" alignItems="center">
        <ListItemIcon sx={{ alignItems: 'center', padding: '5px' }}>
          <Icon width="26px" icon={alertTriangleOutline} />
        </ListItemIcon>
        <ListItemText
          sx={{ width: '300px' }}
          primary={(
            <Box flexDirection="column">
              <Box>
                <Typography variant="subtitle2">
                  {messageData?.data?.title}
                </Typography>
                <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                  {messageData?.data?.body}
                </Typography>
              </Box>
              <Link
                href={messageData?.data?.link}
                variant="body2"
              >
                Click
              </Link>
            </Box>
                        )}
        />
      </Stack>
      <IconButton
        aria-label="close"
        color="inherit"
        sx={{ p: 0.5, height: '28px', width: '28px' }}
        onClick={onClose}
      >
        <Icon icon={closeFill} width={20} height={20} />
      </IconButton>
    </Paper>
  </Snackbar>
);
