import { Theme } from '@material-ui/core/styles';

type IconButtonReturn = { MuiIconButton: { styleOverrides: { root: Record<string, never> } } }

export const IconButton = (theme: Theme): IconButtonReturn => ({
  MuiIconButton: {
    styleOverrides: {
      root: {},
    },
  },
});
