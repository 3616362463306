import { Instance, types } from 'mobx-state-tree';
import { ReclamationItemModel } from './ReclamationModel';
import { ListMetaDataModel } from './CommonModel';
import { DEFAULT_META } from '../../constants';

export const HistoryModel = types.model('HistoryModel', {
  items: types.optional(types.array(ReclamationItemModel), []),
  meta: types.optional(ListMetaDataModel, DEFAULT_META),
});

export type History = Instance<typeof HistoryModel>
