import { useEffect } from 'react';
import { useStore } from './useStore';
import { ListParams, Option } from '../types';

const LIMIT = 200;

const params = { limit: LIMIT };

type AutoCompleteOptions = 'countries' | 'cities' | 'models' | 'colors' | 'shops' | 'strollerParts' | 'deviationCodes' | 'reclamations';

type AutoCompleteConfig = {
  [key in AutoCompleteOptions]: {
    action: (params: ListParams) => Promise<void>,
    result: Option[],
  }
}

const makeAutoCompleteConfig = (actions, results): AutoCompleteConfig => ({
  countries: {
    action: actions.fetchCountries,
    result: results.countriesAutoComplete,
  },
  cities: {
    action: actions.fetchCities,
    result: results.citiesAutoComplete,
  },
  models: {
    action: actions.fetchServiceModels,
    result: results.modelsAutoComplete,
  },
  colors: {
    action: actions.fetchServiceColors,
    result: results.colorsAutoComplete,
  },
  shops: {
    action: actions.fetchShops,
    result: results.shopsAutoComplete,
  },
  strollerParts: {
    action: actions.fetchStrollerParts,
    result: results.strollerPartsAutoComplete,
  },
  deviationCodes: {
    action: actions.fetchDeviationCodes,
    result: actions.deviationCodesAutoComplete,
  },
  reclamations: {
    action: actions.fetchReclamations,
    result: actions.reclamationsAutoComplete,
  },
});

export const useAutocomplete = (list: AutoCompleteOptions): Option[] => {
  const {
    countriesModel: {
      countriesAutoComplete,
      fetchCountries,
    },
    serviceModels: {
      modelsAutoComplete,
      fetchServiceModels,
    },
    serviceColors: {
      colorsAutoComplete,
      fetchServiceColors,
    },
    cities: {
      fetchCities,
      citiesAutoComplete,
    },
    shopsModel: {
      fetchShops,
      shopsAutoComplete,
    },
    strollersModel: {
      fetchStrollers,
      strollersAutoComplete,
    },
    strollerParts: {
      fetchStrollerParts,
      strollerPartsAutoComplete,
    },
    deviationCodes: {
      fetchDeviationCodes,
      deviationCodesAutoComplete,
    },
    reclamations: {
      fetchReclamations,
      reclamationsAutoComplete,
    },
  } = useStore();

  const actions = {
    fetchCountries,
    fetchServiceModels,
    fetchServiceColors,
    fetchCities,
    fetchShops,
    fetchStrollers,
    fetchStrollerParts,
    fetchDeviationCodes,
    fetchReclamations,
  };
  const results = {
    countriesAutoComplete,
    modelsAutoComplete,
    colorsAutoComplete,
    citiesAutoComplete,
    shopsAutoComplete,
    strollersAutoComplete,
    strollerPartsAutoComplete,
    deviationCodesAutoComplete,
    reclamationsAutoComplete,
  };
  const config: AutoCompleteConfig = makeAutoCompleteConfig(actions, results);

  useEffect(() => {
    const { action } = config[list];
    action(params);
  }, []);

  return config[list].result;
};
