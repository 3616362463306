import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createShopStoreModel } from './ShopStore';
import { createCityStoreModel } from './CityStore';
import { createUserStoreModel } from './UserStore';
import { createAuthStoreModel } from './AuthStore';
import { createCountryStoreModel } from './CountryStore';
import { createStrollerStoreModel } from './StrollerStore';
import { createConsumerStoreModel } from './ConsumerStore';
import { createServiceModelStoreModel } from './ServiceModelStore';
import { createServiceColorStoreModel } from './ServiceColorStore';
import { createModuleStoreModel } from './ModuleStore';
import { createKnowledgeBaseStoreModel } from './KnowledgeBaseStore';
import { createReclamationStoreModel } from './ReclamationStore';
import { createDeviationCodeStoreModel } from './DeviationCodeStore';
import { createStrollerPartsStoreModel } from './StrollerPartsStore';
import { createSparePartStoreModel } from './SparePartStore';
import { createLanguagesStoreModel } from './LanguagesStore';
import { createKanbanStoreModel } from './KanbanStore';
import { createReportStoreModel } from './ReportStore';
import { createNotificationsStoreModel } from './NotificationsStore';
import { createChannelStoreModel } from './ChannelStore';
import { createMessageStoreModel } from './MessageStore';

export const RootStoreModel = types.model('RootStore').props({
  shopsModel: createShopStoreModel(),
  countriesModel: createCountryStoreModel(),
  strollersModel: createStrollerStoreModel(),
  knowledgeBasesModel: createKnowledgeBaseStoreModel(),
  cities: createCityStoreModel(),
  users: createUserStoreModel(),
  auth: createAuthStoreModel(),
  consumers: createConsumerStoreModel(),
  serviceModels: createServiceModelStoreModel(),
  serviceColors: createServiceColorStoreModel(),
  permissions: createModuleStoreModel(),
  reclamations: createReclamationStoreModel(),
  deviationCodes: createDeviationCodeStoreModel(),
  strollerParts: createStrollerPartsStoreModel(),
  spareParts: createSparePartStoreModel(),
  languages: createLanguagesStoreModel(),
  kanban: createKanbanStoreModel(),
  reports: createReportStoreModel(),
  notifications: createNotificationsStoreModel(),
  channels: createChannelStoreModel(),
  messages: createMessageStoreModel(),
});

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
