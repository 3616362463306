import React, { useEffect, useCallback } from 'react';
import { Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { sortBy, uniqBy } from 'lodash';
import { HeaderBreadcrumbs, Page } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { Form } from '../Form';
import { useRequest, useStore } from '../../../hooks';
import {
  Link,
  QueryParams,
  StrollerPartValues,
} from '../../../types';
import rules from '../Form/rules';
import { StateWrapper } from '../../../components/common/StateWrapper';

const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Stroller parts', href: PATH_DASHBOARD.strollerParts.list },
  { name: 'Edit' },
];

export const UpdateStrollerPart: React.FC = observer((): JSX.Element => {
  const { push } = useHistory();
  const { id } = useParams<QueryParams>();

  const {
    languages: {
      items,
    },
    strollerParts: {
      updateStrollerPart,
      getStrollerPartById,
      fetchStrollerPartById,
    },
  } = useStore();

  const strollerPart = getStrollerPartById(id);

  const form = useForm<StrollerPartValues>({
    defaultValues: {
      name: '',
      ordering: 0,
      locales: items.map((language) => ({
        name: '',
        language: language.url,
      })),
    },
    resolver: yupResolver(rules),
  });

  const handleUpdate = useCallback(async (values: StrollerPartValues): Promise<void> => {
    await updateStrollerPart(id, values);
    push(PATH_DASHBOARD.strollerParts.list);
  }, [id]);

  const { isFetching, isError } = useRequest({
    action: fetchStrollerPartById,
    params: id,
    condition: !strollerPart,
  });

  useEffect(() => {
    if (strollerPart) {
      let locales = items.map((language) => ({
        name: '',
        language: language.url,
      }));
      const urls = items.map((language) => language.url);
      if (strollerPart?.locales?.length) {
        locales = uniqBy([...strollerPart.locales, ...locales], 'language').filter((item) => urls.includes(item.language));
      }
      const sortedLocales = sortBy(locales, function (item) {
        return urls.indexOf(item.language);
      });
      form.reset({
        name: strollerPart.name,
        ordering: strollerPart.ordering,
        locales: sortedLocales,
      });
    }
  }, [strollerPart, items.length]);

  const isReady = !!strollerPart && !isFetching;

  return (
    <Page title="Edit stroller part">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Edit stroller part"
          links={LINKS}
        />
        <FormProvider {...form}>
          <StateWrapper isError={isError} isReady={isReady}>
            <Form onAction={handleUpdate} />
          </StateWrapper>
        </FormProvider>
      </Container>
    </Page>
  );
});
