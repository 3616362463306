import React from 'react';
import { observer } from 'mobx-react-lite';
import { Card } from '@material-ui/core';
import { ChatWindow } from '../../Chat/ChatWindow';
import { useStore } from '../../../hooks';
import { NotFound } from '../../../components';

export const ReclamationChat: React.FC = observer((): JSX.Element => {
  const {
    reclamations: {
      reclamation,
    },
  } = useStore();

  return reclamation?.channel ? (
    <Card sx={{ height: '72vh', display: 'flex' }}>
      <ChatWindow channelId={reclamation.channel?.id} />
    </Card>
  ) : (
    <Card sx={{
      height: '72vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <NotFound message="There are no any dialogs with customer" />
    </Card>
  );
});
