import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  CommonListHead,
  ReclamationItem,
  NotFound,
} from '../../components';
import { TABLE_HEAD_LIST } from '../Reclamations/List/constants';
import { useStore } from '../../hooks';
import { QueryParams } from '../../types';
import { FIRST_PAGE } from '../../constants';

const EMPTY_HISTORY_MESSAGE = 'There is no history of this shop yet';

export const ShopHistory: React.FC = observer((): JSX.Element => {
  const {
    shopsModel: {
      getShopHistory,
      history: {
        items,
        meta: {
          totalItems,
          itemsPerPage,
          currentPage,
        },
      },
    },
  } = useStore();

  const { id } = useParams<QueryParams>();
  const [page, setPage] = useState<number>(FIRST_PAGE);

  useEffect(() => {
    getShopHistory({ page, shopId: id });
  }, [page, id]);

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  }, []);

  return (
    <Card>
      <TableContainer>
        <Table>
          <CommonListHead headListData={TABLE_HEAD_LIST} />
          <TableBody>
            {items.length ? (
              items.map((item) => (
                <ReclamationItem
                  key={item.id}
                  data={item}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                  <NotFound message={EMPTY_HISTORY_MESSAGE} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!items.length && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalItems}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
        />
      )}
    </Card>
  );
});
