import { ApiResponse, create } from 'apisauce';
import { getTokenFromStorage } from '../utils/localStorage';
import { ROUTES } from '../constants';
import { FileData } from '../types';
import { CommonResponse } from './api';
import { getGeneralApiProblem } from './api/api-problem';

const token = getTokenFromStorage();

export const fileService = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: token ? `Bearer ${token}` : '',
  },
});

export const uploadFile = async (file: File): Promise<FileData | undefined> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response: ApiResponse<FileData> = await fileService.post(
      ROUTES.FILES.UPLOAD_IMAGE,
      formData,
    );
    return response.data;
  } catch (e) {
    throw new Error('Bad data');
  }
};

export const uploadVideo = async (file: File): Promise<FileData | undefined> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response: ApiResponse<FileData> = await fileService.post(
      ROUTES.FILES.UPLOAD_VIDEO,
      formData,
    );
    return response.data;
  } catch {
    throw new Error('Bad data');
  }
};

export const fetchFile = async (imgPath: string): Promise<string | undefined> => {
  try {
    const response: ApiResponse<string> = await fileService.get(
      `${ROUTES.FILES.GET}/${imgPath}`,
    );
    return response.data;
  } catch {
    throw new Error('Bad data');
  }
};

export const deleteFile = async (id: string): Promise<CommonResponse> => {
  try {
    const response: ApiResponse<CommonResponse> = await fileService.delete(
      `${ROUTES.FILES.GET}/${id}`,
    );

    if (!response.ok) {
      const exception = getGeneralApiProblem<CommonResponse>(response);
      if (exception) return exception;
    }
    return { kind: 'ok' };
  } catch (e) {
    throw new Error('Bad data');
  }
};
