import { Icon } from '@iconify/react';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import roundHistory from '@iconify/icons-ic/round-history';
import { PATH_DASHBOARD } from '../../app';
import { Link } from '../../types';
import { ShopForm as ShopInfoTab } from './ShopForm';
import { TAB_ICON_PROPS } from '../../constants';
import { ShopHistory as HistoryTab } from './ShopHistory';

export enum ShopValues {
  ShopInfo = 'shop_info',
  History = 'history',
}

type ShopTab = {
  value: ShopValues,
  icon: JSX.Element,
  component: JSX.Element,
}

export const SHOP_TABS: readonly Readonly<ShopTab>[] = [
  {
    value: ShopValues.ShopInfo,
    icon: <Icon icon={roundAccountBox} {...TAB_ICON_PROPS} />,
    component: <ShopInfoTab />,
  },
  {
    value: ShopValues.History,
    icon: <Icon icon={roundHistory} {...TAB_ICON_PROPS} />,
    component: <HistoryTab />,
  },
] as const;

export const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Shops list', href: PATH_DASHBOARD.shops.list },
  { name: 'Shop' },
];
