import React, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import {
  Box, Container, Tab, Tabs,
} from '@material-ui/core';
import { capitalCase } from 'change-case';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { HeaderBreadcrumbs, Page } from '../../components';
import { LINKS, RECLAMATION_TABS, ReclamationValues } from './constants';
import { useStore } from '../../hooks';
import { QueryParams } from '../../types';

const CountHints = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: theme.palette.error.main,
  color: 'white',
  fontSize: '10px',
  fontWeight: 800,
  transform: 'translateY(-10px)',
}));

export const Reclamation: React.FC = observer((): JSX.Element => {
  const { id } = useParams<QueryParams>();

  const {
    knowledgeBasesModel: {
      fetchHintsCountByReclamationId,
      hints: {
        count,
      },
    },
    reclamations: {
      resetSelectedReclamation,
    },
  } = useStore();

  const [currentTab, setCurrentTab] = useState<ReclamationValues>(RECLAMATION_TABS[0].value);
  const handleChangeTab = useCallback((e: SyntheticEvent, value: ReclamationValues): void => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    fetchHintsCountByReclamationId(Number(id));
    return (): void => resetSelectedReclamation();
  }, []);

  return (
    <Page title="Claim">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Claim"
          links={LINKS}
        />
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {RECLAMATION_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={(
                <>
                  {capitalCase(tab.value)}
                  {tab.value === ReclamationValues.Hints && (<CountHints>{count}</CountHints>)}
                </>
              )}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Box sx={{ mb: 5 }} />
        {
          RECLAMATION_TABS.map((
            tab,
          ) => tab.value === currentTab && <Box key={tab.value}>{tab.component}</Box>)
        }
      </Container>
    </Page>
  );
});
