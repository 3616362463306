import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TokenType } from '../../../types';
import { useStore } from '../../../hooks';

const SUCCESS = 'ok';

type QueryParams = {
  token: string,
}

type UseVerifyToken = {
  isCorrectToken: boolean,
  isPending: boolean,
}

export const useVerifyToken = (type: TokenType): UseVerifyToken => {
  const { auth } = useStore();
  const { token }: QueryParams = useParams();
  const [isCorrectToken, setIsCorrectToken] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    const verifyToken = async (): Promise<void> => {
      const data = await auth.checkToken({
        token,
        type,
      });
      if (data.kind === SUCCESS) {
        setIsCorrectToken(true);
      }
      setIsPending(false);
    };
    verifyToken();
  }, []);

  return {
    isCorrectToken,
    isPending,
  };
};
