import {
  applySnapshot,
  flow, getEnv,
  Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import {
  ServiceColorModel,
  ListMetaDataModel,
  ColorListParams,
  Option,
  ServiceColorsListData,
  ServiceColor,
} from '../types';
import { COMMON_SCHEMA, DEFAULT_META } from '../constants';
import { mapEntityToOption } from '../utils';

export const ServiceColorStore = types.model('ServiceColorStore')
  .props({
    items: types.optional(types.array(ServiceColorModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    fetchServiceColors: flow(function* fetchServiceColors(params: ColorListParams) {
      try {
        const res: ServiceColorsListData = yield getEnv(self).api.fetchServiceColors(params);
        const { items, meta } = res;
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        throw new Error('Error with getting service colors');
      }
    }),
  }))
  .views((self) => ({
    get colorsAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<ServiceColor>(item, COMMON_SCHEMA));
    },
  }));

export type ServiceColorStore = Instance<typeof ServiceColorStore>

export type ServiceColorStoreSnapShot = SnapshotOut<typeof ServiceColorStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createServiceColorStoreModel = () => types.optional(ServiceColorStore, {});
