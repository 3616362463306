import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Avatar, Box, Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { isEqual } from 'date-fns';
import { Icon } from '@iconify/react';
import editOutline from '@iconify/icons-eva/edit-outline';
import { Message } from '../../../../types';
import { useStore } from '../../../../hooks';
import { MessageTypes } from '../../../../constants';
import { fDateTime, getFileUrl } from '../../../../utils';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
  zIndex: 10,
}));

const TextMessageStyle = styled(Typography)(({ theme }) => ({
  wordBreak: 'break-all',
}));

const MessageImgStyle = styled('img')(({ theme }) => ({
  height: 200,
  maxWidth: 296,
  width: '100%',
  cursor: 'pointer',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
}));

const MessageVideoStyle = styled('video')(({ theme }) => ({
  height: 200,
  maxWidth: 296,
  width: '100%',
  cursor: 'pointer',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
}));

type MessageCardProps = {
  message: Message,
  onChangeEditState: (value: boolean) => () => void,
}

export const MessageCard: React.FC<MessageCardProps> = observer(({
  message,
  onChangeEditState,
}) => {
  const {
    channels: {
      selectedChannel,
    },
    auth: {
      profile,
    },
  } = useStore();

  const isAuthor = profile.id === message.author?.id;

  const avatarSrc = useMemo((): string | undefined => {
    if (message.author) {
      if (message.author?.avatar) return getFileUrl(message.author.avatar);
      return undefined;
    }
    return selectedChannel.avatar || undefined;
  }, [message.author?.avatar, selectedChannel.avatar]);

  const firstName = useMemo((): string => {
    if (message.author) {
      return message.author.name;
    }
    return selectedChannel.firstName;
  }, [message.author?.name, selectedChannel.firstName]);

  const isUpdated = !isEqual(new Date(message.createdAt), new Date(message.updatedAt));

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          ...(isAuthor && {
            ml: 'auto',
            marginRight: '10px',
          }),
        }}
      >
        {!isAuthor && (
        <Avatar
          alt="client_avatar"
          src={avatarSrc}
          sx={{ width: 32, height: 32 }}
        />
        )}
        <Box sx={{ ml: 2 }}>
          <InfoStyle noWrap variant="caption" sx={{ ...(isAuthor && { justifyContent: 'flex-end' }) }}>
            {!isAuthor && `${firstName},`}
                            &nbsp;
            {fDateTime(new Date(message.createdAt))}
            {' '}
            {isUpdated && '(edited)'}
            {isAuthor && (
            <Icon
              onClick={onChangeEditState(true)}
              icon={editOutline}
              style={{
                cursor: 'pointer',
              }}
              width={16}
              height={16}
            />
            )}
          </InfoStyle>

          <ContentStyle
            sx={{
              ...(isAuthor && {
                color: 'grey.800',
                bgcolor: 'primary.lighter',
              }),
            }}
          >
            {message.type === MessageTypes.TEXT && (<TextMessageStyle variant="body2">{message.content}</TextMessageStyle>)}
            {message.type === MessageTypes.PICTURE && message.files.map(({ file }) => (
              <>
                <MessageImgStyle
                  alt="attachment"
                  src={getFileUrl(file)}
                />
                <TextMessageStyle variant="body2">{message.content}</TextMessageStyle>
              </>
            ))}
            {message.type === MessageTypes.VIDEO && message.files.map(({ file }) => (
              <>
                <MessageVideoStyle
                  src={getFileUrl(file)}
                  controls
                />
                <TextMessageStyle variant="body2">{message.content}</TextMessageStyle>
              </>
            ))}
          </ContentStyle>
        </Box>
      </Box>
    </RootStyle>
  );
});
