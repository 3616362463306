import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Stack,
  TablePagination,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { NotificationItem } from './NotificationItem';
import { FIRST_PAGE } from '../../../constants';

export const NotificationsList: React.FC = observer((): JSX.Element => {
  const {
    notifications: {
      fetchNotifications,
      notifications: {
        items,
        meta,
      },
    },
  } = useStore();

  const [page, setPage] = useState<number>(FIRST_PAGE);

  const handlePageChange = useCallback((
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage + 1);
  }, []);

  useEffect(() => {
    fetchNotifications({ page });
  }, [page]);

  return (
    <Card>
      <CardHeader title="Notifications" />
      <Stack
        spacing={2}
        sx={{ p: 3 }}
        divider={<Divider sx={{ margin: '0 !important' }} orientation="horizontal" flexItem />}
      >
        {items.map((item) => (
          <NotificationItem key={item.id} data={item} />
        ))}
      </Stack>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={meta.totalItems}
        rowsPerPage={meta.itemsPerPage}
        page={meta.currentPage - 1}
        onPageChange={handlePageChange}
      />
    </Card>
  );
});
