import React from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@material-ui/core/styles';
import {
  Box,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '90%',
  transition: theme.transitions.create('box-shadow', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

type SearchProps = {
  searchValue: string;
  onSearch: (newSearchValue: string) => void;
};

export const Search:React.FC<SearchProps> = ({
  searchValue,
  onSearch,
  ...other
}) => (
  <RootStyle {...other}>
    <SearchStyle
      size="small"
      value={searchValue}
      onChange={(e): void => onSearch(e.target.value)}
      placeholder="Search channels..."
      startAdornment={(
        <InputAdornment position="start">
          <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
        </InputAdornment>
            )}
    />
  </RootStyle>
);
