import { ApiResponse, ApisauceInstance, create } from 'apisauce';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import {
  CountriesResponse,
  CountryResponse,
  CommonResponse,
  ShopsResponse,
  UsersResponse,
  UserResponse,
  ProfileResponse,
  AuthResponse,
  ShopResponse,
  StrollerResponse,
  StrollersResponse,
  ConsumerResponse,
  CitiesResponse,
  ServiceModelResponse,
  ServiceColorResponse,
  ConsumerContactInfoResponse,
  ConsumerRegistrationResponse,
  ModuleResponse,
  ReclamationsResponse,
  KnowledgeBasesResponse,
  KnowledgeBaseResponse,
  ConsumersResponse,
  ReclamationResponse,
  DeviationCodesResponse,
  DeviationCodeResponse,
  StrollerPartsResponse,
  StrollerPartResponse,
  SparePartsResponse,
  LanguagesResponse,
  SparePartResponse,
  ReclamationCommentsResponse,
  ReclamationCommentResponse,
  AvatarResponse,
  AllClaimsReportResponse,
  ReclamationConsumerResponse,
  StatusesResponse,
  KanbanReclamationsResponse,
  ReclamationsCountByPeriodsResponse,
  ReclamationsCountByStatusesResponse,
  AllCountriesReportResponse,
  CountResponse,
  AvgTimeProcessingResponse,
  NotificationsResponse,
  NotificationTokenResponse,
  NotificationUnreadCountResponse,
  NotificationResponse,
  ClosedReclamationsCountResponse,
  HintsListResponse,
  ConsumerCountryCountResponse,
  CreateConsumerResponse,
  ReclamationsCSATResponse,
  ChannelsResponse,
  MessagesResponse,
  ChannelResponse,
  ChannelManagerResponse,
  MessageResponse,
  UpdatedMessageResponse,
  UnconnectedReclamationsResponse, UpdateClientResponse,
} from './api.types';
import { ResponseStatuses, ROUTES } from '../../constants';
import { getGeneralApiProblem, Problem } from './api-problem';
import {
  CheckTokenValues,
  ShopFormValues,
  ForgotPasswordValues,
  LoginFormValues,
  RegisterFormValues,
  ShopParams,
  ChangePasswordValues,
  ProfileValues,
  ListParams,
  ResetPasswordData,
  AvatarValues,
  StrollerFormValues,
  CountryValues,
  ReclamationsParams,
  ConsumerContactInfoData,
  ConsumerRegistrationData,
  StrollerListParams,
  KnowledgeBaseParams,
  KnowledgeBaseFormValues,
  ConsumersParams,
  ColorListParams,
  ReclamationInfoValues,
  DeviationCodeValues,
  DeviationCodesListParams,
  StrollerPartValues,
  SparePartsListParams,
  ReclamationCommentValues,
  UserData,
  SparePartValues,
  ReclamationConsumerValues,
  ClaimsListParams,
  CountriesListParams,
  KanbanReclamationsParams,
  ReclamationsCountParams,
  AvgTimeProcessingParams,
  ConsumerCountryCount,
  ConsumerCountryCountParams,
  InviteValues,
  AuthData,
  CreateConsumerValues,
  ReclamationsConsumerCSAT,
  ReclamationStatusValues,
  CountryListParams,
  MessageListParams,
  SendMessageData,
  UpdateMessageData,
  UnconnectedReclamationValues,
} from '../../types';
import { removeTokenFromStorage, getTokenFromStorage } from '../../utils/localStorage';
import { ChannelManagerData, ChannelsListParams, UpdateClientValues } from '../../types/channel';
import { ApiErrorData, checkResponse } from './api.helpers';
import { exelService, fileService, pdfService } from '../index';

const token = getTokenFromStorage();

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup(): void {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    this.apisauce.addMonitor(Api.unauthorizedMonitor);
    this.apisauce.addMonitor(Api.authenticationMonitor);
  }

  setAuthHeader(): void {
    this.apisauce.setHeaders({
      Authorization: `Bearer ${getTokenFromStorage()}`,
    });
  }

  private static unauthorizedMonitor(response: ApiResponse<never>): void {
    const { ok, status } = response;
    const ERROR_STATUS = status === ResponseStatuses.UNAUTHORIZED;

    if (!ok && ERROR_STATUS) {
      removeTokenFromStorage();
    }
  }

  private static authenticationMonitor(response: ApiResponse<AuthResponse>): void {
    if (response.config && response.ok) {
      const { url } = response.config;
      if (url === ROUTES.AUTH.LOGIN || url === ROUTES.AUTH.REGISTER) {
        const data = response.data as AuthData;
        exelService.setHeaders({
          Authorization: `Bearer ${data.accessToken}`,
        });
        fileService.setHeaders({
          Authorization: `Bearer ${data.accessToken}`,
        });
        pdfService.setHeaders({
          Authorization: `Bearer ${data.accessToken}`,
        });
      }
    }
  }

  // AUTHORIZATION

  async inviteUser(data: InviteValues): Promise<CommonResponse> {
    const response: ApiResponse<CommonResponse> = await this.apisauce.post(
      ROUTES.USERS.INVITE,
      data,
    );
    if (!response.ok) {
      if (response.problem === Problem.CLIENT_ERROR) {
        throw response.data;
      }
      throw new Error('Bad data');
    }
    return { kind: 'ok' };
  }

  async login(data: LoginFormValues): Promise<AuthResponse> {
    try {
      const response: ApiResponse<AuthResponse> = await this.apisauce.post(ROUTES.AUTH.LOGIN, data);
      checkResponse<AuthResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async register(data: RegisterFormValues): Promise<AuthResponse> {
    try {
      const response: ApiResponse<AuthResponse> = await this.apisauce.post(
        ROUTES.AUTH.REGISTER,
        data,
      );
      checkResponse<AuthResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async sendReset(data: ForgotPasswordValues): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.post(
        ROUTES.AUTH.SEND_RESET,
        data,
      );
      checkResponse<CommonResponse>(response);
      return { kind: 'ok' };
    } catch {
      throw new Error('Bad data');
    }
  }

  async resetPassword(data: ResetPasswordData): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.post(
        ROUTES.AUTH.RESET_PASSWORD,
        data,
      );
      checkResponse<CommonResponse>(response);
      return { kind: 'ok' };
    } catch {
      throw new Error('Bad data');
    }
  }

  async checkToken(data: CheckTokenValues): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.post(
        ROUTES.AUTH.CHECK_TOKEN,
        data,
      );
      checkResponse<CommonResponse>(response);
      return { kind: 'ok' };
    } catch {
      throw new Error('Bad data');
    }
  }

  async getProfile(): Promise<ProfileResponse> {
    try {
      const response: ApiResponse<ProfileResponse> = await this.apisauce.get(ROUTES.AUTH.PROFILE);
      checkResponse<ProfileResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async updateProfile(data: ProfileValues): Promise<ProfileResponse> {
    try {
      const response: ApiResponse<ProfileResponse> = await this.apisauce.put(
        ROUTES.AUTH.PROFILE,
        data,
      );
      checkResponse<ProfileResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async uploadAvatar(values: AvatarValues): Promise<AvatarResponse> {
    try {
      const response: ApiResponse<AvatarResponse> = await this.apisauce.put(
        ROUTES.AUTH.AVATAR,
        values,
      );
      checkResponse<AvatarResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async removeAvatar(): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.delete(ROUTES.AUTH.AVATAR);

      if (!response.ok) {
        const exception = getGeneralApiProblem<CommonResponse>(response);
        if (exception) return exception;
      }
      return { kind: 'ok' };
    } catch {
      throw new Error('Bad data');
    }
  }

  async changePassword(data: ChangePasswordValues): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.put(
        ROUTES.AUTH.CHANGE_PASSWORD,
        data,
      );
      if (!response.ok) {
        const exception = getGeneralApiProblem<CommonResponse>(response);
        if (exception) return exception;
      }
      return { kind: 'ok' };
    } catch {
      throw new Error('Bad data');
    }
  }

  // USERS

  async fetchUsers(params: ListParams = {}): Promise<UsersResponse> {
    try {
      const response: ApiResponse<UsersResponse> = await this.apisauce.get(
        ROUTES.USERS.ROOT,
        params,
      );
      checkResponse<UsersResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchUser(id: string): Promise<UserResponse> {
    const response: ApiResponse<UserResponse> = await this.apisauce.get(
      `${ROUTES.USERS.ROOT}/${id}`,
    );
    checkResponse<UserResponse>(response);
    return response.data;
  }

  async updateUser({ id, data }: UserData): Promise<CommonResponse> {
    const response: ApiResponse<CommonResponse> = await this.apisauce.put(
      `${ROUTES.USERS.ROOT}/${id}`,
      data,
    );
    if (!response.ok) {
      const exception = getGeneralApiProblem<CommonResponse>(response);
      if (exception) throw exception;
    }
    try {
      return {
        kind: 'ok',
      };
    } catch {
      throw new Error('Bad data');
    }
  }

  async deleteUser(id: number): Promise<CommonResponse> {
    const response: ApiResponse<CommonResponse> = await this.apisauce.delete(
      `${ROUTES.USERS.ROOT}/${id}`,
    );
    if (!response.ok) {
      const exception = getGeneralApiProblem<CommonResponse>(response);
      if (exception) throw exception;
    }
    try {
      return {
        kind: 'ok',
      };
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchShops(params: ListParams = {}): Promise<ShopsResponse> {
    try {
      const response: ApiResponse<ShopsResponse> = await this.apisauce.get(
        ROUTES.SHOPS.ROOT,
        params,
      );
      checkResponse<ShopsResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchShop(params: ShopParams): Promise<ShopResponse> {
    const response: ApiResponse<ShopResponse> = await this.apisauce.get(
      `${ROUTES.SHOPS.ROOT}/${params.id}`,
    );
    checkResponse<ShopResponse>(response);
    return response.data;
  }

  async updateShop(params: ShopParams, data: ShopFormValues): Promise<ShopResponse> {
    try {
      const response: ApiResponse<ShopResponse> = await this.apisauce.put(
        `${ROUTES.SHOPS.ROOT}/${params.id}`,
        { ...data },
      );
      checkResponse<ShopResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchCities(params: ListParams = {}): Promise<CitiesResponse> {
    try {
      const response: ApiResponse<CitiesResponse> = await this.apisauce.get(
        ROUTES.CITIES.ROOT,
        params,
      );
      checkResponse<CitiesResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchAllCountries(params: ListParams = {}): Promise<CountriesResponse> {
    try {
      const response: ApiResponse<CountriesResponse> = await this.apisauce.get(
        ROUTES.COUNTRIES.ROOT_ALL,
        params,
      );
      checkResponse<CountriesResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchCountries(params: CountryListParams = {}): Promise<CountriesResponse> {
    try {
      const response: ApiResponse<CountriesResponse> = await this.apisauce.get(
        ROUTES.COUNTRIES.ROOT,
        params,
      );
      checkResponse<CountriesResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchCountry(id: string): Promise<CountryResponse> {
    const response: ApiResponse<CountryResponse> = await this.apisauce.get(
      `${ROUTES.COUNTRIES.ROOT}/${id}`,
    );
    checkResponse<CountryResponse>(response);
    return response.data;
  }

  async updateCountry(id: string, data: CountryValues): Promise<CountryResponse> {
    try {
      const response: ApiResponse<CountryResponse> = await this.apisauce.put(
        `${ROUTES.COUNTRIES.ROOT}/${id}`,
        { ...data },
      );
      checkResponse<CountryResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchStrollers(params: StrollerListParams = {}): Promise<StrollersResponse> {
    try {
      const response: ApiResponse<StrollersResponse> = await this.apisauce.get(
        ROUTES.STROLLERS.ROOT,
        params,
      );
      checkResponse<StrollersResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchStroller(id: string): Promise<StrollerResponse> {
    const response: ApiResponse<StrollerResponse> = await this.apisauce.get(
      `${ROUTES.STROLLERS.ROOT}/${id}`,
    );
    checkResponse<StrollerResponse>(response);
    return response.data;
  }

  async updateStroller(id: string, data: StrollerFormValues): Promise<StrollerResponse> {
    try {
      const response: ApiResponse<StrollerResponse> = await this.apisauce.put(
        `${ROUTES.STROLLERS.ROOT}/${id}`,
        { ...data },
      );
      checkResponse<StrollerResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchConsumers(params: ConsumersParams = {}): Promise<ConsumersResponse> {
    try {
      const response: ApiResponse<ConsumersResponse> = await this.apisauce.get(
        ROUTES.CONSUMERS.ROOT,
        params,
      );
      checkResponse<ConsumersResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchConsumer(id: string): Promise<ConsumerResponse> {
    const response: ApiResponse<ConsumerResponse> = await this.apisauce.get(
      `${ROUTES.CONSUMERS.ROOT}/${id}`,
    );
    checkResponse<ConsumerResponse>(response);
    return response.data;
  }

  async updateConsumerContactInfo({
    id,
    data,
  }: ConsumerContactInfoData): Promise<ConsumerContactInfoResponse> {
    try {
      const response: ApiResponse<ConsumerContactInfoResponse> = await this.apisauce.put(
        `${ROUTES.CONSUMERS.ROOT}/${id}/${ROUTES.CONSUMERS.CONTACT_INFO}`,
        data,
      );
      checkResponse<ConsumerContactInfoResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async updateConsumerRegistration({
    id,
    data,
  }: ConsumerRegistrationData): Promise<ConsumerRegistrationResponse> {
    try {
      const response: ApiResponse<ConsumerRegistrationResponse> = await this.apisauce.put(
        `${ROUTES.CONSUMERS.ROOT}/${id}/${ROUTES.CONSUMERS.REGISTRATION}`,
        data,
      );
      checkResponse<ConsumerRegistrationResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async createConsumer(data: CreateConsumerValues): Promise<CreateConsumerResponse> {
    try {
      const response: ApiResponse<CreateConsumerResponse> = await this.apisauce.post(
        `${ROUTES.CONSUMERS.ROOT}/${ROUTES.CONSUMERS.CREATE}`,
        data,
      );
      checkResponse<CreateConsumerResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchServiceModels(params: ListParams = {}): Promise<ServiceModelResponse> {
    try {
      const response: ApiResponse<ServiceModelResponse> = await this.apisauce.get(
        ROUTES.SERVICE_MODELS.ROOT,
        params,
      );
      checkResponse<ServiceModelResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchServiceColors(params: ColorListParams = {}): Promise<ServiceColorResponse> {
    try {
      const response: ApiResponse<ServiceColorResponse> = await this.apisauce.get(
        ROUTES.SERVICE_COLORS.ROOT,
        params,
      );
      checkResponse<ServiceColorResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchModules(): Promise<ModuleResponse> {
    try {
      const response: ApiResponse<ModuleResponse> = await this.apisauce.get(ROUTES.MODULES.ROOT);
      checkResponse<ModuleResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchKnowledgeBases(params: ListParams = {}): Promise<KnowledgeBasesResponse> {
    try {
      const response: ApiResponse<KnowledgeBasesResponse> = await this.apisauce.get(
        ROUTES.KNOWLEDGE_BASES.ROOT,
        params,
      );
      checkResponse<KnowledgeBasesResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchKnowledgeBase(params: KnowledgeBaseParams): Promise<KnowledgeBaseResponse> {
    const response: ApiResponse<KnowledgeBaseResponse> = await this.apisauce.get(
      `${ROUTES.KNOWLEDGE_BASES.ROOT}/${params.id}`,
    );
    checkResponse<KnowledgeBaseResponse>(response);
    return response.data;
  }

  async updateKnowledgeBase(
    params: KnowledgeBaseParams,
    data: KnowledgeBaseFormValues,
  ): Promise<KnowledgeBaseResponse> {
    try {
      const response: ApiResponse<KnowledgeBaseResponse> = await this.apisauce.put(
        `${ROUTES.KNOWLEDGE_BASES.ROOT}/${params.id}`,
        { ...data },
      );
      checkResponse<KnowledgeBaseResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async createKnowledgeBase(data: KnowledgeBaseFormValues): Promise<KnowledgeBaseResponse> {
    try {
      const response: ApiResponse<KnowledgeBaseResponse> = await this.apisauce.post(
        `${ROUTES.KNOWLEDGE_BASES.ROOT}`,
        { ...data },
      );
      checkResponse<KnowledgeBaseResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async deleteKnowledgeBase(params: KnowledgeBaseParams): Promise<CommonResponse> {
    const response: ApiResponse<CommonResponse> = await this.apisauce.delete(
      `${ROUTES.KNOWLEDGE_BASES.ROOT}/${params.id}`,
    );
    if (!response.ok) {
      const exception = getGeneralApiProblem<CommonResponse>(response);
      if (exception) throw exception;
    }
    try {
      return {
        kind: 'ok',
      };
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchHintsCountByReclamationId(reclamationId: number): Promise<CountResponse> {
    try {
      const response: ApiResponse<CountResponse> = await this.apisauce.get(
        ROUTES.KNOWLEDGE_BASES.COUNT,
        { reclamation_id: reclamationId },
      );
      checkResponse<CountResponse>(response);
      return response.data || 0;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchHintsByReclamationId(reclamationId: number): Promise<HintsListResponse> {
    try {
      const response: ApiResponse<HintsListResponse> = await this.apisauce.get(
        ROUTES.KNOWLEDGE_BASES.HINTS,
        { reclamation_id: reclamationId },
      );
      checkResponse<HintsListResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  // RECLAMATIONS

  async fetchReclamations(params: ReclamationsParams = {}): Promise<ReclamationsResponse> {
    try {
      const response: ApiResponse<ReclamationsResponse> = await this.apisauce.get(
        ROUTES.RECLAMATIONS.ROOT,
        params,
      );
      checkResponse<ReclamationsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async getReclamationById(id: string): Promise<ReclamationResponse> {
    const response: ApiResponse<ReclamationResponse> = await this.apisauce.get(
      `${ROUTES.RECLAMATIONS.ROOT}/${id}`,
    );
    checkResponse<ReclamationResponse>(response);
    return response.data;
  }

  async updateReclamation(id: string, data: ReclamationInfoValues): Promise<ReclamationResponse> {
    const response: ApiResponse<ReclamationResponse> = await this.apisauce.put(
      `${ROUTES.RECLAMATIONS.ROOT}/${id}`,
      data,
    );
    if (!response.ok) {
      if (response.problem === Problem.CLIENT_ERROR) {
        throw response.data;
      }
      throw new Error('Bad data');
    }
    return response.data;
  }

  async updateReclamationConsumerInfo(
    id: string,
    data: ReclamationConsumerValues,
  ): Promise<ReclamationConsumerResponse> {
    try {
      const response: ApiResponse<ReclamationConsumerResponse> = await this.apisauce.put(
        `${ROUTES.RECLAMATIONS.ROOT}/${id} /${ROUTES.RECLAMATIONS.INFO}`,
        data,
      );
      checkResponse<ReclamationConsumerResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async updateReclamationStatus(
    id: string,
    data: ReclamationStatusValues,
  ): Promise<CommonResponse> {
    const response: ApiResponse<CommonResponse, ApiErrorData> = await this.apisauce.put(
      `${ROUTES.RECLAMATIONS.ROOT}/${id} /${ROUTES.RECLAMATIONS.STATUS}`, data,
    );
    if (!response.ok) {
      if (response.problem === Problem.CLIENT_ERROR) {
        throw response.data;
      }
      throw new Error('Bad data');
    }
    return {
      kind: 'ok',
    };
  }

  async getCommentsByReclamationId(id: string): Promise<ReclamationCommentsResponse> {
    try {
      const response: ApiResponse<ReclamationCommentsResponse> = await this.apisauce.get(
        `${ROUTES.RECLAMATIONS.ROOT}/${id}/${ROUTES.RECLAMATIONS.COMMENTS}`,
      );
      checkResponse<ReclamationCommentsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async createCommentByReclamationId(
    id: string,
    data: ReclamationCommentValues,
  ): Promise<ReclamationCommentResponse> {
    try {
      const response: ApiResponse<ReclamationCommentResponse> = await this.apisauce.post(
        `${ROUTES.RECLAMATIONS.ROOT}/${id}/${ROUTES.RECLAMATIONS.COMMENTS}`,
        data,
      );
      checkResponse<ReclamationCommentResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async updateCommentByReclamationId(
    id: string,
    commentId: number,
    data: ReclamationCommentValues,
  ): Promise<ReclamationCommentResponse> {
    try {
      const response: ApiResponse<ReclamationCommentResponse> = await this.apisauce.put(
        `${ROUTES.RECLAMATIONS.ROOT}/${id}/${ROUTES.RECLAMATIONS.COMMENTS}/${commentId}`,
        data,
      );
      checkResponse<ReclamationCommentResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchUnconnectedReclamations(
    params: UnconnectedReclamationValues,
  ): Promise<UnconnectedReclamationsResponse> {
    try {
      const response: ApiResponse<UnconnectedReclamationsResponse> = await this.apisauce.get(
        `${ROUTES.RECLAMATIONS.ROOT}/${ROUTES.RECLAMATIONS.AUTOCOMPLETE}`,
        params,
      );
      checkResponse<UnconnectedReclamationsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  // REPORTS

  async fetchAllClaimsReport(params: ClaimsListParams): Promise<AllClaimsReportResponse> {
    try {
      const response: ApiResponse<AllClaimsReportResponse> = await this.apisauce.get(
        ROUTES.REPORTS.ALL_CLAIMS,
        params,
      );
      checkResponse<AllClaimsReportResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchReclamationsCountByPeriods(
    params: ReclamationsCountParams,
  ): Promise<ReclamationsCountByPeriodsResponse> {
    try {
      const response: ApiResponse<ReclamationsCountByPeriodsResponse> = await this.apisauce.get(
        ROUTES.REPORTS.RECLAMATIONS_COUNT_BY_PERIODS,
        params,
      );
      checkResponse<ReclamationsCountByPeriodsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchReclamationsCountByStatuses(
    params: ReclamationsCountParams,
  ): Promise<ReclamationsCountByStatusesResponse> {
    try {
      const response: ApiResponse<ReclamationsCountByStatusesResponse> = await this.apisauce.get(
        ROUTES.REPORTS.RECLAMATIONS_COUNT_BY_STATUSES,
        params,
      );
      checkResponse<ReclamationsCountByStatusesResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchAllCountriesReport(params: CountriesListParams): Promise<AllCountriesReportResponse> {
    try {
      const response: ApiResponse<AllCountriesReportResponse> = await this.apisauce.get(
        ROUTES.REPORTS.ALL_COUNTRIES,
        params,
      );
      checkResponse<AllCountriesReportResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchClosedReclamationsCount(
    params: ReclamationsCountParams,
  ): Promise<ClosedReclamationsCountResponse> {
    try {
      const response: ApiResponse<ClosedReclamationsCountResponse> = await this.apisauce.get(
        ROUTES.REPORTS.CLOSED_RECLAMATIONS_COUNT,
        params,
      );
      checkResponse<ClosedReclamationsCountResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchReclamationsCount(params: ReclamationsCountParams): Promise<CountResponse> {
    try {
      const response: ApiResponse<CountResponse> = await this.apisauce.get(
        ROUTES.REPORTS.RECLAMATIONS_COUNT,
        params,
      );
      checkResponse<CountResponse>(response);
      return response.data || 0;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchAvgTimeProcessingReclamations(
    params: AvgTimeProcessingParams,
  ): Promise<AvgTimeProcessingResponse> {
    try {
      const response: ApiResponse<AvgTimeProcessingResponse> = await this.apisauce.get(
        ROUTES.REPORTS.AVG_TIME_PROCESSING,
        params,
      );
      checkResponse<AvgTimeProcessingResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchDeviationCodes(params: DeviationCodesListParams): Promise<DeviationCodesResponse> {
    try {
      const response: ApiResponse<DeviationCodesResponse> = await this.apisauce.get(
        ROUTES.DEVIATION_CODES.ROOT,
        params,
      );
      checkResponse<DeviationCodesResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchDeviationCodeById(id: string): Promise<DeviationCodeResponse> {
    const response: ApiResponse<DeviationCodeResponse> = await this.apisauce.get(
      `${ROUTES.DEVIATION_CODES.ROOT}/${id}`,
    );
    checkResponse<DeviationCodeResponse>(response);
    return response.data;
  }

  async createDeviationCode(data: DeviationCodeValues): Promise<DeviationCodeResponse> {
    try {
      const response: ApiResponse<DeviationCodeResponse> = await this.apisauce.post(
        ROUTES.DEVIATION_CODES.ROOT,
        data,
      );
      checkResponse<DeviationCodeResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async updateDeviationCode(id: string, data: DeviationCodeValues): Promise<DeviationCodeResponse> {
    try {
      const response: ApiResponse<DeviationCodeResponse> = await this.apisauce.put(
        `${ROUTES.DEVIATION_CODES.ROOT}/${id}`,
        data,
      );
      checkResponse<DeviationCodeResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async deleteDeviationCode(id: number): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.delete(
        `${ROUTES.DEVIATION_CODES.ROOT}/${id}`,
      );
      checkResponse<CommonResponse>(response);
      return { kind: 'ok' };
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchStrollerParts(params: ListParams): Promise<StrollerPartsResponse> {
    try {
      const response: ApiResponse<StrollerPartsResponse> = await this.apisauce.get(
        ROUTES.STROLLER_PARTS.ROOT,
        params,
      );
      checkResponse<StrollerPartsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchStrollerPartById(id: string): Promise<StrollerPartResponse> {
    const response: ApiResponse<StrollerPartResponse> = await this.apisauce.get(
      `${ROUTES.STROLLER_PARTS.ROOT}/${id}`,
    );
    checkResponse<StrollerPartResponse>(response);
    return response.data;
  }

  async createStrollerPart(data: StrollerPartValues): Promise<StrollerPartResponse> {
    try {
      const response: ApiResponse<StrollerPartResponse> = await this.apisauce.post(
        ROUTES.STROLLER_PARTS.ROOT,
        data,
      );
      checkResponse<StrollerPartResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async updateStrollerPart(id: string, data: StrollerPartValues): Promise<StrollerPartResponse> {
    try {
      const response: ApiResponse<StrollerPartResponse> = await this.apisauce.put(
        `${ROUTES.STROLLER_PARTS.ROOT}/${id}`,
        data,
      );
      checkResponse<StrollerPartResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async deleteStrollerPart(id: number): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.delete(
        `${ROUTES.STROLLER_PARTS.ROOT}/${id}`,
      );
      checkResponse<CommonResponse>(response);
      return { kind: 'ok' };
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchLanguages(): Promise<LanguagesResponse> {
    try {
      const response: ApiResponse<LanguagesResponse> = await this.apisauce.get(
        `${ROUTES.LANGUAGES.ROOT}`,
      );
      checkResponse<LanguagesResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchSpareParts(params: SparePartsListParams): Promise<SparePartsResponse> {
    try {
      const response: ApiResponse<SparePartsResponse> = await this.apisauce.get(
        ROUTES.SPARE_PARTS.ROOT,
        params,
      );
      checkResponse<SparePartsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchSparePartById(id: string): Promise<SparePartResponse> {
    const response: ApiResponse<SparePartResponse> = await this.apisauce.get(
      `${ROUTES.SPARE_PARTS.ROOT}/${id}`,
    );
    checkResponse<SparePartResponse>(response);
    return response.data;
  }

  async createSparePart(data: SparePartValues): Promise<SparePartResponse> {
    try {
      const response: ApiResponse<SparePartResponse> = await this.apisauce.post(
        ROUTES.SPARE_PARTS.ROOT,
        data,
      );
      checkResponse<SparePartResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async updateSparePart(id: string, data: SparePartValues): Promise<SparePartResponse> {
    const response: ApiResponse<SparePartResponse> = await this.apisauce.put(
      `${ROUTES.SPARE_PARTS.ROOT}/${id}`,
      data,
    );
    if (!response.ok) {
      if (response.problem === Problem.CLIENT_ERROR) {
        throw response.data;
      }
      throw new Error('Bad data');
    }
    return response.data;
  }

  async deleteSparePart(id: number): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.delete(
        `${ROUTES.SPARE_PARTS.ROOT}/${id}`,
      );
      checkResponse<CommonResponse>(response);
      return { kind: 'ok' };
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchKanbanStatuses(): Promise<StatusesResponse> {
    try {
      const response: ApiResponse<StatusesResponse> = await this.apisauce.get(ROUTES.KANBAN.LIST);
      checkResponse<StatusesResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchKanbanReclamations(
    params: KanbanReclamationsParams,
  ): Promise<KanbanReclamationsResponse> {
    try {
      const response: ApiResponse<KanbanReclamationsResponse> = await this.apisauce.get(
        ROUTES.KANBAN.RECLAMATIONS,
        params,
      );
      checkResponse<KanbanReclamationsResponse>(response);
      return response.data;
    } catch {
      throw new Error('Bad data');
    }
  }

  async fetchNotifications(params: ListParams): Promise<NotificationsResponse> {
    try {
      const response: ApiResponse<NotificationsResponse> = await this.apisauce.get(
        ROUTES.NOTIFICATIONS.ROOT,
        params,
      );
      checkResponse<NotificationsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async postNotificationToken(data: string): Promise<NotificationTokenResponse> {
    try {
      const response: ApiResponse<NotificationTokenResponse> = await this.apisauce.post(
        `${ROUTES.NOTIFICATIONS.ROOT}/${ROUTES.NOTIFICATIONS.TOKEN}`,
        { token: data },
      );
      checkResponse<NotificationTokenResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async markReadNotifications(): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.post(
        `${ROUTES.NOTIFICATIONS.ROOT}/${ROUTES.NOTIFICATIONS.READ}`,
      );
      checkResponse<CommonResponse>(response);
      return { kind: 'ok' };
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async markReadNotification(id: number): Promise<NotificationResponse> {
    try {
      const response: ApiResponse<NotificationResponse> = await this.apisauce.post(
        `${ROUTES.NOTIFICATIONS.ROOT}/${ROUTES.NOTIFICATIONS.READ}/${id}`,
      );
      checkResponse<NotificationResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchUnreadCount(): Promise<NotificationUnreadCountResponse> {
    try {
      const response: ApiResponse<NotificationUnreadCountResponse> = await this.apisauce.get(
        `${ROUTES.NOTIFICATIONS.ROOT}/${ROUTES.NOTIFICATIONS.UNREAD_COUNT}`,
      );
      checkResponse<NotificationUnreadCountResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchUnreadNotifications(): Promise<NotificationsResponse> {
    try {
      const response: ApiResponse<NotificationsResponse> = await this.apisauce.get(
        `${ROUTES.NOTIFICATIONS.ROOT}/${ROUTES.NOTIFICATIONS.UNREAD}`,
      );
      checkResponse<NotificationsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchConsumerCountryCount(
    params: ConsumerCountryCountParams = {},
  ): Promise<ConsumerCountryCountResponse> {
    try {
      const response: ApiResponse<ConsumerCountryCount[]> = await this.apisauce.get(
        `${ROUTES.CONSUMERS.ROOT}/${ROUTES.CONSUMERS.COUNT_BY_COUNTRY}`,
        params,
      );
      checkResponse<ConsumerCountryCountResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchReclamationsCSAT(
  ): Promise<ReclamationsCSATResponse> {
    try {
      const response: ApiResponse<ReclamationsConsumerCSAT[]> = await this.apisauce.get(
        `${ROUTES.RECLAMATIONS.ROOT}/${ROUTES.RECLAMATIONS.CSAT}`,
      );

      checkResponse<ReclamationsCSATResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  // MESSENGER
  async fetchChannels(params: ChannelsListParams): Promise<ChannelsResponse> {
    try {
      const response: ApiResponse<ChannelsResponse> = await this.apisauce.get(
        ROUTES.CHANNELS.ROOT, params,
      );
      checkResponse<ChannelsResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchChannelById(id: string): Promise<ChannelResponse> {
    try {
      const response: ApiResponse<ChannelResponse> = await this.apisauce.get(
        `${ROUTES.CHANNELS.ROOT}/${id}`,
      );
      checkResponse<ChannelResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async fetchMessages(params: MessageListParams): Promise<MessagesResponse> {
    try {
      const response: ApiResponse<MessagesResponse> = await this.apisauce.get(
        `${ROUTES.MESSAGES.ROOT}`,
        params,
      );
      checkResponse<MessagesResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async resetUnreadMessages(data: ChannelManagerData): Promise<ChannelManagerResponse> {
    try {
      const response: ApiResponse<ChannelManagerResponse> = await this.apisauce.put(
        `${ROUTES.CHANNELS.ROOT}/${ROUTES.CHANNELS.RESET_UNREAD_MESSAGES}`,
        data,
      );
      checkResponse<ChannelManagerResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async connectChannelToManager(data: ChannelManagerData): Promise<ChannelManagerResponse> {
    try {
      const response: ApiResponse<ChannelManagerResponse> = await this.apisauce.post(
        `${ROUTES.CHANNELS.ROOT}/${ROUTES.CHANNELS.CONNECT_CHANNEL_TO_MANAGER}`,
        data,
      );
      checkResponse<ChannelManagerResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async sendMessage(data: SendMessageData): Promise<MessageResponse> {
    try {
      const response: ApiResponse<MessageResponse> = await this.apisauce.post(
        `${ROUTES.MESSAGES.ROOT}/${ROUTES.MESSAGES.SEND}`,
        data,
      );
      checkResponse<MessageResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async updateMessage(id: number, data: UpdateMessageData): Promise<UpdatedMessageResponse> {
    try {
      const response: ApiResponse<UpdatedMessageResponse> = await this.apisauce.put(
        `${ROUTES.MESSAGES.ROOT}/${id}`,
        data,
      );
      checkResponse<UpdatedMessageResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async setChannelToReclamation(
    id: string,
    data: UnconnectedReclamationValues,
  ): Promise<ChannelManagerResponse> {
    try {
      const response: ApiResponse<ChannelManagerResponse> = await this.apisauce.put(
        `${ROUTES.CHANNELS.ROOT}/${id}/${ROUTES.CHANNELS.SET_CHANNEL_TO_RECLAMATION}`,
        data,
      );
      checkResponse<ChannelManagerResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async updateClientInfo(id: number, data: UpdateClientValues): Promise<UpdateClientResponse> {
    try {
      const response: ApiResponse<UpdateClientResponse> = await this.apisauce.put(
        `${ROUTES.CLIENTS.ROOT}/${id}`,
        data,
      );
      checkResponse<UpdateClientResponse>(response);
      return response.data;
    } catch (e) {
      throw new Error('Bad data');
    }
  }

  async deleteReclamation(id: number): Promise<CommonResponse> {
    try {
      const response: ApiResponse<CommonResponse> = await this.apisauce.delete(
        `${ROUTES.RECLAMATIONS.ROOT}/${id}`,
      );
      checkResponse<CommonResponse>(response);
      return { kind: 'ok' };
    } catch (e) {
      throw new Error('Bad data');
    }
  }
}
