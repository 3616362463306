import { Instance, types } from 'mobx-state-tree';
import { Module as ModuleEnum } from '../../constants';

export const PermissionModel = types.model('PermissionModel', {
  permission: types.string,
  roles: types.array(types.number),
});

export const ModuleModel = types.model('ModuleModel', {
  name: types.string,
  label: types.enumeration<ModuleEnum>('Label', Object.values(ModuleEnum)),
  permissions: types.array(PermissionModel),
});

export type Permission = Instance<typeof PermissionModel>

export type Module = Instance<typeof ModuleModel>
