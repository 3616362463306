import React from 'react';
import {
  TableRow, TableCell, TableHead, TableSortLabel,
} from '@material-ui/core';
import { HeadCell } from '../../../types/grid';
import { SortingOrder } from '../../../constants';

type CommonListHeadProps = {
  headListData: readonly HeadCell[],
  align?: 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined
  order?: SortingOrder,
  orderBy?: string,
  onClick?: (string) => void,
}

export const CommonListHead:React.FC<CommonListHeadProps> = ({
  headListData,
  align = 'left',
  order,
  orderBy,
  onClick,
}): JSX.Element => {
  const handleClick = (label: string): void => {
    if (typeof onClick === 'function') {
      onClick(label);
    }
  };
  return (
    <TableHead>
      <TableRow>
        {headListData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={align}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : SortingOrder.ASC}
              onClick={(): void => handleClick(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
