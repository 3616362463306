import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

// eslint-disable-next-line max-len
type TypographyReturn = { MuiTypography: { styleOverrides: { paragraph: { marginBottom: string }; gutterBottom: { marginBottom: string } } } }
export const Typography = (theme: Theme): TypographyReturn => ({
  MuiTypography: {
    styleOverrides: {
      paragraph: {
        marginBottom: theme.spacing(2),
      },
      gutterBottom: {
        marginBottom: theme.spacing(1),
      },
    },
  },
});
