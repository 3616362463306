import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

type UseOnItemClick = {
  onItemClick: () => void,
}

export const useOnItemClick = (
  updatingLink: string,
  itemId: number | string | undefined,
): UseOnItemClick => {
  const { push } = useHistory();

  const onItemClick = useCallback((): void => {
    push(`${updatingLink}/${itemId}`);
  }, [updatingLink, itemId]);

  return { onItemClick };
};
