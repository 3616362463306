import { Instance, types } from 'mobx-state-tree';
import { ServiceColorModel } from './ServiceColorModel';
import { ServiceModelModel } from './ServiceModelModel';
import { CountryModel } from './CountryModel';
import { CityModel } from './CityModel';
import { ShopModel } from './ShopModel';

export const StrollerModel = types.model('StrollerModel', {
  id: types.number,
  firstName: types.string,
  lastName: types.string,
  phone: types.string,
  email: types.string,
  countryExtId: types.maybeNull(types.string),
  city: types.string,
  country: CountryModel,
  model: ServiceModelModel,
  modelExtId: types.maybeNull(types.string),
  colorExtId: types.string,
  color: ServiceColorModel,
  price: types.string,
  serialNumber: types.string,
  sellerCountry: CountryModel,
  sellerCity: CityModel,
  sellerCountryExtId: types.maybeNull(types.string),
  sellerCityExtId: types.maybeNull(types.string),
  sellerShopExtId: types.maybeNull(types.string),
  sellerShop: ShopModel,
  datePurchase: types.string,
  receiptPhotoLink: types.string,
  createdAt: types.string,
  updatedAt: types.string,
});

export type Stroller = Instance<typeof StrollerModel>
