import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { fDateTime, getFileFullName, getFileThumb } from '../../../../utils';

const FileItemStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 2.5),
  cursor: 'pointer',
}));

const FileThumbStyle = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  flexShrink: 0,
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[500_16],
}));

type AttachmentItemProps = {
    fileUrl: string,
    createdAt: string
}

export const AttachmentItem:React.FC<AttachmentItemProps> = ({ fileUrl, createdAt }) => {
  const handleClick = useCallback(() => {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [fileUrl]);

  return (
    <FileItemStyle onClick={handleClick}>
      <FileThumbStyle>{getFileThumb(fileUrl)}</FileThumbStyle>
      <Box sx={{ ml: 1.5, maxWidth: 150 }}>
        <Typography variant="body2" noWrap>
          {getFileFullName(fileUrl)}
        </Typography>
        <Typography noWrap variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
          {fDateTime(createdAt)}
        </Typography>
      </Box>
    </FileItemStyle>
  );
};
