import React from 'react';
import { Grid, GridSize } from '@material-ui/core';
import { ClaimsCountCard } from './ClaimsCountCard';
import { ConfigCountCard } from '../../../types';

type ClaimsCountListProps = {
    config: ConfigCountCard[],
    itemSize: GridSize
}

export const ClaimsCountList: React.FC<ClaimsCountListProps> = ({
  config,
  itemSize,
}): JSX.Element => (
  <Grid container spacing={2}>
    {config.map((card) => (
      <Grid item xs={12} md={itemSize}>
        <ClaimsCountCard title={card.title} value={card.value} color={card.color} />
      </Grid>
    ))}
  </Grid>
);
