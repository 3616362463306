import { Icon, IconifyIcon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import {
  Button, Box, Divider, MenuItem,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { PATH_DASHBOARD, PATH_PAGE } from '../../app';
import { CustomAvatar, MenuPopover, MIconButton } from '../../components';
import { useStore } from '../../hooks';
import { createAvatar } from '../../utils/createAvatar';
import { getFileUrl } from '../../utils';

type MenuOption = {
  label: string,
  icon: IconifyIcon,
  linkTo: string,
}

const MENU_OPTIONS: MenuOption[] = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: PATH_PAGE.home,
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: PATH_DASHBOARD.profile,
  },
];

export const AccountPopover:React.FC = observer(() => {
  const {
    auth: {
      logOut,
      profile,
    },
  } = useStore();
  const { push } = useHistory();
  const anchorRef = useRef(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const togglePopover = (value: boolean) => (): void => setIsOpened(value);

  const logoutCallback = (): void => {
    push(PATH_PAGE.home);
  };

  const handleLogout = (): void => {
    logOut(logoutCallback);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={togglePopover(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(isOpened && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme): string => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <CustomAvatar
          src={profile.avatar?.file ? getFileUrl(profile.avatar.file) : undefined}
          color={profile.avatar?.file ? 'default' : createAvatar(profile.name).color}
        >
          {createAvatar(profile.name).name}
        </CustomAvatar>
      </MIconButton>

      <MenuPopover
        open={isOpened}
        onClose={togglePopover(false)}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Divider sx={{ my: 1 }} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={togglePopover(false)}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
});
