import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Autocomplete, Box, Card, Grid, Stack, TextField,
} from '@material-ui/core';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import { LoadingButton } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingScreen, PermissionWrapper } from '../../../components';
import { useAutocomplete, useStore } from '../../../hooks';
import rules from './rules';
import { getOption } from '../../../utils';
import { ConsumerContactInfoValues, Option } from '../../../types';
import {
  DEFAULT_AUTOCOMPLETE_ID, List, Module, PermissionAction,
} from '../../../constants';

type Params = {
  id: string
}

export const ContactInfo: React.FC = observer((): JSX.Element => {
  const { id } = useParams<Params>();
  const {
    consumers: {
      getConsumerById,
      updateContactInfo,
    },
  } = useStore();

  const countries = useAutocomplete(List.Countries);

  const consumer = getConsumerById(id);

  const {
    handleSubmit, control, reset, setValue, formState: { isSubmitting },
  } = useForm<ConsumerContactInfoValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      city: '',
      countryId: DEFAULT_AUTOCOMPLETE_ID,
    },
    resolver: yupResolver(rules),
  });

  const handleChangeSelectValue = useCallback((name: FieldPath<ConsumerContactInfoValues>) => (
    _,
    data: Option | null,
  ): void => {
    setValue(name, data?.value || DEFAULT_AUTOCOMPLETE_ID);
  }, []);

  useEffect(() => {
    if (consumer) {
      reset({
        firstName: consumer.firstName,
        lastName: consumer.lastName,
        email: consumer.email,
        phone: consumer.phone,
        city: consumer.city,
        countryId: consumer.country.id,
      });
    }
  }, [consumer]);

  const isReady = !!consumer && !!countries.length;

  const handleUpdateContactInfo = async (data: ConsumerContactInfoValues): Promise<void> => {
    await updateContactInfo({ id, data });
  };

  return isReady ? (
    <form onSubmit={handleSubmit(handleUpdateContactInfo)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Name"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Surname"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="phone"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Phone number"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Email address"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="city"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="City"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="countryId"
                  control={control}
                  render={({
                    field: { value }, fieldState: { error },
                  }): JSX.Element => (
                    <Autocomplete
                      defaultValue={{
                        label: consumer.country.name || '',
                        value: consumer.country.id || DEFAULT_AUTOCOMPLETE_ID,
                      }}
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(countries, value)}
                      options={countries}
                      onChange={handleChangeSelectValue('countryId')}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          label="Country"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
              <PermissionWrapper
                action={PermissionAction.EDIT}
                module={Module.CONSUMERS}
              >
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Save Changes
                  </LoadingButton>
                </Box>
              </PermissionWrapper>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  ) : (<LoadingScreen />);
});
