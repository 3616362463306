export enum Module {
  USERS = 'users',
  AUTH = 'auth',
  SHOPS = 'shops',
  CHAT = 'chat',
  RECLAMATIONS = 'reclamations',
  CONSUMERS = 'consumers',
  KANBAN = 'kanban',
  CLAIM_COMMENTS = 'claim-comments',
  KNOWLEDGE_BASE = 'knowledge-base',
  COUNTRIES = 'countries',
  DEVIATION_CODES = 'deviation-codes',
  STROLLER_PARTS = 'stroller-parts',
  SPARE_PARTS = 'spare-parts',
  NOTIFICATIONS = 'notifications',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  CHARTS = 'charts',
  CUSTOMER_CARE = 'customer-care',
  IP_TELEPHONY = 'ip-telephonies',
  LANGUAGES = 'languages',
}

export enum UserRole {
  ADMIN = 1,
  SERVICE_MANAGER = 2,
  TEAM_MEMBER = 3,
  STROLLERS_VIEWER = 4,
}

export enum PermissionAction {
  GET = 'get',
  SHOW = 'show',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}
