import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';

import { DEFAULT_META } from '../constants';
import {
  ListMetaDataModel,
  KnowledgeBaseModel,
  KnowledgeBaseFormValues,
  KnowledgeBaseListData,
  KnowledgeBaseParams,
  KnowledgeBaseItemValues,
  KnowledgeBaseListParams,
  HintsModel,
} from '../types';

const DEFAULT_HINTS = {
  count: 0,
};

export const KnowledgeBaseStore = types.model('KnowledgeBaseStore')
  .props({
    items: types.optional(types.array(KnowledgeBaseModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
    hints: types.optional(HintsModel, DEFAULT_HINTS),
  })
  .actions((self) => ({
    fetchKnowledgeBases: flow(function* fetchKnowledgeBases(params: KnowledgeBaseListParams) {
      try {
        const res: KnowledgeBaseListData = yield getEnv(self).api.fetchKnowledgeBases(params);
        const { items, meta } = res;
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting posts');
      }
    }),
    fetchKnowledgeBase: flow(function* fetchKnowledgeBase(id: KnowledgeBaseParams) {
      const data = yield getEnv(self).api.fetchKnowledgeBase(id);
      applySnapshot(self.items, [data]);
    }),
    createKnowledgeBase: flow(
      function* createKnowledgeBase(data: KnowledgeBaseFormValues) {
        try {
          yield getEnv(self).api.createKnowledgeBase(data);
          toast.success('Post was created');
        } catch (e) {
          toast.error('Error with creating post');
        }
      },
    ),
    updateKnowledgeBase: flow(
      function* updateKnowledgeBase(id: KnowledgeBaseParams, data: KnowledgeBaseFormValues) {
        try {
          yield getEnv(self).api.updateKnowledgeBase(id, data);
          toast.success('Post was updated');
        } catch (e) {
          toast.error('Error with updating post');
        }
      },
    ),
    deleteKnowledgeBase: flow(
      function* deleteKnowledgeBase(id: KnowledgeBaseParams) {
        try {
          yield getEnv(self).api.deleteKnowledgeBase(id);
          self.items = cast(self.items.filter((item) => item.id === Number(id)));
          toast.success('Post was deleted');
        } catch (e) {
          toast.error('Error with deleting post');
        }
      },
    ),
    fetchHintsCountByReclamationId: flow(function* fetchHintsCountByReclamationId(
      reclamationId: number,
    ) {
      try {
        self.hints.count = yield getEnv(self)
          .api
          .fetchHintsCountByReclamationId(reclamationId);
      } catch (e) {
        toast.error('Error with getting count of the hints');
      }
    }),
    fetchHintsByReclamationId: flow(function* fetchCountByReclamationId(
      reclamationId: number,
    ) {
      try {
        self.hints.list = yield getEnv(self)
          .api
          .fetchHintsByReclamationId(reclamationId);
      } catch (e) {
        toast.error('Error with getting hints of the claim');
      }
    }),
  }))
  .views((self) => ({
    getKnowledgeBaseById(id): KnowledgeBaseItemValues | undefined {
      return self.items.find((knowledgeBase) => knowledgeBase.id === Number(id));
    },
  }));

export type KnowledgeBaseStore = Instance<typeof KnowledgeBaseStore>

export type KnowledgeBaseStoreSnapShot = SnapshotOut<typeof KnowledgeBaseStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createKnowledgeBaseStoreModel = () => types.optional(KnowledgeBaseStore, {});
