import { HeadCell } from '../../../types/grid';
import { PATH_DASHBOARD } from '../../../app';
import { Link } from '../../../types';

export const TABLE_HEAD_LIST: readonly HeadCell[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'code', label: 'Code' },
  { id: 'class', label: 'Class' },
  { id: 'more_menu', label: '' },
] as const;

export const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'List' },
];
