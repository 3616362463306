import { Instance, types } from 'mobx-state-tree';
import { LocalesModel } from './CommonModel';
import { LanguageModel } from './LanguageModel';

export const CountryModel = types.model('CountryModel', {
  id: types.number,
  name: types.string,
  code: types.maybeNull(types.string),
  locales: types.maybe(types.array(LocalesModel)),
  status: types.number,
  languageId: types.maybeNull(types.number),
  language: types.maybeNull(LanguageModel),
  createdAt: types.string,
  updatedAt: types.string,
  externalId: types.string,
  showInCharts: types.boolean,
});

export type Country = Instance<typeof CountryModel>
