import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography, Box, BoxProps } from '@material-ui/core';

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

interface EmptyContentProps extends BoxProps {
  title: string;
  img?: string;
  description?: string;
}

export const EmptyContent:React.FC<EmptyContentProps> = ({
  title, description, img, ...other
}) => (
  <RootStyle {...other}>
    <Box
      component="img"
      alt="empty content"
      src={img || '/static/illustrations/illustration_empty_content.svg'}
      sx={{ height: 240, mb: 3 }}
    />

    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>

    {description && (
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      {description}
    </Typography>
    )}
  </RootStyle>
);
