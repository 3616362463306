import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Button, Typography, Container } from '@material-ui/core';
// components
import { Page } from '../../components';
//
import { MaintenanceIllustration } from '../../assets/icons';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export const Maintenance:React.FC = () => (
  <RootStyle title="Maintenance | Minimal-UI">
    <Container sx={{ textAlign: 'center' }}>
      <Typography variant="h3" paragraph>
        Website currently under maintenance
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>
        We are currently working hard on this page!
      </Typography>

      <MaintenanceIllustration />

      <Button variant="contained" size="large" component={RouterLink} to="/">
        Go to Home
      </Button>
    </Container>
  </RootStyle>
);
