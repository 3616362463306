import React from 'react';
import { Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { ResetPasswordValues } from '../../../types';
import { useStore } from '../../../hooks';
import rules from './rules';
import { PasswordInput } from '../../../components';

type QueryParams = {
  token: string,
}

export const ResetPasswordForm: React.FC = observer((): JSX.Element => {
  const { auth } = useStore();
  const { token } = useParams<QueryParams>();

  const {
    control, handleSubmit,
  } = useForm<ResetPasswordValues>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(rules),
  });

  const handleReset = async (values: ResetPasswordValues): Promise<void> => {
    await auth.resetPassword({
      ...values,
      token,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleReset)}>
      <Stack spacing={3}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({
            field: { onBlur, onChange, value }, fieldState: { error },
          }): JSX.Element => (
            <PasswordInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={error}
              label="New password"
            />
          )}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Reset Password
        </LoadingButton>
      </Stack>
    </form>
  );
});
