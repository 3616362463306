import { Instance, types } from 'mobx-state-tree';

export const ServiceColorModel = types.model('ServiceColorModel', {
  id: types.number,
  name: types.string,
  extProductUuid: types.string,
  isOld: types.number,
});

export type ServiceColor = Instance<typeof ServiceColorModel>
