import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableCell, TableRow } from '@material-ui/core';
import { User } from '../../../types';
import { CommonMoreMenu } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { STATUSES } from '../../../constants';
import { useOnItemClick } from '../../../hooks/useOnItemClick';

type CELL = {
  name: string,
  render?: (data: User, cell: CELL) => string,
}

const CELLS: CELL[] = [
  {
    name: 'id',
  },
  {
    name: 'name',
  },
  {
    name: 'surname',
  },
  {
    name: 'email',
  },
  {
    name: 'status',
    render: (data, cell): string => STATUSES[data[cell.name]].title,
  },
  {
    name: 'country',
    render: (data, cell): string => data[cell.name]?.name || '-',
  },
];

type UserItemProps = {
  data: User,
  profileId: number,
  onDelete: (id: number) => void,
}

const TableRowStyles = {
  cursor: 'pointer',
};

const UPDATING_LINK = PATH_DASHBOARD.user.root;

export const UserItem: React.FunctionComponent<UserItemProps> = observer(({
  data, profileId, onDelete,
}): JSX.Element => {
  const { onItemClick } = useOnItemClick(UPDATING_LINK, data.id);
  return (
    <TableRow
      hover
      sx={TableRowStyles}
    >
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          onClick={onItemClick}
        >
          {cell.render ? cell.render(data, cell) : data[cell.name]}
        </TableCell>
      ))}
      <TableCell align="right">
        {profileId !== data.id ? (
          <CommonMoreMenu
            itemId={data.id}
            updatingLink={UPDATING_LINK}
            onDelete={onDelete}
          />
        ) : (
          <CommonMoreMenu
            itemId={data.id}
            updatingLink={PATH_DASHBOARD.user.root}
          />
        )}
      </TableCell>
    </TableRow>
  );
});
