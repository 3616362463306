import palette from '../../theme/palette';
import { SettingsContextProps } from '../../types';

export const PRIMARY_COLOR = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    customLight: '#4B1A98',
    main: '#7635dc',
    dark: '#431A9E',
    customDark: '#4B1A98',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    customLight: '#80E1FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    customDark: '#0089B3',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#CCDFFF',
    light: '#6697FF',
    customLight: '#80A2FF',
    main: '#0045FF',
    dark: '#0027B7',
    customDark: '#0030B3',
    darker: '#00137A',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    customLight: '#FECB81',
    main: '#fda92d',
    dark: '#B66816',
    customDark: '#B16A02',
    darker: '#793908',
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    customLight: '#FF8080',
    main: '#FF3030',
    dark: '#B71833',
    customDark: '#B30000',
    darker: '#7A0930',
    contrastText: '#fff',
  },
];

export const DEFAULT_STATE: SettingsContextProps = {
  themeMode: 'light',
  themeColor: 'default',
  onChangeMode: () => { console.log('onChangeMode'); },
  onChangeColor: () => { console.log('onChangeColor'); },
  onToggleStretch: () => { console.log('onToggleStretch'); },
  setColor: PRIMARY_COLOR[0],
  colorOption: [],
};
