import React, { useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { makeStyles, createStyles } from '@material-ui/styles';
import { alpha, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  type BackgroundIconReturn ={
    backgroundSize: string;
    backgroundPosition: string;
    backgroundRepeat: string;
    backgroundColor: string;
    backgroundImage: string;
  }
  const backgroundIcon = (iconName: string):BackgroundIconReturn => ({
    backgroundSize: '24px 24px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: alpha(theme.palette.grey[900], 0.8),
    backgroundImage: `url("/static/icons/controls/${iconName}.svg")`,
  });

  return createStyles({
    root: {
      backdropFilter: 'blur(8px)',
      backgroundColor: alpha(theme.palette.grey[900], 0.88),

      // Toolbar
      '& .ril__toolbar': {
        height: 'auto !important',
        padding: theme.spacing(2, 3),
        backgroundColor: 'transparent',
      },
      '& .ril__toolbarLeftSide': { display: 'none' },
      '& .ril__toolbarRightSide': {
        height: 'auto !important',
        padding: 0,
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        '& li': {
          display: 'flex',
          alignItems: 'center',
        },
        '& li:first-of-type': {
          flexGrow: 1,
        },
        '& li:not(:first-of-type)': {
          width: theme.spacing(5),
          height: theme.spacing(5),
          justifyContent: 'center',
          marginLeft: theme.spacing(1),
          borderRadius: theme.shape.borderRadius,
        },
      },

      // Button
      '& button:focus': { outline: 'none' },
      '& .ril__toolbarRightSide button': {
        width: '100%',
        height: '100%',
        '&.ril__zoomInButton': backgroundIcon('maximize-outline'),
        '&.ril__zoomOutButton': backgroundIcon('minimize-outline'),
        '&.ril__closeButton': backgroundIcon('close'),
      },
      '& .ril__navButtons': {
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        '&.ril__navButtonPrev': {
          left: theme.spacing(2),
          right: 'auto',
          ...backgroundIcon(isRTL ? 'arrow-ios-forward' : 'arrow-ios-back'),
        },
        '&.ril__navButtonNext': {
          right: theme.spacing(2),
          left: 'auto',
          ...backgroundIcon(isRTL ? 'arrow-ios-back' : 'arrow-ios-forward'),
        },
      },
    },
  });
});

interface ModalLightboxProps {
  images: string[];
  photoIndex: number;
  setPhotoIndex: (index: number) => void;
  isOpen: boolean;
  onClose: VoidFunction;
}

export const LightboxModal:React.FC<ModalLightboxProps> = ({
  images,
  photoIndex,
  setPhotoIndex,
  isOpen,
  onClose,
  ...other
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const customStyles = {
    overlay: {
      zIndex: 9999,
    },
  };

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={
            ():void => setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={():void => setPhotoIndex((photoIndex + 1) % images.length)}
          toolbarButtons={[<Typography variant="subtitle2">{`${photoIndex + 1} / ${images.length}`}</Typography>]}
          reactModalStyle={customStyles}
          wrapperClassName={classes.root}
          {...other}
        />
      )}
    </>
  );
};
