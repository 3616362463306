import io, { Socket } from 'socket.io-client';
import { RootStore } from '../models';
import { SocketEventTypesEnum } from '../constants';
import { ChannelItem, Message, UpdatedMessage } from '../types';
import { ChannelManagerRes } from '../types/channel';

type InitSocketProps = {
    store: RootStore | undefined
}

export const socket: Socket = io(process.env.REACT_APP_API_URL as string, {
  reconnectionDelay: 2000,
  autoConnect: false,
});

export const initSocket = ({ store }: InitSocketProps): void => {
  socket.on('connect', (): void => {
    console.debug('> Socket connected!');
  });

  socket.on('disconnect', () => {
    socket.removeAllListeners();
    console.debug('> Socket disconnected!');
  });
  socket.on(SocketEventTypesEnum.NEW_MESSAGE, (data: Message) => {
    store?.messages.saveMessageFromSocket(data);
    store?.channels.moveChannelToTheTop(data);
  });
  socket.on(SocketEventTypesEnum.CONVERSATION_CREATED, (data: ChannelItem) => {
    store?.channels.saveChannelFromSocket(data);
  });
  socket.on(SocketEventTypesEnum.RESET_UNREAD, (data: ChannelManagerRes) => {
    store?.channels.resetUnreadMessages(data);
  });
  socket.on(SocketEventTypesEnum.EDIT_MESSAGE, (data: UpdatedMessage) => {
    store?.messages.editMessageById(data);
  });
};
