import {
  applySnapshot,
  flow,
  getEnv,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  LanguageModel,
  LanguagesListData,
  ListMetaDataModel,
} from '../types';
import { DEFAULT_META } from '../constants';

export const LanguagesStore = types.model(' LanguagesStore')
  .props({
    items: types.optional(types.array(LanguageModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
  })
  .actions((self) => ({
    fetchLanguages: flow(function* fetchLanguage() {
      try {
        const {
          items,
          meta,
        }: LanguagesListData = yield getEnv(self).api.fetchLanguages();
        applySnapshot(self.items, items);
        self.meta = meta;
      } catch (e) {
        toast.error('Error with getting languages');
      }
    }),
  }));

export type LanguagesStore = Instance<typeof LanguagesStore>

export type LanguagesStoreSnapShot = SnapshotOut<typeof LanguagesStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createLanguagesStoreModel = () => types.optional(LanguagesStore, {});
