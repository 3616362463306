import * as yup from 'yup';
import {
  nullableStringSchema, numberReqSchema, objectIdSchema, stringReqSchema,
} from '../../../utils';

const rules = yup.object({
  name: stringReqSchema('Name'),
  code: nullableStringSchema(10),
  class: nullableStringSchema(1),
  status: numberReqSchema('Status'),
  strollerPart: objectIdSchema('Stroller part'),
});

export default rules;
