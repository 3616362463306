import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import {
  Box, Button, Container, Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { PATH_AUTH } from '../../../app';
import { Page } from '../../../components';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { useStore } from '../../../hooks';
import { SentIcon } from '../../../assets';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export const ForgotPassword: React.FC = observer((): JSX.Element => {
  const { auth } = useStore();
  return (
    <RootStyle title="Reset Password">
      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          {!auth.isSentResetEmail ? (
            <>
              <Typography variant="h3" paragraph>
                Forgot your password?
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the email address associated with your account and We will email you a
                link to reset your password.
              </Typography>

              <ForgotPasswordForm />

              <Button
                fullWidth
                size="large"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ mt: 1 }}
              >
                Back
              </Button>
            </>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <SentIcon />
              <Typography variant="h3" gutterBottom>
                Request sent successfully
              </Typography>
              <Typography>
                Please check your email.
              </Typography>

              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ mt: 5 }}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </RootStyle>
  );
});
