import { PATH_DASHBOARD, ProtectedRouteConfig } from './routesConfig';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import {
  Kanban, KnowledgeBaseNewPost, KnowledgeBasePost, KnowledgeBasePosts, KnowledgeBaseUpdatePost,
} from '../pages';
import { Chat } from '../pages/Chat/Chat';
import { Profile, UpdateUser, UserList } from '../pages/User';
import { ShopsList } from '../pages/Shops';
import { Shop } from '../pages/Shop';
import { CountriesList, CountriesUpdate } from '../pages/Countries';
import { StrollersList, StrollersUpdate } from '../pages/Strollers';
import { ReclamationList } from '../pages/Reclamations';
import { Reclamation } from '../pages/Reclamation';
import { ConsumerList } from '../pages/Consumers';
import { Consumer } from '../pages/Consumer';
import { CreateDeviationCode, DeviationCodeList, UpdateDeviationCode } from '../pages/DeviationCodes';
import { CreateStrollerPart, StrollerPartsList, UpdateStrollerPart } from '../pages/StrollerParts';
import { SparePartsList } from '../pages/SpareParts';
import { CreateSparePart } from '../pages/SpareParts/Create';
import { UpdateSparePart } from '../pages/SpareParts/Update';
import { CustomerCare } from '../pages/CustomerCare';
import { Module, PermissionAction } from '../constants';
import { Reports } from '../pages/Reports/Reports';
import { CreateConsumer } from '../pages/Consumers/Create';

export const PROTECTED_ROUTES: ProtectedRouteConfig[] = [
  {
    exact: true,
    path: '/',
    component: Dashboard,
    action: PermissionAction.SHOW,
    module: Module.DASHBOARD,
  },
  {
    exact: true,
    path: PATH_DASHBOARD.root,
    component: Dashboard,
    action: PermissionAction.SHOW,
    module: Module.DASHBOARD,
  },
  {
    exact: true,
    path: PATH_DASHBOARD.reports.root,
    component: Reports,
    action: PermissionAction.SHOW,
    module: Module.REPORTS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.profile,
    component: Profile,
    action: PermissionAction.SHOW,
    module: Module.AUTH,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.general.app,
    component: Dashboard,
    action: PermissionAction.SHOW,
    module: Module.DASHBOARD,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.knowledgeBases.list,
    component: KnowledgeBasePosts,
    action: PermissionAction.SHOW,
    module: Module.KNOWLEDGE_BASE,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.knowledgeBases.post,
    component: KnowledgeBasePost,
    action: PermissionAction.SHOW,
    module: Module.KNOWLEDGE_BASE,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.knowledgeBases.newPost,
    component: KnowledgeBaseNewPost,
    action: PermissionAction.CREATE,
    module: Module.KNOWLEDGE_BASE,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.knowledgeBases.editPost,
    component: KnowledgeBaseUpdatePost,
    action: PermissionAction.EDIT,
    module: Module.KNOWLEDGE_BASE,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.user.list,
    component: UserList,
    action: PermissionAction.SHOW,
    module: Module.USERS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.user.edit,
    component: UpdateUser,
    action: PermissionAction.EDIT,
    module: Module.USERS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.kanban,
    component: Kanban,
    action: PermissionAction.SHOW,
    module: Module.KANBAN,
  },
  {
    exact: true,
    path: PATH_DASHBOARD.chat.root,
    component: Chat,
    action: PermissionAction.SHOW,
    module: Module.CHAT,
  },
  {
    exact: true,
    path: PATH_DASHBOARD.chat.conversation,
    component: Chat,
    action: PermissionAction.SHOW,
    module: Module.CHAT,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.shops.list,
    component: ShopsList,
    action: PermissionAction.SHOW,
    module: Module.SHOPS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.shops.view,
    component: Shop,
    action: PermissionAction.SHOW,
    module: Module.SHOPS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.countries.list,
    component: CountriesList,
    action: PermissionAction.SHOW,
    module: Module.COUNTRIES,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.countries.editCountry,
    component: CountriesUpdate,
    action: PermissionAction.EDIT,
    module: Module.COUNTRIES,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.strollers.list,
    component: StrollersList,
    action: PermissionAction.SHOW,
    module: Module.CONSUMERS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.strollers.editStroller,
    component: StrollersUpdate,
    action: PermissionAction.SHOW,
    module: Module.CONSUMERS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.reclamations.list,
    component: ReclamationList,
    action: PermissionAction.SHOW,
    module: Module.RECLAMATIONS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.reclamations.view,
    component: Reclamation,
    action: PermissionAction.SHOW,
    module: Module.RECLAMATIONS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.consumers.list,
    component: ConsumerList,
    action: PermissionAction.SHOW,
    module: Module.CONSUMERS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.consumers.create,
    component: CreateConsumer,
    action: PermissionAction.CREATE,
    module: Module.CONSUMERS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.consumers.view,
    component: Consumer,
    action: PermissionAction.SHOW,
    module: Module.CONSUMERS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.deviationCodes.list,
    component: DeviationCodeList,
    action: PermissionAction.SHOW,
    module: Module.DEVIATION_CODES,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.deviationCodes.edit,
    component: UpdateDeviationCode,
    action: PermissionAction.EDIT,
    module: Module.DEVIATION_CODES,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.deviationCodes.create,
    component: CreateDeviationCode,
    action: PermissionAction.CREATE,
    module: Module.DEVIATION_CODES,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.strollerParts.list,
    component: StrollerPartsList,
    action: PermissionAction.SHOW,
    module: Module.STROLLER_PARTS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.strollerParts.create,
    component: CreateStrollerPart,
    action: PermissionAction.CREATE,
    module: Module.STROLLER_PARTS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.strollerParts.edit,
    component: UpdateStrollerPart,
    action: PermissionAction.EDIT,
    module: Module.STROLLER_PARTS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.spareParts.list,
    component: SparePartsList,
    action: PermissionAction.SHOW,
    module: Module.SPARE_PARTS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.spareParts.create,
    component: CreateSparePart,
    action: PermissionAction.CREATE,
    module: Module.SPARE_PARTS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.spareParts.edit,
    component: UpdateSparePart,
    action: PermissionAction.EDIT,
    module: Module.SPARE_PARTS,
  },
  {
    exact: false,
    path: PATH_DASHBOARD.customerCare,
    component: CustomerCare,
    action: PermissionAction.SHOW,
    module: Module.CUSTOMER_CARE,
  },
];
