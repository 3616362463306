import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type PopoverReturn= { MuiPopover: { styleOverrides: { paper: { boxShadow: string } } } }
export const Popover = (theme: Theme): PopoverReturn => ({
  MuiPopover: {
    styleOverrides: {
      paper: {
        boxShadow: theme.customShadows.z12,
      },
    },
  },
});
