import React, { SyntheticEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Autocomplete,
  Grid, IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import DateFnsUtils from '@material-ui/lab/AdapterDateFns';
import ClearIcon from '@material-ui/icons/Clear';
import { useStore } from '../../../hooks';
import { Option } from '../../../types';
import {
  COMMON_OPTIONS,
  COMMON_PICKER_DATE_VIEW,
  COMMON_PICKER_MASK,
} from '../../../constants';
import {
  useMultipleSelectFilter,
  useDatePeriodFilter,
  useWarrantyFilter,
} from '../hooks';
import { BarChart } from '../../../components';

const EVENT = null as unknown as SyntheticEvent;
const CHART_ID = 'closedClaimsChart';

export const ClosedClaimsCount: React.FC = observer((): JSX.Element => {
  const {
    reports: {
      fetchClosedReclamationsCount,
      resetClosedReclamationsCount,
      reclamationsCount: {
        closedClaims,
      },
    },
    countriesModel: {
      countriesAutoComplete: countries,
      countriesForChartFilters: defaultCountries,
    },
  } = useStore();

  const {
    ids: countryIds,
    handleChangeValues: handleChangeCountries,
    handleDisabledOption,
  } = useMultipleSelectFilter();

  const {
    dateTo,
    dateFrom,
    handleChangeDateTo,
    handleChangeDateFrom,
  } = useDatePeriodFilter();

  const {
    warranty,
    handleChangeWarranty,
  } = useWarrantyFilter();

  useEffect(() => {
    // use const, because this method is used in the onChange of the AutoComplete
    // onChange has event as a first argument, which type is Synthetic event
    handleChangeCountries(EVENT, defaultCountries);
  }, []);

  useEffect(() => {
    if (countryIds) {
      fetchClosedReclamationsCount({
        countryIds,
        dateTo,
        dateFrom,
        warranty: warranty?.value,
      });
    } else {
      resetClosedReclamationsCount();
    }
  }, [countryIds, dateTo, dateFrom, warranty?.value]);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Number of closed claims
      </Typography>
      <Grid display="flex" container spacing={3} direction="row">
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={12} mb={2}>
            <Autocomplete
              multiple
              autoComplete={false}
              fullWidth
              options={countries}
              defaultValue={defaultCountries}
              onChange={handleChangeCountries}
              getOptionDisabled={handleDisabledOption}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(params): JSX.Element => <TextField {...params} label="Countries" />}
            />
          </Grid>
          <Grid item xs={12} md={12} mb={2}>
            <Autocomplete
              autoComplete={false}
              fullWidth
              value={warranty}
              options={COMMON_OPTIONS}
              onChange={handleChangeWarranty}
              getOptionLabel={(option: Option): string => option.label}
              renderInput={(params): JSX.Element => <TextField {...params} label="Warranty" />}
            />
          </Grid>
          <Grid item xs={12} md={12} mb={2}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DatePicker
                inputFormat={COMMON_PICKER_DATE_VIEW}
                mask={COMMON_PICKER_MASK}
                views={['day']}
                label="Date from"
                value={dateFrom}
                onChange={handleChangeDateFrom}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      onClick={(): void => handleChangeDateFrom(null)}
                      disabled={!dateFrom}
                      style={{ order: 1 }}
                    >
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                  style: { order: 2, marginLeft: 0 },
                }}
                renderInput={(params: TextFieldProps): JSX.Element => (
                  <TextField {...params} fullWidth disabled />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={12}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DatePicker
                inputFormat={COMMON_PICKER_DATE_VIEW}
                mask={COMMON_PICKER_MASK}
                views={['day']}
                label="Date to"
                value={dateTo}
                onChange={handleChangeDateTo}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      onClick={(): void => handleChangeDateTo(null)}
                      disabled={!dateTo}
                      style={{ order: 1 }}
                    >
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                  style: { order: 2, marginLeft: 0 },
                }}
                renderInput={(params: TextFieldProps): JSX.Element => (
                  <TextField {...params} fullWidth disabled />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <BarChart
            id={CHART_ID}
            title="Number of closed claims"
            subtitle="by country"
            data={closedClaims.map((entity) => entity.count)}
            categories={closedClaims.map((entity) => entity.name)}
            metaData={closedClaims.map((entity) => entity.isCountry)}
          />
        </Grid>
      </Grid>
    </>
  );
});
