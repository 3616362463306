import React from 'react';
import { Paper, PaperProps, Typography } from '@material-ui/core';

export const SearchNotFound:React.FC<PaperProps> = ({ ...other }) => (
  <Paper {...other}>
    <Typography gutterBottom align="center" variant="subtitle1">
      Not found
    </Typography>
    <Typography variant="body2" align="center">
      No results found
    </Typography>
  </Paper>
);
