import {
  SEMI_DONUT_CHART_ARROW_DEFAULT_ANGLE,
  SEMI_DONUT_CHART_MAX_ANGLE,
} from '../constants';

export const calcArrowAngle = (value: number, total: number): number => {
  const proportion = value / total;
  const percent = proportion > 1 ? 1 : proportion;

  return (
    percent * SEMI_DONUT_CHART_MAX_ANGLE - SEMI_DONUT_CHART_ARROW_DEFAULT_ANGLE
  );
};
