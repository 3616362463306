import React, {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { Autocomplete, TextField } from '@material-ui/core';
import {
  Controller, FieldPath, useFormContext, useWatch,
} from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-hot-toast';
import {
  DeviationCode,
  Option,
  ReclamationInfoValues,
  SparePart as SparePartType,
  SparePartOption,
} from '../../../types';
import { useStore } from '../../../hooks';
import {
  getOption, mapEntityToOption, convertEmptyStringToNull, mapSparePartToOption,
} from '../../../utils';
import {
  COMMON_OPTIONS,
  COMMON_SCHEMA,
  Data,
  DEFAULT_AUTOCOMPLETE_ID, SPARE_PART_SCHEMA, UserRole,
  WAY_OF_SOLUTIONS,
} from '../../../constants';
import { DefaultSelect } from '../DefaultSelect';
import { DEVIATION_CODES_CONFIG } from './constants';

const LIMIT = 100;
const DEFAULT_PARAMS = { limit: LIMIT };

const DEFAULT_IDS = [0, 1, 2];

type SparePartProps = {
    id: number,
    handleChangeSelectValue: (name: FieldPath<ReclamationInfoValues>) => (_, data: Data) => void,
}

export const ReclamationPart: React.FunctionComponent<SparePartProps> = observer(({
  id,
  handleChangeSelectValue,
}) => {
  const {
    strollerParts: {
      strollerPartsAutoComplete: strollerParts,
    },
    reclamations: {
      reclamation,
      getReclamationPartByIndex,
    },
    spareParts: {
      fetchSpareParts,
    },
    deviationCodes: {
      fetchDeviationCodes,
    },
    auth: {
      profile: {
        role,
      },
    },
  } = useStore();

  const reclamationPart = getReclamationPartByIndex(id);

  const { control, setValue } = useFormContext();

  const [partId, setPartId] = useState<number | undefined>(undefined);
  const [sparePartId, setSparePartId] = useState<number | null | undefined>(undefined);
  const [deviationCodes, setDeviationCodes] = useState<Option[]>([]);
  const [spareParts, setSpareParts] = useState<SparePartOption[]>([]);

  const fetchDeviationCodesAutocomplete = useCallback(async () => {
    try {
      const codes = await fetchDeviationCodes({
        partId,
        ...DEFAULT_PARAMS,
      }) as DeviationCode[];
      const options = codes.map((code) => mapEntityToOption<DeviationCode>(code, COMMON_SCHEMA));
      setDeviationCodes(options);
    } catch (e) {
      toast.error('Error with fetching deviation codes autocomplete');
    }
  }, [partId]);

  const fetchSparePartsAutocomplete = useCallback(async () => {
    try {
      const parts = await fetchSpareParts({
        partId,
        ...DEFAULT_PARAMS,
      }) as SparePartType[];
      const options = parts.map((part) => mapSparePartToOption<SparePartType>(
        part,
        SPARE_PART_SCHEMA,
      ));
      setSpareParts(options);
    } catch (e) {
      toast.error('Error with fetching spare parts autocomplete');
    }
  }, [partId]);

  const handleChangeStrollerPart = useCallback((_,
    data: Option | null): void => {
    setValue(`parts.${id}.strollerPart.id`, data?.value);
    setPartId(Number(data?.value));
    setValue(`parts.${id}.sparePart.id`, null);
    // eslint-disable-next-line no-restricted-syntax
    for (const devCodeId of DEFAULT_IDS) {
      setValue(`parts.${id}.deviationCodes.${devCodeId}.id`, null);
    }
  }, [id]);

  const isServiceManager = role === UserRole.SERVICE_MANAGER;

  useEffect(() => {
    if (reclamationPart?.strollerPart?.id) {
      setPartId(reclamationPart?.strollerPart.id);
    }
  }, [reclamationPart?.strollerPart?.id]);

  useEffect(() => {
    if (partId) {
      fetchSparePartsAutocomplete();
      fetchDeviationCodesAutocomplete();
    } else {
      setSpareParts([]);
      setDeviationCodes([]);
    }
  }, [partId]);

  const areAllData = !!reclamation && !!partId;
  const isReady = !partId || areAllData;

  const watchedSparePartId = useWatch({
    control,
    name: `parts.${id}.sparePart.id`,
  });

  const handleChangeSparePart = (name: FieldPath<ReclamationInfoValues>) => (
    _,
    data: Data,
  ): void => {
    const value = data?.value !== 'undefined' ? Number(data?.value) : null;
    setValue(name, value);
    setSparePartId(value);
  };

  useEffect(() => {
    setSparePartId(watchedSparePartId);
  }, [watchedSparePartId]);

  useEffect(() => {
    if (sparePartId) {
      const neededSparePart = spareParts.find((item) => item.value === sparePartId);
      if (neededSparePart) {
        setValue(`parts.${id}.price`, neededSparePart.price);
      } else {
        setValue(`parts.${id}.price`, '0');
      }
    } else {
      setValue(`parts.${id}.price`, '0');
    }
  }, [sparePartId, spareParts, id]);

  return isReady ? (
    <>
      <Controller
        name={`parts.${id}.strollerPart.id`}
        control={control}
        render={({
          field: { value }, fieldState: { error },
        }): JSX.Element => (
          <Autocomplete
            autoComplete={false}
            fullWidth
            value={getOption(strollerParts, value)}
            defaultValue={{
              label: reclamation?.parts[id]?.strollerPart?.name || '',
              value: reclamation?.parts[id]?.strollerPart?.id || DEFAULT_AUTOCOMPLETE_ID,
            }}
            options={strollerParts}
            onChange={handleChangeStrollerPart}
            getOptionLabel={(option: Option): string => option.label}
            disabled={isServiceManager}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                label="Stroller part"
                error={!!error}
                helperText={error?.message}
                sx={{ minWidth: '200px' }}
              />
            )}
          />
        )}
      />
      {DEVIATION_CODES_CONFIG.map(({ id: devCodeId }) => (
        <Controller
          name={`parts.${id}.deviationCodes.${devCodeId}.id`}
          control={control}
          render={({
            field: { value }, fieldState: { error },
          }): JSX.Element => (
            <DefaultSelect
              key={devCodeId}
              name={`parts.${id}.deviationCodes.${devCodeId}.id`}
              defaultValue={{
                label: reclamation?.parts[id]?.deviationCodes[devCodeId]?.name || '',
                value: reclamation?.parts[id]?.deviationCodes[devCodeId]?.id
                              || DEFAULT_AUTOCOMPLETE_ID,
              }}
              value={value}
              onChange={handleChangeSelectValue}
              error={error}
              options={deviationCodes}
              label={`Deviation code - ${devCodeId + 1}`}
              disabled={isServiceManager}
            />
          )}
        />
      ))}
      <Controller
        name={`parts.${id}.warranty`}
        control={control}
        render={({
          field: { value }, fieldState: { error },
        }): JSX.Element => (
          <Autocomplete
            autoComplete={false}
            fullWidth
            value={getOption(COMMON_OPTIONS, value)}
            options={COMMON_OPTIONS}
            onChange={handleChangeSelectValue(`parts.${id}.warranty`)}
            getOptionLabel={(option: Option): string => option.label}
            disabled={isServiceManager}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                label="Warranty"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        )}
      />
      <Controller
        name={`parts.${id}.wayOfSolution`}
        control={control}
        render={({
          field: { value }, fieldState: { error },
        }): JSX.Element => (
          <Autocomplete
            autoComplete={false}
            fullWidth
            value={getOption<string>(WAY_OF_SOLUTIONS, value)}
            options={WAY_OF_SOLUTIONS}
            onChange={handleChangeSelectValue(`parts.${id}.wayOfSolution`)}
            getOptionLabel={(option: Option<string>): string => option.label}
            disabled={isServiceManager}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                label="Way of solution"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        )}
      />
      <Controller
        name={`parts.${id}.sparePart.id`}
        control={control}
        render={({
          field: { value }, fieldState: { error },
        }): JSX.Element => (
          <DefaultSelect
            name={`parts.${id}.sparePart.id`}
            defaultValue={{
              label: reclamation?.parts[id]?.sparePart?.name || '',
              value: reclamation?.parts[id]?.sparePart?.id || DEFAULT_AUTOCOMPLETE_ID,
            }}
            value={value}
            onChange={handleChangeSparePart}
            error={error}
            options={spareParts}
            label="Spare part"
            disabled={isServiceManager}
          />
        )}
      />
      <Controller
        name={`parts.${id}.price`}
        control={control}
        render={({
          field: { onBlur, onChange, value }, fieldState: { error },
        }): JSX.Element => (
          <TextField
            fullWidth
            type="number"
            label="Price"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={!!error}
            helperText={error?.message}
            disabled
          />
        )}
      />
      <Controller
        name={`parts.${id}.quantity`}
        control={control}
        render={({
          field: { onBlur, onChange, value }, fieldState: { error },
        }): JSX.Element => (
          <TextField
            fullWidth
            type="number"
            label="Quantity"
            onBlur={onBlur}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              const target = e.target as HTMLInputElement;
              const convertedValue = convertEmptyStringToNull(target.value);
              onChange({
                target: {
                  value: convertedValue,
                },
              });
            }}
            defaultValue={0}
            value={value}
            error={!!error}
            helperText={error?.message}
            disabled={isServiceManager}
          />
        )}
      />
      <TextField
        fullWidth
        label="Sum"
        value={reclamation?.parts[id]?.totalPrice || ''}
        disabled
      />
    </>
  ) : (<></>);
});
