import React from 'react';
import { Paper, Stack, Typography } from '@material-ui/core';
import { KanbanCountry } from '../../../types';
import { KanbanCountryCard } from './KanbanCountryCard';
import { HEAD_NAME_STYLES, PAPER_STYLES } from '../constants';

type KanbanColumnProps = {
  name: string;
  countries: KanbanCountry[];
};

export const KanbanColumn:React.FC<KanbanColumnProps> = ({ name, countries }) => (
  <Paper
    variant="outlined"
    sx={PAPER_STYLES}
  >
    <Stack spacing={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ pt: 3 }}
      >
        <Typography variant="h6" sx={HEAD_NAME_STYLES}>{name}</Typography>
      </Stack>
      <Stack
        spacing={2}
        sx={{ paddingBottom: 2 }}
      >
        {countries.map((country) => (
          <KanbanCountryCard
            id={country.id}
            key={country.id}
            name={country.name}
            count={country.reclamation_count}
            status={name}
          />
        ))}
      </Stack>
    </Stack>
  </Paper>
);
