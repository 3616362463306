import countries from '../assets/jsonData/codes.json';

type CountryCode = {
    name: string,
    code: string,
    dial_code: string,
} | undefined

export const getCountryPhoneCode = (code: string): string => {
  const countryCode: CountryCode = countries.find((country) => country.code === code.toUpperCase());
  if (countryCode) {
    return countryCode.dial_code;
  }
  return '+';
};
