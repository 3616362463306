import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { fDateTime } from '../../../../utils';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: '10px',
  padding: theme.spacing(0, 2),
}));

const Audio = styled('audio')(() => ({
  width: '210px',
  height: '40px',
}));

type RecordingItemProps = {
    url: string | null,
    createdAt: string,
}

export const RecordingItem: React.FC<RecordingItemProps> = ({
  url,
  createdAt,
}): JSX.Element => (
  <RootStyle>
    <Audio controls src={url || undefined}><track /></Audio>
    <Typography noWrap variant="caption" sx={{ color: 'text.secondary', textAlign: 'right' }}>
      {fDateTime(createdAt)}
    </Typography>
  </RootStyle>
);
