import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { QueryParams } from '../../../types';
import { useStore } from '../../../hooks';
import { RightSideBar } from '../RightSideBar';
import { MessageList } from './MessageList';
import { MessageInput } from './MessageInput';
import { DEFAULT_ID, UserRole } from '../../../constants';
import { ChatHeader } from './ChatHeader';

type ChatWindowProps = {
  channelId?: number | null,
}

export const ChatWindow:React.FC<ChatWindowProps> = observer(({ channelId = null }) => {
  const {
    channels: {
      fetchChannelById,
      readMessagesEvent,
      connectChannelToManager,
      selectedChannel,
      resetSelectedChannel,
    },
    auth: {
      profile,
    },
  } = useStore();

  const { id: chatId } = useParams<QueryParams>();
  const id = channelId || chatId;

  const isAdmin = profile.role === UserRole.ADMIN;

  const onVisibilityChange = (): void => {
    if (!document.hidden
        && selectedChannel?.unreadMessagesCount !== null
        && selectedChannel?.unreadMessagesCount > 0) {
      readMessagesEvent({ channelId: Number(id), userId: profile.id });
    }
  };

  useEffect(() => {
    if (id) {
      fetchChannelById(String(id));
    }
    return (): void => resetSelectedChannel();
  }, [id]);

  useEffect(() => {
    if (selectedChannel.id !== DEFAULT_ID) {
      if (selectedChannel?.unreadMessagesCount) {
        readMessagesEvent({ channelId: Number(id), userId: profile.id });
      }
      if (selectedChannel?.unreadMessagesCount === null) {
        connectChannelToManager({ channelId: Number(id), userId: profile.id });
      }
    }
  }, [profile.id, selectedChannel.id]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    return (): void => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);

  return id ? (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      {(!selectedChannel.reclamationId || isAdmin) && (
        <>
          <ChatHeader />
          <Divider />
        </>
      )}
      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <MessageList />
          <Divider />
          <MessageInput />
        </Box>
        <RightSideBar />
      </Box>
    </Box>
  ) : (<></>);
});
