import React from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

type CommonListToolbarProps = {
  searchValue: string;
  onSearch: (newSearchValue: string) => void;
};

export const CommonListToolbar:React.FC<CommonListToolbarProps> = ({
  searchValue,
  onSearch,
}): JSX.Element => (
  <RootStyle>
    <SearchStyle
      value={searchValue}
      onChange={(e): void => onSearch(e.target.value)}
      placeholder="Search..."
      startAdornment={(
        <InputAdornment position="start">
          <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
        </InputAdornment>
          )}
    />
  </RootStyle>
);
