import { Theme } from '@material-ui/core/styles';

type DialogReturn = {
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: string;
        borderTop: number;
        borderBottom: number
      }
    }
  };
  MuiDialog: {
    styleOverrides: {
      paper: {
        boxShadow: string;
        '&.MuiDialog-paperFullScreen': { borderRadius: number };
        '&.MuiDialog-paper .MuiDialogActions-root': { padding: string };
        '@media (max-width: 663.95px)': { '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': { maxWidth: string } };
        '&.MuiPaper-rounded': { borderRadius: string | number };
        '@media (max-width: 600px)': { margin: string } };
      paperFullWidth: { width: string }
    }
  };
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: string
      }
    }
  };
  MuiDialogActions: {
    styleOverrides: {
      root: {
        '& > :not(:first-of-type)': { marginLeft: string }
      }
    }
  }
}
export const Dialog = (theme: Theme): DialogReturn => ({
  MuiDialog: {
    styleOverrides: {
      paper: {
        boxShadow: theme.customShadows.z24,
        '&.MuiPaper-rounded': {
          borderRadius: theme.shape.borderRadius,
        },
        '&.MuiDialog-paperFullScreen': {
          borderRadius: 0,
        },
        '&.MuiDialog-paper .MuiDialogActions-root': {
          padding: theme.spacing(3),
        },
        '@media (max-width: 600px)': {
          margin: theme.spacing(2),
        },
        '@media (max-width: 663.95px)': {
          '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
            maxWidth: '100%',
          },
        },
      },
      paperFullWidth: {
        width: '100%',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        borderTop: 0,
        borderBottom: 0,
        padding: theme.spacing(3),
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        '& > :not(:first-of-type)': {
          marginLeft: theme.spacing(1.5),
        },
      },
    },
  },
});
