import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Tab,
} from '@material-ui/core';
import {
  LoadingButton, TabList, TabPanel, TabContext,
} from '@material-ui/lab';
import { StrollerPartValues } from '../../../types';
import { useStore } from '../../../hooks';
import { LoadingScreen } from '../../../components';

type FormProps = {
  onAction: (values: StrollerPartValues) => Promise<void>,
}

export const Form: React.FC<FormProps> = ({ onAction }): JSX.Element => {
  const [tabValue, setTabValue] = useState<string>('0');

  const {
    control, handleSubmit, formState: { isSubmitting },
  } = useFormContext();

  const {
    languages: {
      fetchLanguages,
      items,
    },
  } = useStore();

  useEffect(() => {
    if (items.length === 0) {
      fetchLanguages();
    }
  }, [items]);

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setTabValue(newValue);
  };

  const isReady = !!items.length;

  return isReady ? (
    <form onSubmit={handleSubmit(onAction)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Controller
                name="name"
                control={control}
                render={({
                  field: { onBlur, onChange, value }, fieldState: { error },
                }): JSX.Element => (
                  <TextField
                    fullWidth
                    label="Part name"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <TabContext value={tabValue}>
                <TabList onChange={handleChange} variant="scrollable">
                  {items.map((language, index) => (
                    <Tab key={language.name} label={language.name} value={String(index)} />
                  ))}
                </TabList>

                <TabPanel key={tabValue} value={tabValue}>
                  <Controller
                    name={`locales.${Number(tabValue)}.name`}
                    control={control}
                    render={({
                      field: {
                        onBlur, onChange, value,
                      }, fieldState: { error },
                    }): JSX.Element => (
                      <TextField
                        fullWidth
                        label="Name"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </TabPanel>
              </TabContext>
              <Controller
                name="ordering"
                control={control}
                render={({
                  field: { onBlur, onChange, value }, fieldState: { error },
                }): JSX.Element => (
                  <TextField
                    fullWidth
                    label="Order"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  ) : (<LoadingScreen />);
};
