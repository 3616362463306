import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  Paper,
  useMediaQuery,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { SidebarContent } from './SidebarContent';

const RootStyle = styled('div')(({ theme }) => ({
  width: 240,
  flexShrink: 0,
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  borderLeft: `solid 1px ${theme.palette.divider}`,
  transition: theme.transitions.create('width'),
}));

const ToggleButtonStyle = styled('div')(({ theme }) => ({
  borderRight: 0,
  display: 'flex',
  overflow: 'hidden',
  position: 'absolute',
  alignItems: 'center',
  top: theme.spacing(1),
  left: theme.spacing(-4),
  width: theme.spacing(4),
  height: theme.spacing(4),
  justifyContent: 'center',
  boxShadow: theme.customShadows.z8,
  border: `solid 1px ${theme.palette.divider}`,
  borderTopLeftRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  borderBottomLeftRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  '&:hover': { backgroundColor: 'rgba(145, 158, 171, 0.56)' },
}));

export const RightSideBar: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const rightSideBarRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isOpenedSidebar, setIsOpenedSidebar] = useState<boolean>(true);

  useEffect(() => {
    if (isMobile) {
      return setIsOpenedSidebar(false);
    }
    return setIsOpenedSidebar(true);
  }, [isMobile]);

  const toggleOpenSidebar = useCallback((): void => setIsOpenedSidebar((prev) => !prev), []);

  useOnClickOutside(rightSideBarRef, toggleOpenSidebar);

  return isMobile ? (
    <RootStyle
      sx={{
        width: 0,
        ...(!isOpenedSidebar && {
          width: 0,
          '& > *': { overflow: 'hidden' },
        }),
      }}
    >
      <ToggleButtonStyle onClick={toggleOpenSidebar}>
        <Icon
          width={16}
          height={16}
          icon={isOpenedSidebar ? arrowIosForwardFill : arrowIosBackFill}
        />
      </ToggleButtonStyle>
      <Drawer anchor="right" open={isOpenedSidebar} variant="temporary">
        <Paper ref={rightSideBarRef} sx={{ width: '250px', height: '100%' }}>
          <SidebarContent />
        </Paper>
      </Drawer>
    </RootStyle>
  ) : (
    <RootStyle
      sx={{
        ...(!isOpenedSidebar && {
          width: 0,
          '& > *': { overflow: 'hidden' },
        }),
      }}
    >
      <ToggleButtonStyle onClick={toggleOpenSidebar}>
        <Icon
          width={16}
          height={16}
          icon={isOpenedSidebar ? arrowIosForwardFill : arrowIosBackFill}
        />
      </ToggleButtonStyle>
      <SidebarContent />
    </RootStyle>
  );
};
