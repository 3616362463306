import * as yup from 'yup';
import { objectIdSchema, stringReqSchema } from '../../../utils';

const rules = yup.object({
  title: stringReqSchema('Title'),
  description: yup.string().required('Description is required').max(255, 'Description must be less than 255 characters long'),
  content: stringReqSchema('Context'),
  strollerParts: yup.array().of(objectIdSchema('Stroller part')).default([]),
});

export default rules;
