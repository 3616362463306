import {
  applySnapshot, cast, flow, getEnv, Instance, SnapshotOut, types,
} from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import {
  Filters,
  FiltersModel,
  ListMetaDataModel, Option,
  ReclamationComment,
  ReclamationCommentModel,
  ReclamationCommentValues,
  ReclamationConsumerValues,
  ReclamationInfoValues,
  ReclamationItem,
  ReclamationItemModel,
  ReclamationModel,
  ReclamationPartValues,
  ReclamationsListData,
  ReclamationsParams,
  ReclamationStatusValues,
  UnconnectedReclamationItemModel,
  UnconnectedReclamationValues,
} from '../types';
import {
  COMMON_SCHEMA, DEFAULT_ID, DEFAULT_META,
  DEFAULT_PARTS_MODEL, DEFAULT_RECLAMATION_FILTERS, FIRST_PAGE, ResponseStatuses,
} from '../constants';
import { mapEntityToOption } from '../utils';
import { ApiErrorData } from '../services/api/api.helpers';

const MAX_COUNT_PARTS = 3;

export const ReclamationStore = types.model('ReclamationStore')
  .props({
    items: types.optional(types.array(ReclamationItemModel), []),
    meta: types.optional(ListMetaDataModel, DEFAULT_META),
    reclamation: types.maybeNull(ReclamationModel),
    comments: types.optional(types.array(ReclamationCommentModel), []),
    unconnectedReclamations: types.optional(types.array(UnconnectedReclamationItemModel), []),
    filters: types.optional(FiltersModel, DEFAULT_RECLAMATION_FILTERS),
    page: types.optional(types.number, FIRST_PAGE),
  })
  .actions((self) => ({
    fetchReclamations: flow(function* fetchReclamations(params: ReclamationsParams = {}) {
      try {
        const {
          items,
          meta,
        }: ReclamationsListData = yield getEnv(self).api.fetchReclamations(params);
        applySnapshot(self.items, items);
        self.meta = meta;
        self.reclamation = null;
      } catch (e) {
        toast.error('Error with getting claims');
      }
    }),
    getReclamationById: flow(function* getReclamationById(id: string) {
      self.reclamation = null;
      const reclamation = yield getEnv(self).api.getReclamationById(id);
      const countOfParts = reclamation?.parts.length;
      if (countOfParts !== MAX_COUNT_PARTS) {
        // set default parts, if there are no max count
        self.reclamation = cast({
          ...reclamation,
          parts: [
            ...reclamation?.parts,
            ...DEFAULT_PARTS_MODEL.slice(0, MAX_COUNT_PARTS - countOfParts),
          ],
        });
      } else {
        self.reclamation = cast({
          ...reclamation,
        });
      }
    }),
    updateReclamation: flow(function* updateReclamation(id: string, data: ReclamationInfoValues) {
      try {
        const reclamation = yield getEnv(self).api.updateReclamation(id, data);
        const countOfParts = reclamation?.parts.length;
        if (countOfParts !== MAX_COUNT_PARTS) {
          // set default parts, if there are no max count
          self.reclamation = cast({
            ...reclamation,
            parts: [
              ...reclamation?.parts,
              ...DEFAULT_PARTS_MODEL.slice(0, MAX_COUNT_PARTS - countOfParts),
            ],
          });
        } else {
          self.reclamation = cast({
            ...reclamation,
          });
        }
        toast.success('Claim was updated successfully');
      } catch (e) {
        const error = e as ApiErrorData;
        if (error.message && error.statusCode === ResponseStatuses.FORBIDDEN) {
          toast.error(error.message);
        } else {
          toast.error('Error with updating claim');
        }
      }
    }),
    updateReclamationConsumerInfo: flow(function* updateReclamationConsumerInfo(
      id: string,
      data: ReclamationConsumerValues,
    ) {
      try {
        const info = yield getEnv(self).api.updateReclamationConsumerInfo(id, data);
        self.reclamation = cast({ ...self.reclamation, ...info });
        toast.success('Consumer info was updated successfully');
      } catch (e) {
        toast.error('Error with updating consumer info');
      }
    }),
    updateReclamationStatus: flow(function* updateReclamationStatus(
      id: string,
      data: ReclamationStatusValues,
    ) {
      try {
        yield getEnv(self).api.updateReclamationStatus(id, data);
        toast.success('Status of the claim was updated successfully');
      } catch (e) {
        const error = e as ApiErrorData;
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error('Error with updating status of the claim');
        }
      }
    }),
    getCommentsByReclamationId: flow(function* getCommentsByReclamationId(id: string) {
      try {
        self.comments = yield getEnv(self).api.getCommentsByReclamationId(id);
      } catch (e) {
        toast.error('Error with getting comments');
      }
    }),
    createCommentByReclamationId: flow(function* getCommentsByReclamationId(
      id: string,
      data: ReclamationCommentValues,
    ) {
      try {
        const comment: ReclamationComment = yield getEnv(self)
          .api
          .createCommentByReclamationId(id, data);
        applySnapshot(self.comments, [...self.comments, comment]);
        toast.success('Comment was added successfully');
      } catch (e) {
        toast.error('Error with creating comment');
      }
    }),
    updateCommentByReclamationId: flow(function* getCommentsByReclamationId(
      id: string,
      commentId: number,
      data: ReclamationCommentValues,
    ) {
      try {
        const comment: ReclamationComment = yield getEnv(self)
          .api
          .updateCommentByReclamationId(id, commentId, data);
        const comId = self.comments.findIndex((item) => item.id === commentId);
        if (comId !== DEFAULT_ID) {
          applySnapshot(self.comments[comId], comment);
          toast.success('Comment was updated successfully');
        }
      } catch (e) {
        toast.error('Error with updating comment');
      }
    }),
    fetchUnconnectedReclamations: flow(function* fetchUnconnectedReclamations(
      params: UnconnectedReclamationValues,
    ) {
      try {
        const response = yield getEnv(self).api.fetchUnconnectedReclamations(params);
        applySnapshot(self.unconnectedReclamations, response);
      } catch (e) {
        toast.error('Error with getting claims without channel');
      }
    }),
    resetSelectedReclamation(): void {
      self.reclamation = null;
    },
    changeFilters(filters: Filters): void {
      self.filters = cast({
        ...filters,
      });
    },
    changePage(page: number): void {
      self.page = page;
    },
  }))
  .actions((self) => ({
    deleteReclamation: flow(function* deleteReclamation(id: number) {
      try {
        yield getEnv(self).api.deleteReclamation(id);
        const params: ReclamationsParams = { page: self.meta.currentPage, ...self.filters };
        self.fetchReclamations(params);
        toast.success('Claim was deleted successfully');
      } catch (e) {
        toast.error('Error with deleting claim');
      }
    }),
  }))
  .views((self) => ({
    get isRealConsumer(): boolean {
      return !!self.reclamation?.consumer?.id;
    },
    getReclamationPartByIndex(index: number): ReclamationPartValues | undefined {
      return self.reclamation?.parts[index];
    },
    get reclamationsAutoComplete(): Option[] {
      return self.items.map((item) => mapEntityToOption<ReclamationItem>(item, COMMON_SCHEMA));
    },
    get getFilters(): Filters {
      return self.filters;
    },
    get currentPage(): number {
      return self.page;
    },
  }));

export type ReclamationStore = Instance<typeof ReclamationStore>

export type ReclamationStoreSnapShot = SnapshotOut<typeof ReclamationStore>

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createReclamationStoreModel = () => types.optional(ReclamationStore, {});
