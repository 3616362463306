import { last } from 'lodash';
import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Link, Typography, Breadcrumbs, BreadcrumbsProps,
} from '@material-ui/core';

// ----------------------------------------------------------------------

const Separator = (
  <Box
    component="span"
    sx={{
      width: 4,
      height: 4,
      borderRadius: '50%',
      bgcolor: 'text.disabled',
    }}
  />
);

type TLink = {
  href?: string;
  name: string;
  icon?: ReactElement;
};

type Link = {
  link: TLink;
}

const LinkItem:React.FC<Link> = ({ link }) => {
  const { href, name, icon } = link;
  return (
    <Link
      key={name}
      variant="body2"
      component={RouterLink}
      to={href || '#'}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        '& > div': { display: 'inherit' },
      }}
    >
      {icon && (
        <Box
          sx={{
            mr: 1,
            '& svg': { width: 20, height: 20 },
          }}
        >
          {icon}
        </Box>
      )}
      {name}
    </Link>
  );
};

export interface MBreadcrumbsProps extends BreadcrumbsProps {
  links: TLink[];
  activeLast?: boolean;
}

export const MBreadcrumbs:React.FC<MBreadcrumbsProps> = (
  { links, activeLast = false, ...other },
) => {
  const currentLink = last(links)?.name;

  return (
    <Breadcrumbs separator={Separator} {...other}>
      {links.map((link) => {
        if (!activeLast) {
          return (
            <div key={link.name}>
              {link.name !== currentLink ? (
                <LinkItem link={link} />
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: 260,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    color: 'text.disabled',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {currentLink}
                </Typography>
              )}
            </div>
          );
        }
        return <LinkItem key={link.name} link={link} />;
      })}
    </Breadcrumbs>
  );
};
