import React from 'react';
import { Module, PermissionAction } from '../constants';

const path = (root: string, subLink: string): string => `${root}${subLink}`;

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export type RouteConfig = {
  exact: boolean;
  path: string;
  component: React.ComponentType;
};

export type ProtectedRouteConfig = {
  action: PermissionAction,
  module: Module,
} & RouteConfig;

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register/:token'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password/:token'),
};

export const PATH_PAGE = {
  home: '/',
  page404: '/404',
  page500: '/500',
  feedback: '/feedback/:lang',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  profile: path(ROOTS_DASHBOARD, '/profile'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:id'),
  },
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/users'),
    list: path(ROOTS_DASHBOARD, '/users/list'),
    edit: path(ROOTS_DASHBOARD, '/users/:id'),
  },
  reclamations: {
    root: path(ROOTS_DASHBOARD, '/claims'),
    list: path(ROOTS_DASHBOARD, '/claims/list'),
    view: path(ROOTS_DASHBOARD, '/claims/:id'),
  },
  consumers: {
    root: path(ROOTS_DASHBOARD, '/customers'),
    list: path(ROOTS_DASHBOARD, '/customers/list'),
    view: path(ROOTS_DASHBOARD, '/customers/:id'),
    create: path(ROOTS_DASHBOARD, '/customers/new'),
  },
  knowledgeBases: {
    root: path(ROOTS_DASHBOARD, '/knowledge-base'),
    list: path(ROOTS_DASHBOARD, '/knowledge-base/list'),
    post_root: path(ROOTS_DASHBOARD, '/knowledge-base/post'),
    post: path(ROOTS_DASHBOARD, '/knowledge-base/post/:id'),
    newPost: path(ROOTS_DASHBOARD, '/knowledge-base/new'),
    edit_root: path(ROOTS_DASHBOARD, '/knowledge-base/edit'),
    editPost: path(ROOTS_DASHBOARD, '/knowledge-base/edit/:id'),
  },
  shops: {
    root: path(ROOTS_DASHBOARD, '/shops'),
    list: path(ROOTS_DASHBOARD, '/shops/list'),
    view: path(ROOTS_DASHBOARD, '/shops/:id'),
  },
  countries: {
    root: path(ROOTS_DASHBOARD, '/countries'),
    list: path(ROOTS_DASHBOARD, '/countries/list'),
    editCountry: path(ROOTS_DASHBOARD, '/countries/:id'),
  },
  strollers: {
    root: path(ROOTS_DASHBOARD, '/registered-strollers'),
    list: path(ROOTS_DASHBOARD, '/registered-strollers/list'),
    editStroller: path(ROOTS_DASHBOARD, '/registered-strollers/:id'),
  },
  deviationCodes: {
    root: path(ROOTS_DASHBOARD, '/deviation-codes'),
    list: path(ROOTS_DASHBOARD, '/deviation-codes/list'),
    edit: path(ROOTS_DASHBOARD, '/deviation-codes/:id'),
    create: path(ROOTS_DASHBOARD, '/deviation-code/new'),
  },
  strollerParts: {
    root: path(ROOTS_DASHBOARD, '/stroller-parts'),
    list: path(ROOTS_DASHBOARD, '/stroller-parts/list'),
    edit: path(ROOTS_DASHBOARD, '/stroller-parts/:id'),
    create: path(ROOTS_DASHBOARD, '/stroller-parts/new'),
  },
  spareParts: {
    root: path(ROOTS_DASHBOARD, '/spare-parts'),
    list: path(ROOTS_DASHBOARD, '/spare-parts/list'),
    edit: path(ROOTS_DASHBOARD, '/spare-parts/:id'),
    create: path(ROOTS_DASHBOARD, '/spare-parts/new'),
  },
  customerCare: path(ROOTS_DASHBOARD, '/customer-care'),
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
