import { HeadCell } from '../../../types/grid';
import { PATH_DASHBOARD } from '../../../app';
import { Link } from '../../../types';
import { DEFAULT_AUTOCOMPLETE_ID } from '../../../constants';

export const TABLE_HEAD_LIST: readonly HeadCell[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'part', label: 'Stroller part' },
  { id: 'model', label: 'Model' },
  { id: 'more_menu', label: '' },
] as const;

export const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'List' },
];

export const DEFAULT_SPARE_PART = {
  name: '',
  part: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  model: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  price: '',
};
