import React, { BaseSyntheticEvent, KeyboardEvent } from 'react';
import {
  IconButton, Stack, TextField,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import roundSend from '@iconify/icons-ic/round-send';
import { useForm, Controller } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from 'lodash';
import { ReclamationCommentValues } from '../../../types';
import rules from './rules';

type CommentInputProps = {
    onSubmit: (values: ReclamationCommentValues) => Promise<void>
    comment?: string,
    render?: () => JSX.Element,
}

const ENTER = 'Enter';

export const CommentForm: React.FC<CommentInputProps> = observer(({
  onSubmit,
  comment,
  render,
}): JSX.Element => {
  const { control, handleSubmit, reset } = useForm<ReclamationCommentValues>({
    defaultValues: {
      comment: comment || '',
    },
    resolver: yupResolver(rules),
  });

  const handleAddComment = async (values: ReclamationCommentValues): Promise<void> => {
    await onSubmit(values);
    reset();
  };

  const handleAddCommentOnEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
    const event = e as BaseSyntheticEvent;
    const target = event as KeyboardEvent<HTMLInputElement>;
    if (target.key === ENTER) {
      handleSubmit(handleAddComment)();
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      <Controller
        name="comment"
        control={control}
        defaultValue=""
        render={({
          field: { onBlur, onChange, value }, fieldState: { error },
        }): JSX.Element => (
          <TextField
            fullWidth
            size="small"
            onBlur={onBlur}
            onChange={onChange}
            inputProps={{
              onKeyDown: handleAddCommentOnEnter,
            }}
            value={value}
            error={!!error}
            helperText={error?.message}
            placeholder="Write a comment…"
            sx={{
              mr: 1,
              '& fieldset': {
                borderWidth: '1px !important',
                borderColor: (theme): string => `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
        )}
      />
      <IconButton onClick={handleSubmit(handleAddComment)}>
        <Icon icon={roundSend} width={24} height={24} />
      </IconButton>
      {isFunction(render) && render()}
    </Stack>
  );
});
