import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { ButtonAnimate } from '../animate';

const MIconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({
    onClick,
    children,
    ...other
  }, ref) => (
    <ButtonAnimate>
      <IconButton ref={ref} {...other} onClick={onClick}>
        {children}
      </IconButton>
    </ButtonAnimate>
  ),
);

export default MIconButton;
