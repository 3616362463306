import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { observer } from 'mobx-react-lite';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { PATH_DASHBOARD } from '../../app';
import { useStore } from '../../hooks';
import {
  Page,
  HeaderBreadcrumbs,
  Markdown,
  ConfirmDialog, PermissionWrapper,
} from '../../components';
import { KnowledgeBaseParams } from '../../types';
import { KnowledgeBasePostHero } from './components';
import { getFileUrl } from '../../utils';
import { PostAttachments } from './components/PostAttachments';
import { Module, PermissionAction } from '../../constants';

const SkeletonLoad: JSX.Element = (
  <>
    <Skeleton
      width="100%"
      height={560}
      variant="rectangular"
      sx={{ borderRadius: 2 }}
    />
    <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
      <Skeleton variant="circular" width={64} height={64} />
      <Box sx={{ flexGrow: 1, ml: 2 }}>
        <Skeleton variant="text" height={20} />
        <Skeleton variant="text" height={20} />
        <Skeleton variant="text" height={20} />
      </Box>
    </Box>
  </>
);

const CoverVideoStyle = styled('video')(() => ({
  width: '100%',
  height: '100%',
  maxHeight: '500px',
  objectFit: 'contain',
  marginTop: '20px',
  marginBottom: '20px',
}));

const LINKS = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Knowledge base', href: PATH_DASHBOARD.knowledgeBases.list },
  { name: 'Post' },
];

export const KnowledgeBasePost: React.FC = observer(() => {
  const theme = useTheme();
  const history = useHistory();
  const id = useParams<KnowledgeBaseParams>();
  const {
    knowledgeBasesModel: {
      fetchKnowledgeBase,
      getKnowledgeBaseById,
      deleteKnowledgeBase,
    },
  } = useStore();
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentPost = getKnowledgeBaseById(id.id);

  const toggleDeleteModal = (value: boolean) => (): void => {
    setIsOpenedDeleteModal(value);
  };

  const handleDelete = async (): Promise<void> => {
    await deleteKnowledgeBase(id);
    setIsOpenedDeleteModal(false);
    history.push(PATH_DASHBOARD.knowledgeBases.list);
  };

  useEffect(() => {
    if (!currentPost) {
      fetchKnowledgeBase(id);
    }
    window.scrollTo(0, 0);
  }, []);

  // todo: fix control buttons (isMobile condition)

  return (
    <Page title="Knowledge base: Post Details | Minimal-UI">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Post Details"
          links={LINKS}
          action={
            !isMobile && (
              <>
                <PermissionWrapper
                  action={PermissionAction.EDIT}
                  module={Module.KNOWLEDGE_BASE}
                >
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.knowledgeBases.edit_root}/${id.id}`}
                    startIcon={<Icon icon={editFill} />}
                  >
                    Edit
                  </Button>
                </PermissionWrapper>
                <PermissionWrapper
                  action={PermissionAction.DELETE}
                  module={Module.KNOWLEDGE_BASE}
                >
                  <Button
                    variant="contained"
                    onClick={toggleDeleteModal(true)}
                    startIcon={<Icon icon={trash2Outline} />}
                    sx={{ marginLeft: 3 }}
                  >
                    Delete
                  </Button>
                </PermissionWrapper>
              </>
            )
          }
        />
        {isMobile && (
        <Stack
          mb={6}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <PermissionWrapper
            action={PermissionAction.EDIT}
            module={Module.KNOWLEDGE_BASE}
          >
            <Button
              variant="contained"
              component={RouterLink}
              to={`${PATH_DASHBOARD.knowledgeBases.edit_root}/${id.id}`}
              startIcon={<Icon icon={editFill} />}
            >
              Edit
            </Button>
          </PermissionWrapper>
          <PermissionWrapper
            action={PermissionAction.DELETE}
            module={Module.KNOWLEDGE_BASE}
          >
            <Button
              variant="contained"
              onClick={toggleDeleteModal(true)}
              startIcon={<Icon icon={trash2Outline} />}
              sx={{ marginLeft: 3 }}
            >
              Delete
            </Button>
          </PermissionWrapper>
        </Stack>
        )}
        {currentPost ? (
          <Card>
            <KnowledgeBasePostHero
              title={currentPost.title}
              preview={currentPost.files[0]?.preview}
              type={currentPost.files[0]?.type}
            />
            <Box sx={{ p: { xs: 3, md: 5 } }}>
              <Typography variant="h6" sx={{ mb: 5 }}>
                {currentPost.description}
              </Typography>
              <Markdown>{currentPost.content}</Markdown>
              <PostAttachments posts={currentPost.files} />
              {currentPost.files?.length > 1 && currentPost.files.map((item, index) => (
                index >= 1 && item.type.includes('video') && (
                <CoverVideoStyle autoPlay loop src={getFileUrl(item.preview)} />
                )
              ))}
            </Box>
          </Card>
        ) : (
          SkeletonLoad
        )}
      </Container>
      <ConfirmDialog
        isOpened={isOpenedDeleteModal}
        onClose={toggleDeleteModal(false)}
        callback={handleDelete}
      />
    </Page>
  );
});
