import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Autocomplete,
  Box,
  Card,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import { SxProps } from '@material-ui/system';
import { Theme } from '@material-ui/core/styles';
import { LoadingScreen, UploadAvatar } from '../../../../components';
import { Option, ProfileValues } from '../../../../types';
import { useStore, useRequest } from '../../../../hooks';
import rules from './rules';
import {
  getOption,
  fData,
  getFileUrl,
  getCountryPhoneCode,
} from '../../../../utils';
import { uploadFile } from '../../../../services';

const MAX_SIZE_AVATAR = 3145728;
const ACCEPT_TYPES = 'image/png, image/gif, image/jpeg, image/jpg';

const AVATAR_STYLES: SxProps<Theme> = {
  mt: 2,
  mx: 'auto',
  display: 'block',
  textAlign: 'center',
  color: 'text.secondary',
};

export const ProfileForm: React.FC = observer((): JSX.Element => {
  const {
    auth: {
      profile,
      updateProfile,
      uploadAvatar,
      removeAvatar,
      getProfile,
    },
    countriesModel: {
      getOfficeCountryById,
      fetchOfficeCountries,
      officeCountriesAutoComplete,
    },
  } = useStore();

  const { control, handleSubmit, setValue } = useForm<ProfileValues>({
    defaultValues: {
      name: profile.name,
      surname: profile.surname,
      phone: profile.phone,
      email: profile.email,
      notify: profile.notify,
      country: {
        id: profile.country.id,
      },
    },
    mode: 'onBlur',
    resolver: yupResolver(rules),
  });

  const handleChangeCountryId = useCallback((_, data: Option): void => {
    setValue('country.id', data.value);
    const country = getOfficeCountryById(data.value);
    const phoneCode = getCountryPhoneCode(country?.code || '');
    setValue('phone', phoneCode);
  }, []);

  const handleDrop = useCallback(async (files: Array<File>) => {
    try {
      const fileData = await uploadFile(files[0]);
      await uploadAvatar({ fileId: fileData?.id || '' });
    } catch (e) {
      toast.error('Error with uploading avatar');
    }
  }, [uploadAvatar]);

  const handleDeleteAvatar = useCallback(async () => {
    await removeAvatar();
  }, []);

  const country = getOfficeCountryById(profile.country.id);

  const { isFetching: isFetchingOfficeCountries } = useRequest({
    action: fetchOfficeCountries,
    condition: !officeCountriesAutoComplete.length && !country,
  });

  const getAvatarUrl = useMemo(() => {
    if (profile.avatar?.file) {
      return getFileUrl(profile.avatar.file);
    }
    return null;
  }, [profile.avatar?.file]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (country && !profile.phone) {
      const phoneCode = getCountryPhoneCode(country.code || '');
      setValue('phone', phoneCode);
    }
  }, [profile.country.id, officeCountriesAutoComplete, country]);

  const isReady = !!profile && !isFetchingOfficeCountries;

  return isReady ? (
    <form onSubmit={handleSubmit(updateProfile)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 3, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <UploadAvatar
                accept={ACCEPT_TYPES}
                file={getAvatarUrl}
                maxSize={MAX_SIZE_AVATAR}
                onDrop={handleDrop}
                onDelete={handleDeleteAvatar}
                caption={(
                  <Typography
                    variant="caption"
                    sx={AVATAR_STYLES}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br />
                    {' '}
                    max size of
                    {' '}
                    {fData(MAX_SIZE_AVATAR)}
                  </Typography>
                )}
              />
            </Box>
            <Controller
              name="notify"
              control={control}
              render={({
                field: { onBlur, onChange, value },
              }): JSX.Element => (
                <FormControlLabel
                  labelPlacement="start"
                  label={(
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Notifications
                    </Typography>
                    )}
                  sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                  control={(
                    <Switch
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      checked={value}
                    />
                    )}
                />
              )}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Name"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="surname"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Surname"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="phone"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Phone number"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value || getCountryPhoneCode(country?.code || '+')}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onBlur, onChange, value }, fieldState: { error },
                  }): JSX.Element => (
                    <TextField
                      fullWidth
                      label="Email address"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Controller
                  name="country.id"
                  control={control}
                  render={({
                    field: { value },
                  }): JSX.Element => (
                    <Autocomplete
                      disableClearable
                      autoComplete={false}
                      fullWidth
                      value={getOption(officeCountriesAutoComplete, value)}
                      options={officeCountriesAutoComplete}
                      onChange={handleChangeCountryId}
                      getOptionLabel={(option: Option): string => option.label}
                      renderInput={(params): JSX.Element => <TextField {...params} label="Country" />}
                    />
                  )}
                />
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={false}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  ) : (<LoadingScreen />);
});
