import { ApiResponse, create } from 'apisauce';
import { getTokenFromStorage } from '../utils/localStorage';
import { ROUTES } from '../constants';
import {
  ClaimsListParams, CountriesListParams, ExelFilesResponse,
} from '../types';

const token = getTokenFromStorage();

export const exelService = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 40000,
  headers: {
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'Content-Disposition': 'attachment; filename="all_claims_report.xlsx"',
    Authorization: token ? `Bearer ${token}` : '',
  },
});

export const getFileAllClaimsReport = async (
  params: ClaimsListParams,
): Promise<ExelFilesResponse | undefined> => {
  try {
    const response: ApiResponse<ExelFilesResponse> = await exelService.get(
      ROUTES.REPORTS.FILE_ALL_CLAIMS, params,
    );
    return response.data;
  } catch (e) {
    throw new Error('Bad data');
  }
};

export const getFileByCountriesReport = async (
  params: CountriesListParams,
): Promise<ExelFilesResponse | undefined> => {
  try {
    const response: ApiResponse<ExelFilesResponse> = await exelService.get(
      ROUTES.REPORTS.FILE_ALL_COUNTRIES, params,
    );
    return response.data;
  } catch (e) {
    throw new Error('Bad data');
  }
};
