import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

type RadioReturn = { MuiRadio: { styleOverrides: { root: { padding: string; svg: { fontSize: number; '&[font-size=small]': { fontSize: number } } } } } }
export const Radio = (theme: Theme): RadioReturn => ({
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1),
        svg: {
          fontSize: 24,
          '&[font-size=small]': {
            fontSize: 20,
          },
        },
      },
    },
  },
});
