import { ReclamationStatus } from './reclamation';

export const DEFAULT_ID = -1;
export const DEFAULT_AUTOCOMPLETE_ID = 1;

export const DEFAULT_OBJECT_ID = {
  id: null,
};

export const DEFAULT_PROFILE = {
  id: DEFAULT_ID,
  name: '',
  surname: '',
  email: '',
  notify: false,
  phone: '',
  country: {
    id: DEFAULT_ID,
  },
  avatar: {
    id: null,
    file: null,
  },
  role: 0,
  customerCare: false,
};

export const DEFAULT_META = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 0,
};

export const ALL_COUNTRIES_REPORT_META = {
  ...DEFAULT_META,
  totalPriceForSelectedCountries: 0,
};

export const DEFAULT_RECLAMATION_FILTERS = {
  id: '',
  firstName: '',
  lastName: '',
  shopName: '',
  serialNumber: '',
  inRepair: undefined,
  status: undefined,
  countryId: undefined,
  modelId: undefined,
  dateFrom: null,
  dateTo: null,
};

export const DEFAULT_PART = {
  id: null,
  strollerPart: DEFAULT_OBJECT_ID,
  sparePart: DEFAULT_OBJECT_ID,
  deviationCodes: [DEFAULT_OBJECT_ID, DEFAULT_OBJECT_ID, DEFAULT_OBJECT_ID],
  warranty: null,
  price: '',
  quantity: 0,
  wayOfSolution: null,
};

export const DEFAULT_PARTS = [DEFAULT_PART, DEFAULT_PART, DEFAULT_PART];

export const DEFAULT_RECLAMATION = {
  country: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  model: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  color: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  description: '',
  shop: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  deliveryCity: '',
  deliveryStreet: '',
  deliveryPostCode: '',
  deliveryCountry: {
    id: DEFAULT_AUTOCOMPLETE_ID,
  },
  managerComment: '',
  manager: {
    id: null,
  },
  pickUpTrackingNumber: '',
  pickupService: '',
  pickUpDate: '',
  deliveryTrackingNumber: '',
  deliveryService: '',
  labourCost: '',
  qualityReport: '',
  status: ReclamationStatus.NEW,
  parts: DEFAULT_PARTS,
  solutionDate: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

export const DEFAULT_CUSTOMER = {
  serialNumber: '',
  datePurchase: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};
