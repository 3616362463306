import React, { useCallback, useMemo } from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { getFileType, getFileName, getFileThumb } from '../../utils';
import { FORMAT_IMG } from '../../constants';

const ThumbStyle = styled('div')(({ theme }) => ({
  width: 48,
  height: 48,
  display: 'flex',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  border: `solid 1px ${theme.palette.divider}`,
  color: theme.palette.text.disabled,
  '& img': { width: '100%', height: '100%' },
}));

type FileItemsProps = {
  fileUrl: string,
  onClick?: () => void,
}

export const FileAttachment: React.FC<FileItemsProps> = ({ fileUrl, onClick }): JSX.Element => {
  const fileType = getFileType(fileUrl);
  const isImg = useMemo(() => FORMAT_IMG.includes(fileType), [fileType]);

  const handleOpenFile = useCallback((): void => {
    if (!isImg) {
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileUrl.split('/').pop() as string;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else if (onClick) {
      onClick();
    }
  }, [isImg, onClick]);

  return (
    <>
      <Box key={fileUrl}>
        <ThumbStyle onClick={handleOpenFile}>
          {getFileThumb(fileUrl)}
        </ThumbStyle>
        <Box
          sx={{
            mt: 0.5,
            maxWidth: 56,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography noWrap variant="caption">
            {getFileName(fileUrl)}
          </Typography>
          <Typography variant="caption">
            .
            {fileType}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
