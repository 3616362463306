import { Icon } from '@iconify/react';
import infoFill from '@iconify/icons-eva/info-fill';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import { Theme } from '@material-ui/core/styles';
import { ColorSchema } from '../../types';

type OutlinedStyleReturn = {
  color: string;
  border: string;
  backgroundColor: string;
  '& .MuiAlert-icon': { color: string };
};

type FilledStyleReturn = {
  color: string;
};

type StandardStyleReturn = {
  color: string;
  backgroundColor: string;
  '& .MuiAlert-icon': { color: string };
};

type AlertReturn = {
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        success: JSX.Element;
        warning: JSX.Element;
        error: JSX.Element;
        info: JSX.Element;
      };
    };
    styleOverrides: {
      standardError: StandardStyleReturn;
      filledInfo: FilledStyleReturn;
      standardInfo: StandardStyleReturn;
      filledWarning: FilledStyleReturn;
      outlinedInfo: OutlinedStyleReturn;
      filledSuccess: FilledStyleReturn;
      message: {
        '& .MuiAlertTitle-root': {
          marginBottom: string;
        };
      };
      outlinedError: OutlinedStyleReturn;
      outlinedSuccess: OutlinedStyleReturn;
      standardSuccess: StandardStyleReturn;
      action: {
        '& button:not(:first-of-type)': {
          marginLeft: string;
        };
      };
      outlinedWarning: OutlinedStyleReturn;
      standardWarning: StandardStyleReturn;
      filledError: FilledStyleReturn;
    };
  };
};
export const Alert = ({ palette, spacing }: Theme): AlertReturn => {
  const { mode } = palette;
  const isLight = mode === 'light';

  const standardStyle = (color: ColorSchema): StandardStyleReturn => ({
    color: palette[color][isLight ? 'darker' : 'lighter'],
    backgroundColor: palette[color][isLight ? 'lighter' : 'darker'],
    '& .MuiAlert-icon': {
      color: palette[color][isLight ? 'main' : 'light'],
    },
  });
  const filledStyle = (color: ColorSchema): FilledStyleReturn => ({
    color: palette[color].contrastText,
  });

  const outlinedStyle = (color: ColorSchema): OutlinedStyleReturn => ({
    color: palette[color][isLight ? 'darker' : 'lighter'],
    border: `solid 1px ${palette[color][isLight ? 'light' : 'dark']}`,
    backgroundColor: palette[color][isLight ? 'lighter' : 'darker'],
    '& .MuiAlert-icon': {
      color: palette[color][isLight ? 'main' : 'light'],
    },
  });

  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          // eslint-disable-next-line react/react-in-jsx-scope
          error: <Icon icon={infoFill} />,
          // eslint-disable-next-line react/react-in-jsx-scope
          info: <Icon icon={alertCircleFill} />,
          // eslint-disable-next-line react/react-in-jsx-scope
          success: <Icon icon={checkmarkCircle2Fill} />,
          // eslint-disable-next-line react/react-in-jsx-scope
          warning: <Icon icon={alertTriangleFill} />,
        },
      },

      styleOverrides: {
        message: {
          '& .MuiAlertTitle-root': {
            // eslint-disable-next-line react/destructuring-assignment
            marginBottom: spacing(0.5),
          },
        },
        action: {
          '& button:not(:first-of-type)': {
            // eslint-disable-next-line react/destructuring-assignment
            marginLeft: spacing(1),
          },
        },

        standardInfo: standardStyle('info'),
        standardSuccess: standardStyle('success'),
        standardWarning: standardStyle('warning'),
        standardError: standardStyle('error'),

        filledInfo: filledStyle('info'),
        filledSuccess: filledStyle('success'),
        filledWarning: filledStyle('warning'),
        filledError: filledStyle('error'),

        outlinedInfo: outlinedStyle('info'),
        outlinedSuccess: outlinedStyle('success'),
        outlinedWarning: outlinedStyle('warning'),
        outlinedError: outlinedStyle('error'),
      },
    },
  };
};
