import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@material-ui/core/styles';
import { SxProps } from '@material-ui/system';
import { ClaimReport } from '../../../types';
import { FilesView } from './FilesView';
import { Label } from '../../../components';

type CELL = {
    name: string,
    render?: (data: ClaimReport, cell: CELL) => JSX.Element,
    coloredBackground?: boolean,
    coloredText?: boolean,
    fontWeight?: boolean,
}

const LABEL_STYLES: SxProps = {
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  lineHeight: '20px',
  margin: '5px 0',
  display: 'block',
  textAlign: 'center',
};

const REPORT_COLOR_LABEL = {
  'new one': 'secondary',
  reworked: 'primary',
};

const CELLS: CELL[] = [
  {
    name: 'id',
  },
  {
    name: 'date',
    render: (data, cell): JSX.Element => (<>{data[cell.name]}</>),
  },
  {
    name: 'description',
  },
  {
    name: 'serialNumber',
  },
  {
    name: 'finding',
    render: (data, cell): JSX.Element => (
      <div style={{ textAlign: 'center' }}>
        <div>{data[cell.name].name}</div>
        <div>{data.id}</div>
      </div>
    ),
  },
  {
    name: 'photos',
    render: (data, cell): JSX.Element => (
      <FilesView files={data[cell.name]} />
    ),
  },
  {
    name: 'parts',
    render: (data, cell): JSX.Element => (
      data[cell.name].map((item) => (
        <div style={{ display: 'block', whiteSpace: 'nowrap' }}>{item}</div>
      ))
    ),
  },
  {
    name: 'solution',
    coloredBackground: true,
    render: (data, cell): JSX.Element => (
      data[cell.name].map((item) => (
        item !== '' && (
          <Label
            color={REPORT_COLOR_LABEL[item] || 'default'}
            sx={LABEL_STYLES}
          >
            {item}
          </Label>
        )
      ))
    ),
  },
  {
    name: 'labourCost',
    coloredText: true,
    fontWeight: true,
  },
  {
    name: 'partsCost',
    coloredText: true,
    fontWeight: true,
  },
  {
    name: 'logisticCost',
    coloredText: true,
    fontWeight: true,
  },
  {
    name: 'sum',
    coloredText: true,
    fontWeight: true,
  },
];

type ClaimsItemProps = {
    data: ClaimReport,
}

export const ClaimsItem: React.FunctionComponent<ClaimsItemProps> = observer(({
  data,
}): JSX.Element => {
  const theme = useTheme();
  return (
    <TableRow>
      {CELLS.map((cell) => (
        <TableCell
          key={cell.name}
          sx={{
            color: cell.coloredText ? theme.palette.error.dark : '',
            fontWeight: cell.fontWeight ? 600 : 'normal',
          }}
        >
          {cell.render ? cell.render(data, cell) : data[cell.name]}
        </TableCell>
      ))}
    </TableRow>
  );
});
