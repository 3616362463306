import React, { useCallback, useEffect } from 'react';
import { useFormContext, Controller, FieldPath } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Stack,
  TextField,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { Option, SparePartValues } from '../../../types';
import { useAutocomplete, useStore } from '../../../hooks';
import { LoadingScreen } from '../../../components';
import { DEFAULT_AUTOCOMPLETE_ID, List } from '../../../constants';
import { fIso8601, getOption } from '../../../utils';

type FormProps = {
  onAction: (values: SparePartValues) => Promise<void>,
  dateUpdatingPrice?: string | null,
}

export const Form: React.FC<FormProps> = observer(({
  onAction,
  dateUpdatingPrice,
}): JSX.Element => {
  const {
    control, handleSubmit, formState: { isSubmitting }, setValue,
  } = useFormContext();

  const models = useAutocomplete(List.Models);
  const strollerParts = useAutocomplete(List.StrollerParts);

  const {
    languages: {
      fetchLanguages,
      items,
    },
  } = useStore();

  const handleChangeSelectValue = useCallback((name: FieldPath<SparePartValues>) => (_,
    data: Option | null): void => {
    setValue(name, data?.value || DEFAULT_AUTOCOMPLETE_ID);
  }, []);

  useEffect(() => {
    fetchLanguages();
  }, []);

  const isReady = !!items
    && !!models.length
    && !!strollerParts.length;

  return isReady ? (
    <form onSubmit={handleSubmit(onAction)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Controller
                name="name"
                control={control}
                render={({
                  field: { onBlur, onChange, value }, fieldState: { error },
                }): JSX.Element => (
                  <TextField
                    fullWidth
                    label="Name"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="part.id"
                control={control}
                render={({
                  field: { value }, fieldState: { error },
                }): JSX.Element => (
                  <Autocomplete
                    autoComplete={false}
                    disableClearable
                    fullWidth
                    value={getOption(strollerParts, value)}
                    options={strollerParts}
                    onChange={handleChangeSelectValue('part.id')}
                    getOptionLabel={(option: Option): string => option.label}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        {...params}
                        label="Stroller part"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="model.id"
                control={control}
                render={({
                  field: { value }, fieldState: { error },
                }): JSX.Element => (
                  <Autocomplete
                    autoComplete={false}
                    disableClearable
                    fullWidth
                    value={getOption(models, value)}
                    options={models}
                    onChange={handleChangeSelectValue('model.id')}
                    getOptionLabel={(option: Option): string => option.label}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        {...params}
                        label="Model"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="price"
                control={control}
                render={({
                  field: { onBlur, onChange, value }, fieldState: { error },
                }): JSX.Element => (
                  <TextField
                    fullWidth
                    label="Price"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              {dateUpdatingPrice && (
              <TextField
                fullWidth
                label="Date of updating price"
                value={fIso8601(dateUpdatingPrice)}
                disabled
              />
              )}
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  ) : (<LoadingScreen />);
});
