import { initializeApp } from 'firebase/app';
import {
  getToken,
  onMessage,
  getMessaging,
  MessagePayload,
} from 'firebase/messaging';
import { setItemToStorage } from './utils/localStorage';
import { LOCAL_STORAGE_DEVICE_TOKEN } from './constants';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// eslint-disable-next-line max-len
export const onMessageListener = (): Promise<MessagePayload> => new Promise<MessagePayload>((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});

const publicKey = process.env.REACT_APP_PUBLIC_KEY;

export const generateToken = async (
  setToken: (value: string) => void,
): Promise<void> => getToken(messaging, { vapidKey: publicKey }).then((currentToken) => {
  if (currentToken) {
    setToken(currentToken);
    setItemToStorage(LOCAL_STORAGE_DEVICE_TOKEN, currentToken);
    // Track the token -> client mapping, by sending to backend server
    // show on the UI that permission is secured
  } else {
    console.log('No registration token available. Request permission to generate one.');
    setToken('');
    // shows on the UI that permission is required
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // catch error while creating client token
});
