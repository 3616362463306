import React from 'react';
import { motion } from 'framer-motion';
import { Box, BoxProps } from '@material-ui/core';
import { varWrapEnter } from './variants';

interface MotionContainerProps extends BoxProps {
  initial?: boolean | string;
  open?: boolean;
}

export const MotionContainer:React.FC<MotionContainerProps> = ({ open, children, ...other }) => (
  <Box
    component={motion.div}
    initial={false}
    animate={open ? 'animate' : 'exit'}
    variants={varWrapEnter}
    {...other}
  >
    {children}
  </Box>
);
