import React, { useCallback, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { HeaderBreadcrumbs, LoadingScreen, Page } from '../../../components';
import { PATH_DASHBOARD } from '../../../app';
import { Form } from '../Form';
import { Link, StrollerPartValues } from '../../../types';
import rules from '../Form/rules';
import { useStore } from '../../../hooks';

const LINKS: Link[] = [
  { name: 'Dashboard', href: PATH_DASHBOARD.root },
  { name: 'Stroller parts', href: PATH_DASHBOARD.strollerParts.list },
  { name: 'New' },
];

export const CreateStrollerPart: React.FC = observer((): JSX.Element => {
  const {
    languages: {
      items,
      fetchLanguages,
    },
    strollerParts: {
      createStrollerPart,
    },
  } = useStore();

  const { push } = useHistory();

  const form = useForm<StrollerPartValues>({
    defaultValues: {
      name: '',
      ordering: 0,
      locales: items.map((language) => ({
        name: '',
        language: language.url,
      })),
    },
    resolver: yupResolver(rules),
  });

  useEffect(() => {
    if (!items.length) {
      fetchLanguages();
    }
    if (items.length) {
      form.reset({
        name: '',
        ordering: 0,
        locales: items.map((language) => ({
          name: '',
          language: language.url,
        })),
      });
    }
  }, [items.length]);

  const handleCreate = useCallback(async (values: StrollerPartValues): Promise<void> => {
    await createStrollerPart(values);
    push(PATH_DASHBOARD.strollerParts.list);
  }, []);

  return items.length ? (
    <Page title="Create stroller part">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Create stroller part"
          links={LINKS}
        />
        <FormProvider {...form}>
          <Form onAction={handleCreate} />
        </FormProvider>
      </Container>
    </Page>
  ) : (<LoadingScreen />);
});
