import React from 'react';
import { useVerifyToken } from '../hooks';
import { LoadingScreen, ErrorPage } from '../../../components';
import { TokenType } from '../../../types';

type VerifyingWrapperProps = {
    tokenType: TokenType,
    children: React.ReactNode,
}

const ERROR_PROPS = {
  title: 'Error with verifying',
  description: 'Your token wasn\'t verified successfully',
  goBack: false,
};

export const VerifyingWrapper: React.FC<VerifyingWrapperProps> = ({
  tokenType,
  children,
}) => {
  const { isCorrectToken, isPending } = useVerifyToken(tokenType);
  if (isPending) return <LoadingScreen />;
  if (!isCorrectToken) return <ErrorPage {...ERROR_PROPS} />;
  return <>{children}</>;
};
